import * as Types from './graphql-sdk';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {
    context: { endpoint: '/apps/process/graphql_multipart' },
} as const;
export const MultipartDealContentMetadataFragmentDoc = gql`
    fragment MultipartDealContentMetadata on DealContentMetadataQL {
        id
        type
        source
        name
        hasContent
        publishedDate
        creationSource
    }
`;
export const GqlOperationRequestDealImportDocument = gql`
    mutation RequestDealImport(
        $targetPipelineId: String!
        $externalSourceUpload: Upload
        $externalSourceUrl: String
    ) {
        requestDealImport(
            targetPipelineId: $targetPipelineId
            externalSourceUrl: $externalSourceUrl
            externalSourceUpload: $externalSourceUpload
        ) {
            ok
            uploadSize
        }
    }
`;
export type RequestDealImportMutationFn = Apollo.MutationFunction<
    Types.RequestDealImportMutation,
    Types.RequestDealImportMutationVariables
>;

/**
 * __useRequestDealImportMutation__
 *
 * To run a mutation, you first call `useRequestDealImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDealImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDealImportMutation, { data, loading, error }] = useRequestDealImportMutation({
 *   variables: {
 *      targetPipelineId: // value for 'targetPipelineId'
 *      externalSourceUpload: // value for 'externalSourceUpload'
 *      externalSourceUrl: // value for 'externalSourceUrl'
 *   },
 * });
 */
export function useRequestDealImportMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.RequestDealImportMutation,
        Types.RequestDealImportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.RequestDealImportMutation,
        Types.RequestDealImportMutationVariables
    >(GqlOperationRequestDealImportDocument, options);
}
export type RequestDealImportMutationHookResult = ReturnType<
    typeof useRequestDealImportMutation
>;
export type RequestDealImportMutationResult =
    Apollo.MutationResult<Types.RequestDealImportMutation>;
export type RequestDealImportMutationOptions = Apollo.BaseMutationOptions<
    Types.RequestDealImportMutation,
    Types.RequestDealImportMutationVariables
>;
export const GqlOperationUploadDealAttachmentDocument = gql`
    mutation UploadDealAttachment(
        $dealId: String!
        $file: Upload
        $url: String
    ) {
        uploadDealAttachment(dealId: $dealId, file: $file, url: $url) {
            ...MultipartDealContentMetadata
        }
    }
    ${MultipartDealContentMetadataFragmentDoc}
`;
export type UploadDealAttachmentMutationFn = Apollo.MutationFunction<
    Types.UploadDealAttachmentMutation,
    Types.UploadDealAttachmentMutationVariables
>;

/**
 * __useUploadDealAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadDealAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDealAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDealAttachmentMutation, { data, loading, error }] = useUploadDealAttachmentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      file: // value for 'file'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUploadDealAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UploadDealAttachmentMutation,
        Types.UploadDealAttachmentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UploadDealAttachmentMutation,
        Types.UploadDealAttachmentMutationVariables
    >(GqlOperationUploadDealAttachmentDocument, options);
}
export type UploadDealAttachmentMutationHookResult = ReturnType<
    typeof useUploadDealAttachmentMutation
>;
export type UploadDealAttachmentMutationResult =
    Apollo.MutationResult<Types.UploadDealAttachmentMutation>;
export type UploadDealAttachmentMutationOptions = Apollo.BaseMutationOptions<
    Types.UploadDealAttachmentMutation,
    Types.UploadDealAttachmentMutationVariables
>;
export const GqlOperationCreateDealFromExternalSourceDocument = gql`
    mutation CreateDealFromExternalSource(
        $pipelineId: String!
        $file: Upload
        $url: String
    ) {
        createDealFromExternalSource(
            pipelineId: $pipelineId
            file: $file
            url: $url
        ) {
            dealId
        }
    }
`;
export type CreateDealFromExternalSourceMutationFn = Apollo.MutationFunction<
    Types.CreateDealFromExternalSourceMutation,
    Types.CreateDealFromExternalSourceMutationVariables
>;

/**
 * __useCreateDealFromExternalSourceMutation__
 *
 * To run a mutation, you first call `useCreateDealFromExternalSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealFromExternalSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealFromExternalSourceMutation, { data, loading, error }] = useCreateDealFromExternalSourceMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      file: // value for 'file'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateDealFromExternalSourceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateDealFromExternalSourceMutation,
        Types.CreateDealFromExternalSourceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateDealFromExternalSourceMutation,
        Types.CreateDealFromExternalSourceMutationVariables
    >(GqlOperationCreateDealFromExternalSourceDocument, options);
}
export type CreateDealFromExternalSourceMutationHookResult = ReturnType<
    typeof useCreateDealFromExternalSourceMutation
>;
export type CreateDealFromExternalSourceMutationResult =
    Apollo.MutationResult<Types.CreateDealFromExternalSourceMutation>;
export type CreateDealFromExternalSourceMutationOptions =
    Apollo.BaseMutationOptions<
        Types.CreateDealFromExternalSourceMutation,
        Types.CreateDealFromExternalSourceMutationVariables
    >;
