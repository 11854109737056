import * as Types from './graphql-sdk';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = { context: { endpoint: '/apps/user/graphql' } } as const;
export const SettingsPartFragmentDoc = gql`
    fragment SettingsPart on UserSettingsQL {
        defaultPipeline
        csvDelimiter
        emailCommentMentions
        emailDigest
        emailPausedDealNotifications
        emailReminders
        emailDealAssigneeChange
        pipelineViewPreferencesAssignees
        emailDealImportCompleted
        pipelineViewPreferencesCustomParameterTag
        pipelineViewPreferencesShowPausedDeals
        pipelineViewPreferencesVisualizationMode
        pipelineViewPreferencesPaginationRows
        pipelineViewPreferencesSorting {
            field
            sop
        }
        pipelineViewPreferencesStageIds
        optInAchievements
        outreachBlockIds
        outreachCallBlockIds
    }
`;
export const UserPartFragmentDoc = gql`
    fragment UserPart on SessionUserWithExtraAttribsQL {
        active
        email
        featureToggles
        firstName
        isCsManager
        language
        itemId
        roles
        lastName
        searchOffset
        loginMethod
        jobRoles
        impersonatorInfo {
            itemId
            email
        }
        colleagues {
            roles
            lastName
            itemId
            firstName
            email
            active
        }
        company {
            name
            itemId
            settings {
                currency
                merging
                preScreening
                useAppDotBr
            }
        }
        settings {
            ...SettingsPart
        }
        searches {
            itemId
            name
            searchData {
                filterModules {
                    filter
                    key
                    value
                }
            }
            type
            userId
        }
    }
    ${SettingsPartFragmentDoc}
`;
export const UserApiTokenFragmentDoc = gql`
    fragment UserApiToken on UserApiTokenQL {
        permissionLevel
        created
        id
        last4CharactersToken
        name
        plainTextToken
        permissionLevel
        prefix
        updated
        userId
    }
`;
export const GqlOperationGetCurrentUserDocument = gql`
    query GetCurrentUser {
        currentUser {
            ...UserPart
        }
    }
    ${UserPartFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetCurrentUserQuery,
        Types.GetCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCurrentUserQuery,
        Types.GetCurrentUserQueryVariables
    >(GqlOperationGetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCurrentUserQuery,
        Types.GetCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCurrentUserQuery,
        Types.GetCurrentUserQueryVariables
    >(GqlOperationGetCurrentUserDocument, options);
}
export function useGetCurrentUserSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCurrentUserQuery,
              Types.GetCurrentUserQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCurrentUserQuery,
        Types.GetCurrentUserQueryVariables
    >(GqlOperationGetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
    typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
    Types.GetCurrentUserQuery,
    Types.GetCurrentUserQueryVariables
>;
export const GqlOperationGetUserApiTokensDocument = gql`
    query GetUserApiTokens {
        userApiTokens {
            ...UserApiToken
        }
    }
    ${UserApiTokenFragmentDoc}
`;

/**
 * __useGetUserApiTokensQuery__
 *
 * To run a query within a React component, call `useGetUserApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserApiTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserApiTokensQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetUserApiTokensQuery,
        Types.GetUserApiTokensQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetUserApiTokensQuery,
        Types.GetUserApiTokensQueryVariables
    >(GqlOperationGetUserApiTokensDocument, options);
}
export function useGetUserApiTokensLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetUserApiTokensQuery,
        Types.GetUserApiTokensQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetUserApiTokensQuery,
        Types.GetUserApiTokensQueryVariables
    >(GqlOperationGetUserApiTokensDocument, options);
}
export function useGetUserApiTokensSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetUserApiTokensQuery,
              Types.GetUserApiTokensQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetUserApiTokensQuery,
        Types.GetUserApiTokensQueryVariables
    >(GqlOperationGetUserApiTokensDocument, options);
}
export type GetUserApiTokensQueryHookResult = ReturnType<
    typeof useGetUserApiTokensQuery
>;
export type GetUserApiTokensLazyQueryHookResult = ReturnType<
    typeof useGetUserApiTokensLazyQuery
>;
export type GetUserApiTokensSuspenseQueryHookResult = ReturnType<
    typeof useGetUserApiTokensSuspenseQuery
>;
export type GetUserApiTokensQueryResult = Apollo.QueryResult<
    Types.GetUserApiTokensQuery,
    Types.GetUserApiTokensQueryVariables
>;
export const GqlOperationLoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
        loginWithStatus(credentials: { email: $email, password: $password }) {
            status
            user {
                ...UserPart
            }
        }
    }
    ${UserPartFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
    Types.LoginMutation,
    Types.LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.LoginMutation,
        Types.LoginMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.LoginMutation,
        Types.LoginMutationVariables
    >(GqlOperationLoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
    Types.LoginMutation,
    Types.LoginMutationVariables
>;
export const GqlOperationImpersonateUserDocument = gql`
    mutation ImpersonateUser($email: String!) {
        adminImpersonateUser(input: { email: $email }) {
            user {
                ...UserPart
            }
        }
    }
    ${UserPartFragmentDoc}
`;
export type ImpersonateUserMutationFn = Apollo.MutationFunction<
    Types.ImpersonateUserMutation,
    Types.ImpersonateUserMutationVariables
>;

/**
 * __useImpersonateUserMutation__
 *
 * To run a mutation, you first call `useImpersonateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserMutation, { data, loading, error }] = useImpersonateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useImpersonateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ImpersonateUserMutation,
        Types.ImpersonateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ImpersonateUserMutation,
        Types.ImpersonateUserMutationVariables
    >(GqlOperationImpersonateUserDocument, options);
}
export type ImpersonateUserMutationHookResult = ReturnType<
    typeof useImpersonateUserMutation
>;
export type ImpersonateUserMutationResult =
    Apollo.MutationResult<Types.ImpersonateUserMutation>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<
    Types.ImpersonateUserMutation,
    Types.ImpersonateUserMutationVariables
>;
export const GqlOperationCreateUserApiTokenDocument = gql`
    mutation CreateUserApiToken(
        $name: String!
        $apiPermissionLevel: ApiPermissionLevel = READ
    ) {
        createUserApiToken(
            input: { name: $name, permissionLevel: $apiPermissionLevel }
        ) {
            ...UserApiToken
        }
    }
    ${UserApiTokenFragmentDoc}
`;
export type CreateUserApiTokenMutationFn = Apollo.MutationFunction<
    Types.CreateUserApiTokenMutation,
    Types.CreateUserApiTokenMutationVariables
>;

/**
 * __useCreateUserApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateUserApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserApiTokenMutation, { data, loading, error }] = useCreateUserApiTokenMutation({
 *   variables: {
 *      name: // value for 'name'
 *      apiPermissionLevel: // value for 'apiPermissionLevel'
 *   },
 * });
 */
export function useCreateUserApiTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateUserApiTokenMutation,
        Types.CreateUserApiTokenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateUserApiTokenMutation,
        Types.CreateUserApiTokenMutationVariables
    >(GqlOperationCreateUserApiTokenDocument, options);
}
export type CreateUserApiTokenMutationHookResult = ReturnType<
    typeof useCreateUserApiTokenMutation
>;
export type CreateUserApiTokenMutationResult =
    Apollo.MutationResult<Types.CreateUserApiTokenMutation>;
export type CreateUserApiTokenMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateUserApiTokenMutation,
    Types.CreateUserApiTokenMutationVariables
>;
export const GqlOperationDeleteUserApiTokenDocument = gql`
    mutation DeleteUserApiToken($id: Int!) {
        deleteUserApiToken(userApiTokenId: $id) {
            ok
        }
    }
`;
export type DeleteUserApiTokenMutationFn = Apollo.MutationFunction<
    Types.DeleteUserApiTokenMutation,
    Types.DeleteUserApiTokenMutationVariables
>;

/**
 * __useDeleteUserApiTokenMutation__
 *
 * To run a mutation, you first call `useDeleteUserApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserApiTokenMutation, { data, loading, error }] = useDeleteUserApiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserApiTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteUserApiTokenMutation,
        Types.DeleteUserApiTokenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteUserApiTokenMutation,
        Types.DeleteUserApiTokenMutationVariables
    >(GqlOperationDeleteUserApiTokenDocument, options);
}
export type DeleteUserApiTokenMutationHookResult = ReturnType<
    typeof useDeleteUserApiTokenMutation
>;
export type DeleteUserApiTokenMutationResult =
    Apollo.MutationResult<Types.DeleteUserApiTokenMutation>;
export type DeleteUserApiTokenMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteUserApiTokenMutation,
    Types.DeleteUserApiTokenMutationVariables
>;
export const GqlOperationupdateUserSettingDocument = gql`
    mutation updateUserSetting($setting: UserSettingName!, $value: JSON!) {
        updateUserSetting(input: { setting: $setting, value: $value }) {
            ok
        }
    }
`;
export type UpdateUserSettingMutationFn = Apollo.MutationFunction<
    Types.UpdateUserSettingMutation,
    Types.UpdateUserSettingMutationVariables
>;

/**
 * __useUpdateUserSettingMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingMutation, { data, loading, error }] = useUpdateUserSettingMutation({
 *   variables: {
 *      setting: // value for 'setting'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateUserSettingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateUserSettingMutation,
        Types.UpdateUserSettingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateUserSettingMutation,
        Types.UpdateUserSettingMutationVariables
    >(GqlOperationupdateUserSettingDocument, options);
}
export type UpdateUserSettingMutationHookResult = ReturnType<
    typeof useUpdateUserSettingMutation
>;
export type UpdateUserSettingMutationResult =
    Apollo.MutationResult<Types.UpdateUserSettingMutation>;
export type UpdateUserSettingMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateUserSettingMutation,
    Types.UpdateUserSettingMutationVariables
>;
export const GqlOperationUpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($language: UserLanguage!) {
        updateUserLanguage(language: $language) {
            ok
        }
    }
`;
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<
    Types.UpdateUserLanguageMutation,
    Types.UpdateUserLanguageMutationVariables
>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateUserLanguageMutation,
        Types.UpdateUserLanguageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateUserLanguageMutation,
        Types.UpdateUserLanguageMutationVariables
    >(GqlOperationUpdateUserLanguageDocument, options);
}
export type UpdateUserLanguageMutationHookResult = ReturnType<
    typeof useUpdateUserLanguageMutation
>;
export type UpdateUserLanguageMutationResult =
    Apollo.MutationResult<Types.UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateUserLanguageMutation,
    Types.UpdateUserLanguageMutationVariables
>;
export const GqlOperationLogoutDocument = gql`
    mutation Logout {
        logout {
            ok
        }
    }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
    Types.LogoutMutation,
    Types.LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.LogoutMutation,
        Types.LogoutMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.LogoutMutation,
        Types.LogoutMutationVariables
    >(GqlOperationLogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<Types.LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    Types.LogoutMutation,
    Types.LogoutMutationVariables
>;
export const GqlOperationSendPasswordResetDocument = gql`
    mutation SendPasswordReset($email: String!) {
        sendPasswordReset(email: $email) {
            ok
        }
    }
`;
export type SendPasswordResetMutationFn = Apollo.MutationFunction<
    Types.SendPasswordResetMutation,
    Types.SendPasswordResetMutationVariables
>;

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SendPasswordResetMutation,
        Types.SendPasswordResetMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SendPasswordResetMutation,
        Types.SendPasswordResetMutationVariables
    >(GqlOperationSendPasswordResetDocument, options);
}
export type SendPasswordResetMutationHookResult = ReturnType<
    typeof useSendPasswordResetMutation
>;
export type SendPasswordResetMutationResult =
    Apollo.MutationResult<Types.SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    Types.SendPasswordResetMutation,
    Types.SendPasswordResetMutationVariables
>;
export const GqlOperationSetXsrfTokenDocument = gql`
    mutation SetXSRFToken {
        setXsrfToken {
            ok
        }
    }
`;
export type SetXsrfTokenMutationFn = Apollo.MutationFunction<
    Types.SetXsrfTokenMutation,
    Types.SetXsrfTokenMutationVariables
>;

/**
 * __useSetXsrfTokenMutation__
 *
 * To run a mutation, you first call `useSetXsrfTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetXsrfTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setXsrfTokenMutation, { data, loading, error }] = useSetXsrfTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetXsrfTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SetXsrfTokenMutation,
        Types.SetXsrfTokenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SetXsrfTokenMutation,
        Types.SetXsrfTokenMutationVariables
    >(GqlOperationSetXsrfTokenDocument, options);
}
export type SetXsrfTokenMutationHookResult = ReturnType<
    typeof useSetXsrfTokenMutation
>;
export type SetXsrfTokenMutationResult =
    Apollo.MutationResult<Types.SetXsrfTokenMutation>;
export type SetXsrfTokenMutationOptions = Apollo.BaseMutationOptions<
    Types.SetXsrfTokenMutation,
    Types.SetXsrfTokenMutationVariables
>;
export const GqlOperationUpdatePasswordDocument = gql`
    mutation UpdatePassword(
        $oldPassword: String!
        $newPassword: String!
        $repeatNewPassword: String!
    ) {
        updatePassword(
            oldPassword: $oldPassword
            newPassword: $newPassword
            repeatNewPassword: $repeatNewPassword
        ) {
            ok
        }
    }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      repeatNewPassword: // value for 'repeatNewPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdatePasswordMutation,
        Types.UpdatePasswordMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdatePasswordMutation,
        Types.UpdatePasswordMutationVariables
    >(GqlOperationUpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<
    typeof useUpdatePasswordMutation
>;
export type UpdatePasswordMutationResult =
    Apollo.MutationResult<Types.UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdatePasswordMutation,
    Types.UpdatePasswordMutationVariables
>;
export const GqlOperationUpdatePasswordFromResetTokenDocument = gql`
    mutation UpdatePasswordFromResetToken(
        $newPassword: String!
        $token: String!
    ) {
        updatePasswordFromResetToken(newPassword: $newPassword, token: $token) {
            ok
        }
    }
`;
export type UpdatePasswordFromResetTokenMutationFn = Apollo.MutationFunction<
    Types.UpdatePasswordFromResetTokenMutation,
    Types.UpdatePasswordFromResetTokenMutationVariables
>;

/**
 * __useUpdatePasswordFromResetTokenMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordFromResetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordFromResetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordFromResetTokenMutation, { data, loading, error }] = useUpdatePasswordFromResetTokenMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdatePasswordFromResetTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdatePasswordFromResetTokenMutation,
        Types.UpdatePasswordFromResetTokenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdatePasswordFromResetTokenMutation,
        Types.UpdatePasswordFromResetTokenMutationVariables
    >(GqlOperationUpdatePasswordFromResetTokenDocument, options);
}
export type UpdatePasswordFromResetTokenMutationHookResult = ReturnType<
    typeof useUpdatePasswordFromResetTokenMutation
>;
export type UpdatePasswordFromResetTokenMutationResult =
    Apollo.MutationResult<Types.UpdatePasswordFromResetTokenMutation>;
export type UpdatePasswordFromResetTokenMutationOptions =
    Apollo.BaseMutationOptions<
        Types.UpdatePasswordFromResetTokenMutation,
        Types.UpdatePasswordFromResetTokenMutationVariables
    >;
