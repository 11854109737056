export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
};

export type AddressQl = {
    __typename?: 'AddressQL';
    city?: Maybe<Scalars['String']['output']>;
    continent?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    houseNumber?: Maybe<Scalars['String']['output']>;
    lat?: Maybe<Scalars['Float']['output']>;
    lng?: Maybe<Scalars['Float']['output']>;
    postCode?: Maybe<Scalars['String']['output']>;
    postCodeEnd?: Maybe<Scalars['String']['output']>;
    postCodeIsExact?: Maybe<Scalars['Boolean']['output']>;
    postCodeStart?: Maybe<Scalars['String']['output']>;
    precisionAccuracy?: Maybe<Scalars['Float']['output']>;
    precisionLevel?: Maybe<Scalars['String']['output']>;
    precisionType?: Maybe<Scalars['String']['output']>;
    rank?: Maybe<Scalars['Float']['output']>;
    road?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
};

export type ArticleDocumentQl = {
    __typename?: 'ArticleDocumentQL';
    abstract?: Maybe<Scalars['String']['output']>;
    address?: Maybe<AddressQl>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    paragraphs?: Maybe<Array<Scalars['String']['output']>>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export enum BrTimePrecision {
    Day = 'DAY',
    HourUtc = 'HOUR_UTC',
    McsUtc = 'MCS_UTC',
    MinUtc = 'MIN_UTC',
    Month = 'MONTH',
    SecUtc = 'SEC_UTC',
    Year = 'YEAR',
}

export type BrTimeQl = {
    __typename?: 'BRTimeQL';
    day?: Maybe<Scalars['Int']['output']>;
    hour?: Maybe<Scalars['Int']['output']>;
    microsecond?: Maybe<Scalars['Int']['output']>;
    minute?: Maybe<Scalars['Int']['output']>;
    month?: Maybe<Scalars['Int']['output']>;
    precision: BrTimePrecision;
    second?: Maybe<Scalars['Int']['output']>;
    year: Scalars['Int']['output'];
};

export enum CategoryNames {
    Agriculture = 'AGRICULTURE',
    Commercial = 'COMMERCIAL',
    CulturalEvent = 'CULTURAL_EVENT',
    EducationScience = 'EDUCATION_SCIENCE',
    Health = 'HEALTH',
    HistoricalReligious = 'HISTORICAL_RELIGIOUS',
    Hospitality = 'HOSPITALITY',
    Industrial = 'INDUSTRIAL',
    Infrastructure = 'INFRASTRUCTURE',
    Landscaping = 'LANDSCAPING',
    Office = 'OFFICE',
    PublicServiceAdministration = 'PUBLIC_SERVICE_ADMINISTRATION',
    Residential = 'RESIDENTIAL',
    Sport = 'SPORT',
}

export type CategoryQl = {
    __typename?: 'CategoryQL';
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subCategories?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactQl = {
    __typename?: 'ContactQL';
    address?: Maybe<AddressQl>;
    associatedItemId?: Maybe<Scalars['String']['output']>;
    companyName?: Maybe<Scalars['String']['output']>;
    companyRoles?: Maybe<Array<Scalars['String']['output']>>;
    email?: Maybe<Scalars['String']['output']>;
    fax?: Maybe<Scalars['String']['output']>;
    homepage?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    personRoles?: Maybe<Array<Scalars['String']['output']>>;
    phoneLandline?: Maybe<Scalars['String']['output']>;
    phoneMobile?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type CountSearchByFilterModulesInputQl = {
    filterModules: Array<FilterModuleForSearchExecQl>;
    searchType: SearchType;
};

export type CountSearchByFilterModulesResultQl = {
    __typename?: 'CountSearchByFilterModulesResultQL';
    totalCount: Scalars['Int']['output'];
};

export type DocGroupWithFolderTagQl = {
    __typename?: 'DocGroupWithFolderTagQL';
    created: Scalars['DateTime']['output'];
    documentIds: Array<Scalars['String']['output']>;
    documents: Array<TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl>;
    folder?: Maybe<FolderTag>;
    id: Scalars['String']['output'];
    mergedDocument: TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl;
    pipeDbCollection: Scalars['String']['output'];
    updated: Scalars['DateTime']['output'];
};

export type FilterModuleForSearchExecQl = {
    data: Scalars['JSON']['input'];
    moduleId: Scalars['Int']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterModuleQl = {
    __typename?: 'FilterModuleQL';
    companyId: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    data: Scalars['JSON']['output'];
    id: Scalars['Int']['output'];
    linkedSearchIds: Array<Scalars['Int']['output']>;
    name: Scalars['String']['output'];
    notReusable: Scalars['Boolean']['output'];
    type: FilterModuleType;
    updated: Scalars['DateTime']['output'];
    userId?: Maybe<Scalars['Int']['output']>;
};

export enum FilterModuleType {
    Article = 'ARTICLE',
    Generic = 'GENERIC',
    Project = 'PROJECT',
    Tender = 'TENDER',
}

export enum FolderTag {
    AllExported = 'ALL_EXPORTED',
    CompEnrich = 'COMP_ENRICH',
    Done = 'DONE',
    Duplicate = 'DUPLICATE',
    ExportedAsCsv = 'EXPORTED_AS_CSV',
    ExportedAsPdf = 'EXPORTED_AS_PDF',
    ExportedToCrm = 'EXPORTED_TO_CRM',
    Inbox = 'INBOX',
    Interesting = 'INTERESTING',
    NotInteresting = 'NOT_INTERESTING',
    PreInbox = 'PRE_INBOX',
    PreScreening = 'PRE_SCREENING',
    Resubmitted = 'RESUBMITTED',
    Snoozed = 'SNOOZED',
}

export type ImageQl = {
    __typename?: 'ImageQL';
    qualityScore?: Maybe<Scalars['Float']['output']>;
    sourceDomain: Scalars['String']['output'];
    sourceParseId?: Maybe<Scalars['String']['output']>;
    sourceTitle?: Maybe<Scalars['String']['output']>;
    sourceUrl: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type LegacyProjectDocumentQl = {
    __typename?: 'LegacyProjectDocumentQL';
    address?: Maybe<AddressQl>;
    awards?: Maybe<Array<Scalars['String']['output']>>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    height?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    materials?: Maybe<Scalars['String']['output']>;
    nFloors?: Maybe<Scalars['Int']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    nRooms?: Maybe<Scalars['Int']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type LinkedFilterModuleQl = {
    __typename?: 'LinkedFilterModuleQL';
    filter: Scalars['String']['output'];
    key: Scalars['Int']['output'];
    value: Scalars['String']['output'];
};

export type Mutation = {
    __typename?: 'Mutation';
    createFilterModule: FilterModuleQl;
    debug: ResultQl;
    deleteFilterModule: ResultQl;
    deleteSearch: ResultQl;
    pauseSearch: ResultQl;
    subscribeSearch: SearchQl;
    unpauseSearch: ResultQl;
    unsubscribeSearch: ResultQl;
    updateFilterModule: ResultQl;
    updateSearchPrescreeningSettings: ResultQl;
    upsertSearch: SearchIdQl;
};

export type MutationCreateFilterModuleArgs = {
    data: Scalars['JSON']['input'];
    filterModuleType: FilterModuleType;
    name: Scalars['String']['input'];
    notReusable: Scalars['Boolean']['input'];
};

export type MutationDeleteFilterModuleArgs = {
    filterModuleId: Scalars['Int']['input'];
};

export type MutationDeleteSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationPauseSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationSubscribeSearchArgs = {
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
};

export type MutationUnpauseSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationUnsubscribeSearchArgs = {
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
};

export type MutationUpdateFilterModuleArgs = {
    data: Scalars['JSON']['input'];
    filterModuleId: Scalars['Int']['input'];
    name: Scalars['String']['input'];
    notReusable: Scalars['Boolean']['input'];
};

export type MutationUpdateSearchPrescreeningSettingsArgs = {
    autoPrescreeningEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    autoPrescreeningInboxLimit?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    searchId: Scalars['Int']['input'];
};

export type MutationUpsertSearchArgs = {
    filterModuleIds: Array<Scalars['Int']['input']>;
    searchId?: InputMaybe<Scalars['Int']['input']>;
    searchName: Scalars['String']['input'];
    searchType: SearchType;
};

export type PaginationConfQl = {
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PhaseQl = {
    __typename?: 'PhaseQL';
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subPhases?: Maybe<Array<Scalars['String']['output']>>;
};

export type PrescreenOutputQl = {
    __typename?: 'PrescreenOutputQL';
    accept: Scalars['Boolean']['output'];
    confidence: Scalars['Int']['output'];
};

export type PrescreeningDocGroupWithFolderTagQl = {
    __typename?: 'PrescreeningDocGroupWithFolderTagQL';
    created: Scalars['DateTime']['output'];
    documentIds: Array<Scalars['String']['output']>;
    documents: Array<TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl>;
    folder?: Maybe<FolderTag>;
    id: Scalars['String']['output'];
    mergedDocument: TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl;
    pipeDbCollection: Scalars['String']['output'];
    prescreenOutput: PrescreenOutputQl;
    updated: Scalars['DateTime']['output'];
};

export type Query = {
    __typename?: 'Query';
    autocompleteSourceDomains: Array<Scalars['String']['output']>;
    countSearchByFilterModules: CountSearchByFilterModulesResultQl;
    debug: ResultQl;
    filterModules: Array<FilterModuleQl>;
    locationSuggestions: Array<SuggestedLocationResultQl>;
    searchByFilterModules: SearchByFilterModulesResultQl;
    searchWithPrescreening: SearchWithPrescreeningResultQl;
    searchesAndSubscriptions: Array<SearchAndSubscribersQl>;
    tenderChildCodes: Array<TenderCodeNodeQl>;
    tenderCodeTree: Array<TenderCodeRootNodeResultQl>;
    tenderCodesFiltered: Array<TenderCodeRootNodeResultQl>;
    tenderTextFromCodes: Array<Scalars['String']['output']>;
};

export type QueryAutocompleteSourceDomainsArgs = {
    partialString: Scalars['String']['input'];
};

export type QueryCountSearchByFilterModulesArgs = {
    days?: Scalars['Int']['input'];
    searchInput: CountSearchByFilterModulesInputQl;
};

export type QueryFilterModulesArgs = {
    filterModuleId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLocationSuggestionsArgs = {
    partialString: Scalars['String']['input'];
};

export type QuerySearchByFilterModulesArgs = {
    searchInput: SearchByFilterModulesInputQl;
};

export type QuerySearchWithPrescreeningArgs = {
    narrative: Scalars['String']['input'];
    searchInput: SearchByFilterModulesInputQl;
};

export type QueryTenderChildCodesArgs = {
    tenderCodeId: Scalars['String']['input'];
};

export type QueryTenderCodeTreeArgs = {
    tenderCodeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryTenderCodesFilteredArgs = {
    procurementSystem?: InputMaybe<TenderCodeSystem>;
    textSearch: Scalars['String']['input'];
};

export type QueryTenderTextFromCodesArgs = {
    cpvCodes: Array<Scalars['String']['input']>;
    naicsCodes: Array<Scalars['String']['input']>;
    unspscCodes: Array<Scalars['String']['input']>;
};

export type ResultQl = {
    __typename?: 'ResultQL';
    ok: Scalars['Boolean']['output'];
};

export type SearchAndSubscribersQl = {
    __typename?: 'SearchAndSubscribersQL';
    autoPrescreeningEnabled: Scalars['Boolean']['output'];
    autoPrescreeningInboxLimit: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    itemId: Scalars['Int']['output'];
    lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    paused: Scalars['Boolean']['output'];
    searchData: SearchDataQl;
    subscriberIds: Array<Scalars['Int']['output']>;
    type: SearchType;
    userCompanyId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export type SearchByFilterModulesInputQl = {
    filterModules: Array<FilterModuleForSearchExecQl>;
    paginationConf?: InputMaybe<PaginationConfQl>;
    searchType: SearchType;
};

export type SearchByFilterModulesResultQl = {
    __typename?: 'SearchByFilterModulesResultQL';
    docGroups: Array<DocGroupWithFolderTagQl>;
    totalCount: Scalars['Int']['output'];
};

export type SearchDataQl = {
    __typename?: 'SearchDataQL';
    filterModules: Array<LinkedFilterModuleQl>;
};

export type SearchIdQl = {
    __typename?: 'SearchIdQL';
    id: Scalars['Int']['output'];
};

export type SearchQl = {
    __typename?: 'SearchQL';
    autoPrescreeningEnabled: Scalars['Boolean']['output'];
    autoPrescreeningInboxLimit: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    itemId: Scalars['Int']['output'];
    lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    paused: Scalars['Boolean']['output'];
    searchData: SearchDataQl;
    type: SearchType;
    userCompanyId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export type SearchResultQl = {
    __typename?: 'SearchResultQL';
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    itemId: Scalars['String']['output'];
    links: Array<Scalars['String']['output']>;
    publishedDate: Scalars['DateTime']['output'];
    state: Scalars['String']['output'];
    summary: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    typeOfProject: Array<CategoryNames>;
};

export type SearchResultResponseQl = {
    __typename?: 'SearchResultResponseQL';
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    searchId: Scalars['String']['output'];
    searchResult: SearchResultQl;
    state: Scalars['String']['output'];
    typeOfProject: Array<CategoryNames>;
};

export enum SearchType {
    Article = 'ARTICLE',
    Tender = 'TENDER',
}

export type SearchWithPrescreeningResultQl = {
    __typename?: 'SearchWithPrescreeningResultQL';
    docGroups: Array<PrescreeningDocGroupWithFolderTagQl>;
    totalCount: Scalars['Int']['output'];
};

export type Subscription = {
    __typename?: 'Subscription';
    neuralSearchSearch: SearchResultResponseQl;
};

export type SubscriptionNeuralSearchSearchArgs = {
    endDate?: InputMaybe<Scalars['DateTime']['input']>;
    prompt: Scalars['String']['input'];
    startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SuggestedLocationResultQl = {
    __typename?: 'SuggestedLocationResultQL';
    countryCode: Scalars['String']['output'];
    key: Scalars['String']['output'];
    population: Scalars['Int']['output'];
    twoFishesId: Scalars['String']['output'];
    type: Scalars['Int']['output'];
    value: Scalars['String']['output'];
};

export type TenderCodeNodeQl = {
    __typename?: 'TenderCodeNodeQL';
    ancestors?: Maybe<Array<Scalars['String']['output']>>;
    children?: Maybe<Array<TenderCodeRootNodeResultQlTenderCodeNodeQl>>;
    hasChildren: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    label: Scalars['String']['output'];
    leaf: Scalars['Boolean']['output'];
    loadOnDemand: Scalars['Boolean']['output'];
    nChildren: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    parent?: Maybe<Scalars['String']['output']>;
    procurementSystem: Scalars['String']['output'];
    root: Scalars['Boolean']['output'];
};

export type TenderCodeRootNodeResultQl = {
    __typename?: 'TenderCodeRootNodeResultQL';
    children?: Maybe<Array<TenderCodeRootNodeResultQlTenderCodeNodeQl>>;
    hasChildren: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    loadOnDemand: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    root: Scalars['Boolean']['output'];
};

export type TenderCodeRootNodeResultQlTenderCodeNodeQl =
    | TenderCodeNodeQl
    | TenderCodeRootNodeResultQl;

export enum TenderCodeSystem {
    Cpv = 'CPV',
    Naics = 'NAICS',
    Unspsc = 'UNSPSC',
}

export type TenderDocumentQl = {
    __typename?: 'TenderDocumentQL';
    allLotsAwarded?: Maybe<Scalars['Boolean']['output']>;
    announcedDate?: Maybe<BrTimeQl>;
    applicationUrl?: Maybe<Scalars['String']['output']>;
    awardCriteria?: Maybe<Scalars['String']['output']>;
    awardedDate?: Maybe<BrTimeQl>;
    categories?: Maybe<Array<CategoryQl>>;
    competitionDeadlineDate?: Maybe<BrTimeQl>;
    competitionDeadlineDateEst?: Maybe<Scalars['Boolean']['output']>;
    competitionPhase?: Maybe<Scalars['String']['output']>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    contacts?: Maybe<Array<ContactQl>>;
    contractAddress?: Maybe<AddressQl>;
    contractAwardWinners?: Maybe<Array<ContactQl>>;
    contractEndDate?: Maybe<BrTimeQl>;
    contractStartDate?: Maybe<BrTimeQl>;
    contractType?: Maybe<Scalars['String']['output']>;
    contractingAuthority?: Maybe<ContactQl>;
    cpvCodes?: Maybe<Array<Scalars['String']['output']>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    directive?: Maybe<Scalars['String']['output']>;
    documentFamilyId?: Maybe<Scalars['String']['output']>;
    documentFamilyRefs?: Maybe<Array<Scalars['String']['output']>>;
    documentType?: Maybe<Scalars['String']['output']>;
    frameworkAgreement?: Maybe<Scalars['String']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    naicsCodes?: Maybe<Array<Scalars['String']['output']>>;
    openingDate?: Maybe<BrTimeQl>;
    procedureType?: Maybe<Scalars['String']['output']>;
    quantityScope?: Maybe<Scalars['String']['output']>;
    rawDataString?: Maybe<Scalars['String']['output']>;
    referenceNumber?: Maybe<Scalars['String']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    tenderType?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    unspscCodes?: Maybe<Array<Scalars['String']['output']>>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl =
    | ArticleDocumentQl
    | LegacyProjectDocumentQl
    | TenderDocumentQl;

export type TenderRootCodePartFragment = {
    __typename?: 'TenderCodeRootNodeResultQL';
    id: string;
    name: string;
    hasChildren: boolean;
    root: boolean;
    children?: Array<
        | {
              __typename?: 'TenderCodeNodeQL';
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
              children?: Array<
                  | {
                        __typename?: 'TenderCodeNodeQL';
                        id: string;
                        name: string;
                        hasChildren: boolean;
                        nChildren: number;
                        leaf: boolean;
                        root: boolean;
                        procurementSystem: string;
                        parent?: string | null;
                    }
                  | { __typename?: 'TenderCodeRootNodeResultQL' }
              > | null;
          }
        | { __typename?: 'TenderCodeRootNodeResultQL' }
    > | null;
};

export type TenderChildCodePartFragment = {
    __typename?: 'TenderCodeNodeQL';
    id: string;
    name: string;
    hasChildren: boolean;
    nChildren: number;
    leaf: boolean;
    root: boolean;
    procurementSystem: string;
    parent?: string | null;
    children?: Array<
        | {
              __typename?: 'TenderCodeNodeQL';
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
          }
        | { __typename?: 'TenderCodeRootNodeResultQL' }
    > | null;
};

export type ChildPartFragment = {
    __typename?: 'TenderCodeNodeQL';
    id: string;
    name: string;
    hasChildren: boolean;
    nChildren: number;
    leaf: boolean;
    root: boolean;
    procurementSystem: string;
    parent?: string | null;
};

export type NestedTenderCodesFilteredPartFragment = {
    __typename?: 'TenderCodeRootNodeResultQL';
    id: string;
    name: string;
    hasChildren: boolean;
    root: boolean;
    children?: Array<
        | {
              __typename?: 'TenderCodeNodeQL';
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
              children?: Array<
                  | {
                        __typename?: 'TenderCodeNodeQL';
                        id: string;
                        name: string;
                        hasChildren: boolean;
                        nChildren: number;
                        leaf: boolean;
                        root: boolean;
                        procurementSystem: string;
                        parent?: string | null;
                        children?: Array<
                            | {
                                  __typename?: 'TenderCodeNodeQL';
                                  id: string;
                                  name: string;
                                  hasChildren: boolean;
                                  nChildren: number;
                                  leaf: boolean;
                                  root: boolean;
                                  procurementSystem: string;
                                  parent?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?: 'TenderCodeNodeQL';
                                            id: string;
                                            name: string;
                                            hasChildren: boolean;
                                            nChildren: number;
                                            leaf: boolean;
                                            root: boolean;
                                            procurementSystem: string;
                                            parent?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?: 'TenderCodeNodeQL';
                                                      id: string;
                                                      name: string;
                                                      hasChildren: boolean;
                                                      nChildren: number;
                                                      leaf: boolean;
                                                      root: boolean;
                                                      procurementSystem: string;
                                                      parent?: string | null;
                                                      children?: Array<
                                                          | {
                                                                __typename?: 'TenderCodeNodeQL';
                                                                id: string;
                                                                name: string;
                                                                hasChildren: boolean;
                                                                nChildren: number;
                                                                leaf: boolean;
                                                                root: boolean;
                                                                procurementSystem: string;
                                                                parent?:
                                                                    | string
                                                                    | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?: 'TenderCodeNodeQL';
                                                                          id: string;
                                                                          name: string;
                                                                          hasChildren: boolean;
                                                                          nChildren: number;
                                                                          leaf: boolean;
                                                                          root: boolean;
                                                                          procurementSystem: string;
                                                                          parent?:
                                                                              | string
                                                                              | null;
                                                                          children?: Array<
                                                                              | {
                                                                                    __typename?: 'TenderCodeNodeQL';
                                                                                    id: string;
                                                                                    name: string;
                                                                                    hasChildren: boolean;
                                                                                    nChildren: number;
                                                                                    leaf: boolean;
                                                                                    root: boolean;
                                                                                    procurementSystem: string;
                                                                                    parent?:
                                                                                        | string
                                                                                        | null;
                                                                                    children?: Array<
                                                                                        | {
                                                                                              __typename?: 'TenderCodeNodeQL';
                                                                                              id: string;
                                                                                              name: string;
                                                                                              hasChildren: boolean;
                                                                                              nChildren: number;
                                                                                              leaf: boolean;
                                                                                              root: boolean;
                                                                                              procurementSystem: string;
                                                                                              parent?:
                                                                                                  | string
                                                                                                  | null;
                                                                                              children?: Array<
                                                                                                  | {
                                                                                                        __typename?: 'TenderCodeNodeQL';
                                                                                                        id: string;
                                                                                                        name: string;
                                                                                                        hasChildren: boolean;
                                                                                                        nChildren: number;
                                                                                                        leaf: boolean;
                                                                                                        root: boolean;
                                                                                                        procurementSystem: string;
                                                                                                        parent?:
                                                                                                            | string
                                                                                                            | null;
                                                                                                    }
                                                                                                  | {
                                                                                                        __typename?: 'TenderCodeRootNodeResultQL';
                                                                                                    }
                                                                                              > | null;
                                                                                          }
                                                                                        | {
                                                                                              __typename?: 'TenderCodeRootNodeResultQL';
                                                                                          }
                                                                                    > | null;
                                                                                }
                                                                              | {
                                                                                    __typename?: 'TenderCodeRootNodeResultQL';
                                                                                }
                                                                          > | null;
                                                                      }
                                                                    | {
                                                                          __typename?: 'TenderCodeRootNodeResultQL';
                                                                      }
                                                                > | null;
                                                            }
                                                          | {
                                                                __typename?: 'TenderCodeRootNodeResultQL';
                                                            }
                                                      > | null;
                                                  }
                                                | {
                                                      __typename?: 'TenderCodeRootNodeResultQL';
                                                  }
                                            > | null;
                                        }
                                      | {
                                            __typename?: 'TenderCodeRootNodeResultQL';
                                        }
                                  > | null;
                              }
                            | { __typename?: 'TenderCodeRootNodeResultQL' }
                        > | null;
                    }
                  | { __typename?: 'TenderCodeRootNodeResultQL' }
              > | null;
          }
        | { __typename?: 'TenderCodeRootNodeResultQL' }
    > | null;
};

export type BrSearchBrDateTimePartFragment = {
    __typename?: 'BRTimeQL';
    day?: number | null;
    hour?: number | null;
    microsecond?: number | null;
    minute?: number | null;
    month?: number | null;
    precision: BrTimePrecision;
    second?: number | null;
    year: number;
};

export type BrSearchAddressPartFragment = {
    __typename?: 'AddressQL';
    city?: string | null;
    continent?: string | null;
    country?: string | null;
    countryCode?: string | null;
    houseNumber?: string | null;
    lat?: number | null;
    lng?: number | null;
    postCode?: string | null;
    postCodeEnd?: string | null;
    postCodeIsExact?: boolean | null;
    postCodeStart?: string | null;
    precisionAccuracy?: number | null;
    precisionLevel?: string | null;
    precisionType?: string | null;
    rank?: number | null;
    road?: string | null;
    state?: string | null;
};

export type BrSearchContactPartFragment = {
    __typename?: 'ContactQL';
    associatedItemId?: string | null;
    companyName?: string | null;
    companyRoles?: Array<string> | null;
    email?: string | null;
    fax?: string | null;
    homepage?: string | null;
    name?: string | null;
    personRoles?: Array<string> | null;
    phoneLandline?: string | null;
    phoneMobile?: string | null;
    title?: string | null;
};

export type BrSearchTenderDocumentPartFragment = {
    __typename?: 'TenderDocumentQL';
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    allLotsAwarded?: boolean | null;
    awardCriteria?: string | null;
    competitionDeadlineDateEst?: boolean | null;
    frameworkAgreement?: string | null;
    competitionPhase?: string | null;
    documentFamilyId?: string | null;
    documentFamilyRefs?: Array<string> | null;
    contractType?: string | null;
    directive?: string | null;
    documentType?: string | null;
    applicationUrl?: string | null;
    procedureType?: string | null;
    quantityScope?: string | null;
    referenceNumber?: string | null;
    tenderType?: string | null;
    cpvCodes?: Array<string> | null;
    naicsCodes?: Array<string> | null;
    unspscCodes?: Array<string> | null;
    rawDataString?: string | null;
    categories?: Array<{
        __typename?: 'CategoryQL';
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        __typename?: 'PhaseQL';
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        __typename?: 'ImageQL';
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    announcedDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractEndDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractStartDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    competitionDeadlineDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    openingDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractAddress?: {
        __typename?: 'AddressQL';
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    contacts?: Array<{
        __typename?: 'ContactQL';
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
    }> | null;
    awardedDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
};

export type BrSearchArticleDocumentPartFragment = {
    __typename?: 'ArticleDocumentQL';
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    abstract?: string | null;
    paragraphs?: Array<string> | null;
    gfa?: number | null;
    nResidentialUnits?: number | null;
    categories?: Array<{
        __typename?: 'CategoryQL';
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        __typename?: 'PhaseQL';
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        __typename?: 'ImageQL';
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    address?: {
        __typename?: 'AddressQL';
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    constructionStartDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    constructionEndDate?: {
        __typename?: 'BRTimeQL';
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contacts?: Array<{
        __typename?: 'ContactQL';
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
    }> | null;
};

export type DocumentSearchResultPartFragment = {
    __typename?: 'PrescreeningDocGroupWithFolderTagQL';
    created: any;
    documentIds: Array<string>;
    folder?: FolderTag | null;
    id: string;
    pipeDbCollection: string;
    updated: any;
    mergedDocument:
        | {
              __typename?: 'ArticleDocumentQL';
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              abstract?: string | null;
              paragraphs?: Array<string> | null;
              gfa?: number | null;
              nResidentialUnits?: number | null;
              categories?: Array<{
                  __typename?: 'CategoryQL';
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  __typename?: 'PhaseQL';
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  __typename?: 'ImageQL';
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              address?: {
                  __typename?: 'AddressQL';
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              constructionStartDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              constructionEndDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contacts?: Array<{
                  __typename?: 'ContactQL';
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
              }> | null;
          }
        | { __typename?: 'LegacyProjectDocumentQL' }
        | {
              __typename?: 'TenderDocumentQL';
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              allLotsAwarded?: boolean | null;
              awardCriteria?: string | null;
              competitionDeadlineDateEst?: boolean | null;
              frameworkAgreement?: string | null;
              competitionPhase?: string | null;
              documentFamilyId?: string | null;
              documentFamilyRefs?: Array<string> | null;
              contractType?: string | null;
              directive?: string | null;
              documentType?: string | null;
              applicationUrl?: string | null;
              procedureType?: string | null;
              quantityScope?: string | null;
              referenceNumber?: string | null;
              tenderType?: string | null;
              cpvCodes?: Array<string> | null;
              naicsCodes?: Array<string> | null;
              unspscCodes?: Array<string> | null;
              rawDataString?: string | null;
              categories?: Array<{
                  __typename?: 'CategoryQL';
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  __typename?: 'PhaseQL';
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  __typename?: 'ImageQL';
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              announcedDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractEndDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractStartDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              competitionDeadlineDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              openingDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractAddress?: {
                  __typename?: 'AddressQL';
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              contacts?: Array<{
                  __typename?: 'ContactQL';
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
              }> | null;
              awardedDate?: {
                  __typename?: 'BRTimeQL';
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          };
    documents: Array<
        | { __typename?: 'ArticleDocumentQL'; title: string }
        | { __typename?: 'LegacyProjectDocumentQL' }
        | { __typename?: 'TenderDocumentQL'; title: string }
    >;
    prescreenOutput: {
        __typename?: 'PrescreenOutputQL';
        accept: boolean;
        confidence: number;
    };
};

export type SearchAndSubscriptionPartFragment = {
    __typename?: 'SearchAndSubscribersQL';
    userId: number;
    itemId: number;
    name: string;
    description?: string | null;
    autoPrescreeningEnabled: boolean;
    autoPrescreeningInboxLimit: number;
    paused: boolean;
    subscriberIds: Array<number>;
    type: SearchType;
    searchData: {
        __typename?: 'SearchDataQL';
        filterModules: Array<{
            __typename?: 'LinkedFilterModuleQL';
            key: number;
        }>;
    };
};

export type SearchSubscribedPartFragment = {
    __typename?: 'SearchQL';
    userId: number;
    itemId: number;
    name: string;
    paused: boolean;
    type: SearchType;
    searchData: {
        __typename?: 'SearchDataQL';
        filterModules: Array<{
            __typename?: 'LinkedFilterModuleQL';
            key: number;
            filter: string;
            value: string;
        }>;
    };
};

export type GetTenderRootCodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenderRootCodesQuery = {
    __typename?: 'Query';
    tenderCodeTree: Array<{
        __typename?: 'TenderCodeRootNodeResultQL';
        id: string;
        name: string;
        hasChildren: boolean;
        root: boolean;
        children?: Array<
            | {
                  __typename?: 'TenderCodeNodeQL';
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
                  children?: Array<
                      | {
                            __typename?: 'TenderCodeNodeQL';
                            id: string;
                            name: string;
                            hasChildren: boolean;
                            nChildren: number;
                            leaf: boolean;
                            root: boolean;
                            procurementSystem: string;
                            parent?: string | null;
                        }
                      | { __typename?: 'TenderCodeRootNodeResultQL' }
                  > | null;
              }
            | { __typename?: 'TenderCodeRootNodeResultQL' }
        > | null;
    }>;
};

export type GetChildTenderCodesQueryVariables = Exact<{
    tenderCodeId: Scalars['String']['input'];
}>;

export type GetChildTenderCodesQuery = {
    __typename?: 'Query';
    tenderChildCodes: Array<{
        __typename?: 'TenderCodeNodeQL';
        id: string;
        name: string;
        hasChildren: boolean;
        nChildren: number;
        leaf: boolean;
        root: boolean;
        procurementSystem: string;
        parent?: string | null;
        children?: Array<
            | {
                  __typename?: 'TenderCodeNodeQL';
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
              }
            | { __typename?: 'TenderCodeRootNodeResultQL' }
        > | null;
    }>;
};

export type GetFilteredTenderCodesQueryVariables = Exact<{
    textSearch: Scalars['String']['input'];
}>;

export type GetFilteredTenderCodesQuery = {
    __typename?: 'Query';
    tenderCodesFiltered: Array<{
        __typename?: 'TenderCodeRootNodeResultQL';
        id: string;
        name: string;
        hasChildren: boolean;
        root: boolean;
        children?: Array<
            | {
                  __typename?: 'TenderCodeNodeQL';
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
                  children?: Array<
                      | {
                            __typename?: 'TenderCodeNodeQL';
                            id: string;
                            name: string;
                            hasChildren: boolean;
                            nChildren: number;
                            leaf: boolean;
                            root: boolean;
                            procurementSystem: string;
                            parent?: string | null;
                            children?: Array<
                                | {
                                      __typename?: 'TenderCodeNodeQL';
                                      id: string;
                                      name: string;
                                      hasChildren: boolean;
                                      nChildren: number;
                                      leaf: boolean;
                                      root: boolean;
                                      procurementSystem: string;
                                      parent?: string | null;
                                      children?: Array<
                                          | {
                                                __typename?: 'TenderCodeNodeQL';
                                                id: string;
                                                name: string;
                                                hasChildren: boolean;
                                                nChildren: number;
                                                leaf: boolean;
                                                root: boolean;
                                                procurementSystem: string;
                                                parent?: string | null;
                                                children?: Array<
                                                    | {
                                                          __typename?: 'TenderCodeNodeQL';
                                                          id: string;
                                                          name: string;
                                                          hasChildren: boolean;
                                                          nChildren: number;
                                                          leaf: boolean;
                                                          root: boolean;
                                                          procurementSystem: string;
                                                          parent?:
                                                              | string
                                                              | null;
                                                          children?: Array<
                                                              | {
                                                                    __typename?: 'TenderCodeNodeQL';
                                                                    id: string;
                                                                    name: string;
                                                                    hasChildren: boolean;
                                                                    nChildren: number;
                                                                    leaf: boolean;
                                                                    root: boolean;
                                                                    procurementSystem: string;
                                                                    parent?:
                                                                        | string
                                                                        | null;
                                                                    children?: Array<
                                                                        | {
                                                                              __typename?: 'TenderCodeNodeQL';
                                                                              id: string;
                                                                              name: string;
                                                                              hasChildren: boolean;
                                                                              nChildren: number;
                                                                              leaf: boolean;
                                                                              root: boolean;
                                                                              procurementSystem: string;
                                                                              parent?:
                                                                                  | string
                                                                                  | null;
                                                                              children?: Array<
                                                                                  | {
                                                                                        __typename?: 'TenderCodeNodeQL';
                                                                                        id: string;
                                                                                        name: string;
                                                                                        hasChildren: boolean;
                                                                                        nChildren: number;
                                                                                        leaf: boolean;
                                                                                        root: boolean;
                                                                                        procurementSystem: string;
                                                                                        parent?:
                                                                                            | string
                                                                                            | null;
                                                                                        children?: Array<
                                                                                            | {
                                                                                                  __typename?: 'TenderCodeNodeQL';
                                                                                                  id: string;
                                                                                                  name: string;
                                                                                                  hasChildren: boolean;
                                                                                                  nChildren: number;
                                                                                                  leaf: boolean;
                                                                                                  root: boolean;
                                                                                                  procurementSystem: string;
                                                                                                  parent?:
                                                                                                      | string
                                                                                                      | null;
                                                                                                  children?: Array<
                                                                                                      | {
                                                                                                            __typename?: 'TenderCodeNodeQL';
                                                                                                            id: string;
                                                                                                            name: string;
                                                                                                            hasChildren: boolean;
                                                                                                            nChildren: number;
                                                                                                            leaf: boolean;
                                                                                                            root: boolean;
                                                                                                            procurementSystem: string;
                                                                                                            parent?:
                                                                                                                | string
                                                                                                                | null;
                                                                                                        }
                                                                                                      | {
                                                                                                            __typename?: 'TenderCodeRootNodeResultQL';
                                                                                                        }
                                                                                                  > | null;
                                                                                              }
                                                                                            | {
                                                                                                  __typename?: 'TenderCodeRootNodeResultQL';
                                                                                              }
                                                                                        > | null;
                                                                                    }
                                                                                  | {
                                                                                        __typename?: 'TenderCodeRootNodeResultQL';
                                                                                    }
                                                                              > | null;
                                                                          }
                                                                        | {
                                                                              __typename?: 'TenderCodeRootNodeResultQL';
                                                                          }
                                                                    > | null;
                                                                }
                                                              | {
                                                                    __typename?: 'TenderCodeRootNodeResultQL';
                                                                }
                                                          > | null;
                                                      }
                                                    | {
                                                          __typename?: 'TenderCodeRootNodeResultQL';
                                                      }
                                                > | null;
                                            }
                                          | {
                                                __typename?: 'TenderCodeRootNodeResultQL';
                                            }
                                      > | null;
                                  }
                                | { __typename?: 'TenderCodeRootNodeResultQL' }
                            > | null;
                        }
                      | { __typename?: 'TenderCodeRootNodeResultQL' }
                  > | null;
              }
            | { __typename?: 'TenderCodeRootNodeResultQL' }
        > | null;
    }>;
};

export type GetFilterModulesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFilterModulesQuery = {
    __typename?: 'Query';
    filterModules: Array<{
        __typename?: 'FilterModuleQL';
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    }>;
};

export type GetFilterModuleByIdQueryVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type GetFilterModuleByIdQuery = {
    __typename?: 'Query';
    filterModules: Array<{
        __typename?: 'FilterModuleQL';
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    }>;
};

export type SearchByFilterModulesWithNarrativeQueryVariables = Exact<{
    searchInput: SearchByFilterModulesInputQl;
    narrative: Scalars['String']['input'];
}>;

export type SearchByFilterModulesWithNarrativeQuery = {
    __typename?: 'Query';
    searchWithPrescreening: {
        __typename?: 'SearchWithPrescreeningResultQL';
        totalCount: number;
        docGroups: Array<{
            __typename?: 'PrescreeningDocGroupWithFolderTagQL';
            created: any;
            documentIds: Array<string>;
            folder?: FolderTag | null;
            id: string;
            pipeDbCollection: string;
            updated: any;
            mergedDocument:
                | {
                      __typename?: 'ArticleDocumentQL';
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      abstract?: string | null;
                      paragraphs?: Array<string> | null;
                      gfa?: number | null;
                      nResidentialUnits?: number | null;
                      categories?: Array<{
                          __typename?: 'CategoryQL';
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          __typename?: 'PhaseQL';
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          __typename?: 'ImageQL';
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      address?: {
                          __typename?: 'AddressQL';
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      constructionStartDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      constructionEndDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contacts?: Array<{
                          __typename?: 'ContactQL';
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                      }> | null;
                  }
                | { __typename?: 'LegacyProjectDocumentQL' }
                | {
                      __typename?: 'TenderDocumentQL';
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      allLotsAwarded?: boolean | null;
                      awardCriteria?: string | null;
                      competitionDeadlineDateEst?: boolean | null;
                      frameworkAgreement?: string | null;
                      competitionPhase?: string | null;
                      documentFamilyId?: string | null;
                      documentFamilyRefs?: Array<string> | null;
                      contractType?: string | null;
                      directive?: string | null;
                      documentType?: string | null;
                      applicationUrl?: string | null;
                      procedureType?: string | null;
                      quantityScope?: string | null;
                      referenceNumber?: string | null;
                      tenderType?: string | null;
                      cpvCodes?: Array<string> | null;
                      naicsCodes?: Array<string> | null;
                      unspscCodes?: Array<string> | null;
                      rawDataString?: string | null;
                      categories?: Array<{
                          __typename?: 'CategoryQL';
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          __typename?: 'PhaseQL';
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          __typename?: 'ImageQL';
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      announcedDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractEndDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractStartDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      competitionDeadlineDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      openingDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractAddress?: {
                          __typename?: 'AddressQL';
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      contacts?: Array<{
                          __typename?: 'ContactQL';
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                      }> | null;
                      awardedDate?: {
                          __typename?: 'BRTimeQL';
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  };
            documents: Array<
                | { __typename?: 'ArticleDocumentQL'; title: string }
                | { __typename?: 'LegacyProjectDocumentQL' }
                | { __typename?: 'TenderDocumentQL'; title: string }
            >;
            prescreenOutput: {
                __typename?: 'PrescreenOutputQL';
                accept: boolean;
                confidence: number;
            };
        }>;
    };
};

export type SearchCountByFilterModulesQueryVariables = Exact<{
    searchInput: CountSearchByFilterModulesInputQl;
}>;

export type SearchCountByFilterModulesQuery = {
    __typename?: 'Query';
    countSearchByFilterModules: {
        __typename?: 'CountSearchByFilterModulesResultQL';
        totalCount: number;
    };
};

export type GetLocationSuggestionsQueryVariables = Exact<{
    partialText: Scalars['String']['input'];
}>;

export type GetLocationSuggestionsQuery = {
    __typename?: 'Query';
    locationSuggestions: Array<{
        __typename?: 'SuggestedLocationResultQL';
        value: string;
        key: string;
        type: number;
        countryCode: string;
        population: number;
        twoFishesId: string;
    }>;
};

export type GetSourceDomainSuggestionsQueryVariables = Exact<{
    partialText: Scalars['String']['input'];
}>;

export type GetSourceDomainSuggestionsQuery = {
    __typename?: 'Query';
    autocompleteSourceDomains: Array<string>;
};

export type GetSearchesAndSubscriptionsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetSearchesAndSubscriptionsQuery = {
    __typename?: 'Query';
    searchesAndSubscriptions: Array<{
        __typename?: 'SearchAndSubscribersQL';
        userId: number;
        itemId: number;
        name: string;
        description?: string | null;
        autoPrescreeningEnabled: boolean;
        autoPrescreeningInboxLimit: number;
        paused: boolean;
        subscriberIds: Array<number>;
        type: SearchType;
        searchData: {
            __typename?: 'SearchDataQL';
            filterModules: Array<{
                __typename?: 'LinkedFilterModuleQL';
                key: number;
            }>;
        };
    }>;
};

export type CreateFilterModuleMutationVariables = Exact<{
    name: Scalars['String']['input'];
    data: Scalars['JSON']['input'];
    type: FilterModuleType;
}>;

export type CreateFilterModuleMutation = {
    __typename?: 'Mutation';
    createFilterModule: {
        __typename?: 'FilterModuleQL';
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    };
};

export type UpdateFilterModuleMutationVariables = Exact<{
    id: Scalars['Int']['input'];
    name: Scalars['String']['input'];
    data: Scalars['JSON']['input'];
}>;

export type UpdateFilterModuleMutation = {
    __typename?: 'Mutation';
    updateFilterModule: { __typename?: 'ResultQL'; ok: boolean };
};

export type DeleteFilterModuleMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteFilterModuleMutation = {
    __typename?: 'Mutation';
    deleteFilterModule: { __typename?: 'ResultQL'; ok: boolean };
};

export type DeleteSearchMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteSearchMutation = {
    __typename?: 'Mutation';
    deleteSearch: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpsertSearchMutationVariables = Exact<{
    id?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    type: SearchType;
    filterModuleIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type UpsertSearchMutation = {
    __typename?: 'Mutation';
    upsertSearch: { __typename?: 'SearchIdQL'; id: number };
};

export type SubscribeToSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
}>;

export type SubscribeToSearchMutation = {
    __typename?: 'Mutation';
    subscribeSearch: {
        __typename?: 'SearchQL';
        userId: number;
        itemId: number;
        name: string;
        paused: boolean;
        type: SearchType;
        searchData: {
            __typename?: 'SearchDataQL';
            filterModules: Array<{
                __typename?: 'LinkedFilterModuleQL';
                key: number;
                filter: string;
                value: string;
            }>;
        };
    };
};

export type UnsubscribeToSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
}>;

export type UnsubscribeToSearchMutation = {
    __typename?: 'Mutation';
    unsubscribeSearch: { __typename?: 'ResultQL'; ok: boolean };
};

export type PauseSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
}>;

export type PauseSearchMutation = {
    __typename?: 'Mutation';
    pauseSearch: { __typename?: 'ResultQL'; ok: boolean };
};

export type UnpauseSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
}>;

export type UnpauseSearchMutation = {
    __typename?: 'Mutation';
    unpauseSearch: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdateSearchPrescreeningSettingsMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    autoPrescreeningEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    autoPrescreeningInboxLimit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateSearchPrescreeningSettingsMutation = {
    __typename?: 'Mutation';
    updateSearchPrescreeningSettings: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetTenderTextFromCodesQueryVariables = Exact<{
    cpvCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
    naicsCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
    unspscCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetTenderTextFromCodesQuery = {
    __typename?: 'Query';
    tenderTextFromCodes: Array<string>;
};
