/* eslint-disable */
import * as Types from './graphql-sdk';

import { DocumentNode } from 'graphql';
import * as Operations from './hooks';

export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        RequestDealImport(
            variables: Types.RequestDealImportMutationVariables,
            options?: C,
        ): Promise<Types.RequestDealImportMutation> {
            return requester<
                Types.RequestDealImportMutation,
                Types.RequestDealImportMutationVariables
            >(
                Operations.GqlOperationRequestDealImportDocument,
                variables,
                options,
            ) as Promise<Types.RequestDealImportMutation>;
        },
        UploadDealAttachment(
            variables: Types.UploadDealAttachmentMutationVariables,
            options?: C,
        ): Promise<Types.UploadDealAttachmentMutation> {
            return requester<
                Types.UploadDealAttachmentMutation,
                Types.UploadDealAttachmentMutationVariables
            >(
                Operations.GqlOperationUploadDealAttachmentDocument,
                variables,
                options,
            ) as Promise<Types.UploadDealAttachmentMutation>;
        },
        CreateDealFromExternalSource(
            variables: Types.CreateDealFromExternalSourceMutationVariables,
            options?: C,
        ): Promise<Types.CreateDealFromExternalSourceMutation> {
            return requester<
                Types.CreateDealFromExternalSourceMutation,
                Types.CreateDealFromExternalSourceMutationVariables
            >(
                Operations.GqlOperationCreateDealFromExternalSourceDocument,
                variables,
                options,
            ) as Promise<Types.CreateDealFromExternalSourceMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
