export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BigInt: { input: any; output: any };
    /** Date (isoformat) */
    Date: { input: any; output: any };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
    JSON: { input: any; output: any };
};

export enum AccountType {
    Dummy = 'DUMMY',
    Production = 'PRODUCTION',
    Template = 'TEMPLATE',
}

export type ActionFeaturesConfigQl = {
    __typename?: 'ActionFeaturesConfigQL';
    active: Scalars['Boolean']['output'];
    companyOutreachStages?: Maybe<Array<CompanyOutreachStage>>;
    contactOutreachStages?: Maybe<Array<ContactOutreachStage>>;
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    settings?: Maybe<Scalars['JSON']['output']>;
};

export type ActivityLogQl = {
    __typename?: 'ActivityLogQL';
    companyBid: Scalars['Int']['output'];
    contactId?: Maybe<Scalars['String']['output']>;
    createdDate: Scalars['DateTime']['output'];
    customType: Scalars['String']['output'];
    deal?: Maybe<DealQl>;
    dealId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isParentVisible: Scalars['Boolean']['output'];
    notes: Scalars['String']['output'];
    relevantCompanyId?: Maybe<Scalars['String']['output']>;
    subType?: Maybe<ActivityLogSubType>;
    type: ActivityLogType;
    updatedDate: Scalars['DateTime']['output'];
    userId: Scalars['Int']['output'];
};

export type ActivityLogStatsQl = {
    __typename?: 'ActivityLogStatsQL';
    count: Scalars['Int']['output'];
    lastActivityDate?: Maybe<Scalars['DateTime']['output']>;
};

export enum ActivityLogSubType {
    DealAttachmentUpload = 'DEAL_ATTACHMENT_UPLOAD',
    DealOwnerChange = 'DEAL_OWNER_CHANGE',
    Email = 'EMAIL',
    Other = 'OTHER',
    PhoneCall = 'PHONE_CALL',
}

export enum ActivityLogType {
    History = 'HISTORY',
    OutreachAttempt = 'OUTREACH_ATTEMPT',
}

export type AddressDetailQl = {
    __typename?: 'AddressDetailQL';
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    street?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
};

export type AnnualSalesPeriodQl = {
    __typename?: 'AnnualSalesPeriodQL';
    frequency: SalesPeriodFrequency;
    year: Scalars['Int']['output'];
};

export type AnnualSalesPeriodQlQuarterlySalesPeriodQl =
    | AnnualSalesPeriodQl
    | QuarterlySalesPeriodQl;

export type AppInfoQl = {
    __typename?: 'AppInfoQL';
    branchHint: Scalars['String']['output'];
    version: Scalars['String']['output'];
};

export type BulkMoveResultQl = {
    __typename?: 'BulkMoveResultQL';
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export type BulkUpdateAttributesResultQl = {
    __typename?: 'BulkUpdateAttributesResultQL';
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export type BulkUpdateCustomFieldsResultQl = {
    __typename?: 'BulkUpdateCustomFieldsResultQL';
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export enum CategoryNames {
    Agriculture = 'AGRICULTURE',
    Commercial = 'COMMERCIAL',
    CulturalEvent = 'CULTURAL_EVENT',
    EducationScience = 'EDUCATION_SCIENCE',
    Health = 'HEALTH',
    HistoricalReligious = 'HISTORICAL_RELIGIOUS',
    Hospitality = 'HOSPITALITY',
    Industrial = 'INDUSTRIAL',
    Infrastructure = 'INFRASTRUCTURE',
    Landscaping = 'LANDSCAPING',
    Office = 'OFFICE',
    PublicServiceAdministration = 'PUBLIC_SERVICE_ADMINISTRATION',
    Residential = 'RESIDENTIAL',
    Sport = 'SPORT',
}

export type ColleagueAchievementsQl = {
    __typename?: 'ColleagueAchievementsQL';
    achievements: UserAchievementsDetailsQl;
    defaultPipelineId: Scalars['String']['output'];
    optInAchievements: Scalars['Boolean']['output'];
    userId: Scalars['Int']['output'];
};

export type CombinedContactExtractionResultQl = {
    __typename?: 'CombinedContactExtractionResultQL';
    projectContextResult?: Maybe<ContactExtractionResultQl>;
    webSearchResult?: Maybe<ContactExtractionResultQl>;
};

export type CommentCreateQl = {
    content: Scalars['String']['input'];
    /** @deprecated User identifiers are now parsed from the content. Stop sending them. */
    mentionedUserBids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CommentQl = {
    __typename?: 'CommentQL';
    content: Scalars['String']['output'];
    createdDate: Scalars['DateTime']['output'];
    creatorBid: Scalars['Int']['output'];
    id: Scalars['String']['output'];
    parentId: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type CommentUpdateQl = {
    content: Scalars['String']['input'];
    /** @deprecated User identifiers are now parsed from the content. Stop sending them. */
    mentionedUserBids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CompanyConfigQl = {
    __typename?: 'CompanyConfigQL';
    actionFeatures: Array<ActionFeaturesConfigQl>;
    configurationUpdatedDate: Scalars['DateTime']['output'];
    customFields: CustomFieldsConfig;
    dealValueVisible: Scalars['Boolean']['output'];
    exportMappings: ExportMappingsConfig;
    roles: RolesConfig;
    salesPeriod?: Maybe<SalesPeriodConfigurationQl>;
};

export enum CompanyOutreachStage {
    FindPeople = 'FIND_PEOPLE',
    Research = 'RESEARCH',
}

export type CompanyQl = {
    __typename?: 'CompanyQL';
    accountType: AccountType;
    config?: Maybe<CompanyConfigQl>;
    id: Scalars['Int']['output'];
    licenseType: LicenseType;
    name: Scalars['String']['output'];
};

export type ContactEnrichmentResultQl = {
    __typename?: 'ContactEnrichmentResultQL';
    emails: Array<EnrichmentDataValueQl>;
    linkedins: Array<EnrichmentDataValueQl>;
    phones: Array<EnrichmentDataValueQl>;
    positions: Array<EnrichmentDataValueQl>;
};

export type ContactExtractionResultQl = {
    __typename?: 'ContactExtractionResultQL';
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    dealId: Scalars['String']['output'];
    extractedCompanies: Array<ExtractedCompanyQl>;
    extractedContacts: Array<ExtractedContactQl>;
    extractionType: ExtractionType;
    id: Scalars['String']['output'];
    status: ContactExtractionStatus;
    updatedDate: Scalars['DateTime']['output'];
};

export enum ContactExtractionStatus {
    Failed = 'FAILED',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
}

export type ContactMutationQl = {
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactOutreachStage {
    Identified = 'IDENTIFIED',
    InContact = 'IN_CONTACT',
    Outreach = 'OUTREACH',
    Research = 'RESEARCH',
}

export type ContactQl = NodeQl & {
    __typename?: 'ContactQL';
    createdDate: Scalars['DateTime']['output'];
    customFields?: Maybe<Array<CustomFieldQl>>;
    extracted?: Maybe<Scalars['Boolean']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isKey?: Maybe<Scalars['Boolean']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    notes?: Maybe<Scalars['String']['output']>;
    parentDeals?: Maybe<Array<DealQl>>;
    relevantCompany?: Maybe<RelevantCompanyQl>;
    role?: Maybe<Scalars['String']['output']>;
};

export type ContactUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type ContactUpdateQl = {
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactsFromExtractedContactsResultQl = {
    __typename?: 'CreateContactsFromExtractedContactsResultQL';
    contacts: Array<ContactQl>;
    ok: Scalars['Boolean']['output'];
    relevantCompanies: Array<RelevantCompanyQl>;
};

export type CreateDealResultQl = {
    __typename?: 'CreateDealResultQL';
    dealId: Scalars['String']['output'];
    ok: Scalars['Boolean']['output'];
};

export type CrmQl = {
    __typename?: 'CrmQL';
    exportedDate?: Maybe<Scalars['DateTime']['output']>;
    remoteUrl?: Maybe<Scalars['String']['output']>;
    state?: Maybe<EntitySyncState>;
};

export type CsvQl = {
    __typename?: 'CsvQL';
    header: Scalars['String']['output'];
    row: Scalars['String']['output'];
};

export type CustomFieldConfigQl = {
    __typename?: 'CustomFieldConfigQL';
    acceptedValues?: Maybe<Array<Scalars['String']['output']>>;
    defaultValue?: Maybe<Scalars['String']['output']>;
    isTag: Scalars['Boolean']['output'];
    isVisibleInDealPage: Scalars['Boolean']['output'];
    label: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    type: CustomFieldType;
};

export type CustomFieldQl = {
    __typename?: 'CustomFieldQL';
    acceptedValues?: Maybe<Array<Scalars['String']['output']>>;
    defaultValue?: Maybe<Scalars['String']['output']>;
    enrichmentDetail?: Maybe<EnrichmentDetailQl>;
    isTag: Scalars['Boolean']['output'];
    isVisibleInDealPage: Scalars['Boolean']['output'];
    label: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    type: CustomFieldType;
    value?: Maybe<Scalars['String']['output']>;
};

export enum CustomFieldType {
    Decision = 'DECISION',
    DecisionCompanies = 'DECISION_COMPANIES',
    DecisionContacts = 'DECISION_CONTACTS',
    Float = 'FLOAT',
    Integer = 'INTEGER',
    Isodate = 'ISODATE',
    String = 'STRING',
}

export type CustomFieldValueInputQl = {
    enrichmentDetail?: InputMaybe<EnrichmentDetailInputQl>;
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type CustomFieldsConfig = {
    __typename?: 'CustomFieldsConfig';
    contacts?: Maybe<Array<CustomFieldConfigQl>>;
    deals?: Maybe<Array<CustomFieldConfigQl>>;
    relevantCompanies?: Maybe<Array<CustomFieldConfigQl>>;
};

export type CustomValueInputQl = {
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type DealAlertQl = {
    __typename?: 'DealAlertQL';
    /** Set to True if deal owner or reminder assignee is current user. */
    currentUserRelated: Scalars['Boolean']['output'];
    type: DealAlertType;
};

export enum DealAlertType {
    FailedSo = 'FAILED_SO',
    Healthy = 'HEALTHY',
    OverdueReminder = 'OVERDUE_REMINDER',
    Paused = 'PAUSED',
    ReadyToReactivate = 'READY_TO_REACTIVATE',
    RecentlyAddedToStage = 'RECENTLY_ADDED_TO_STAGE',
    SlowMoving = 'SLOW_MOVING',
    Unassigned = 'UNASSIGNED',
    UpcomingReminder = 'UPCOMING_REMINDER',
}

export enum DealAttribute {
    Assignee = 'ASSIGNEE',
    DealValue = 'DEAL_VALUE',
    Notes = 'NOTES',
}

export type DealCollectionEdgeQl = {
    __typename?: 'DealCollectionEdgeQL';
    cursor: Scalars['String']['output'];
    node: DealQl;
};

export type DealCollectionInfoQl = {
    __typename?: 'DealCollectionInfoQL';
    edges?: Maybe<Array<DealCollectionEdgeQl>>;
    pageInfo: PageInfoQl;
    /** Total number of elements in the object collection which match the filter criteria. */
    totalCount: Scalars['Int']['output'];
};

export enum DealContentCreationSource {
    AdditionalSourcesLlm = 'ADDITIONAL_SOURCES_LLM',
    DealImport = 'DEAL_IMPORT',
    UserCreated = 'USER_CREATED',
}

export type DealContentMetadataQl = {
    __typename?: 'DealContentMetadataQL';
    creationSource: DealContentCreationSource;
    hasContent: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    name?: Maybe<Scalars['String']['output']>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    type: DealContentType;
};

export enum DealContentType {
    File = 'FILE',
    Text = 'TEXT',
    Url = 'URL',
}

export enum DealCreationSource {
    ByopFileAttachment = 'BYOP_FILE_ATTACHMENT',
    ByopPastedContext = 'BYOP_PASTED_CONTEXT',
    ByopUrlAttachment = 'BYOP_URL_ATTACHMENT',
    ExternalApi = 'EXTERNAL_API',
    Manual = 'MANUAL',
    NeuralSearch = 'NEURAL_SEARCH',
    ProjectLookup = 'PROJECT_LOOKUP',
    Screening = 'SCREENING',
}

export type DealExportQl = NodeQl & {
    __typename?: 'DealExportQL';
    crm: CrmQl;
    csv: CsvQl;
    id: Scalars['String']['output'];
};

export type DealExportQlCrmArgs = {
    integrationId: Scalars['String']['input'];
};

export type DealExportQlCsvArgs = {
    delimiter?: Scalars['String']['input'];
};

/** If the deal assignee equals 0 means it is unassigned */
export type DealQl = NodeQl & {
    __typename?: 'DealQL';
    activityLog: Array<ActivityLogQl>;
    alert: DealAlertQl;
    assignee: Scalars['Int']['output'];
    closestNextStepDate?: Maybe<Scalars['DateTime']['output']>;
    comments?: Maybe<Array<CommentQl>>;
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    contacts?: Maybe<Array<ContactQl>>;
    contents: Array<DealContentMetadataQl>;
    createdDate: Scalars['DateTime']['output'];
    currentObjectives: Array<ObjectiveQl>;
    currentStage: DealStageQl;
    customFields?: Maybe<Array<CustomFieldQl>>;
    expectedCurrentStageConversionDate: Scalars['DateTime']['output'];
    expectedFinalConversionDate: Scalars['DateTime']['output'];
    expectedSalesPeriod?: Maybe<AnnualSalesPeriodQlQuarterlySalesPeriodQl>;
    export: DealExportQl;
    exportedDate?: Maybe<ExportedDateQl>;
    hasComments: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    importInfo?: Maybe<ImportInfoQl>;
    inlineReminders: Array<InlineReminderQl>;
    notes: Scalars['String']['output'];
    outreachLogStats: ActivityLogStatsQl;
    pausedDate?: Maybe<Scalars['DateTime']['output']>;
    pausedUntil?: Maybe<Scalars['DateTime']['output']>;
    pipeline: PipelineQl;
    project?: Maybe<ProjectQl>;
    relevantCompanies?: Maybe<Array<RelevantCompanyQl>>;
    reminders: Array<ReminderQl>;
    stageUpdatedDate: Scalars['DateTime']['output'];
    state: DealState;
    summary?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    unpausedDate?: Maybe<Scalars['DateTime']['output']>;
    value: ValueCurrencyQl;
};

export type DealStageQl = {
    __typename?: 'DealStageQL';
    id: Scalars['String']['output'];
};

export enum DealState {
    Disqualified = 'DISQUALIFIED',
    Done = 'DONE',
    InPlay = 'IN_PLAY',
}

export type DealUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type DealUpdateInputQl = {
    assignee?: InputMaybe<Scalars['Int']['input']>;
    dealValue?: InputMaybe<ValueCurrencyInput>;
    exported?: InputMaybe<ExportedType>;
    notes?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type DebugEmailInputQl = {
    body: Scalars['String']['input'];
    senderEmail: Scalars['String']['input'];
    senderName: Scalars['String']['input'];
    subject: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    toName: Scalars['String']['input'];
};

export type DebugQl = {
    __typename?: 'DebugQL';
    request: DebugRequestQl;
};

export type DebugRequestQl = {
    __typename?: 'DebugRequestQL';
    tracing: DebugRequestTracingQl;
};

export type DebugRequestTracingQl = {
    __typename?: 'DebugRequestTracingQL';
    traceId?: Maybe<Scalars['String']['output']>;
};

export type DomainEnrichmentDataValueQl = {
    __typename?: 'DomainEnrichmentDataValueQL';
    domain: Scalars['String']['output'];
    name: Scalars['String']['output'];
    providers: Array<EnrichmentDataProviderSource>;
};

export enum DynamicFilter {
    DfFailedSo = 'DF_FAILED_SO',
    DfNoReminderSet = 'DF_NO_REMINDER_SET',
    DfOverdueReminder = 'DF_OVERDUE_REMINDER',
    DfReadyToReactivate = 'DF_READY_TO_REACTIVATE',
    DfSlowMoving = 'DF_SLOW_MOVING',
    DfUnassigned = 'DF_UNASSIGNED',
}

export type DynamicFilterMetricsQl = {
    __typename?: 'DynamicFilterMetricsQL';
    count: Scalars['Int']['output'];
    dynamicFilter: DynamicFilter;
};

export type EmailAddressHeaderQl = {
    __typename?: 'EmailAddressHeaderQL';
    bccReceivers?: Maybe<Array<EmailAddressQl>>;
    ccReceivers?: Maybe<Array<EmailAddressQl>>;
    fromSender: EmailAddressQl;
    replyTo?: Maybe<EmailAddressQl>;
    toReceivers: Array<EmailAddressQl>;
};

export type EmailAddressQl = {
    __typename?: 'EmailAddressQL';
    email: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type EmailTemplateParamQl = {
    __typename?: 'EmailTemplateParamQL';
    key: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type EmailTemplateParamsQl = {
    __typename?: 'EmailTemplateParamsQL';
    params?: Maybe<Array<EmailTemplateParamQl>>;
    templateId: Scalars['String']['output'];
};

export enum EnrichmentDataProviderSource {
    Clearbit = 'CLEARBIT',
    ContactOut = 'CONTACT_OUT',
    DealFront = 'DEAL_FRONT',
    GooglePlaces = 'GOOGLE_PLACES',
    Hunter = 'HUNTER',
    Mock = 'MOCK',
    RocketReach = 'ROCKET_REACH',
}

export type EnrichmentDataValueQl = {
    __typename?: 'EnrichmentDataValueQL';
    providers: Array<EnrichmentDataProviderSource>;
    value: Scalars['String']['output'];
};

export type EnrichmentDetailInputQl = {
    id: Scalars['String']['input'];
    source: Scalars['String']['input'];
};

export type EnrichmentDetailQl = {
    __typename?: 'EnrichmentDetailQL';
    id: Scalars['String']['output'];
    source: Scalars['String']['output'];
};

export enum EntitySyncObjectTypes {
    Deal = 'DEAL',
}

export type EntitySyncRequestQl = NodeQl & {
    __typename?: 'EntitySyncRequestQL';
    createdDate: Scalars['DateTime']['output'];
    entityId: Scalars['String']['output'];
    entityType: EntitySyncObjectTypes;
    id: Scalars['String']['output'];
    integrationId: Scalars['String']['output'];
    state: EntitySyncState;
};

export type EntitySyncRequestResultQl = {
    __typename?: 'EntitySyncRequestResultQL';
    entitySyncRequest: EntitySyncRequestQl;
    ok: Scalars['Boolean']['output'];
};

export enum EntitySyncState {
    Created = 'CREATED',
    Error = 'ERROR',
    Exported = 'EXPORTED',
}

export type ExportDealEmailInputQl = {
    dealId: Scalars['String']['input'];
    dealOwner: Scalars['String']['input'];
    dealOwnerEmail: Scalars['String']['input'];
    dealUrl: Scalars['String']['input'];
    language: UserLanguage;
    senderName: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    toName: Scalars['String']['input'];
};

export enum ExportMappingCodeVersion {
    Draft = 'DRAFT',
    Live = 'LIVE',
    Prev = 'PREV',
}

export type ExportMappingQl = NodeQl & {
    __typename?: 'ExportMappingQL';
    codeVersions: Array<ExportMappingVersionedCodeQl>;
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type ExportMappingVersionedCodeQl = {
    __typename?: 'ExportMappingVersionedCodeQL';
    code?: Maybe<Scalars['String']['output']>;
    version: ExportMappingCodeVersion;
};

export type ExportMappingsConfig = {
    __typename?: 'ExportMappingsConfig';
    defaultExportMappingId?: Maybe<Scalars['String']['output']>;
};

export type ExportedDateQl = {
    __typename?: 'ExportedDateQL';
    csv?: Maybe<Scalars['DateTime']['output']>;
    email?: Maybe<Scalars['DateTime']['output']>;
};

export enum ExportedType {
    Csv = 'CSV',
    Email = 'EMAIL',
}

export type ExtractedCompanyQl = {
    __typename?: 'ExtractedCompanyQL';
    legitimateInterestText: Scalars['String']['output'];
    name: Scalars['String']['output'];
    role: Scalars['String']['output'];
    sourceUrls: Array<Scalars['String']['output']>;
};

export type ExtractedContactQl = {
    __typename?: 'ExtractedContactQL';
    companyName: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    legitimateInterestText: Scalars['String']['output'];
    role: Scalars['String']['output'];
    sourceUrls: Array<Scalars['String']['output']>;
};

export enum ExtractionType {
    ProjectContext = 'PROJECT_CONTEXT',
    WebSearch = 'WEB_SEARCH',
}

export enum FilterKeys {
    FkActivityLogContactId = 'FK_ACTIVITY_LOG_CONTACT_ID',
    FkActivityLogDealId = 'FK_ACTIVITY_LOG_DEAL_ID',
    FkActivityLogSubType = 'FK_ACTIVITY_LOG_SUB_TYPE',
    FkActivityLogType = 'FK_ACTIVITY_LOG_TYPE',
    FkContactCreatedDate = 'FK_CONTACT_CREATED_DATE',
    FkContactPipelineId = 'FK_CONTACT_PIPELINE_ID',
    FkDealAssigneeBid = 'FK_DEAL_ASSIGNEE_BID',
    FkDealCompletedDate = 'FK_DEAL_COMPLETED_DATE',
    FkDealContacts = 'FK_DEAL_CONTACTS',
    FkDealCreatedDate = 'FK_DEAL_CREATED_DATE',
    FkDealCsvExportedDate = 'FK_DEAL_CSV_EXPORTED_DATE',
    FkDealCurrentStageId = 'FK_DEAL_CURRENT_STAGE_ID',
    FkDealCustomValue = 'FK_DEAL_CUSTOM_VALUE',
    FkDealExpectedCurrentStageConversionDate = 'FK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
    FkDealExpectedFinalConversionDate = 'FK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
    FkDealExpectedSalesPeriod = 'FK_DEAL_EXPECTED_SALES_PERIOD',
    FkDealHidePaused = 'FK_DEAL_HIDE_PAUSED',
    FkDealId = 'FK_DEAL_ID',
    FkDealPausedUntil = 'FK_DEAL_PAUSED_UNTIL',
    FkDealPipelineId = 'FK_DEAL_PIPELINE_ID',
    FkDealStageUpdatedDate = 'FK_DEAL_STAGE_UPDATED_DATE',
    FkDealState = 'FK_DEAL_STATE',
    FkDealTitle = 'FK_DEAL_TITLE',
    FkDealValueCurrencyNaive = 'FK_DEAL_VALUE_CURRENCY_NAIVE',
    FkDealVisible = 'FK_DEAL_VISIBLE',
    FkEsrControllerRetryAvailableDate = 'FK_ESR_CONTROLLER_RETRY_AVAILABLE_DATE',
    FkEsrControllerState = 'FK_ESR_CONTROLLER_STATE',
    FkEsrEntityId = 'FK_ESR_ENTITY_ID',
    FkEsrIntegrationId = 'FK_ESR_INTEGRATION_ID',
    FkObjectiveCriteria = 'FK_OBJECTIVE_CRITERIA',
    FkObjectiveLinkedFieldName = 'FK_OBJECTIVE_LINKED_FIELD_NAME',
    FkObjectiveLinkedFieldType = 'FK_OBJECTIVE_LINKED_FIELD_TYPE',
    FkObjectivePipelineId = 'FK_OBJECTIVE_PIPELINE_ID',
    FkObjectiveStageId = 'FK_OBJECTIVE_STAGE_ID',
    FkPipeVisible = 'FK_PIPE_VISIBLE',
    FkRelevantCompanyRoleUpdatedDate = 'FK_RELEVANT_COMPANY_ROLE_UPDATED_DATE',
}

export type FilterOpQl = {
    field: FilterKeys;
    fop: FilterOperators;
    value?: InputMaybe<Scalars['JSON']['input']>;
    values?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export enum FilterOperators {
    FopEq = 'FOP_EQ',
    FopGt = 'FOP_GT',
    FopGte = 'FOP_GTE',
    FopIn = 'FOP_IN',
    FopLt = 'FOP_LT',
    FopLte = 'FOP_LTE',
    FopNe = 'FOP_NE',
    FopNin = 'FOP_NIN',
    FopRegex = 'FOP_REGEX',
}

export type ImportInfoQl = {
    __typename?: 'ImportInfoQL';
    creationSource: DealCreationSource;
    sourceName: Scalars['String']['output'];
    status: ImportStatus;
};

export enum ImportStatus {
    Failed = 'FAILED',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
}

export type InlineReminderQl = {
    __typename?: 'InlineReminderQL';
    /** User ID of the user who is assigned to the reminder */
    assignee: Scalars['Int']['output'];
    /** Date when the reminder was completed */
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    /** Reminder due date (not a datetime) */
    dueDate: Scalars['Date']['output'];
    /** Reference to the reminder in the 'reminders' collection */
    id: Scalars['String']['output'];
};

export type InstantMessageQl = {
    __typename?: 'InstantMessageQL';
    content: InstantMessageUserDealImportCompletedQl;
    type: InstantUserMessageType;
    userSourceId: Scalars['Int']['output'];
    userTargetId: Scalars['Int']['output'];
};

export type InstantMessageUserDealImportCompletedQl = {
    __typename?: 'InstantMessageUserDealImportCompletedQL';
    dealId: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export enum InstantUserMessageType {
    UserDealImportCompleted = 'USER_DEAL_IMPORT_COMPLETED',
}

export enum IntegrationCodeSlots {
    CodeSlotDraft = 'CODE_SLOT_DRAFT',
    CodeSlotLive = 'CODE_SLOT_LIVE',
    CodeSlotPrev = 'CODE_SLOT_PREV',
}

export enum IntegrationDriver {
    CrmDynamicsDefault = 'CRM_DYNAMICS_DEFAULT',
    CrmFakeDefault = 'CRM_FAKE_DEFAULT',
    CrmSalesforceDefault = 'CRM_SALESFORCE_DEFAULT',
    ExternalUrlDefault = 'EXTERNAL_URL_DEFAULT',
}

export enum IntegrationMapper {
    MapperDynamicsBasic = 'MAPPER_DYNAMICS_BASIC',
    MapperFake = 'MAPPER_FAKE',
    MapperObgDynamics = 'MAPPER_OBG_DYNAMICS',
    MapperPyscriptV2 = 'MAPPER_PYSCRIPT_V2',
    MapperSalesforceBasic = 'MAPPER_SALESFORCE_BASIC',
}

export type IntegrationMappingDebugApplyResultQl = {
    __typename?: 'IntegrationMappingDebugApplyResultQL';
    logOutput: Scalars['String']['output'];
};

export type IntegrationMappingDebugQl = {
    __typename?: 'IntegrationMappingDebugQL';
    applyCode: IntegrationMappingDebugApplyResultQl;
};

export type IntegrationMappingDebugQlApplyCodeArgs = {
    code: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type IntegrationMappingQl = {
    __typename?: 'IntegrationMappingQL';
    code?: Maybe<Scalars['String']['output']>;
    debug: IntegrationMappingDebugQl;
};

export type IntegrationMappingQlCodeArgs = {
    slot?: IntegrationCodeSlots;
};

export enum IntegrationProvider {
    CrmDynamics = 'CRM_DYNAMICS',
    CrmFake = 'CRM_FAKE',
    CrmSalesforce = 'CRM_SALESFORCE',
    ExternalUrl = 'EXTERNAL_URL',
}

export type IntegrationQl = NodeQl & {
    __typename?: 'IntegrationQL';
    clientConfigUrl?: Maybe<Scalars['String']['output']>;
    createdDate: Scalars['DateTime']['output'];
    driver: IntegrationDriver;
    id: Scalars['String']['output'];
    /** @deprecated It's always 'CRM' */
    integrationType: IntegrationType;
    mapper: IntegrationMapper;
    mapping: IntegrationMappingQl;
    name: Scalars['String']['output'];
    provider: IntegrationProvider;
    rawMappingConfig?: Maybe<Scalars['JSON']['output']>;
    updatedDate: Scalars['DateTime']['output'];
    verifySecureConnection: Scalars['Boolean']['output'];
};

export enum IntegrationType {
    Crm = 'CRM',
}

export enum LicenseType {
    Enterprise = 'ENTERPRISE',
    Medium = 'MEDIUM',
    Other = 'OTHER',
    PaidTrial = 'PAID_TRIAL',
    Small = 'SMALL',
}

export type LinkedFieldInputQl = {
    name: Scalars['String']['input'];
    type: LinkedFieldType;
};

export type LinkedFieldQl = {
    __typename?: 'LinkedFieldQL';
    name: Scalars['String']['output'];
    type: LinkedFieldType;
};

export enum LinkedFieldType {
    CompanyRole = 'COMPANY_ROLE',
    ContactRole = 'CONTACT_ROLE',
    DealCustom = 'DEAL_CUSTOM',
    DealNative = 'DEAL_NATIVE',
}

export type LinkedInProfileContactInformationQl = {
    __typename?: 'LinkedInProfileContactInformationQL';
    personalEmail: Array<Scalars['String']['output']>;
    phone: Array<Scalars['String']['output']>;
    workEmail: Array<Scalars['String']['output']>;
};

export type LinkedInProfileQl = {
    __typename?: 'LinkedInProfileQL';
    companyCountry: Scalars['String']['output'];
    companyName: Scalars['String']['output'];
    country: Scalars['String']['output'];
    fullName: Scalars['String']['output'];
    hasEmail: Scalars['Boolean']['output'];
    hasPhone: Scalars['Boolean']['output'];
    headline: Scalars['String']['output'];
    latestExperience: Scalars['String']['output'];
    linkedinUsername: Scalars['String']['output'];
    location: Scalars['String']['output'];
    profilePictureUrl: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type LinkedInSearchResultQl = {
    __typename?: 'LinkedInSearchResultQL';
    metaData: SearchMetaDataQl;
    profiles: Array<LinkedInProfileQl>;
};

export type MentionQl = {
    __typename?: 'MentionQL';
    commentId: Scalars['String']['output'];
    creatorBid: Scalars['Int']['output'];
    deal: DealQl;
    isRead: Scalars['Boolean']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type Mutation = {
    __typename?: 'Mutation';
    addDealComment: CommentQl;
    autoEnrichCompany: RelevantCompanyQl;
    bulkMove: BulkMoveResultQl;
    bulkUpdateDealAttributes: BulkUpdateAttributesResultQl;
    bulkUpdateDealCustomValues: BulkUpdateCustomFieldsResultQl;
    completeReminder: ReminderQl;
    createActivityLog: ActivityLogQl;
    createContact: ContactQl;
    createContactsFromExtractedContacts: CreateContactsFromExtractedContactsResultQl;
    createDeal: CreateDealResultQl;
    createOutreachBlock: OutreachBlockQl;
    createRelevantCompany: RelevantCompanyQl;
    createReminder: ReminderQl;
    debugSendEmail: OutboundEmailResultQl;
    deleteActivityLog: ResultQl;
    deleteComment: ResultQl;
    deleteContact: ResultQl;
    deleteOutreachBlock: ResultQl;
    deleteRelevantCompany: ResultQl;
    deleteReminder: ResultQl;
    dismissCommentMention: ResultQl;
    disqualifyDeal: DealQl;
    exportDealEmail: OutboundEmailResultQl;
    exportToCrm: EntitySyncRequestResultQl;
    markDealAsDone: DealQl;
    moveToStage: ResultQl;
    overrideCompanyInfoWithPlace: RelevantCompanyQl;
    pauseDeal: ResultQl;
    readCommentMention: ResultQl;
    removeDealContent: ResultQl;
    removePlaceFromCompany: RelevantCompanyQl;
    restoreDeal: DealQl;
    runContactExtractionForDeal: ResultQl;
    uncompleteReminder: ReminderQl;
    unpauseDeal: ResultQl;
    updateActivityLog: ActivityLogQl;
    updateComment: CommentQl;
    updateContact: ContactQl;
    updateDeal: ResultQl;
    updateDealCustomValues: ResultQl;
    updateObjective: ResultQl;
    updateOutreachBlock: ResultQl;
    updateRelevantCompany: RelevantCompanyQl;
    updateReminder: ReminderQl;
    updateUserAchievementState: ResultQl;
    updateUserAchievementsEnrolmentOptIn: ResultQl;
};

export type MutationAddDealCommentArgs = {
    data: CommentCreateQl;
    dealId: Scalars['String']['input'];
};

export type MutationAutoEnrichCompanyArgs = {
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationBulkMoveArgs = {
    dealIds: Array<Scalars['String']['input']>;
    targetDealState: DealState;
    targetPipelineId: Scalars['String']['input'];
    targetStageId: Scalars['String']['input'];
    unpauseDeals?: Scalars['Boolean']['input'];
};

export type MutationBulkUpdateDealAttributesArgs = {
    attribute: DealAttribute;
    dealIds: Array<Scalars['String']['input']>;
    value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationBulkUpdateDealCustomValuesArgs = {
    dealIds: Array<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type MutationCompleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationCreateActivityLogArgs = {
    activityType: ActivityLogType;
    contactId?: InputMaybe<Scalars['String']['input']>;
    customType?: Scalars['String']['input'];
    dealId?: InputMaybe<Scalars['String']['input']>;
    notes?: Scalars['String']['input'];
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    subType?: InputMaybe<ActivityLogSubType>;
};

export type MutationCreateContactArgs = {
    createdFrom?: InputMaybe<Scalars['String']['input']>;
    data: ContactMutationQl;
    dealId: Scalars['String']['input'];
    enrichmentProviders?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateContactsFromExtractedContactsArgs = {
    dealId: Scalars['String']['input'];
    extractedCompanies: Array<ReformattedExtractedCompanyInputQl>;
    extractedContacts: Array<ReformattedExtractedContactInputQl>;
};

export type MutationCreateDealArgs = {
    creationSource?: InputMaybe<DealCreationSource>;
    currentSearchName?: InputMaybe<Scalars['String']['input']>;
    pipelineId: Scalars['String']['input'];
    projectDataFromCustomText?: InputMaybe<ProjectDataFromCustomTextQl>;
    projectDataFromSearchResult?: InputMaybe<ProjectDataFromSearchResultQl>;
    projectId?: InputMaybe<Scalars['String']['input']>;
    searchNames?: InputMaybe<Array<Scalars['String']['input']>>;
    title: Scalars['String']['input'];
};

export type MutationCreateOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockCreateMutationQl;
    vpoSessionId: Scalars['String']['input'];
};

export type MutationCreateRelevantCompanyArgs = {
    createdFrom?: InputMaybe<Scalars['String']['input']>;
    data: RelevantCompanyCreateQl;
    dealId: Scalars['String']['input'];
    enrichmentProviders?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateReminderArgs = {
    data: ReminderCreateInputQl;
    launchSource?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDebugSendEmailArgs = {
    inEmail: DebugEmailInputQl;
};

export type MutationDeleteActivityLogArgs = {
    activityLogId: Scalars['String']['input'];
};

export type MutationDeleteCommentArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationDeleteContactArgs = {
    contactId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type MutationDeleteOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlockId: Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
};

export type MutationDeleteRelevantCompanyArgs = {
    dealId: Scalars['String']['input'];
    deleteAssociatedContacts?: Scalars['Boolean']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationDeleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationDismissCommentMentionArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationDisqualifyDealArgs = {
    dealId: Scalars['String']['input'];
    reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationExportDealEmailArgs = {
    inEmail: ExportDealEmailInputQl;
};

export type MutationExportToCrmArgs = {
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
};

export type MutationMarkDealAsDoneArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationMoveToStageArgs = {
    dealId: Scalars['String']['input'];
    stageId: Scalars['String']['input'];
};

export type MutationOverrideCompanyInfoWithPlaceArgs = {
    dealId: Scalars['String']['input'];
    fields?: InputMaybe<Array<Scalars['String']['input']>>;
    placeId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationPauseDealArgs = {
    dealId: Scalars['String']['input'];
    pausedUntil: Scalars['DateTime']['input'];
};

export type MutationReadCommentMentionArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationRemoveDealContentArgs = {
    contentId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type MutationRemovePlaceFromCompanyArgs = {
    field?: InputMaybe<Scalars['String']['input']>;
    placeId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationRestoreDealArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationRunContactExtractionForDealArgs = {
    dealId: Scalars['String']['input'];
    language?: UserLanguage;
};

export type MutationUncompleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationUnpauseDealArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationUpdateActivityLogArgs = {
    activityLogId: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    customType?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCommentArgs = {
    commentId: Scalars['String']['input'];
    update: CommentUpdateQl;
};

export type MutationUpdateContactArgs = {
    contactId: Scalars['String']['input'];
    update: ContactUpdateQl;
};

export type MutationUpdateDealArgs = {
    dealId: Scalars['String']['input'];
    update: DealUpdateInputQl;
};

export type MutationUpdateDealCustomValuesArgs = {
    dealId: Scalars['String']['input'];
    update: DealUpdateCustomInputQl;
};

export type MutationUpdateObjectiveArgs = {
    objectiveId: Scalars['String']['input'];
    update: ObjectiveUpdateInputQl;
};

export type MutationUpdateOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockUpdateMutationQl;
    vpoSessionId: Scalars['String']['input'];
};

export type MutationUpdateRelevantCompanyArgs = {
    relevantCompanyId: Scalars['String']['input'];
    update: RelevantCompanyUpdateQl;
};

export type MutationUpdateReminderArgs = {
    reminderId: Scalars['String']['input'];
    update: ReminderUpdateInputQl;
};

export type MutationUpdateUserAchievementStateArgs = {
    achievementName: UserAchievementName;
    status: UserAchievementState;
};

export type MutationUpdateUserAchievementsEnrolmentOptInArgs = {
    optIn: Scalars['Boolean']['input'];
};

export type NodeQl = {
    id: Scalars['String']['output'];
};

export type ObjectiveConfigQl = {
    __typename?: 'ObjectiveConfigQL';
    criteria: Scalars['String']['output'];
    id?: Maybe<Scalars['String']['output']>;
    linkedField?: Maybe<LinkedFieldQl>;
    order: Scalars['Int']['output'];
    type: ObjectiveType;
};

export type ObjectiveQl = NodeQl & {
    __typename?: 'ObjectiveQL';
    criteria: Scalars['String']['output'];
    id: Scalars['String']['output'];
    linkedField?: Maybe<LinkedFieldQl>;
    objectiveTemplate?: Maybe<Scalars['String']['output']>;
    order?: Maybe<Scalars['Int']['output']>;
    state: Tristate;
    status: Scalars['String']['output'];
    type: ObjectiveType;
};

export enum ObjectiveType {
    Confirm = 'CONFIRM',
    Question = 'QUESTION',
}

export type ObjectiveUpdateInputQl = {
    criteria?: InputMaybe<Scalars['String']['input']>;
    linkedField?: InputMaybe<LinkedFieldInputQl>;
    state?: InputMaybe<Tristate>;
    status?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<ObjectiveType>;
};

export type OmniSearchResultQl = {
    __typename?: 'OmniSearchResultQL';
    activityLogs: Array<ActivityLogQl>;
    comments: Array<CommentQl>;
    companies: Array<RelevantCompanyQl>;
    contacts: Array<ContactQl>;
    deals: Array<DealQl>;
    nextSteps: Array<ReminderQl>;
};

export type OutboundEmailQl = NodeQl & {
    __typename?: 'OutboundEmailQL';
    addresses: EmailAddressHeaderQl;
    enqueuedDate?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['String']['output'];
    mtaMessageId?: Maybe<Scalars['String']['output']>;
    senderBid: Scalars['Int']['output'];
    sentDate?: Maybe<Scalars['DateTime']['output']>;
    state: OutboundEmailState;
    template: EmailTemplateParamsQl;
};

export type OutboundEmailResultQl = {
    __typename?: 'OutboundEmailResultQL';
    email: OutboundEmailQl;
    ok: Scalars['Boolean']['output'];
};

export enum OutboundEmailState {
    Draft = 'DRAFT',
    Enqueued = 'ENQUEUED',
    Failed = 'FAILED',
    Sending = 'SENDING',
    Sent = 'SENT',
}

export type OutreachBlockCreateMutationQl = {
    text: Scalars['String']['input'];
    title: Scalars['String']['input'];
    type: OutreachBlockType;
};

export type OutreachBlockQl = {
    __typename?: 'OutreachBlockQl';
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    outreachType: OutreachType;
    text: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: OutreachBlockType;
    updatedDate: Scalars['DateTime']['output'];
};

export enum OutreachBlockType {
    DesiredOutcome = 'DESIRED_OUTCOME',
    ValueProposition = 'VALUE_PROPOSITION',
    WritingStyle = 'WRITING_STYLE',
}

export type OutreachBlockUpdateMutationQl = {
    id: Scalars['String']['input'];
    text?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export enum OutreachType {
    All = 'ALL',
    Call = 'CALL',
    Email = 'EMAIL',
}

export type PageInfoQl = {
    __typename?: 'PageInfoQL';
    count: Scalars['Int']['output'];
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationConfQl = {
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PipelineConfigQl = {
    __typename?: 'PipelineConfigQL';
    averageDealValue: ValueCurrencyQl;
    goalsEnabled: Scalars['Boolean']['output'];
    revenueGoal: ValueCurrencyQl;
    stages: PipelineStagesConfigQl;
    timePeriodStart: Scalars['DateTime']['output'];
};

export type PipelineQl = NodeQl & {
    __typename?: 'PipelineQL';
    config: PipelineConfigQl;
    deals: Array<DealQl>;
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    statistics: PipelineStatsQl;
    visible: Scalars['Boolean']['output'];
};

export type PipelineQlStatisticsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type PipelineStageConfigQl = {
    __typename?: 'PipelineStageConfigQL';
    defaultObjectives?: Maybe<Array<ObjectiveConfigQl>>;
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    stageConversionTime: Scalars['Float']['output'];
    targetConversionRate?: Maybe<Scalars['Float']['output']>;
};

export type PipelineStageStatsDealQl = {
    __typename?: 'PipelineStageStatsDealQL';
    numberOfDeals: Scalars['Int']['output'];
    /** @deprecated Calculation no longer needed. */
    totalDealValue: Scalars['BigInt']['output'];
};

export type PipelineStageStatsQl = {
    __typename?: 'PipelineStageStatsQL';
    atRiskDeals: PipelineStageStatsDealQl;
    disqualifiedDeals: PipelineStageStatsDealQl;
    goal: Scalars['Int']['output'];
    healthyDeals: PipelineStageStatsDealQl;
    id: Scalars['String']['output'];
    inStageDeals: PipelineStageStatsDealQl;
    markedDoneDeals: PipelineStageStatsDealQl;
    observedConversionRate: Scalars['Float']['output'];
    progressedDeals: PipelineStageStatsDealQl;
    targetConversionRate: Scalars['Float']['output'];
};

export type PipelineStagesConfigQl = {
    __typename?: 'PipelineStagesConfigQL';
    initialStage: Scalars['String']['output'];
    lastStage: Scalars['String']['output'];
    stages: Array<PipelineStageConfigQl>;
};

export type PipelineStatisticsQl = {
    __typename?: 'PipelineStatisticsQL';
    companyBid: Scalars['Int']['output'];
    countContacts: Scalars['Int']['output'];
    countDeals: Scalars['Int']['output'];
    countDealsWon: Scalars['Int']['output'];
    countLeadsQualified: Scalars['Int']['output'];
    countMeetingsBooked: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    pipelineId: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
    userId?: Maybe<Scalars['Int']['output']>;
};

export type PipelineStatsQl = {
    __typename?: 'PipelineStatsQL';
    dynamicFiltersMetrics: Array<DynamicFilterMetricsQl>;
    stages: Array<PipelineStageStatsQl>;
};

export type PlaceDetailsQl = {
    __typename?: 'PlaceDetailsQL';
    addressDetail?: Maybe<AddressDetailQl>;
    email?: Maybe<Scalars['String']['output']>;
    formattedAddress?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    internationalPhoneNumber?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    source: PlaceSource;
    type?: Maybe<Scalars['String']['output']>;
    website?: Maybe<Scalars['String']['output']>;
};

export type PlaceQl = NodeQl & {
    __typename?: 'PlaceQL';
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export enum PlaceSource {
    GooglePlaces = 'GOOGLE_PLACES',
    WebSearch = 'WEB_SEARCH',
}

export type ProjectAddressQl = {
    __typename?: 'ProjectAddressQL';
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    state: Scalars['String']['output'];
};

export type ProjectDataFromCustomTextQl = {
    body?: InputMaybe<Scalars['String']['input']>;
    publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
    sourceUrl?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectDataFromSearchResultQl = {
    articleId: Scalars['String']['input'];
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    projectTypes: Array<CategoryNames>;
    publishedDate: Scalars['DateTime']['input'];
    sourceUrls: Array<Scalars['String']['input']>;
    state: Scalars['String']['input'];
    summary: Scalars['String']['input'];
};

export type ProjectDetailsQl = {
    __typename?: 'ProjectDetailsQL';
    language: UserLanguage;
    paragraphs: Array<Scalars['String']['output']>;
    projectAddress?: Maybe<ProjectAddressQl>;
    projectTypes: Array<CategoryNames>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    sourceUrl?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type ProjectQl = {
    __typename?: 'ProjectQL';
    projectDetails?: Maybe<ProjectDetailsQl>;
    projectId: Scalars['String']['output'];
    type: ProjectType;
};

export enum ProjectType {
    ArticleFreeText = 'ARTICLE_FREE_TEXT',
    ArticleItemId = 'ARTICLE_ITEM_ID',
    Br = 'BR',
    Unknown = 'UNKNOWN',
}

export type QuarterlySalesPeriodQl = {
    __typename?: 'QuarterlySalesPeriodQL';
    frequency: SalesPeriodFrequency;
    quarter: Scalars['Int']['output'];
    year: Scalars['Int']['output'];
};

export type Query = {
    __typename?: 'Query';
    actionFeatureContactEnrichment: ContactEnrichmentResultQl;
    actionFeatureContactEnrichmentCompanyDomain: Array<DomainEnrichmentDataValueQl>;
    actionFeatureGoogleEnrichmentPlaceDetails: PlaceDetailsQl;
    actionFeatureGoogleEnrichmentPlaces: Array<PlaceQl>;
    actionFeatureValuePropOutreachCallScript: QuestionResponseQl;
    actionFeatureValuePropOutreachEmail: QuestionResponseQl;
    actionFeatureValuePropOutreachFollowUp: QuestionResponseQl;
    actionFeatureXray: QuestionResponseQl;
    allDeals: Array<DealQl>;
    allEntitySyncRequests: Array<EntitySyncRequestQl>;
    allExportMappings: Array<ExportMappingQl>;
    allIntegrations: Array<IntegrationQl>;
    allOutboundemails: Array<OutboundEmailQl>;
    /** Returns all pipelines */
    allPipelines: Array<PipelineQl>;
    allValueMilestones: Array<ValueMilestoneQl>;
    appInfo: AppInfoQl;
    colleaguesAchievements: Array<ColleagueAchievementsQl>;
    company: CompanyQl;
    deal: DealQl;
    dealsCollection: DealCollectionInfoQl;
    debug: DebugQl;
    entitySyncRequest: EntitySyncRequestQl;
    exportMapping: ExportMappingQl;
    getCompanyLinkedinProfiles: LinkedInSearchResultQl;
    getContactExtractionResult: CombinedContactExtractionResultQl;
    getDealAttachmentDownloadUrl: StringQl;
    getLinkedinProfileContactInformation: LinkedInProfileContactInformationQl;
    integration: IntegrationQl;
    me: UserQl;
    omniSearch: OmniSearchResultQl;
    outboundemail: OutboundEmailQl;
    pipeline: PipelineQl;
    pipelineStatistics?: Maybe<PipelineStatisticsQl>;
    timeoutTest: Scalars['String']['output'];
    translate: Scalars['String']['output'];
    userAchievements: UserAchievementsQl;
};

export type QueryActionFeatureContactEnrichmentArgs = {
    company: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    contactName: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    domain?: InputMaybe<Scalars['String']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    triggeredFromSearchRefinement?: Scalars['Boolean']['input'];
};

export type QueryActionFeatureContactEnrichmentCompanyDomainArgs = {
    companyName: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureGoogleEnrichmentPlaceDetailsArgs = {
    language: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
};

export type QueryActionFeatureGoogleEnrichmentPlacesArgs = {
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type QueryActionFeatureValuePropOutreachCallScriptArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureValuePropOutreachEmailArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    vpoSessionId: Scalars['String']['input'];
};

export type QueryActionFeatureValuePropOutreachFollowUpArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureXrayArgs = {
    dealId?: InputMaybe<Scalars['String']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['Int']['input']>;
    question: Scalars['String']['input'];
};

export type QueryAllDealsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryAllEntitySyncRequestsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryAllPipelinesArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryDealArgs = {
    dealId: Scalars['String']['input'];
};

export type QueryDealsCollectionArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryEntitySyncRequestArgs = {
    esrId: Scalars['String']['input'];
};

export type QueryExportMappingArgs = {
    exportMappingId: Scalars['String']['input'];
};

export type QueryGetCompanyLinkedinProfilesArgs = {
    companyName: Scalars['String']['input'];
    keyword?: InputMaybe<Scalars['String']['input']>;
    location?: InputMaybe<Scalars['String']['input']>;
    page?: Scalars['Int']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetContactExtractionResultArgs = {
    dealId: Scalars['String']['input'];
};

export type QueryGetDealAttachmentDownloadUrlArgs = {
    contentId: Scalars['String']['input'];
    inline?: Scalars['Boolean']['input'];
};

export type QueryGetLinkedinProfileContactInformationArgs = {
    linkedinUsername: Scalars['String']['input'];
};

export type QueryIntegrationArgs = {
    integrationId: Scalars['String']['input'];
};

export type QueryOmniSearchArgs = {
    limit: Scalars['Int']['input'];
    query: Scalars['String']['input'];
};

export type QueryOutboundemailArgs = {
    emailId: Scalars['String']['input'];
};

export type QueryPipelineArgs = {
    pipelineId: Scalars['String']['input'];
};

export type QueryPipelineStatisticsArgs = {
    forCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
    pipelineId: Scalars['String']['input'];
};

export type QueryTimeoutTestArgs = {
    timeoutSec?: Scalars['Int']['input'];
};

export type QueryTranslateArgs = {
    language: Scalars['String']['input'];
    text: Scalars['String']['input'];
};

export type QueryParamsQl = {
    /** Optional dynamic filters to apply to the result set. Multiple dynamic filters are combined with OR and applied after the 'filters'. */
    dynamicFiltering?: InputMaybe<Array<DynamicFilter>>;
    /** Optional filters to apply to the result set. Multiple filters are combined with AND. */
    filtering?: InputMaybe<Array<FilterOpQl>>;
    pagination?: InputMaybe<PaginationConfQl>;
    /** Optional sorting to apply to the result set. */
    sorting?: InputMaybe<Array<SortingOpQl>>;
};

export type QuestionResponseQl = {
    __typename?: 'QuestionResponseQL';
    answer: Scalars['String']['output'];
};

export type ReformattedExtractedCompanyInputQl = {
    customValues?: InputMaybe<Array<CustomFieldValueInputQl>>;
    extractionTypes: Array<ExtractionType>;
    name: Scalars['String']['input'];
    notes: Scalars['String']['input'];
};

export type ReformattedExtractedContactInputQl = {
    companyName: Scalars['String']['input'];
    customValues?: InputMaybe<Array<CustomFieldValueInputQl>>;
    extractionTypes: Array<ExtractionType>;
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    notes: Scalars['String']['input'];
};

export type RegenerationSpecQl = {
    previousText: Scalars['String']['input'];
    userRawPrompt: Scalars['String']['input'];
};

export type RelevantCompanyCreateQl = {
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    name: Scalars['String']['input'];
    notes?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type RelevantCompanyQl = NodeQl & {
    __typename?: 'RelevantCompanyQL';
    contacts?: Maybe<Array<ContactQl>>;
    createdDate: Scalars['DateTime']['output'];
    customFields?: Maybe<Array<CustomFieldQl>>;
    extracted?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['String']['output'];
    isKey?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    notes?: Maybe<Scalars['String']['output']>;
    parentDeals?: Maybe<Array<DealQl>>;
    placeDetails?: Maybe<Array<PlaceDetailsQl>>;
    role?: Maybe<Scalars['String']['output']>;
};

export type RelevantCompanyUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type RelevantCompanyUpdateQl = {
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderCreateInputQl = {
    assigneeId?: InputMaybe<Scalars['Int']['input']>;
    description: Scalars['String']['input'];
    dueDate: Scalars['Date']['input'];
    target: ReminderTargetInputQl;
};

export type ReminderQl = {
    __typename?: 'ReminderQL';
    assignee: Scalars['Int']['output'];
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    createdDate: Scalars['DateTime']['output'];
    creator: Scalars['Int']['output'];
    description: Scalars['String']['output'];
    dueDate: Scalars['Date']['output'];
    id: Scalars['String']['output'];
    target: ReminderTargetQl;
    updatedDate: Scalars['DateTime']['output'];
};

export type ReminderTargetInputQl = {
    id: Scalars['String']['input'];
    type: ReminderTargetType;
};

export type ReminderTargetQl = {
    __typename?: 'ReminderTargetQL';
    id: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: ReminderTargetType;
};

export enum ReminderTargetType {
    Deal = 'DEAL',
}

export type ReminderUpdateInputQl = {
    assigneeId?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    dueDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ResultQl = {
    __typename?: 'ResultQL';
    ok: Scalars['Boolean']['output'];
};

export type RoleConfigQl = {
    __typename?: 'RoleConfigQL';
    exportable?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
};

export type RolesConfig = {
    __typename?: 'RolesConfig';
    contacts?: Maybe<Array<RoleConfigQl>>;
    relevantCompanies?: Maybe<Array<RoleConfigQl>>;
};

export type SalesPeriodConfigurationQl = {
    __typename?: 'SalesPeriodConfigurationQL';
    frequency: SalesPeriodFrequency;
};

export enum SalesPeriodFrequency {
    Annually = 'ANNUALLY',
    Quarterly = 'QUARTERLY',
}

export type SearchMetaDataQl = {
    __typename?: 'SearchMetaDataQL';
    page: Scalars['Int']['output'];
    pageSize: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
};

export enum SortingKeys {
    SkActivityLogCreatedDate = 'SK_ACTIVITY_LOG_CREATED_DATE',
    SkDealAssigneeBid = 'SK_DEAL_ASSIGNEE_BID',
    SkDealClosestNextStepDate = 'SK_DEAL_CLOSEST_NEXT_STEP_DATE',
    SkDealCompletedDate = 'SK_DEAL_COMPLETED_DATE',
    SkDealCreatedDate = 'SK_DEAL_CREATED_DATE',
    SkDealCsvExportedDate = 'SK_DEAL_CSV_EXPORTED_DATE',
    SkDealCurrentStageId = 'SK_DEAL_CURRENT_STAGE_ID',
    SkDealExpectedCurrentStageConversionDate = 'SK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
    SkDealExpectedFinalConversionDate = 'SK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
    SkDealExpectedSalesPeriod = 'SK_DEAL_EXPECTED_SALES_PERIOD',
    SkDealOutreachCount = 'SK_DEAL_OUTREACH_COUNT',
    SkDealPipelineId = 'SK_DEAL_PIPELINE_ID',
    SkDealStageUpdatedDate = 'SK_DEAL_STAGE_UPDATED_DATE',
    SkDealState = 'SK_DEAL_STATE',
    SkDealTitle = 'SK_DEAL_TITLE',
    SkDealValueCurrencyNaive = 'SK_DEAL_VALUE_CURRENCY_NAIVE',
    SkEsrCreatedDate = 'SK_ESR_CREATED_DATE',
    SkPipeCreatedDate = 'SK_PIPE_CREATED_DATE',
}

export type SortingOpQl = {
    field: SortingKeys;
    sop: SortingOperators;
};

export enum SortingOperators {
    SopAsc = 'SOP_ASC',
    SopDesc = 'SOP_DESC',
}

export type StringQl = {
    __typename?: 'StringQL';
    value: Scalars['String']['output'];
};

export type Subscription = {
    __typename?: 'Subscription';
    actionFeatureValuePropOutreachCallScript: QuestionResponseQl;
    actionFeatureValuePropOutreachEmail: QuestionResponseQl;
    actionFeatureValuePropOutreachFollowUp: QuestionResponseQl;
    actionFeatureXray: QuestionResponseQl;
    subscribeUserUpdates: InstantMessageQl;
};

export type SubscriptionActionFeatureValuePropOutreachCallScriptArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionActionFeatureValuePropOutreachEmailArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    vpoSessionId: Scalars['String']['input'];
};

export type SubscriptionActionFeatureValuePropOutreachFollowUpArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionActionFeatureXrayArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    dealId?: InputMaybe<Scalars['String']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['Int']['input']>;
    question: Scalars['String']['input'];
};

export enum Tristate {
    Failure = 'FAILURE',
    NotDecided = 'NOT_DECIDED',
    Success = 'SUCCESS',
}

export enum UserAchievementName {
    AddContactsEmailOrPhone = 'ADD_CONTACTS_EMAIL_OR_PHONE',
    AskArticleWizard = 'ASK_ARTICLE_WIZARD',
    AssignContactDealRole = 'ASSIGN_CONTACT_DEAL_ROLE',
    BookedFirstMeeting = 'BOOKED_FIRST_MEETING',
    CreateYourFirstContact = 'CREATE_YOUR_FIRST_CONTACT',
    CreateYourFirstDeal = 'CREATE_YOUR_FIRST_DEAL',
    DisqualifyADeal = 'DISQUALIFY_A_DEAL',
    OutreachByEmail = 'OUTREACH_BY_EMAIL',
    OutreachByPhone = 'OUTREACH_BY_PHONE',
    PauseADeal = 'PAUSE_A_DEAL',
    PlanANextStep = 'PLAN_A_NEXT_STEP',
    ResolveANextStep = 'RESOLVE_A_NEXT_STEP',
}

export enum UserAchievementState {
    Achieved = 'ACHIEVED',
    NotAchieved = 'NOT_ACHIEVED',
    Started = 'STARTED',
}

export type UserAchievementsDetailsQl = {
    __typename?: 'UserAchievementsDetailsQL';
    addContactsEmailOrPhone: UserSingleAchievementDetailsQl;
    askArticleWizard: UserSingleAchievementDetailsQl;
    assignContactDealRole: UserSingleAchievementDetailsQl;
    bookedFirstMeeting: UserSingleAchievementDetailsQl;
    createYourFirstContact: UserSingleAchievementDetailsQl;
    createYourFirstDeal: UserSingleAchievementDetailsQl;
    disqualifyADeal: UserSingleAchievementDetailsQl;
    outreachByEmail: UserSingleAchievementDetailsQl;
    outreachByPhone: UserSingleAchievementDetailsQl;
    pauseADeal: UserSingleAchievementDetailsQl;
    planANextStep: UserSingleAchievementDetailsQl;
    resolveANextStep: UserSingleAchievementDetailsQl;
};

export type UserAchievementsQl = {
    __typename?: 'UserAchievementsQL';
    achievements: UserAchievementsDetailsQl;
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
    userId: Scalars['Int']['output'];
};

export enum UserFeatureFlag {
    CompanyInfoWeb = 'COMPANY_INFO_WEB',
    SampleFlag = 'SAMPLE_FLAG',
}

export enum UserLanguage {
    De = 'DE',
    En = 'EN',
}

export type UserQl = NodeQl & {
    __typename?: 'UserQL';
    companyId: Scalars['String']['output'];
    featureEnabled: Scalars['Boolean']['output'];
    hubspotToken: Scalars['String']['output'];
    id: Scalars['String']['output'];
    mentions?: Maybe<Array<MentionQl>>;
    myDeals: Array<DealQl>;
    reminders: Array<ReminderQl>;
};

export type UserQlFeatureEnabledArgs = {
    defaultValue?: Scalars['Boolean']['input'];
    featureFlag: UserFeatureFlag;
};

export type UserSingleAchievementDetailsQl = {
    __typename?: 'UserSingleAchievementDetailsQL';
    achievementState: UserAchievementState;
    achievementStateUpdateDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ValueCurrencyInput = {
    currency: Scalars['String']['input'];
    value: Scalars['BigInt']['input'];
};

export type ValueCurrencyQl = {
    __typename?: 'ValueCurrencyQL';
    currency: Scalars['String']['output'];
    value: Scalars['BigInt']['output'];
};

export type ValueMilestoneQl = {
    __typename?: 'ValueMilestoneQL';
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    pipeline: Scalars['String']['output'];
    stage: Scalars['String']['output'];
    type: ValueMilestoneType;
    updatedDate: Scalars['DateTime']['output'];
};

export enum ValueMilestoneType {
    DealClosedWon = 'DEAL_CLOSED_WON',
    LeadQualified = 'LEAD_QUALIFIED',
    MeetingBooked = 'MEETING_BOOKED',
}

export type DealAlertPartFragment = {
    __typename?: 'DealAlertQL';
    type: DealAlertType;
    currentUserRelated: boolean;
};

export type ActivityLogPartFragment = {
    __typename?: 'ActivityLogQL';
    id: string;
    companyBid: number;
    createdDate: any;
    updatedDate: any;
    userId: number;
    type: ActivityLogType;
    notes: string;
    dealId?: string | null;
    contactId?: string | null;
    relevantCompanyId?: string | null;
    subType?: ActivityLogSubType | null;
    customType: string;
};

export type DealPartFragment = {
    __typename?: 'DealQL';
    id: string;
    title: string;
    assignee: number;
    state: DealState;
    notes: string;
    stageUpdatedDate: any;
    createdDate: any;
    completedDate?: any | null;
    pausedUntil?: any | null;
    summary?: string | null;
    value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
    project?: { __typename?: 'ProjectQL'; projectId: string } | null;
    pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
    currentObjectives: Array<{
        __typename?: 'ObjectiveQL';
        criteria: string;
        id: string;
        state: Tristate;
        status: string;
        type: ObjectiveType;
        order?: number | null;
        linkedField?: {
            __typename?: 'LinkedFieldQL';
            name: string;
            type: LinkedFieldType;
        } | null;
    }>;
    currentStage: { __typename?: 'DealStageQL'; id: string };
    exportedDate?: {
        __typename?: 'ExportedDateQL';
        email?: any | null;
        csv?: any | null;
    } | null;
    reminders: Array<{
        __typename?: 'ReminderQL';
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: {
            __typename?: 'ReminderTargetQL';
            id: string;
            type: ReminderTargetType;
            title: string;
        };
    }>;
    alert: {
        __typename?: 'DealAlertQL';
        type: DealAlertType;
        currentUserRelated: boolean;
    };
    activityLog: Array<{
        __typename?: 'ActivityLogQL';
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    }>;
};

export type DealAnnualSalesPeriodFragment = {
    __typename?: 'AnnualSalesPeriodQL';
    year: number;
    frequency: SalesPeriodFrequency;
};

export type DealQuarterlySalesPeriodFragment = {
    __typename?: 'QuarterlySalesPeriodQL';
    year: number;
    quarter: number;
    frequency: SalesPeriodFrequency;
};

export type PipelineStatisticsDealPartFragment = {
    __typename?: 'DealQL';
    id: string;
    title: string;
    assignee: number;
    createdDate: any;
    completedDate?: any | null;
    state: DealState;
    pausedUntil?: any | null;
    expectedCurrentStageConversionDate: any;
    value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
    currentStage: { __typename?: 'DealStageQL'; id: string };
    exportedDate?: {
        __typename?: 'ExportedDateQL';
        email?: any | null;
        csv?: any | null;
    } | null;
    expectedSalesPeriod?:
        | {
              __typename?: 'AnnualSalesPeriodQL';
              year: number;
              frequency: SalesPeriodFrequency;
          }
        | {
              __typename?: 'QuarterlySalesPeriodQL';
              year: number;
              quarter: number;
              frequency: SalesPeriodFrequency;
          }
        | null;
    alert: {
        __typename?: 'DealAlertQL';
        type: DealAlertType;
        currentUserRelated: boolean;
    };
    project?: { __typename?: 'ProjectQL'; projectId: string } | null;
};

export type MinimalDealPartFragment = {
    __typename?: 'DealQL';
    id: string;
    title: string;
    assignee: number;
    createdDate: any;
    completedDate?: any | null;
    state: DealState;
    pausedUntil?: any | null;
    expectedCurrentStageConversionDate: any;
    pipeline: { __typename?: 'PipelineQL'; name: string; id: string };
    value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
    currentStage: { __typename?: 'DealStageQL'; id: string };
    exportedDate?: {
        __typename?: 'ExportedDateQL';
        email?: any | null;
        csv?: any | null;
    } | null;
    expectedSalesPeriod?:
        | {
              __typename?: 'AnnualSalesPeriodQL';
              year: number;
              frequency: SalesPeriodFrequency;
          }
        | {
              __typename?: 'QuarterlySalesPeriodQL';
              year: number;
              quarter: number;
              frequency: SalesPeriodFrequency;
          }
        | null;
    alert: {
        __typename?: 'DealAlertQL';
        type: DealAlertType;
        currentUserRelated: boolean;
    };
    project?: { __typename?: 'ProjectQL'; projectId: string } | null;
};

export type PipelineDealPartFragment = {
    __typename?: 'DealQL';
    id: string;
    pausedUntil?: any | null;
    title: string;
    stageUpdatedDate: any;
    assignee: number;
    alert: {
        __typename?: 'DealAlertQL';
        type: DealAlertType;
        currentUserRelated: boolean;
    };
    currentStage: { __typename?: 'DealStageQL'; id: string };
    outreachLogStats: {
        __typename?: 'ActivityLogStatsQL';
        count: number;
        lastActivityDate?: any | null;
    };
};

export type ObjectivePartFragment = {
    __typename?: 'ObjectiveQL';
    criteria: string;
    id: string;
    state: Tristate;
    status: string;
    type: ObjectiveType;
    order?: number | null;
    linkedField?: {
        __typename?: 'LinkedFieldQL';
        name: string;
        type: LinkedFieldType;
    } | null;
};

export type ProjectIdPartFragment = {
    __typename?: 'ProjectQL';
    projectId: string;
};

export type DealContactPartFragment = {
    __typename?: 'ContactQL';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    role?: string | null;
    notes?: string | null;
    extracted?: boolean | null;
    isKey?: boolean | null;
    createdDate: any;
    customFields?: Array<{
        __typename?: 'CustomFieldQL';
        acceptedValues?: Array<string> | null;
        defaultValue?: string | null;
        label: string;
        name: string;
        type: CustomFieldType;
        value?: string | null;
        order: number;
        isTag: boolean;
        isVisibleInDealPage: boolean;
        enrichmentDetail?: {
            __typename?: 'EnrichmentDetailQL';
            id: string;
            source: string;
        } | null;
    }> | null;
    relevantCompany?: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
    } | null;
    parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
};

export type DealCompanyPartFragment = {
    __typename?: 'RelevantCompanyQL';
    id: string;
    name: string;
    role?: string | null;
    notes?: string | null;
    extracted?: boolean | null;
    isKey?: boolean | null;
    createdDate: any;
    customFields?: Array<{
        __typename?: 'CustomFieldQL';
        acceptedValues?: Array<string> | null;
        defaultValue?: string | null;
        label: string;
        name: string;
        type: CustomFieldType;
        value?: string | null;
        order: number;
        isTag: boolean;
        isVisibleInDealPage: boolean;
        enrichmentDetail?: {
            __typename?: 'EnrichmentDetailQL';
            id: string;
            source: string;
        } | null;
    }> | null;
    contacts?: Array<{
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    }> | null;
    parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    placeDetails?: Array<{
        __typename?: 'PlaceDetailsQL';
        name?: string | null;
        type?: string | null;
        formattedAddress?: string | null;
        id: string;
        internationalPhoneNumber?: string | null;
        website?: string | null;
        email?: string | null;
        addressDetail?: {
            __typename?: 'AddressDetailQL';
            city?: string | null;
            country?: string | null;
            countryCode?: string | null;
            postalCode?: string | null;
            street?: string | null;
            streetNumber?: string | null;
        } | null;
    }> | null;
};

export type PipelinePartFragment = {
    __typename?: 'PipelineQL';
    id: string;
    name: string;
    config: {
        __typename?: 'PipelineConfigQL';
        timePeriodStart: any;
        goalsEnabled: boolean;
        averageDealValue: {
            __typename?: 'ValueCurrencyQL';
            currency: string;
            value: any;
        };
        revenueGoal: {
            __typename?: 'ValueCurrencyQL';
            currency: string;
            value: any;
        };
        stages: {
            __typename?: 'PipelineStagesConfigQL';
            initialStage: string;
            lastStage: string;
            stages: Array<{
                __typename?: 'PipelineStageConfigQL';
                id: string;
                name: string;
                order: number;
                targetConversionRate?: number | null;
                defaultObjectives?: Array<{
                    __typename?: 'ObjectiveConfigQL';
                    criteria: string;
                    linkedField?: {
                        __typename?: 'LinkedFieldQL';
                        name: string;
                        type: LinkedFieldType;
                    } | null;
                }> | null;
            }>;
        };
    };
    statistics: {
        __typename?: 'PipelineStatsQL';
        stages: Array<{
            __typename?: 'PipelineStageStatsQL';
            id: string;
            observedConversionRate: number;
            goal: number;
            targetConversionRate: number;
            atRiskDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
            healthyDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
            inStageDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
            markedDoneDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
            disqualifiedDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
            progressedDeals: {
                __typename?: 'PipelineStageStatsDealQL';
                numberOfDeals: number;
                totalDealValue: any;
            };
        }>;
    };
};

export type PipelineUpdateNamePartFragment = {
    __typename?: 'PipelineQL';
    id: string;
    name: string;
};

export type PipelineStageStatisticsPartFragment = {
    __typename?: 'PipelineStageStatsQL';
    id: string;
    observedConversionRate: number;
    goal: number;
    targetConversionRate: number;
    atRiskDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
    healthyDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
    inStageDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
    markedDoneDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
    disqualifiedDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
    progressedDeals: {
        __typename?: 'PipelineStageStatsDealQL';
        numberOfDeals: number;
        totalDealValue: any;
    };
};

export type PipelineStatisticsPartFragment = {
    __typename?: 'PipelineStatsQL';
    stages: Array<{
        __typename?: 'PipelineStageStatsQL';
        id: string;
        observedConversionRate: number;
        goal: number;
        targetConversionRate: number;
        atRiskDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
        healthyDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
        inStageDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
        markedDoneDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
        disqualifiedDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
        progressedDeals: {
            __typename?: 'PipelineStageStatsDealQL';
            numberOfDeals: number;
            totalDealValue: any;
        };
    }>;
};

export type PipelineConfigPartFragment = {
    __typename?: 'PipelineConfigQL';
    timePeriodStart: any;
    goalsEnabled: boolean;
    averageDealValue: {
        __typename?: 'ValueCurrencyQL';
        currency: string;
        value: any;
    };
    revenueGoal: {
        __typename?: 'ValueCurrencyQL';
        currency: string;
        value: any;
    };
    stages: {
        __typename?: 'PipelineStagesConfigQL';
        initialStage: string;
        lastStage: string;
        stages: Array<{
            __typename?: 'PipelineStageConfigQL';
            id: string;
            name: string;
            order: number;
            targetConversionRate?: number | null;
            defaultObjectives?: Array<{
                __typename?: 'ObjectiveConfigQL';
                criteria: string;
                linkedField?: {
                    __typename?: 'LinkedFieldQL';
                    name: string;
                    type: LinkedFieldType;
                } | null;
            }> | null;
        }>;
    };
};

export type PipelineStagesConfigFragment = {
    __typename?: 'PipelineStagesConfigQL';
    initialStage: string;
    lastStage: string;
    stages: Array<{
        __typename?: 'PipelineStageConfigQL';
        id: string;
        name: string;
        order: number;
        targetConversionRate?: number | null;
        defaultObjectives?: Array<{
            __typename?: 'ObjectiveConfigQL';
            criteria: string;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }> | null;
    }>;
};

export type PipelineStagesDataConfigFragment = {
    __typename?: 'PipelineStageConfigQL';
    id: string;
    name: string;
    order: number;
    targetConversionRate?: number | null;
    defaultObjectives?: Array<{
        __typename?: 'ObjectiveConfigQL';
        criteria: string;
        linkedField?: {
            __typename?: 'LinkedFieldQL';
            name: string;
            type: LinkedFieldType;
        } | null;
    }> | null;
};

export type ValueCurrencyPartFragment = {
    __typename?: 'ValueCurrencyQL';
    currency: string;
    value: any;
};

export type IntegrationPartFragment = {
    __typename?: 'IntegrationQL';
    id: string;
    name: string;
    integrationType: IntegrationType;
    provider: IntegrationProvider;
    driver: IntegrationDriver;
    mapper: IntegrationMapper;
    createdDate: any;
    updatedDate: any;
};

export type CrmStatePartFragment = {
    __typename?: 'CrmQL';
    state?: EntitySyncState | null;
    remoteUrl?: string | null;
    exportedDate?: any | null;
};

export type DealCommentFragment = {
    __typename?: 'CommentQL';
    id: string;
    creatorBid: number;
    content: string;
    createdDate: any;
    updatedDate: any;
    parentId: string;
};

export type CustomFieldFragment = {
    __typename?: 'CustomFieldQL';
    acceptedValues?: Array<string> | null;
    defaultValue?: string | null;
    label: string;
    name: string;
    type: CustomFieldType;
    value?: string | null;
    order: number;
    isTag: boolean;
    isVisibleInDealPage: boolean;
    enrichmentDetail?: {
        __typename?: 'EnrichmentDetailQL';
        id: string;
        source: string;
    } | null;
};

export type CustomFieldConfigFragment = {
    __typename?: 'CustomFieldConfigQL';
    acceptedValues?: Array<string> | null;
    defaultValue?: string | null;
    label: string;
    name: string;
    type: CustomFieldType;
    order: number;
    isTag: boolean;
    isVisibleInDealPage: boolean;
};

export type MentionPartFragment = {
    __typename?: 'MentionQL';
    commentId: string;
    creatorBid: number;
    updatedDate: any;
    isRead: boolean;
    deal: { __typename?: 'DealQL'; id: string; title: string };
};

export type MentionDealPartFragment = {
    __typename?: 'DealQL';
    id: string;
    title: string;
};

export type RolesPartFragment = {
    __typename?: 'RolesConfig';
    contacts?: Array<{ __typename?: 'RoleConfigQL'; name: string }> | null;
    relevantCompanies?: Array<{
        __typename?: 'RoleConfigQL';
        name: string;
    }> | null;
};

export type LinkedFieldPartFragment = {
    __typename?: 'LinkedFieldQL';
    name: string;
    type: LinkedFieldType;
};

export type GoogleEnrichmentPartFragment = {
    __typename?: 'PlaceDetailsQL';
    name?: string | null;
    type?: string | null;
    formattedAddress?: string | null;
    id: string;
    internationalPhoneNumber?: string | null;
    website?: string | null;
    email?: string | null;
    addressDetail?: {
        __typename?: 'AddressDetailQL';
        city?: string | null;
        country?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        street?: string | null;
        streetNumber?: string | null;
    } | null;
};

export type ExtractedContactPartFragment = {
    __typename?: 'ExtractedContactQL';
    firstName: string;
    lastName: string;
    companyName: string;
    legitimateInterestText: string;
    role: string;
    sourceUrls: Array<string>;
};

export type PlacePartFragment = {
    __typename?: 'PlaceQL';
    id: string;
    name: string;
};

export type ReminderPartFragment = {
    __typename?: 'ReminderQL';
    id: string;
    assignee: number;
    description: string;
    dueDate: any;
    createdDate: any;
    updatedDate: any;
    completedDate?: any | null;
    creator: number;
    target: {
        __typename?: 'ReminderTargetQL';
        id: string;
        type: ReminderTargetType;
        title: string;
    };
};

export type DynamicFilterMetricsPartFragment = {
    __typename?: 'DynamicFilterMetricsQL';
    count: number;
    dynamicFilter: DynamicFilter;
};

export type ExtractedCompanyPartFragment = {
    __typename?: 'ExtractedCompanyQL';
    legitimateInterestText: string;
    name: string;
    role: string;
    sourceUrls: Array<string>;
};

export type AchievementDetailsPartFragment = {
    __typename?: 'UserSingleAchievementDetailsQL';
    achievementState: UserAchievementState;
    achievementStateUpdateDate?: any | null;
};

export type UserAchievementsFragment = {
    __typename?: 'UserAchievementsDetailsQL';
    resolveANextStep: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    planANextStep: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    addContactsEmailOrPhone: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    askArticleWizard: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    assignContactDealRole: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    bookedFirstMeeting: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    outreachByEmail: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    disqualifyADeal: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    createYourFirstContact: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    createYourFirstDeal: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    outreachByPhone: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    pauseADeal: {
        __typename?: 'UserSingleAchievementDetailsQL';
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
};

export type ValueMilestoneFragment = {
    __typename?: 'ValueMilestoneQL';
    id: string;
    type: ValueMilestoneType;
    pipeline: string;
};

export type ColleagueAchievementsFragment = {
    __typename?: 'ColleagueAchievementsQL';
    defaultPipelineId: string;
    userId: number;
    optInAchievements: boolean;
    achievements: {
        __typename?: 'UserAchievementsDetailsQL';
        resolveANextStep: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        planANextStep: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        addContactsEmailOrPhone: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        askArticleWizard: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        assignContactDealRole: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        bookedFirstMeeting: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        outreachByEmail: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        disqualifyADeal: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        createYourFirstContact: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        createYourFirstDeal: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        outreachByPhone: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        pauseADeal: {
            __typename?: 'UserSingleAchievementDetailsQL';
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
    };
};

export type PipelineStatisticsFragment = {
    __typename?: 'PipelineStatisticsQL';
    countContacts: number;
    countDeals: number;
    countDealsWon: number;
    countLeadsQualified: number;
    countMeetingsBooked: number;
};

export type ContactExtractionPartFragment = {
    __typename?: 'ContactExtractionResultQL';
    status: ContactExtractionStatus;
    extractionType: ExtractionType;
    extractedContacts: Array<{
        __typename?: 'ExtractedContactQL';
        firstName: string;
        lastName: string;
        companyName: string;
        legitimateInterestText: string;
        role: string;
        sourceUrls: Array<string>;
    }>;
    extractedCompanies: Array<{
        __typename?: 'ExtractedCompanyQL';
        legitimateInterestText: string;
        name: string;
        role: string;
        sourceUrls: Array<string>;
    }>;
};

export type ContactExtractionResultFragment = {
    __typename?: 'CombinedContactExtractionResultQL';
    projectContextResult?: {
        __typename?: 'ContactExtractionResultQL';
        status: ContactExtractionStatus;
        extractionType: ExtractionType;
        extractedContacts: Array<{
            __typename?: 'ExtractedContactQL';
            firstName: string;
            lastName: string;
            companyName: string;
            legitimateInterestText: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
        extractedCompanies: Array<{
            __typename?: 'ExtractedCompanyQL';
            legitimateInterestText: string;
            name: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
    } | null;
    webSearchResult?: {
        __typename?: 'ContactExtractionResultQL';
        status: ContactExtractionStatus;
        extractionType: ExtractionType;
        extractedContacts: Array<{
            __typename?: 'ExtractedContactQL';
            firstName: string;
            lastName: string;
            companyName: string;
            legitimateInterestText: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
        extractedCompanies: Array<{
            __typename?: 'ExtractedCompanyQL';
            legitimateInterestText: string;
            name: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
    } | null;
};

export type LinkedinProfileFragment = {
    __typename?: 'LinkedInProfileQL';
    title: string;
    location: string;
    linkedinUsername: string;
    headline: string;
    hasPhone: boolean;
    hasEmail: boolean;
    fullName: string;
    companyCountry: string;
    companyName: string;
};

export type LinkedinSearchMetaFragment = {
    __typename?: 'SearchMetaDataQL';
    page: number;
    pageSize: number;
    total: number;
};

export type LinkedinProfileContactInfoFragment = {
    __typename?: 'LinkedInProfileContactInformationQL';
    personalEmail: Array<string>;
    phone: Array<string>;
    workEmail: Array<string>;
};

export type DealContentMetadataFragment = {
    __typename?: 'DealContentMetadataQL';
    id: string;
    type: DealContentType;
    source?: string | null;
    name?: string | null;
    publishedDate?: any | null;
    hasContent: boolean;
    creationSource: DealContentCreationSource;
};

export type DealFileAttachmentDownloadUrlFragment = {
    __typename?: 'StringQL';
    value: string;
};

export type InstantMessageFragment = {
    __typename?: 'InstantMessageQL';
    userTargetId: number;
    userSourceId: number;
    type: InstantUserMessageType;
    content: {
        __typename?: 'InstantMessageUserDealImportCompletedQL';
        dealId: string;
        title: string;
    };
};

export type MoveDealToStageMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    stageId: Scalars['String']['input'];
}>;

export type MoveDealToStageMutation = {
    __typename?: 'Mutation';
    moveToStage: { __typename?: 'ResultQL'; ok: boolean };
};

export type AssignUserToDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    update: DealUpdateInputQl;
}>;

export type AssignUserToDealMutation = {
    __typename?: 'Mutation';
    updateDeal: { __typename?: 'ResultQL'; ok: boolean };
};

export type DisqualifyDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type DisqualifyDealMutation = {
    __typename?: 'Mutation';
    disqualifyDeal: {
        __typename?: 'DealQL';
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
        project?: { __typename?: 'ProjectQL'; projectId: string } | null;
        pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
        currentObjectives: Array<{
            __typename?: 'ObjectiveQL';
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }>;
        currentStage: { __typename?: 'DealStageQL'; id: string };
        exportedDate?: {
            __typename?: 'ExportedDateQL';
            email?: any | null;
            csv?: any | null;
        } | null;
        reminders: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
        activityLog: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type RestoreDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type RestoreDealMutation = {
    __typename?: 'Mutation';
    restoreDeal: {
        __typename?: 'DealQL';
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
        project?: { __typename?: 'ProjectQL'; projectId: string } | null;
        pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
        currentObjectives: Array<{
            __typename?: 'ObjectiveQL';
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }>;
        currentStage: { __typename?: 'DealStageQL'; id: string };
        exportedDate?: {
            __typename?: 'ExportedDateQL';
            email?: any | null;
            csv?: any | null;
        } | null;
        reminders: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
        activityLog: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type PauseDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    pausedUntil: Scalars['DateTime']['input'];
}>;

export type PauseDealMutation = {
    __typename?: 'Mutation';
    pauseDeal: { __typename?: 'ResultQL'; ok: boolean };
};

export type UnPauseDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type UnPauseDealMutation = {
    __typename?: 'Mutation';
    unpauseDeal: { __typename?: 'ResultQL'; ok: boolean };
};

export type MarkDealAsDoneMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type MarkDealAsDoneMutation = {
    __typename?: 'Mutation';
    markDealAsDone: {
        __typename?: 'DealQL';
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
        project?: { __typename?: 'ProjectQL'; projectId: string } | null;
        pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
        currentObjectives: Array<{
            __typename?: 'ObjectiveQL';
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }>;
        currentStage: { __typename?: 'DealStageQL'; id: string };
        exportedDate?: {
            __typename?: 'ExportedDateQL';
            email?: any | null;
            csv?: any | null;
        } | null;
        reminders: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
        activityLog: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type CreateNewDealMutationMutationVariables = Exact<{
    pipelineId: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
    creationSource: DealCreationSource;
    searchNames?: InputMaybe<
        Array<Scalars['String']['input']> | Scalars['String']['input']
    >;
    currentSearchName?: InputMaybe<Scalars['String']['input']>;
    projectDataFromCustomText?: InputMaybe<ProjectDataFromCustomTextQl>;
    projectDataFromSearchResult?: InputMaybe<ProjectDataFromSearchResultQl>;
}>;

export type CreateNewDealMutationMutation = {
    __typename?: 'Mutation';
    createDeal: {
        __typename?: 'CreateDealResultQL';
        dealId: string;
        ok: boolean;
    };
};

export type UpdateDealInformationMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    updateDeal: DealUpdateInputQl;
}>;

export type UpdateDealInformationMutation = {
    __typename?: 'Mutation';
    updateDeal: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdateObjectiveMutationMutationVariables = Exact<{
    objectiveId: Scalars['String']['input'];
    updateObjective: ObjectiveUpdateInputQl;
}>;

export type UpdateObjectiveMutationMutation = {
    __typename?: 'Mutation';
    updateObjective: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetAllDealsQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetAllDealsQuery = {
    __typename?: 'Query';
    allDeals: Array<{
        __typename?: 'DealQL';
        id: string;
        title: string;
        assignee: number;
        createdDate: any;
        completedDate?: any | null;
        state: DealState;
        pausedUntil?: any | null;
        expectedCurrentStageConversionDate: any;
        pipeline: { __typename?: 'PipelineQL'; name: string; id: string };
        value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
        currentStage: { __typename?: 'DealStageQL'; id: string };
        exportedDate?: {
            __typename?: 'ExportedDateQL';
            email?: any | null;
            csv?: any | null;
        } | null;
        expectedSalesPeriod?:
            | {
                  __typename?: 'AnnualSalesPeriodQL';
                  year: number;
                  frequency: SalesPeriodFrequency;
              }
            | {
                  __typename?: 'QuarterlySalesPeriodQL';
                  year: number;
                  quarter: number;
                  frequency: SalesPeriodFrequency;
              }
            | null;
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
        project?: { __typename?: 'ProjectQL'; projectId: string } | null;
    }>;
};

export type GetDealQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { __typename?: 'ValueCurrencyQL'; currency: string; value: any };
        project?: { __typename?: 'ProjectQL'; projectId: string } | null;
        pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
        currentObjectives: Array<{
            __typename?: 'ObjectiveQL';
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }>;
        currentStage: { __typename?: 'DealStageQL'; id: string };
        exportedDate?: {
            __typename?: 'ExportedDateQL';
            email?: any | null;
            csv?: any | null;
        } | null;
        reminders: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
        activityLog: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type ExportCsvQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    delimiter: Scalars['String']['input'];
}>;

export type ExportCsvQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        export: {
            __typename?: 'DealExportQL';
            csv: { __typename?: 'CsvQL'; row: string; header: string };
        };
    };
};

export type ExportBatchCsvQueryVariables = Exact<{
    queryParams: QueryParamsQl;
    delimiter: Scalars['String']['input'];
}>;

export type ExportBatchCsvQuery = {
    __typename?: 'Query';
    allDeals: Array<{
        __typename?: 'DealQL';
        id: string;
        export: {
            __typename?: 'DealExportQL';
            csv: { __typename?: 'CsvQL'; row: string; header: string };
        };
    }>;
};

export type GetDealObjectivesQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealObjectivesQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        currentObjectives: Array<{
            __typename?: 'ObjectiveQL';
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: {
                __typename?: 'LinkedFieldQL';
                name: string;
                type: LinkedFieldType;
            } | null;
        }>;
    };
};

export type GetDealStageUpdateDateQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealStageUpdateDateQuery = {
    __typename?: 'Query';
    deal: { __typename?: 'DealQL'; stageUpdatedDate: any };
};

export type GetDealContactsQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealContactsQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        relevantCompanies?: Array<{
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            contacts?: Array<{
                __typename?: 'ContactQL';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    __typename?: 'CustomFieldQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: {
                        __typename?: 'EnrichmentDetailQL';
                        id: string;
                        source: string;
                    } | null;
                }> | null;
                relevantCompany?: {
                    __typename?: 'RelevantCompanyQL';
                    id: string;
                    name: string;
                } | null;
                parentDeals?: Array<{
                    __typename?: 'DealQL';
                    id: string;
                }> | null;
            }> | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
            placeDetails?: Array<{
                __typename?: 'PlaceDetailsQL';
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    __typename?: 'AddressDetailQL';
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
    };
};

export type CreateCompanyMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
    role?: InputMaybe<Scalars['String']['input']>;
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    notes?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    createdFrom?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCompanyMutation = {
    __typename?: 'Mutation';
    createRelevantCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCompanyMutation = {
    __typename?: 'Mutation';
    updateRelevantCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyNoteMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    notes: Scalars['String']['input'];
}>;

export type UpdateCompanyNoteMutation = {
    __typename?: 'Mutation';
    updateRelevantCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyRoleMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    role: Scalars['String']['input'];
    isKey: Scalars['Boolean']['input'];
}>;

export type UpdateCompanyRoleMutation = {
    __typename?: 'Mutation';
    updateRelevantCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type DeleteCompanyMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
    deleteAssociatedContacts: Scalars['Boolean']['input'];
}>;

export type DeleteCompanyMutation = {
    __typename?: 'Mutation';
    deleteRelevantCompany: { __typename?: 'ResultQL'; ok: boolean };
};

export type ExportEmailMutationVariables = Exact<{
    senderName: Scalars['String']['input'];
    toName: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    language: UserLanguage;
    dealId: Scalars['String']['input'];
    dealOwner: Scalars['String']['input'];
    dealOwnerEmail: Scalars['String']['input'];
    dealUrl: Scalars['String']['input'];
}>;

export type ExportEmailMutation = {
    __typename?: 'Mutation';
    exportDealEmail: {
        __typename?: 'OutboundEmailResultQL';
        ok: boolean;
        email: {
            __typename?: 'OutboundEmailQL';
            enqueuedDate?: any | null;
            id: string;
            mtaMessageId?: string | null;
            senderBid: number;
            sentDate?: any | null;
            state: OutboundEmailState;
            template: {
                __typename?: 'EmailTemplateParamsQL';
                templateId: string;
                params?: Array<{
                    __typename?: 'EmailTemplateParamQL';
                    key: string;
                    value: string;
                }> | null;
            };
            addresses: {
                __typename?: 'EmailAddressHeaderQL';
                toReceivers: Array<{
                    __typename?: 'EmailAddressQL';
                    email: string;
                    name: string;
                }>;
                replyTo?: {
                    __typename?: 'EmailAddressQL';
                    email: string;
                    name: string;
                } | null;
                fromSender: {
                    __typename?: 'EmailAddressQL';
                    email: string;
                    name: string;
                };
                ccReceivers?: Array<{
                    __typename?: 'EmailAddressQL';
                    email: string;
                    name: string;
                }> | null;
                bccReceivers?: Array<{
                    __typename?: 'EmailAddressQL';
                    email: string;
                    name: string;
                }> | null;
            };
        };
    };
};

export type CreateContactMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    createdFrom?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateContactMutation = {
    __typename?: 'Mutation';
    createContact: {
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    };
};

export type UpdateContactMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateContactMutation = {
    __typename?: 'Mutation';
    updateContact: {
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    };
};

export type UpdateContactNoteMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    notes: Scalars['String']['input'];
}>;

export type UpdateContactNoteMutation = {
    __typename?: 'Mutation';
    updateContact: {
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    };
};

export type UpdateContactRoleMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    role: Scalars['String']['input'];
    isKey: Scalars['Boolean']['input'];
}>;

export type UpdateContactRoleMutation = {
    __typename?: 'Mutation';
    updateContact: {
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    };
};

export type DeleteContactMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    contactId: Scalars['String']['input'];
}>;

export type DeleteContactMutation = {
    __typename?: 'Mutation';
    deleteContact: { __typename?: 'ResultQL'; ok: boolean };
};

export type AllPipelinesQueryQueryVariables = Exact<{
    queryParams?: InputMaybe<QueryParamsQl>;
}>;

export type AllPipelinesQueryQuery = {
    __typename?: 'Query';
    allPipelines: Array<{
        __typename?: 'PipelineQL';
        id: string;
        name: string;
        config: {
            __typename?: 'PipelineConfigQL';
            timePeriodStart: any;
            goalsEnabled: boolean;
            averageDealValue: {
                __typename?: 'ValueCurrencyQL';
                currency: string;
                value: any;
            };
            revenueGoal: {
                __typename?: 'ValueCurrencyQL';
                currency: string;
                value: any;
            };
            stages: {
                __typename?: 'PipelineStagesConfigQL';
                initialStage: string;
                lastStage: string;
                stages: Array<{
                    __typename?: 'PipelineStageConfigQL';
                    id: string;
                    name: string;
                    order: number;
                    targetConversionRate?: number | null;
                    defaultObjectives?: Array<{
                        __typename?: 'ObjectiveConfigQL';
                        criteria: string;
                        linkedField?: {
                            __typename?: 'LinkedFieldQL';
                            name: string;
                            type: LinkedFieldType;
                        } | null;
                    }> | null;
                }>;
            };
        };
        statistics: {
            __typename?: 'PipelineStatsQL';
            stages: Array<{
                __typename?: 'PipelineStageStatsQL';
                id: string;
                observedConversionRate: number;
                goal: number;
                targetConversionRate: number;
                atRiskDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                healthyDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                inStageDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                markedDoneDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                disqualifiedDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                progressedDeals: {
                    __typename?: 'PipelineStageStatsDealQL';
                    numberOfDeals: number;
                    totalDealValue: any;
                };
            }>;
        };
    }>;
};

export type BulkMoveMutationVariables = Exact<{
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    targetPipelineId: Scalars['String']['input'];
    targetStageId: Scalars['String']['input'];
    targetDealState: DealState;
    unpauseDeals?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BulkMoveMutation = {
    __typename?: 'Mutation';
    bulkMove: {
        __typename?: 'BulkMoveResultQL';
        ok: boolean;
        updatedDealIds: Array<string>;
    };
};

export type BulkUpdateDealCustomParametersMutationVariables = Exact<{
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
}>;

export type BulkUpdateDealCustomParametersMutation = {
    __typename?: 'Mutation';
    bulkUpdateDealCustomValues: {
        __typename?: 'BulkUpdateCustomFieldsResultQL';
        updatedDealIds: Array<string>;
    };
};

export type BulkUpdateDealAttributesMutationVariables = Exact<{
    attribute: DealAttribute;
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    value?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type BulkUpdateDealAttributesMutation = {
    __typename?: 'Mutation';
    bulkUpdateDealAttributes: {
        __typename?: 'BulkUpdateAttributesResultQL';
        updatedDealIds: Array<string>;
    };
};

export type GetDealsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetDealsCountQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        totalCount: number;
    };
};

export type GetAllDealsCollectionQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetAllDealsCollectionQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        totalCount: number;
        pageInfo: {
            __typename?: 'PageInfoQL';
            count: number;
            hasNextPage: boolean;
        };
        edges?: Array<{
            __typename?: 'DealCollectionEdgeQL';
            node: {
                __typename?: 'DealQL';
                id: string;
                title: string;
                assignee: number;
                createdDate: any;
                completedDate?: any | null;
                state: DealState;
                pausedUntil?: any | null;
                expectedCurrentStageConversionDate: any;
                pipeline: {
                    __typename?: 'PipelineQL';
                    name: string;
                    id: string;
                };
                value: {
                    __typename?: 'ValueCurrencyQL';
                    currency: string;
                    value: any;
                };
                currentStage: { __typename?: 'DealStageQL'; id: string };
                exportedDate?: {
                    __typename?: 'ExportedDateQL';
                    email?: any | null;
                    csv?: any | null;
                } | null;
                expectedSalesPeriod?:
                    | {
                          __typename?: 'AnnualSalesPeriodQL';
                          year: number;
                          frequency: SalesPeriodFrequency;
                      }
                    | {
                          __typename?: 'QuarterlySalesPeriodQL';
                          year: number;
                          quarter: number;
                          frequency: SalesPeriodFrequency;
                      }
                    | null;
                alert: {
                    __typename?: 'DealAlertQL';
                    type: DealAlertType;
                    currentUserRelated: boolean;
                };
                project?: {
                    __typename?: 'ProjectQL';
                    projectId: string;
                } | null;
            };
        }> | null;
    };
};

export type GetDealCustomParametersQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealCustomParametersQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
    };
};

export type UpdateDealCustomParametersMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    update: DealUpdateCustomInputQl;
}>;

export type UpdateDealCustomParametersMutation = {
    __typename?: 'Mutation';
    updateDealCustomValues: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetAllIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllIntegrationsQuery = {
    __typename?: 'Query';
    allIntegrations: Array<{
        __typename?: 'IntegrationQL';
        id: string;
        name: string;
        integrationType: IntegrationType;
        provider: IntegrationProvider;
        driver: IntegrationDriver;
        mapper: IntegrationMapper;
        createdDate: any;
        updatedDate: any;
    }>;
};

export type GetDealCommentsQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealCommentsQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        comments?: Array<{
            __typename?: 'CommentQL';
            id: string;
            creatorBid: number;
            content: string;
            createdDate: any;
            updatedDate: any;
            parentId: string;
        }> | null;
    };
};

export type AddDealCommentMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    data: CommentCreateQl;
}>;

export type AddDealCommentMutation = {
    __typename?: 'Mutation';
    addDealComment: {
        __typename?: 'CommentQL';
        id: string;
        creatorBid: number;
        content: string;
        createdDate: any;
        updatedDate: any;
        parentId: string;
    };
};

export type UpdateCommentMutationVariables = Exact<{
    commentId: Scalars['String']['input'];
    data: CommentUpdateQl;
}>;

export type UpdateCommentMutation = {
    __typename?: 'Mutation';
    updateComment: {
        __typename?: 'CommentQL';
        id: string;
        creatorBid: number;
        content: string;
        createdDate: any;
        updatedDate: any;
        parentId: string;
    };
};

export type DeleteCommentMutationVariables = Exact<{
    commentId: Scalars['String']['input'];
}>;

export type DeleteCommentMutation = {
    __typename?: 'Mutation';
    deleteComment: { __typename?: 'ResultQL'; ok: boolean };
};

export type ExportToCrmMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
}>;

export type ExportToCrmMutation = {
    __typename?: 'Mutation';
    exportToCrm: { __typename?: 'EntitySyncRequestResultQL'; ok: boolean };
};

export type GetCrmStateQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
}>;

export type GetCrmStateQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        export: {
            __typename?: 'DealExportQL';
            crm: {
                __typename?: 'CrmQL';
                state?: EntitySyncState | null;
                remoteUrl?: string | null;
                exportedDate?: any | null;
            };
        };
    };
};

export type GetMyHubSpotTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyHubSpotTokenQuery = {
    __typename?: 'Query';
    me: { __typename?: 'UserQL'; hubspotToken: string };
};

export type UpdatePersonMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    update: ContactUpdateQl;
}>;

export type UpdatePersonMutation = {
    __typename?: 'Mutation';
    updateContact: {
        __typename?: 'ContactQL';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        relevantCompany?: {
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
        } | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
    };
};

export type UpdateRelevantCompanyMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    update: RelevantCompanyUpdateQl;
}>;

export type UpdateRelevantCompanyMutation = {
    __typename?: 'Mutation';
    updateRelevantCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type GetAccountConfigurationQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAccountConfigurationQuery = {
    __typename?: 'Query';
    company: {
        __typename?: 'CompanyQL';
        config?: {
            __typename?: 'CompanyConfigQL';
            dealValueVisible: boolean;
            actionFeatures: Array<{
                __typename?: 'ActionFeaturesConfigQL';
                active: boolean;
                name: string;
                order: number;
                settings?: any | null;
                companyOutreachStages?: Array<CompanyOutreachStage> | null;
                contactOutreachStages?: Array<ContactOutreachStage> | null;
            }>;
            salesPeriod?: {
                __typename?: 'SalesPeriodConfigurationQL';
                frequency: SalesPeriodFrequency;
            } | null;
            customFields: {
                __typename?: 'CustomFieldsConfig';
                deals?: Array<{
                    __typename?: 'CustomFieldConfigQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
                contacts?: Array<{
                    __typename?: 'CustomFieldConfigQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
                relevantCompanies?: Array<{
                    __typename?: 'CustomFieldConfigQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
            };
            roles: {
                __typename?: 'RolesConfig';
                contacts?: Array<{
                    __typename?: 'RoleConfigQL';
                    name: string;
                }> | null;
                relevantCompanies?: Array<{
                    __typename?: 'RoleConfigQL';
                    name: string;
                }> | null;
            };
        } | null;
    };
};

export type GetDealLocationDataQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
}>;

export type GetDealLocationDataQuery = {
    __typename?: 'Query';
    actionFeatureGoogleEnrichmentPlaces: Array<{
        __typename?: 'PlaceQL';
        id: string;
        name: string;
    }>;
};

export type GetGoogleEnrichmentDataQueryVariables = Exact<{
    language: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
}>;

export type GetGoogleEnrichmentDataQuery = {
    __typename?: 'Query';
    actionFeatureGoogleEnrichmentPlaceDetails: {
        __typename?: 'PlaceDetailsQL';
        name?: string | null;
        type?: string | null;
        formattedAddress?: string | null;
        id: string;
        internationalPhoneNumber?: string | null;
        website?: string | null;
        email?: string | null;
        addressDetail?: {
            __typename?: 'AddressDetailQL';
            city?: string | null;
            country?: string | null;
            countryCode?: string | null;
            postalCode?: string | null;
            street?: string | null;
            streetNumber?: string | null;
        } | null;
    };
};

export type CreateContactsFromExtractedContactsMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    extractedContacts:
        | Array<ReformattedExtractedContactInputQl>
        | ReformattedExtractedContactInputQl;
    extractedCompanies:
        | Array<ReformattedExtractedCompanyInputQl>
        | ReformattedExtractedCompanyInputQl;
}>;

export type CreateContactsFromExtractedContactsMutation = {
    __typename?: 'Mutation';
    createContactsFromExtractedContacts: {
        __typename?: 'CreateContactsFromExtractedContactsResultQL';
        contacts: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }>;
        relevantCompanies: Array<{
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            contacts?: Array<{
                __typename?: 'ContactQL';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    __typename?: 'CustomFieldQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: {
                        __typename?: 'EnrichmentDetailQL';
                        id: string;
                        source: string;
                    } | null;
                }> | null;
                relevantCompany?: {
                    __typename?: 'RelevantCompanyQL';
                    id: string;
                    name: string;
                } | null;
                parentDeals?: Array<{
                    __typename?: 'DealQL';
                    id: string;
                }> | null;
            }> | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
            placeDetails?: Array<{
                __typename?: 'PlaceDetailsQL';
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    __typename?: 'AddressDetailQL';
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }>;
    };
};

export type CreateReminderMutationVariables = Exact<{
    data: ReminderCreateInputQl;
    launchSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateReminderMutation = {
    __typename?: 'Mutation';
    createReminder: {
        __typename?: 'ReminderQL';
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: {
            __typename?: 'ReminderTargetQL';
            id: string;
            type: ReminderTargetType;
            title: string;
        };
    };
};

export type UpdateReminderMutationVariables = Exact<{
    reminderId: Scalars['String']['input'];
    update: ReminderUpdateInputQl;
}>;

export type UpdateReminderMutation = {
    __typename?: 'Mutation';
    updateReminder: {
        __typename?: 'ReminderQL';
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: {
            __typename?: 'ReminderTargetQL';
            id: string;
            type: ReminderTargetType;
            title: string;
        };
    };
};

export type GetMyRemindersAndMentionsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetMyRemindersAndMentionsQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'UserQL';
        mentions?: Array<{
            __typename?: 'MentionQL';
            commentId: string;
            creatorBid: number;
            updatedDate: any;
            isRead: boolean;
            deal: { __typename?: 'DealQL'; id: string; title: string };
        }> | null;
        reminders: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
    };
};

export type DismissReminderMutationVariables = Exact<{
    reminderId: Scalars['String']['input'];
}>;

export type DismissReminderMutation = {
    __typename?: 'Mutation';
    completeReminder: {
        __typename?: 'ReminderQL';
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: {
            __typename?: 'ReminderTargetQL';
            id: string;
            type: ReminderTargetType;
            title: string;
        };
    };
};

export type MarkMentionAsReadMutationVariables = Exact<{
    mentionId: Scalars['String']['input'];
}>;

export type MarkMentionAsReadMutation = {
    __typename?: 'Mutation';
    readCommentMention: { __typename?: 'ResultQL'; ok: boolean };
};

export type DismissMentionMutationVariables = Exact<{
    mentionId: Scalars['String']['input'];
}>;

export type DismissMentionMutation = {
    __typename?: 'Mutation';
    dismissCommentMention: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetCompanyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyTypesQuery = {
    __typename?: 'Query';
    company: {
        __typename?: 'CompanyQL';
        accountType: AccountType;
        licenseType: LicenseType;
    };
};

export type GetDealAlertQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealAlertQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        alert: {
            __typename?: 'DealAlertQL';
            type: DealAlertType;
            currentUserRelated: boolean;
        };
    };
};

export type GetDynamicFilterMetricsQueryVariables = Exact<{
    pipelineId: Scalars['String']['input'];
    filters: Array<FilterOpQl> | FilterOpQl;
}>;

export type GetDynamicFilterMetricsQuery = {
    __typename?: 'Query';
    pipeline: {
        __typename?: 'PipelineQL';
        statistics: {
            __typename?: 'PipelineStatsQL';
            dynamicFiltersMetrics: Array<{
                __typename?: 'DynamicFilterMetricsQL';
                count: number;
                dynamicFilter: DynamicFilter;
            }>;
        };
    };
};

export type GetDealsCollectionByPipelineQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetDealsCollectionByPipelineQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        totalCount: number;
        edges?: Array<{
            __typename?: 'DealCollectionEdgeQL';
            node: {
                __typename?: 'DealQL';
                id: string;
                pausedUntil?: any | null;
                title: string;
                stageUpdatedDate: any;
                assignee: number;
                alert: {
                    __typename?: 'DealAlertQL';
                    type: DealAlertType;
                    currentUserRelated: boolean;
                };
                currentStage: { __typename?: 'DealStageQL'; id: string };
                outreachLogStats: {
                    __typename?: 'ActivityLogStatsQL';
                    count: number;
                    lastActivityDate?: any | null;
                };
            };
        }> | null;
    };
};

export type AskQuestionQueryVariables = Exact<{
    question: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['Int']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    dealId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AskQuestionQuery = {
    __typename?: 'Query';
    actionFeatureXray: { __typename?: 'QuestionResponseQL'; answer: string };
};

export type AskQuestionRealtimeSubscriptionVariables = Exact<{
    question: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['Int']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    dealId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AskQuestionRealtimeSubscription = {
    __typename?: 'Subscription';
    actionFeatureXray: { __typename?: 'QuestionResponseQL'; answer: string };
};

export type LiveUserNotificationsSubscriptionVariables = Exact<{
    [key: string]: never;
}>;

export type LiveUserNotificationsSubscription = {
    __typename?: 'Subscription';
    subscribeUserUpdates: {
        __typename?: 'InstantMessageQL';
        userTargetId: number;
        userSourceId: number;
        type: InstantUserMessageType;
        content: {
            __typename?: 'InstantMessageUserDealImportCompletedQL';
            dealId: string;
            title: string;
        };
    };
};

export type GenerateValuePropEmailQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type GenerateValuePropEmailQuery = {
    __typename?: 'Query';
    actionFeatureValuePropOutreachEmail: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GenerateValuePropEmailFollowUpQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropEmailFollowUpQuery = {
    __typename?: 'Query';
    actionFeatureValuePropOutreachFollowUp: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GenerateValuePropCallScriptQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropCallScriptQuery = {
    __typename?: 'Query';
    actionFeatureValuePropOutreachCallScript: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GenerateValuePropEmailStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type GenerateValuePropEmailStreamSubscription = {
    __typename?: 'Subscription';
    actionFeatureValuePropOutreachEmail: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GenerateValuePropEmailFollowUpStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropEmailFollowUpStreamSubscription = {
    __typename?: 'Subscription';
    actionFeatureValuePropOutreachFollowUp: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GenerateValuePropCallScriptStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropCallScriptStreamSubscription = {
    __typename?: 'Subscription';
    actionFeatureValuePropOutreachCallScript: {
        __typename?: 'QuestionResponseQL';
        answer: string;
    };
};

export type GetDomainEnrichmentResultQueryVariables = Exact<{
    companyName: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
    contactId: Scalars['String']['input'];
}>;

export type GetDomainEnrichmentResultQuery = {
    __typename?: 'Query';
    actionFeatureContactEnrichmentCompanyDomain: Array<{
        __typename?: 'DomainEnrichmentDataValueQL';
        domain: string;
        name: string;
        providers: Array<EnrichmentDataProviderSource>;
    }>;
};

export type GetContactEnrichmentResultQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    contactName: Scalars['String']['input'];
    company: Scalars['String']['input'];
    domain?: InputMaybe<Scalars['String']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    contactId?: InputMaybe<Scalars['String']['input']>;
    triggeredFromSearchRefinement?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetContactEnrichmentResultQuery = {
    __typename?: 'Query';
    actionFeatureContactEnrichment: {
        __typename?: 'ContactEnrichmentResultQL';
        emails: Array<{
            __typename?: 'EnrichmentDataValueQL';
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        phones: Array<{
            __typename?: 'EnrichmentDataValueQL';
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        linkedins: Array<{
            __typename?: 'EnrichmentDataValueQL';
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        positions: Array<{
            __typename?: 'EnrichmentDataValueQL';
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
    };
};

export type SetAchievementsOptInMutationVariables = Exact<{
    optIn: Scalars['Boolean']['input'];
}>;

export type SetAchievementsOptInMutation = {
    __typename?: 'Mutation';
    updateUserAchievementsEnrolmentOptIn: {
        __typename?: 'ResultQL';
        ok: boolean;
    };
};

export type GetUserAchievementsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserAchievementsQuery = {
    __typename?: 'Query';
    userAchievements: {
        __typename?: 'UserAchievementsQL';
        achievements: {
            __typename?: 'UserAchievementsDetailsQL';
            resolveANextStep: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            planANextStep: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            addContactsEmailOrPhone: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            askArticleWizard: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            assignContactDealRole: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            bookedFirstMeeting: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByEmail: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            disqualifyADeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstContact: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstDeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByPhone: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            pauseADeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
        };
    };
};

export type UpdateAchievementStateMutationVariables = Exact<{
    name: UserAchievementName;
    state: UserAchievementState;
}>;

export type UpdateAchievementStateMutation = {
    __typename?: 'Mutation';
    updateUserAchievementState: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetValueMilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetValueMilestonesQuery = {
    __typename?: 'Query';
    allValueMilestones: Array<{
        __typename?: 'ValueMilestoneQL';
        id: string;
        type: ValueMilestoneType;
        pipeline: string;
    }>;
};

export type GetTeamsAchievementsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetTeamsAchievementsQuery = {
    __typename?: 'Query';
    colleaguesAchievements: Array<{
        __typename?: 'ColleagueAchievementsQL';
        defaultPipelineId: string;
        userId: number;
        optInAchievements: boolean;
        achievements: {
            __typename?: 'UserAchievementsDetailsQL';
            resolveANextStep: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            planANextStep: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            addContactsEmailOrPhone: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            askArticleWizard: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            assignContactDealRole: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            bookedFirstMeeting: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByEmail: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            disqualifyADeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstContact: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstDeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByPhone: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            pauseADeal: {
                __typename?: 'UserSingleAchievementDetailsQL';
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
        };
    }>;
};

export type GetPipelineStatisticsQueryVariables = Exact<{
    forCurrentUser: Scalars['Boolean']['input'];
    pipelineId: Scalars['String']['input'];
}>;

export type GetPipelineStatisticsQuery = {
    __typename?: 'Query';
    pipelineStatistics?: {
        __typename?: 'PipelineStatisticsQL';
        countContacts: number;
        countDeals: number;
        countDealsWon: number;
        countLeadsQualified: number;
        countMeetingsBooked: number;
    } | null;
};

export type GetCustomFieldsForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetCustomFieldsForDealsQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        edges?: Array<{
            __typename?: 'DealCollectionEdgeQL';
            node: {
                __typename?: 'DealQL';
                id: string;
                customFields?: Array<{
                    __typename?: 'CustomFieldQL';
                    isTag: boolean;
                    label: string;
                    name: string;
                    value?: string | null;
                }> | null;
            };
        }> | null;
    };
};

export type GetRemindersForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetRemindersForDealsQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        edges?: Array<{
            __typename?: 'DealCollectionEdgeQL';
            node: {
                __typename?: 'DealQL';
                id: string;
                reminders: Array<{
                    __typename?: 'ReminderQL';
                    id: string;
                    assignee: number;
                    description: string;
                    dueDate: any;
                    createdDate: any;
                    updatedDate: any;
                    completedDate?: any | null;
                    creator: number;
                    target: {
                        __typename?: 'ReminderTargetQL';
                        id: string;
                        type: ReminderTargetType;
                        title: string;
                    };
                }>;
            };
        }> | null;
    };
};

export type GetCommentsForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetCommentsForDealsQuery = {
    __typename?: 'Query';
    dealsCollection: {
        __typename?: 'DealCollectionInfoQL';
        edges?: Array<{
            __typename?: 'DealCollectionEdgeQL';
            node: {
                __typename?: 'DealQL';
                id: string;
                comments?: Array<{
                    __typename?: 'CommentQL';
                    id: string;
                    creatorBid: number;
                    content: string;
                    createdDate: any;
                    updatedDate: any;
                    parentId: string;
                }> | null;
            };
        }> | null;
    };
};

export type DeleteOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlockId: Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type DeleteOutreachBlockMutation = {
    __typename?: 'Mutation';
    deleteOutreachBlock: { __typename?: 'ResultQL'; ok: boolean };
};

export type CreateOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockCreateMutationQl;
    vpoSessionId: Scalars['String']['input'];
}>;

export type CreateOutreachBlockMutation = {
    __typename?: 'Mutation';
    createOutreachBlock: { __typename?: 'OutreachBlockQl'; id: string };
};

export type UpdateOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockUpdateMutationQl;
    vpoSessionId: Scalars['String']['input'];
}>;

export type UpdateOutreachBlockMutation = {
    __typename?: 'Mutation';
    updateOutreachBlock: { __typename?: 'ResultQL'; ok: boolean };
};

export type TranslateQueryVariables = Exact<{
    text: Scalars['String']['input'];
    language: Scalars['String']['input'];
}>;

export type TranslateQuery = { __typename?: 'Query'; translate: string };

export type CreateDealActivityMutationVariables = Exact<{
    activityType: ActivityLogType;
    subType?: InputMaybe<ActivityLogSubType>;
    dealId?: InputMaybe<Scalars['String']['input']>;
    contactId?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    notes?: Scalars['String']['input'];
    customType?: Scalars['String']['input'];
}>;

export type CreateDealActivityMutation = {
    __typename?: 'Mutation';
    createActivityLog: {
        __typename?: 'ActivityLogQL';
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    };
};

export type UpdateDealActivityMutationVariables = Exact<{
    activityLogId: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    customType?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDealActivityMutation = {
    __typename?: 'Mutation';
    updateActivityLog: {
        __typename?: 'ActivityLogQL';
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    };
};

export type DeleteDealActivityMutationVariables = Exact<{
    activityLogId: Scalars['String']['input'];
}>;

export type DeleteDealActivityMutation = {
    __typename?: 'Mutation';
    deleteActivityLog: { __typename?: 'ResultQL'; ok: boolean };
};

export type OmniSearchQueryVariables = Exact<{
    searchTerm: Scalars['String']['input'];
    limit: Scalars['Int']['input'];
}>;

export type OmniSearchQuery = {
    __typename?: 'Query';
    omniSearch: {
        __typename?: 'OmniSearchResultQL';
        comments: Array<{
            __typename?: 'CommentQL';
            id: string;
            creatorBid: number;
            content: string;
            createdDate: any;
            updatedDate: any;
            parentId: string;
        }>;
        companies: Array<{
            __typename?: 'RelevantCompanyQL';
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            contacts?: Array<{
                __typename?: 'ContactQL';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    __typename?: 'CustomFieldQL';
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: {
                        __typename?: 'EnrichmentDetailQL';
                        id: string;
                        source: string;
                    } | null;
                }> | null;
                relevantCompany?: {
                    __typename?: 'RelevantCompanyQL';
                    id: string;
                    name: string;
                } | null;
                parentDeals?: Array<{
                    __typename?: 'DealQL';
                    id: string;
                }> | null;
            }> | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
            placeDetails?: Array<{
                __typename?: 'PlaceDetailsQL';
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    __typename?: 'AddressDetailQL';
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }>;
        contacts: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }>;
        deals: Array<{
            __typename?: 'DealQL';
            id: string;
            title: string;
            assignee: number;
            state: DealState;
            notes: string;
            stageUpdatedDate: any;
            createdDate: any;
            completedDate?: any | null;
            pausedUntil?: any | null;
            summary?: string | null;
            value: {
                __typename?: 'ValueCurrencyQL';
                currency: string;
                value: any;
            };
            project?: { __typename?: 'ProjectQL'; projectId: string } | null;
            pipeline: { __typename?: 'PipelineQL'; id: string; name: string };
            currentObjectives: Array<{
                __typename?: 'ObjectiveQL';
                criteria: string;
                id: string;
                state: Tristate;
                status: string;
                type: ObjectiveType;
                order?: number | null;
                linkedField?: {
                    __typename?: 'LinkedFieldQL';
                    name: string;
                    type: LinkedFieldType;
                } | null;
            }>;
            currentStage: { __typename?: 'DealStageQL'; id: string };
            exportedDate?: {
                __typename?: 'ExportedDateQL';
                email?: any | null;
                csv?: any | null;
            } | null;
            reminders: Array<{
                __typename?: 'ReminderQL';
                id: string;
                assignee: number;
                description: string;
                dueDate: any;
                createdDate: any;
                updatedDate: any;
                completedDate?: any | null;
                creator: number;
                target: {
                    __typename?: 'ReminderTargetQL';
                    id: string;
                    type: ReminderTargetType;
                    title: string;
                };
            }>;
            alert: {
                __typename?: 'DealAlertQL';
                type: DealAlertType;
                currentUserRelated: boolean;
            };
            activityLog: Array<{
                __typename?: 'ActivityLogQL';
                id: string;
                companyBid: number;
                createdDate: any;
                updatedDate: any;
                userId: number;
                type: ActivityLogType;
                notes: string;
                dealId?: string | null;
                contactId?: string | null;
                relevantCompanyId?: string | null;
                subType?: ActivityLogSubType | null;
                customType: string;
            }>;
        }>;
        nextSteps: Array<{
            __typename?: 'ReminderQL';
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: {
                __typename?: 'ReminderTargetQL';
                id: string;
                type: ReminderTargetType;
                title: string;
            };
        }>;
        activityLogs: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type GetContactExtractionResultQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetContactExtractionResultQuery = {
    __typename?: 'Query';
    getContactExtractionResult: {
        __typename?: 'CombinedContactExtractionResultQL';
        projectContextResult?: {
            __typename?: 'ContactExtractionResultQL';
            status: ContactExtractionStatus;
            extractionType: ExtractionType;
            extractedContacts: Array<{
                __typename?: 'ExtractedContactQL';
                firstName: string;
                lastName: string;
                companyName: string;
                legitimateInterestText: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
            extractedCompanies: Array<{
                __typename?: 'ExtractedCompanyQL';
                legitimateInterestText: string;
                name: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
        } | null;
        webSearchResult?: {
            __typename?: 'ContactExtractionResultQL';
            status: ContactExtractionStatus;
            extractionType: ExtractionType;
            extractedContacts: Array<{
                __typename?: 'ExtractedContactQL';
                firstName: string;
                lastName: string;
                companyName: string;
                legitimateInterestText: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
            extractedCompanies: Array<{
                __typename?: 'ExtractedCompanyQL';
                legitimateInterestText: string;
                name: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
        } | null;
    };
};

export type RunContactExtractionForDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    language: UserLanguage;
}>;

export type RunContactExtractionForDealMutation = {
    __typename?: 'Mutation';
    runContactExtractionForDeal: { __typename?: 'ResultQL'; ok: boolean };
};

export type GetActivityLogByDealQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetActivityLogByDealQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        activityLog: Array<{
            __typename?: 'ActivityLogQL';
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type RunAutoEnrichmentForCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
}>;

export type RunAutoEnrichmentForCompanyMutation = {
    __typename?: 'Mutation';
    autoEnrichCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type RemoveEnrichmentItemFromCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
    field?: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveEnrichmentItemFromCompanyMutation = {
    __typename?: 'Mutation';
    removePlaceFromCompany: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyWithEnrichmentMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
    fields?: InputMaybe<
        Array<Scalars['String']['input']> | Scalars['String']['input']
    >;
}>;

export type UpdateCompanyWithEnrichmentMutation = {
    __typename?: 'Mutation';
    overrideCompanyInfoWithPlace: {
        __typename?: 'RelevantCompanyQL';
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            __typename?: 'CustomFieldQL';
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: {
                __typename?: 'EnrichmentDetailQL';
                id: string;
                source: string;
            } | null;
        }> | null;
        contacts?: Array<{
            __typename?: 'ContactQL';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                __typename?: 'CustomFieldQL';
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: {
                    __typename?: 'EnrichmentDetailQL';
                    id: string;
                    source: string;
                } | null;
            }> | null;
            relevantCompany?: {
                __typename?: 'RelevantCompanyQL';
                id: string;
                name: string;
            } | null;
            parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        }> | null;
        parentDeals?: Array<{ __typename?: 'DealQL'; id: string }> | null;
        placeDetails?: Array<{
            __typename?: 'PlaceDetailsQL';
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                __typename?: 'AddressDetailQL';
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type FindContactsForCompanyQueryVariables = Exact<{
    companyName: Scalars['String']['input'];
    keyword?: InputMaybe<Scalars['String']['input']>;
    page: Scalars['Int']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
    location?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindContactsForCompanyQuery = {
    __typename?: 'Query';
    getCompanyLinkedinProfiles: {
        __typename?: 'LinkedInSearchResultQL';
        profiles: Array<{
            __typename?: 'LinkedInProfileQL';
            title: string;
            location: string;
            linkedinUsername: string;
            headline: string;
            hasPhone: boolean;
            hasEmail: boolean;
            fullName: string;
            companyCountry: string;
            companyName: string;
        }>;
        metaData: {
            __typename?: 'SearchMetaDataQL';
            page: number;
            pageSize: number;
            total: number;
        };
    };
};

export type GetLinkedinProfileContactInfoQueryVariables = Exact<{
    linkedinUserName: Scalars['String']['input'];
}>;

export type GetLinkedinProfileContactInfoQuery = {
    __typename?: 'Query';
    getLinkedinProfileContactInformation: {
        __typename?: 'LinkedInProfileContactInformationQL';
        personalEmail: Array<string>;
        phone: Array<string>;
        workEmail: Array<string>;
    };
};

export type GetDealContentMetadataQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealContentMetadataQuery = {
    __typename?: 'Query';
    deal: {
        __typename?: 'DealQL';
        contents: Array<{
            __typename?: 'DealContentMetadataQL';
            id: string;
            type: DealContentType;
            source?: string | null;
            name?: string | null;
            publishedDate?: any | null;
            hasContent: boolean;
            creationSource: DealContentCreationSource;
        }>;
    };
};

export type GetDealContentAttachmentDownloadUrlQueryVariables = Exact<{
    contentId: Scalars['String']['input'];
    inline: Scalars['Boolean']['input'];
}>;

export type GetDealContentAttachmentDownloadUrlQuery = {
    __typename?: 'Query';
    getDealAttachmentDownloadUrl: { __typename?: 'StringQL'; value: string };
};

export type DeleteDealContentMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    contentId: Scalars['String']['input'];
}>;

export type DeleteDealContentMutation = {
    __typename?: 'Mutation';
    removeDealContent: { __typename?: 'ResultQL'; ok: boolean };
};
