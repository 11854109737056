export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
};

export type AdminImpersonateUserResultQl = {
    __typename?: 'AdminImpersonateUserResultQL';
    cookieName: Scalars['String']['output'];
    token: Scalars['String']['output'];
    user: SessionUserWithExtraAttribsQl;
};

export enum ApiPermissionLevel {
    Read = 'READ',
    Write = 'WRITE',
}

export type BaseUserQl = {
    __typename?: 'BaseUserQL';
    active: Scalars['Boolean']['output'];
    email: Scalars['String']['output'];
    featureToggles: Array<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    isCsManager: Scalars['Boolean']['output'];
    itemId: Scalars['Int']['output'];
    jobRoles: Array<UserJobRoles>;
    language: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    roles: Array<Scalars['String']['output']>;
    settings: UserSettingsQl;
};

export type ColleagueQl = {
    __typename?: 'ColleagueQL';
    active: Scalars['Boolean']['output'];
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    itemId: Scalars['Int']['output'];
    jobRoles: Array<UserJobRoles>;
    lastName: Scalars['String']['output'];
    roles: Array<Scalars['String']['output']>;
};

export type CompanySettingsQl = {
    __typename?: 'CompanySettingsQL';
    currency: Currency;
    merging: Scalars['Boolean']['output'];
    preScreening: Scalars['Boolean']['output'];
    useAppDotBr: Scalars['Boolean']['output'];
};

export type CreateUserApiTokenInputQl = {
    name: Scalars['String']['input'];
    permissionLevel?: ApiPermissionLevel;
};

export enum Currency {
    Eur = 'EUR',
    Usd = 'USD',
}

export type ImpersonateUserInputQl = {
    email: Scalars['String']['input'];
};

export type ImpersonatorInfoQl = {
    __typename?: 'ImpersonatorInfoQL';
    email: Scalars['String']['output'];
    itemId: Scalars['Int']['output'];
};

export type LinkedFilterModuleQl = {
    __typename?: 'LinkedFilterModuleQL';
    filter: Scalars['String']['output'];
    key: Scalars['Int']['output'];
    value: Scalars['String']['output'];
};

export type LoginInputQl = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export enum LoginMethod {
    Authentication = 'AUTHENTICATION',
    ExternalApi = 'EXTERNAL_API',
    Impersonation = 'IMPERSONATION',
}

export type Mutation = {
    __typename?: 'Mutation';
    /** deprecated */
    adminImpersonateUser: AdminImpersonateUserResultQl;
    createUserApiToken: UserApiTokenQl;
    deleteUserApiToken: ResultQl;
    invalidateAllSessions: ResultQl;
    loginWithStatus: SessionUserAuthenticationResultQl;
    logout: ResultQl;
    sendPasswordReset: ResultQl;
    setXsrfToken: ResultQl;
    updatePassword: ResultQl;
    updatePasswordFromResetToken: ResultQl;
    updateUserLanguage: ResultQl;
    updateUserSetting: ResultQl;
};

export type MutationAdminImpersonateUserArgs = {
    input: ImpersonateUserInputQl;
};

export type MutationCreateUserApiTokenArgs = {
    input: CreateUserApiTokenInputQl;
};

export type MutationDeleteUserApiTokenArgs = {
    userApiTokenId: Scalars['Int']['input'];
};

export type MutationInvalidateAllSessionsArgs = {
    keepSessionToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLoginWithStatusArgs = {
    credentials: LoginInputQl;
};

export type MutationSendPasswordResetArgs = {
    email: Scalars['String']['input'];
};

export type MutationUpdatePasswordArgs = {
    newPassword: Scalars['String']['input'];
    oldPassword: Scalars['String']['input'];
    repeatNewPassword: Scalars['String']['input'];
};

export type MutationUpdatePasswordFromResetTokenArgs = {
    newPassword: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationUpdateUserLanguageArgs = {
    language: UserLanguage;
};

export type MutationUpdateUserSettingArgs = {
    input: UpdateUserSettingInputQl;
};

export type Query = {
    __typename?: 'Query';
    baseUserById: BaseUserQl;
    baseUsersInCurrentCompany: Array<BaseUserQl>;
    currentUser: SessionUserWithExtraAttribsQl;
    userApiTokens: Array<UserApiTokenQl>;
    userByUserApiToken?: Maybe<SessionUserQl>;
};

export type QueryBaseUserByIdArgs = {
    userId: Scalars['Int']['input'];
};

export type QueryUserByUserApiTokenArgs = {
    token: Scalars['String']['input'];
};

export type ResultQl = {
    __typename?: 'ResultQL';
    ok: Scalars['Boolean']['output'];
};

export type SearchDataQl = {
    __typename?: 'SearchDataQL';
    filterModules: Array<LinkedFilterModuleQl>;
};

export type SearchQl = {
    __typename?: 'SearchQL';
    created: Scalars['DateTime']['output'];
    itemId: Scalars['Int']['output'];
    lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    paused: Scalars['Boolean']['output'];
    searchData: SearchDataQl;
    type: SearchType;
    userCompanyId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export enum SearchType {
    Article = 'ARTICLE',
    Tender = 'TENDER',
}

export type SessionCompanyQl = {
    __typename?: 'SessionCompanyQL';
    active: Scalars['Boolean']['output'];
    itemId: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    settings: CompanySettingsQl;
};

export type SessionUserAuthenticationResultQl = {
    __typename?: 'SessionUserAuthenticationResultQL';
    status: UserAuthenticationStatus;
    user?: Maybe<SessionUserWithExtraAttribsQl>;
};

export type SessionUserQl = {
    __typename?: 'SessionUserQL';
    active: Scalars['Boolean']['output'];
    apiPermissionLevel?: Maybe<ApiPermissionLevel>;
    company: SessionCompanyQl;
    email: Scalars['String']['output'];
    featureToggles: Array<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    impersonatorInfo?: Maybe<ImpersonatorInfoQl>;
    isCsManager: Scalars['Boolean']['output'];
    itemId: Scalars['Int']['output'];
    jobRoles: Array<UserJobRoles>;
    language: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    loginMethod: LoginMethod;
    roles: Array<Scalars['String']['output']>;
    searchOffset: Scalars['Int']['output'];
    settings: UserSettingsQl;
};

export type SessionUserWithExtraAttribsQl = {
    __typename?: 'SessionUserWithExtraAttribsQL';
    active: Scalars['Boolean']['output'];
    apiPermissionLevel?: Maybe<ApiPermissionLevel>;
    colleagues: Array<ColleagueQl>;
    company: SessionCompanyQl;
    email: Scalars['String']['output'];
    featureToggles: Array<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    impersonatorInfo?: Maybe<ImpersonatorInfoQl>;
    isCsManager: Scalars['Boolean']['output'];
    itemId: Scalars['Int']['output'];
    jobRoles: Array<UserJobRoles>;
    language: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    loginMethod: LoginMethod;
    roles: Array<Scalars['String']['output']>;
    searchOffset: Scalars['Int']['output'];
    searches: Array<SearchQl>;
    settings: UserSettingsQl;
};

export enum SortingKey {
    SkDealAssigneeBid = 'SK_DEAL_ASSIGNEE_BID',
    SkDealClosestNextStepDate = 'SK_DEAL_CLOSEST_NEXT_STEP_DATE',
    SkDealCompletedDate = 'SK_DEAL_COMPLETED_DATE',
    SkDealCreatedDate = 'SK_DEAL_CREATED_DATE',
    SkDealCsvExportedDate = 'SK_DEAL_CSV_EXPORTED_DATE',
    SkDealCurrentStageId = 'SK_DEAL_CURRENT_STAGE_ID',
    SkDealExpectedCurrentStageConversionDate = 'SK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
    SkDealExpectedFinalConversionDate = 'SK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
    SkDealExpectedSalesPeriod = 'SK_DEAL_EXPECTED_SALES_PERIOD',
    SkDealOutreachCount = 'SK_DEAL_OUTREACH_COUNT',
    SkDealPipelineId = 'SK_DEAL_PIPELINE_ID',
    SkDealStageUpdatedDate = 'SK_DEAL_STAGE_UPDATED_DATE',
    SkDealState = 'SK_DEAL_STATE',
    SkDealTitle = 'SK_DEAL_TITLE',
    SkDealValueCurrencyNaive = 'SK_DEAL_VALUE_CURRENCY_NAIVE',
}

export type SortingOpQl = {
    __typename?: 'SortingOpQL';
    field: SortingKey;
    sop: SortingOperator;
};

export enum SortingOperator {
    SopAsc = 'SOP_ASC',
    SopDesc = 'SOP_DESC',
}

export type UpdateUserSettingInputQl = {
    setting: UserSettingName;
    value: Scalars['JSON']['input'];
};

export type UserApiTokenQl = {
    __typename?: 'UserApiTokenQL';
    created: Scalars['DateTime']['output'];
    id: Scalars['Int']['output'];
    last4CharactersToken: Scalars['String']['output'];
    name: Scalars['String']['output'];
    permissionLevel: ApiPermissionLevel;
    plainTextToken?: Maybe<Scalars['String']['output']>;
    prefix: Scalars['String']['output'];
    updated: Scalars['DateTime']['output'];
    userId: Scalars['Int']['output'];
};

export enum UserAuthenticationStatus {
    InvalidCredentials = 'INVALID_CREDENTIALS',
    Success = 'SUCCESS',
    UserInactive = 'USER_INACTIVE',
}

export enum UserJobRoles {
    MeetingBooker = 'MEETING_BOOKER',
    Observer = 'OBSERVER',
    Researcher = 'RESEARCHER',
    SaleOwner = 'SALE_OWNER',
    Screener = 'SCREENER',
    Supervisor = 'SUPERVISOR',
}

export enum UserLanguage {
    De = 'DE',
    En = 'EN',
}

export enum UserSettingName {
    CsvDelimiter = 'CSV_DELIMITER',
    DefaultPipeline = 'DEFAULT_PIPELINE',
    EmailCommentMentions = 'EMAIL_COMMENT_MENTIONS',
    EmailDealAssigneeChange = 'EMAIL_DEAL_ASSIGNEE_CHANGE',
    EmailDealImportCompleted = 'EMAIL_DEAL_IMPORT_COMPLETED',
    EmailDigest = 'EMAIL_DIGEST',
    EmailPausedDealNotifications = 'EMAIL_PAUSED_DEAL_NOTIFICATIONS',
    EmailReminders = 'EMAIL_REMINDERS',
    OptInAchievements = 'OPT_IN_ACHIEVEMENTS',
    OutreachBlockIds = 'OUTREACH_BLOCK_IDS',
    OutreachCallBlockIds = 'OUTREACH_CALL_BLOCK_IDS',
    PipelineViewPreferencesAssignees = 'PIPELINE_VIEW_PREFERENCES_ASSIGNEES',
    PipelineViewPreferencesCustomParameterTag = 'PIPELINE_VIEW_PREFERENCES_CUSTOM_PARAMETER_TAG',
    PipelineViewPreferencesPaginationRows = 'PIPELINE_VIEW_PREFERENCES_PAGINATION_ROWS',
    PipelineViewPreferencesShowPausedDeals = 'PIPELINE_VIEW_PREFERENCES_SHOW_PAUSED_DEALS',
    PipelineViewPreferencesSorting = 'PIPELINE_VIEW_PREFERENCES_SORTING',
    PipelineViewPreferencesStageIds = 'PIPELINE_VIEW_PREFERENCES_STAGE_IDS',
    PipelineViewPreferencesVisualizationMode = 'PIPELINE_VIEW_PREFERENCES_VISUALIZATION_MODE',
}

export type UserSettingsQl = {
    __typename?: 'UserSettingsQL';
    csvDelimiter: Scalars['String']['output'];
    defaultPipeline?: Maybe<Scalars['String']['output']>;
    emailCommentMentions: Scalars['Boolean']['output'];
    emailDealAssigneeChange: Scalars['Boolean']['output'];
    emailDealImportCompleted: Scalars['Boolean']['output'];
    emailDigest: Scalars['String']['output'];
    emailPausedDealNotifications: Scalars['Boolean']['output'];
    emailReminders: Scalars['Boolean']['output'];
    optInAchievements: Scalars['Boolean']['output'];
    outreachBlockIds: Array<Scalars['String']['output']>;
    outreachCallBlockIds: Array<Scalars['String']['output']>;
    pipelineViewPreferencesAssignees: Array<Scalars['Int']['output']>;
    pipelineViewPreferencesCustomParameterTag?: Maybe<
        Scalars['JSON']['output']
    >;
    pipelineViewPreferencesPaginationRows: Scalars['Int']['output'];
    pipelineViewPreferencesShowPausedDeals: Scalars['Boolean']['output'];
    pipelineViewPreferencesSorting: Array<SortingOpQl>;
    pipelineViewPreferencesStageIds: Array<Scalars['String']['output']>;
    pipelineViewPreferencesVisualizationMode: Scalars['String']['output'];
};

export type UserPartFragment = {
    __typename?: 'SessionUserWithExtraAttribsQL';
    active: boolean;
    email: string;
    featureToggles: Array<string>;
    firstName: string;
    isCsManager: boolean;
    language: string;
    itemId: number;
    roles: Array<string>;
    lastName: string;
    searchOffset: number;
    loginMethod: LoginMethod;
    jobRoles: Array<UserJobRoles>;
    impersonatorInfo?: {
        __typename?: 'ImpersonatorInfoQL';
        itemId: number;
        email: string;
    } | null;
    colleagues: Array<{
        __typename?: 'ColleagueQL';
        roles: Array<string>;
        lastName: string;
        itemId: number;
        firstName: string;
        email: string;
        active: boolean;
    }>;
    company: {
        __typename?: 'SessionCompanyQL';
        name: string;
        itemId: number;
        settings: {
            __typename?: 'CompanySettingsQL';
            currency: Currency;
            merging: boolean;
            preScreening: boolean;
            useAppDotBr: boolean;
        };
    };
    settings: {
        __typename?: 'UserSettingsQL';
        defaultPipeline?: string | null;
        csvDelimiter: string;
        emailCommentMentions: boolean;
        emailDigest: string;
        emailPausedDealNotifications: boolean;
        emailReminders: boolean;
        emailDealAssigneeChange: boolean;
        pipelineViewPreferencesAssignees: Array<number>;
        emailDealImportCompleted: boolean;
        pipelineViewPreferencesCustomParameterTag?: any | null;
        pipelineViewPreferencesShowPausedDeals: boolean;
        pipelineViewPreferencesVisualizationMode: string;
        pipelineViewPreferencesPaginationRows: number;
        pipelineViewPreferencesStageIds: Array<string>;
        optInAchievements: boolean;
        outreachBlockIds: Array<string>;
        outreachCallBlockIds: Array<string>;
        pipelineViewPreferencesSorting: Array<{
            __typename?: 'SortingOpQL';
            field: SortingKey;
            sop: SortingOperator;
        }>;
    };
    searches: Array<{
        __typename?: 'SearchQL';
        itemId: number;
        name: string;
        type: SearchType;
        userId: number;
        searchData: {
            __typename?: 'SearchDataQL';
            filterModules: Array<{
                __typename?: 'LinkedFilterModuleQL';
                filter: string;
                key: number;
                value: string;
            }>;
        };
    }>;
};

export type UserApiTokenFragment = {
    __typename?: 'UserApiTokenQL';
    permissionLevel: ApiPermissionLevel;
    created: any;
    id: number;
    last4CharactersToken: string;
    name: string;
    plainTextToken?: string | null;
    prefix: string;
    updated: any;
    userId: number;
};

export type SettingsPartFragment = {
    __typename?: 'UserSettingsQL';
    defaultPipeline?: string | null;
    csvDelimiter: string;
    emailCommentMentions: boolean;
    emailDigest: string;
    emailPausedDealNotifications: boolean;
    emailReminders: boolean;
    emailDealAssigneeChange: boolean;
    pipelineViewPreferencesAssignees: Array<number>;
    emailDealImportCompleted: boolean;
    pipelineViewPreferencesCustomParameterTag?: any | null;
    pipelineViewPreferencesShowPausedDeals: boolean;
    pipelineViewPreferencesVisualizationMode: string;
    pipelineViewPreferencesPaginationRows: number;
    pipelineViewPreferencesStageIds: Array<string>;
    optInAchievements: boolean;
    outreachBlockIds: Array<string>;
    outreachCallBlockIds: Array<string>;
    pipelineViewPreferencesSorting: Array<{
        __typename?: 'SortingOpQL';
        field: SortingKey;
        sop: SortingOperator;
    }>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
    __typename?: 'Query';
    currentUser: {
        __typename?: 'SessionUserWithExtraAttribsQL';
        active: boolean;
        email: string;
        featureToggles: Array<string>;
        firstName: string;
        isCsManager: boolean;
        language: string;
        itemId: number;
        roles: Array<string>;
        lastName: string;
        searchOffset: number;
        loginMethod: LoginMethod;
        jobRoles: Array<UserJobRoles>;
        impersonatorInfo?: {
            __typename?: 'ImpersonatorInfoQL';
            itemId: number;
            email: string;
        } | null;
        colleagues: Array<{
            __typename?: 'ColleagueQL';
            roles: Array<string>;
            lastName: string;
            itemId: number;
            firstName: string;
            email: string;
            active: boolean;
        }>;
        company: {
            __typename?: 'SessionCompanyQL';
            name: string;
            itemId: number;
            settings: {
                __typename?: 'CompanySettingsQL';
                currency: Currency;
                merging: boolean;
                preScreening: boolean;
                useAppDotBr: boolean;
            };
        };
        settings: {
            __typename?: 'UserSettingsQL';
            defaultPipeline?: string | null;
            csvDelimiter: string;
            emailCommentMentions: boolean;
            emailDigest: string;
            emailPausedDealNotifications: boolean;
            emailReminders: boolean;
            emailDealAssigneeChange: boolean;
            pipelineViewPreferencesAssignees: Array<number>;
            emailDealImportCompleted: boolean;
            pipelineViewPreferencesCustomParameterTag?: any | null;
            pipelineViewPreferencesShowPausedDeals: boolean;
            pipelineViewPreferencesVisualizationMode: string;
            pipelineViewPreferencesPaginationRows: number;
            pipelineViewPreferencesStageIds: Array<string>;
            optInAchievements: boolean;
            outreachBlockIds: Array<string>;
            outreachCallBlockIds: Array<string>;
            pipelineViewPreferencesSorting: Array<{
                __typename?: 'SortingOpQL';
                field: SortingKey;
                sop: SortingOperator;
            }>;
        };
        searches: Array<{
            __typename?: 'SearchQL';
            itemId: number;
            name: string;
            type: SearchType;
            userId: number;
            searchData: {
                __typename?: 'SearchDataQL';
                filterModules: Array<{
                    __typename?: 'LinkedFilterModuleQL';
                    filter: string;
                    key: number;
                    value: string;
                }>;
            };
        }>;
    };
};

export type GetUserApiTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserApiTokensQuery = {
    __typename?: 'Query';
    userApiTokens: Array<{
        __typename?: 'UserApiTokenQL';
        permissionLevel: ApiPermissionLevel;
        created: any;
        id: number;
        last4CharactersToken: string;
        name: string;
        plainTextToken?: string | null;
        prefix: string;
        updated: any;
        userId: number;
    }>;
};

export type LoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    loginWithStatus: {
        __typename?: 'SessionUserAuthenticationResultQL';
        status: UserAuthenticationStatus;
        user?: {
            __typename?: 'SessionUserWithExtraAttribsQL';
            active: boolean;
            email: string;
            featureToggles: Array<string>;
            firstName: string;
            isCsManager: boolean;
            language: string;
            itemId: number;
            roles: Array<string>;
            lastName: string;
            searchOffset: number;
            loginMethod: LoginMethod;
            jobRoles: Array<UserJobRoles>;
            impersonatorInfo?: {
                __typename?: 'ImpersonatorInfoQL';
                itemId: number;
                email: string;
            } | null;
            colleagues: Array<{
                __typename?: 'ColleagueQL';
                roles: Array<string>;
                lastName: string;
                itemId: number;
                firstName: string;
                email: string;
                active: boolean;
            }>;
            company: {
                __typename?: 'SessionCompanyQL';
                name: string;
                itemId: number;
                settings: {
                    __typename?: 'CompanySettingsQL';
                    currency: Currency;
                    merging: boolean;
                    preScreening: boolean;
                    useAppDotBr: boolean;
                };
            };
            settings: {
                __typename?: 'UserSettingsQL';
                defaultPipeline?: string | null;
                csvDelimiter: string;
                emailCommentMentions: boolean;
                emailDigest: string;
                emailPausedDealNotifications: boolean;
                emailReminders: boolean;
                emailDealAssigneeChange: boolean;
                pipelineViewPreferencesAssignees: Array<number>;
                emailDealImportCompleted: boolean;
                pipelineViewPreferencesCustomParameterTag?: any | null;
                pipelineViewPreferencesShowPausedDeals: boolean;
                pipelineViewPreferencesVisualizationMode: string;
                pipelineViewPreferencesPaginationRows: number;
                pipelineViewPreferencesStageIds: Array<string>;
                optInAchievements: boolean;
                outreachBlockIds: Array<string>;
                outreachCallBlockIds: Array<string>;
                pipelineViewPreferencesSorting: Array<{
                    __typename?: 'SortingOpQL';
                    field: SortingKey;
                    sop: SortingOperator;
                }>;
            };
            searches: Array<{
                __typename?: 'SearchQL';
                itemId: number;
                name: string;
                type: SearchType;
                userId: number;
                searchData: {
                    __typename?: 'SearchDataQL';
                    filterModules: Array<{
                        __typename?: 'LinkedFilterModuleQL';
                        filter: string;
                        key: number;
                        value: string;
                    }>;
                };
            }>;
        } | null;
    };
};

export type ImpersonateUserMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type ImpersonateUserMutation = {
    __typename?: 'Mutation';
    adminImpersonateUser: {
        __typename?: 'AdminImpersonateUserResultQL';
        user: {
            __typename?: 'SessionUserWithExtraAttribsQL';
            active: boolean;
            email: string;
            featureToggles: Array<string>;
            firstName: string;
            isCsManager: boolean;
            language: string;
            itemId: number;
            roles: Array<string>;
            lastName: string;
            searchOffset: number;
            loginMethod: LoginMethod;
            jobRoles: Array<UserJobRoles>;
            impersonatorInfo?: {
                __typename?: 'ImpersonatorInfoQL';
                itemId: number;
                email: string;
            } | null;
            colleagues: Array<{
                __typename?: 'ColleagueQL';
                roles: Array<string>;
                lastName: string;
                itemId: number;
                firstName: string;
                email: string;
                active: boolean;
            }>;
            company: {
                __typename?: 'SessionCompanyQL';
                name: string;
                itemId: number;
                settings: {
                    __typename?: 'CompanySettingsQL';
                    currency: Currency;
                    merging: boolean;
                    preScreening: boolean;
                    useAppDotBr: boolean;
                };
            };
            settings: {
                __typename?: 'UserSettingsQL';
                defaultPipeline?: string | null;
                csvDelimiter: string;
                emailCommentMentions: boolean;
                emailDigest: string;
                emailPausedDealNotifications: boolean;
                emailReminders: boolean;
                emailDealAssigneeChange: boolean;
                pipelineViewPreferencesAssignees: Array<number>;
                emailDealImportCompleted: boolean;
                pipelineViewPreferencesCustomParameterTag?: any | null;
                pipelineViewPreferencesShowPausedDeals: boolean;
                pipelineViewPreferencesVisualizationMode: string;
                pipelineViewPreferencesPaginationRows: number;
                pipelineViewPreferencesStageIds: Array<string>;
                optInAchievements: boolean;
                outreachBlockIds: Array<string>;
                outreachCallBlockIds: Array<string>;
                pipelineViewPreferencesSorting: Array<{
                    __typename?: 'SortingOpQL';
                    field: SortingKey;
                    sop: SortingOperator;
                }>;
            };
            searches: Array<{
                __typename?: 'SearchQL';
                itemId: number;
                name: string;
                type: SearchType;
                userId: number;
                searchData: {
                    __typename?: 'SearchDataQL';
                    filterModules: Array<{
                        __typename?: 'LinkedFilterModuleQL';
                        filter: string;
                        key: number;
                        value: string;
                    }>;
                };
            }>;
        };
    };
};

export type CreateUserApiTokenMutationVariables = Exact<{
    name: Scalars['String']['input'];
    apiPermissionLevel?: InputMaybe<ApiPermissionLevel>;
}>;

export type CreateUserApiTokenMutation = {
    __typename?: 'Mutation';
    createUserApiToken: {
        __typename?: 'UserApiTokenQL';
        permissionLevel: ApiPermissionLevel;
        created: any;
        id: number;
        last4CharactersToken: string;
        name: string;
        plainTextToken?: string | null;
        prefix: string;
        updated: any;
        userId: number;
    };
};

export type DeleteUserApiTokenMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteUserApiTokenMutation = {
    __typename?: 'Mutation';
    deleteUserApiToken: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdateUserSettingMutationVariables = Exact<{
    setting: UserSettingName;
    value: Scalars['JSON']['input'];
}>;

export type UpdateUserSettingMutation = {
    __typename?: 'Mutation';
    updateUserSetting: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdateUserLanguageMutationVariables = Exact<{
    language: UserLanguage;
}>;

export type UpdateUserLanguageMutation = {
    __typename?: 'Mutation';
    updateUserLanguage: { __typename?: 'ResultQL'; ok: boolean };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
    __typename?: 'Mutation';
    logout: { __typename?: 'ResultQL'; ok: boolean };
};

export type SendPasswordResetMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type SendPasswordResetMutation = {
    __typename?: 'Mutation';
    sendPasswordReset: { __typename?: 'ResultQL'; ok: boolean };
};

export type SetXsrfTokenMutationVariables = Exact<{ [key: string]: never }>;

export type SetXsrfTokenMutation = {
    __typename?: 'Mutation';
    setXsrfToken: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdatePasswordMutationVariables = Exact<{
    oldPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
    repeatNewPassword: Scalars['String']['input'];
}>;

export type UpdatePasswordMutation = {
    __typename?: 'Mutation';
    updatePassword: { __typename?: 'ResultQL'; ok: boolean };
};

export type UpdatePasswordFromResetTokenMutationVariables = Exact<{
    newPassword: Scalars['String']['input'];
    token: Scalars['String']['input'];
}>;

export type UpdatePasswordFromResetTokenMutation = {
    __typename?: 'Mutation';
    updatePasswordFromResetToken: { __typename?: 'ResultQL'; ok: boolean };
};
