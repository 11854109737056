import { makeAutoObservable } from 'mobx';

import { DealsApi } from 'src/data/api/deal/deal.api';
import { buildQueryFilters } from 'src/data/api/pipeline/pipeline.api.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { PipelineDeal } from 'src/domain/models/deal/deal.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { RequestStatus } from 'src/data/api/utils/request-status';
import { UserStore } from 'src/data/stores/user/user.store';
import { ComponentProps } from 'react';
import { DataTable } from '@buildingradar/ui_library/data-table';
import { SortingOpQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGINATION_CONFIG: PaginationConfig = {
    hidePagination: false,
    onPaginationChange: undefined,
    pageCount: undefined,
    pagination: {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    },
    paginationMode: undefined,
    rowCount: 0,
    rowsPerPageOptions: [
        DEFAULT_PAGE_SIZE,
        DEFAULT_PAGE_SIZE * 2,
        DEFAULT_PAGE_SIZE * 4,
    ],
};

export type PaginationConfig = Exclude<
    ComponentProps<typeof DataTable>['paginationConfig'],
    undefined
>;
export type PaginationState = Exclude<
    PaginationConfig['pagination'],
    undefined
>;

type SortingConfig = Exclude<
    ComponentProps<typeof DataTable>['sortingConfig'],
    undefined
>;
export type SortingState = Exclude<SortingConfig['sorting'], undefined>;

export interface IDashboardTableViewFeature {
    deals: PipelineDeal[];
    count: number;
    fetchingStatus: RequestStatus;
    currentPaginationConfig: PaginationConfig;
    setCurrentPaginationState: (state: PaginationState) => void;
    setCurrentSearchKeyword: (keyword?: string) => void;
    setSortingDealParams: (params: SortingOpQl[]) => Promise<void>;
    requestDashboardDeals: () => Promise<void>;
    assignOwnerToDeal: (userId: number, dealId: string) => Promise<void>;
}

export class DashboardTableViewFeature implements IDashboardTableViewFeature {
    constructor(
        private dealApi: DealsApi,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    fetchingStatus: RequestStatus = 'not-started';
    currentPaginationConfig: PaginationConfig = DEFAULT_PAGINATION_CONFIG;
    sortingDealParams: SortingOpQl[] = [];
    currentSearchKeyword?: string;
    deals: PipelineDeal[] = [];
    count: number = 0;

    setFetchingStatus = (status: RequestStatus) =>
        (this.fetchingStatus = status);

    setCurrentPaginationState = async (state: PaginationState) => {
        this.currentPaginationConfig.pagination = state;
        await this.requestDashboardDeals();
    };

    setCurrentSearchKeyword = async (keyword?: string) => {
        this.currentSearchKeyword = keyword;
        await this.requestDashboardDeals();
    };

    requestDashboardDeals = async () => {
        try {
            this.currentPaginationConfig.paginationMode = 'server';

            const currentUserId = this.userStore.user?.itemId;
            if (!currentUserId) {
                throw Error('User is not set');
            }
            const customParameterTag =
                this.accountConfigurationStore.customParameterTag;

            const filters = buildQueryFilters(
                {
                    usersIds: [currentUserId],
                    customParameterTagOptions: [],
                    showPausedDeals: false,
                    stageIds: [],
                },
                customParameterTag,
                true,
                this.currentSearchKeyword,
            );

            this.fetchingStatus = 'loading';

            const dealsCollection = await handleRequestAsync(
                this.dealApi.getDealsByPipeline,
                {
                    options: {
                        filtering: filters,
                        pagination: {
                            first: this.currentPaginationConfig.pagination!
                                .pageSize,
                            offset:
                                this.currentPaginationConfig.pagination!
                                    .pageIndex *
                                this.currentPaginationConfig.pagination!
                                    .pageSize,
                        },
                        sorting: this.sortingDealParams,
                    },
                },
                undefined,
                undefined,
                'requestDashboardDeals',
            );

            this.fetchingStatus = 'done';

            if (dealsCollection) {
                this.deals = dealsCollection.deals;
                this.count = dealsCollection.totalCount;
                this.currentPaginationConfig = {
                    ...this.currentPaginationConfig,
                    rowCount: dealsCollection.totalCount,
                };
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-dashboard-deals',
                error as Error,
            );
            this.fetchingStatus = 'error';
        }
    };

    assignOwnerToDeal = async (assigneeId: number, dealId: string) => {
        await handleRequestAsync(
            this.dealApi.assignUserToDeal,
            { dealId, assigneeId },
            undefined,
            (error) =>
                this.baseStore.onRequestFailed('assign-owner-to-deal', error),
        );

        await this.requestDashboardDeals();
    };

    setSortingDealParams = async (params: SortingOpQl[]): Promise<void> => {
        this.sortingDealParams = params;
        await this.requestDashboardDeals();
    };
}
