/* eslint-disable */
import * as Types from './graphql-sdk';

import { DocumentNode } from 'graphql';
import * as Operations from './hooks';

export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        GetTenderRootCodes(
            variables?: Types.GetTenderRootCodesQueryVariables,
            options?: C,
        ): Promise<Types.GetTenderRootCodesQuery> {
            return requester<
                Types.GetTenderRootCodesQuery,
                Types.GetTenderRootCodesQueryVariables
            >(
                Operations.GqlOperationGetTenderRootCodesDocument,
                variables,
                options,
            ) as Promise<Types.GetTenderRootCodesQuery>;
        },
        GetChildTenderCodes(
            variables: Types.GetChildTenderCodesQueryVariables,
            options?: C,
        ): Promise<Types.GetChildTenderCodesQuery> {
            return requester<
                Types.GetChildTenderCodesQuery,
                Types.GetChildTenderCodesQueryVariables
            >(
                Operations.GqlOperationGetChildTenderCodesDocument,
                variables,
                options,
            ) as Promise<Types.GetChildTenderCodesQuery>;
        },
        GetFilteredTenderCodes(
            variables: Types.GetFilteredTenderCodesQueryVariables,
            options?: C,
        ): Promise<Types.GetFilteredTenderCodesQuery> {
            return requester<
                Types.GetFilteredTenderCodesQuery,
                Types.GetFilteredTenderCodesQueryVariables
            >(
                Operations.GqlOperationGetFilteredTenderCodesDocument,
                variables,
                options,
            ) as Promise<Types.GetFilteredTenderCodesQuery>;
        },
        GetFilterModules(
            variables?: Types.GetFilterModulesQueryVariables,
            options?: C,
        ): Promise<Types.GetFilterModulesQuery> {
            return requester<
                Types.GetFilterModulesQuery,
                Types.GetFilterModulesQueryVariables
            >(
                Operations.GqlOperationGetFilterModulesDocument,
                variables,
                options,
            ) as Promise<Types.GetFilterModulesQuery>;
        },
        GetFilterModuleById(
            variables: Types.GetFilterModuleByIdQueryVariables,
            options?: C,
        ): Promise<Types.GetFilterModuleByIdQuery> {
            return requester<
                Types.GetFilterModuleByIdQuery,
                Types.GetFilterModuleByIdQueryVariables
            >(
                Operations.GqlOperationGetFilterModuleByIdDocument,
                variables,
                options,
            ) as Promise<Types.GetFilterModuleByIdQuery>;
        },
        SearchByFilterModulesWithNarrative(
            variables: Types.SearchByFilterModulesWithNarrativeQueryVariables,
            options?: C,
        ): Promise<Types.SearchByFilterModulesWithNarrativeQuery> {
            return requester<
                Types.SearchByFilterModulesWithNarrativeQuery,
                Types.SearchByFilterModulesWithNarrativeQueryVariables
            >(
                Operations.GqlOperationSearchByFilterModulesWithNarrativeDocument,
                variables,
                options,
            ) as Promise<Types.SearchByFilterModulesWithNarrativeQuery>;
        },
        SearchCountByFilterModules(
            variables: Types.SearchCountByFilterModulesQueryVariables,
            options?: C,
        ): Promise<Types.SearchCountByFilterModulesQuery> {
            return requester<
                Types.SearchCountByFilterModulesQuery,
                Types.SearchCountByFilterModulesQueryVariables
            >(
                Operations.GqlOperationSearchCountByFilterModulesDocument,
                variables,
                options,
            ) as Promise<Types.SearchCountByFilterModulesQuery>;
        },
        GetLocationSuggestions(
            variables: Types.GetLocationSuggestionsQueryVariables,
            options?: C,
        ): Promise<Types.GetLocationSuggestionsQuery> {
            return requester<
                Types.GetLocationSuggestionsQuery,
                Types.GetLocationSuggestionsQueryVariables
            >(
                Operations.GqlOperationGetLocationSuggestionsDocument,
                variables,
                options,
            ) as Promise<Types.GetLocationSuggestionsQuery>;
        },
        GetSourceDomainSuggestions(
            variables: Types.GetSourceDomainSuggestionsQueryVariables,
            options?: C,
        ): Promise<Types.GetSourceDomainSuggestionsQuery> {
            return requester<
                Types.GetSourceDomainSuggestionsQuery,
                Types.GetSourceDomainSuggestionsQueryVariables
            >(
                Operations.GqlOperationGetSourceDomainSuggestionsDocument,
                variables,
                options,
            ) as Promise<Types.GetSourceDomainSuggestionsQuery>;
        },
        GetSearchesAndSubscriptions(
            variables?: Types.GetSearchesAndSubscriptionsQueryVariables,
            options?: C,
        ): Promise<Types.GetSearchesAndSubscriptionsQuery> {
            return requester<
                Types.GetSearchesAndSubscriptionsQuery,
                Types.GetSearchesAndSubscriptionsQueryVariables
            >(
                Operations.GqlOperationGetSearchesAndSubscriptionsDocument,
                variables,
                options,
            ) as Promise<Types.GetSearchesAndSubscriptionsQuery>;
        },
        CreateFilterModule(
            variables: Types.CreateFilterModuleMutationVariables,
            options?: C,
        ): Promise<Types.CreateFilterModuleMutation> {
            return requester<
                Types.CreateFilterModuleMutation,
                Types.CreateFilterModuleMutationVariables
            >(
                Operations.GqlOperationCreateFilterModuleDocument,
                variables,
                options,
            ) as Promise<Types.CreateFilterModuleMutation>;
        },
        UpdateFilterModule(
            variables: Types.UpdateFilterModuleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateFilterModuleMutation> {
            return requester<
                Types.UpdateFilterModuleMutation,
                Types.UpdateFilterModuleMutationVariables
            >(
                Operations.GqlOperationUpdateFilterModuleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateFilterModuleMutation>;
        },
        DeleteFilterModule(
            variables: Types.DeleteFilterModuleMutationVariables,
            options?: C,
        ): Promise<Types.DeleteFilterModuleMutation> {
            return requester<
                Types.DeleteFilterModuleMutation,
                Types.DeleteFilterModuleMutationVariables
            >(
                Operations.GqlOperationDeleteFilterModuleDocument,
                variables,
                options,
            ) as Promise<Types.DeleteFilterModuleMutation>;
        },
        DeleteSearch(
            variables: Types.DeleteSearchMutationVariables,
            options?: C,
        ): Promise<Types.DeleteSearchMutation> {
            return requester<
                Types.DeleteSearchMutation,
                Types.DeleteSearchMutationVariables
            >(
                Operations.GqlOperationDeleteSearchDocument,
                variables,
                options,
            ) as Promise<Types.DeleteSearchMutation>;
        },
        UpsertSearch(
            variables: Types.UpsertSearchMutationVariables,
            options?: C,
        ): Promise<Types.UpsertSearchMutation> {
            return requester<
                Types.UpsertSearchMutation,
                Types.UpsertSearchMutationVariables
            >(
                Operations.GqlOperationUpsertSearchDocument,
                variables,
                options,
            ) as Promise<Types.UpsertSearchMutation>;
        },
        SubscribeToSearch(
            variables: Types.SubscribeToSearchMutationVariables,
            options?: C,
        ): Promise<Types.SubscribeToSearchMutation> {
            return requester<
                Types.SubscribeToSearchMutation,
                Types.SubscribeToSearchMutationVariables
            >(
                Operations.GqlOperationSubscribeToSearchDocument,
                variables,
                options,
            ) as Promise<Types.SubscribeToSearchMutation>;
        },
        UnsubscribeToSearch(
            variables: Types.UnsubscribeToSearchMutationVariables,
            options?: C,
        ): Promise<Types.UnsubscribeToSearchMutation> {
            return requester<
                Types.UnsubscribeToSearchMutation,
                Types.UnsubscribeToSearchMutationVariables
            >(
                Operations.GqlOperationUnsubscribeToSearchDocument,
                variables,
                options,
            ) as Promise<Types.UnsubscribeToSearchMutation>;
        },
        PauseSearch(
            variables: Types.PauseSearchMutationVariables,
            options?: C,
        ): Promise<Types.PauseSearchMutation> {
            return requester<
                Types.PauseSearchMutation,
                Types.PauseSearchMutationVariables
            >(
                Operations.GqlOperationPauseSearchDocument,
                variables,
                options,
            ) as Promise<Types.PauseSearchMutation>;
        },
        UnpauseSearch(
            variables: Types.UnpauseSearchMutationVariables,
            options?: C,
        ): Promise<Types.UnpauseSearchMutation> {
            return requester<
                Types.UnpauseSearchMutation,
                Types.UnpauseSearchMutationVariables
            >(
                Operations.GqlOperationUnpauseSearchDocument,
                variables,
                options,
            ) as Promise<Types.UnpauseSearchMutation>;
        },
        UpdateSearchPrescreeningSettings(
            variables: Types.UpdateSearchPrescreeningSettingsMutationVariables,
            options?: C,
        ): Promise<Types.UpdateSearchPrescreeningSettingsMutation> {
            return requester<
                Types.UpdateSearchPrescreeningSettingsMutation,
                Types.UpdateSearchPrescreeningSettingsMutationVariables
            >(
                Operations.GqlOperationUpdateSearchPrescreeningSettingsDocument,
                variables,
                options,
            ) as Promise<Types.UpdateSearchPrescreeningSettingsMutation>;
        },
        GetTenderTextFromCodes(
            variables: Types.GetTenderTextFromCodesQueryVariables,
            options?: C,
        ): Promise<Types.GetTenderTextFromCodesQuery> {
            return requester<
                Types.GetTenderTextFromCodesQuery,
                Types.GetTenderTextFromCodesQueryVariables
            >(
                Operations.GqlOperationGetTenderTextFromCodesDocument,
                variables,
                options,
            ) as Promise<Types.GetTenderTextFromCodesQuery>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
