import { AbortParams } from 'src/data/api/api-client';
import {
    GqlOperationGenerateValuePropCallScriptStreamDocument,
    GenerateValuePropCallScriptStreamSubscription,
    GenerateValuePropCallScriptStreamSubscriptionVariables,
    GqlOperationGenerateValuePropEmailFollowUpStreamDocument,
    GenerateValuePropEmailFollowUpStreamSubscription,
    GenerateValuePropEmailFollowUpStreamSubscriptionVariables,
    GqlOperationGenerateValuePropEmailStreamDocument,
    GenerateValuePropEmailStreamSubscription,
    GenerateValuePropEmailStreamSubscriptionVariables,
    RegenerationSpecQl,
} from 'src/data/api/graphql/br_process/generated';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { doNothing } from 'src/utils/function.utils';
import { PropositionType } from '../domain/model/propositionType';

interface GenerateContentParams extends AbortParams {
    contactId?: string;
    companyId?: string;
    dealId: string;
    vpoSessionId: string;
    regenerationSpec?: RegenerationSpecQl;
    outreachBlockIds: string[];
    propositionType: PropositionType;
    onNextValue?: (value: string, propositionType: PropositionType) => void;
}

export interface IVpoGenerationApi {
    getGeneratedContentHybrid: (
        params: GenerateContentParams,
    ) => Promise<string>;
}

export const createVpoGenerationApi = (
    processGqlSdk: ProcessGqlSdk,
): IVpoGenerationApi => {
    const getGeneratedContentHybrid = async (params: GenerateContentParams) => {
        return getSubscription(params);
    };

    const getSubscription = async ({
        contactId,
        companyId,
        dealId,
        regenerationSpec,
        outreachBlockIds,
        vpoSessionId,
        propositionType,
        onNextValue = doNothing,
        signal,
    }: GenerateContentParams): Promise<string> => {
        let answer = '';
        return new Promise((resolve, reject) => {
            if (propositionType === 'first-email') {
                processGqlSdk.runSubscription<
                    GenerateValuePropEmailStreamSubscription,
                    GenerateValuePropEmailStreamSubscriptionVariables
                >(
                    {
                        query: GqlOperationGenerateValuePropEmailStreamDocument,
                        variables: {
                            contactId,
                            companyId,
                            dealId,
                            vpoSessionId,
                            regenerationSpec,
                            outreachBlockIds,
                        },
                    },
                    (value) => {
                        answer +=
                            value.actionFeatureValuePropOutreachEmail.answer;
                        onNextValue(answer, propositionType);
                    },
                    () => resolve(answer),
                    (error) => reject(error),
                    signal,
                );
            } else if (propositionType === 'follow-up-email') {
                processGqlSdk.runSubscription<
                    GenerateValuePropEmailFollowUpStreamSubscription,
                    GenerateValuePropEmailFollowUpStreamSubscriptionVariables
                >(
                    {
                        query: GqlOperationGenerateValuePropEmailFollowUpStreamDocument,

                        variables: {
                            contactId,
                            companyId,
                            dealId,
                            regenerationSpec,
                            outreachBlockIds,
                        },
                    },
                    (value) => {
                        answer +=
                            value.actionFeatureValuePropOutreachFollowUp.answer;
                        onNextValue(answer, propositionType);
                    },
                    () => resolve(answer),
                    (error) => reject(error),
                    signal,
                );
            } else if (propositionType === 'call-script') {
                processGqlSdk.runSubscription<
                    GenerateValuePropCallScriptStreamSubscription,
                    GenerateValuePropCallScriptStreamSubscriptionVariables
                >(
                    {
                        query: GqlOperationGenerateValuePropCallScriptStreamDocument,
                        variables: {
                            contactId,
                            companyId,
                            dealId,
                            regenerationSpec,
                            outreachBlockIds,
                        },
                    },
                    (value) => {
                        answer +=
                            value.actionFeatureValuePropOutreachCallScript
                                .answer;
                        onNextValue(answer, propositionType);
                    },
                    () => resolve(answer),
                    (error) => reject(error),
                    signal,
                );
            } else {
                reject('Invalid proposition type');
            }
        });
    };

    return {
        getGeneratedContentHybrid,
    };
};
