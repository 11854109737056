/* eslint-disable */
import * as Types from './graphql-sdk';

import { DocumentNode } from 'graphql';
import * as Operations from './hooks';

export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        MoveDealToStage(
            variables: Types.MoveDealToStageMutationVariables,
            options?: C,
        ): Promise<Types.MoveDealToStageMutation> {
            return requester<
                Types.MoveDealToStageMutation,
                Types.MoveDealToStageMutationVariables
            >(
                Operations.GqlOperationMoveDealToStageDocument,
                variables,
                options,
            ) as Promise<Types.MoveDealToStageMutation>;
        },
        AssignUserToDeal(
            variables: Types.AssignUserToDealMutationVariables,
            options?: C,
        ): Promise<Types.AssignUserToDealMutation> {
            return requester<
                Types.AssignUserToDealMutation,
                Types.AssignUserToDealMutationVariables
            >(
                Operations.GqlOperationAssignUserToDealDocument,
                variables,
                options,
            ) as Promise<Types.AssignUserToDealMutation>;
        },
        DisqualifyDeal(
            variables: Types.DisqualifyDealMutationVariables,
            options?: C,
        ): Promise<Types.DisqualifyDealMutation> {
            return requester<
                Types.DisqualifyDealMutation,
                Types.DisqualifyDealMutationVariables
            >(
                Operations.GqlOperationDisqualifyDealDocument,
                variables,
                options,
            ) as Promise<Types.DisqualifyDealMutation>;
        },
        RestoreDeal(
            variables: Types.RestoreDealMutationVariables,
            options?: C,
        ): Promise<Types.RestoreDealMutation> {
            return requester<
                Types.RestoreDealMutation,
                Types.RestoreDealMutationVariables
            >(
                Operations.GqlOperationRestoreDealDocument,
                variables,
                options,
            ) as Promise<Types.RestoreDealMutation>;
        },
        PauseDeal(
            variables: Types.PauseDealMutationVariables,
            options?: C,
        ): Promise<Types.PauseDealMutation> {
            return requester<
                Types.PauseDealMutation,
                Types.PauseDealMutationVariables
            >(
                Operations.GqlOperationPauseDealDocument,
                variables,
                options,
            ) as Promise<Types.PauseDealMutation>;
        },
        UnPauseDeal(
            variables: Types.UnPauseDealMutationVariables,
            options?: C,
        ): Promise<Types.UnPauseDealMutation> {
            return requester<
                Types.UnPauseDealMutation,
                Types.UnPauseDealMutationVariables
            >(
                Operations.GqlOperationUnPauseDealDocument,
                variables,
                options,
            ) as Promise<Types.UnPauseDealMutation>;
        },
        MarkDealAsDone(
            variables: Types.MarkDealAsDoneMutationVariables,
            options?: C,
        ): Promise<Types.MarkDealAsDoneMutation> {
            return requester<
                Types.MarkDealAsDoneMutation,
                Types.MarkDealAsDoneMutationVariables
            >(
                Operations.GqlOperationMarkDealAsDoneDocument,
                variables,
                options,
            ) as Promise<Types.MarkDealAsDoneMutation>;
        },
        CreateNewDealMutation(
            variables: Types.CreateNewDealMutationMutationVariables,
            options?: C,
        ): Promise<Types.CreateNewDealMutationMutation> {
            return requester<
                Types.CreateNewDealMutationMutation,
                Types.CreateNewDealMutationMutationVariables
            >(
                Operations.GqlOperationCreateNewDealMutationDocument,
                variables,
                options,
            ) as Promise<Types.CreateNewDealMutationMutation>;
        },
        UpdateDealInformation(
            variables: Types.UpdateDealInformationMutationVariables,
            options?: C,
        ): Promise<Types.UpdateDealInformationMutation> {
            return requester<
                Types.UpdateDealInformationMutation,
                Types.UpdateDealInformationMutationVariables
            >(
                Operations.GqlOperationUpdateDealInformationDocument,
                variables,
                options,
            ) as Promise<Types.UpdateDealInformationMutation>;
        },
        UpdateObjectiveMutation(
            variables: Types.UpdateObjectiveMutationMutationVariables,
            options?: C,
        ): Promise<Types.UpdateObjectiveMutationMutation> {
            return requester<
                Types.UpdateObjectiveMutationMutation,
                Types.UpdateObjectiveMutationMutationVariables
            >(
                Operations.GqlOperationUpdateObjectiveMutationDocument,
                variables,
                options,
            ) as Promise<Types.UpdateObjectiveMutationMutation>;
        },
        GetAllDeals(
            variables: Types.GetAllDealsQueryVariables,
            options?: C,
        ): Promise<Types.GetAllDealsQuery> {
            return requester<
                Types.GetAllDealsQuery,
                Types.GetAllDealsQueryVariables
            >(
                Operations.GqlOperationGetAllDealsDocument,
                variables,
                options,
            ) as Promise<Types.GetAllDealsQuery>;
        },
        GetDeal(
            variables: Types.GetDealQueryVariables,
            options?: C,
        ): Promise<Types.GetDealQuery> {
            return requester<Types.GetDealQuery, Types.GetDealQueryVariables>(
                Operations.GqlOperationGetDealDocument,
                variables,
                options,
            ) as Promise<Types.GetDealQuery>;
        },
        ExportCSV(
            variables: Types.ExportCsvQueryVariables,
            options?: C,
        ): Promise<Types.ExportCsvQuery> {
            return requester<
                Types.ExportCsvQuery,
                Types.ExportCsvQueryVariables
            >(
                Operations.GqlOperationExportCsvDocument,
                variables,
                options,
            ) as Promise<Types.ExportCsvQuery>;
        },
        ExportBatchCSV(
            variables: Types.ExportBatchCsvQueryVariables,
            options?: C,
        ): Promise<Types.ExportBatchCsvQuery> {
            return requester<
                Types.ExportBatchCsvQuery,
                Types.ExportBatchCsvQueryVariables
            >(
                Operations.GqlOperationExportBatchCsvDocument,
                variables,
                options,
            ) as Promise<Types.ExportBatchCsvQuery>;
        },
        GetDealObjectives(
            variables: Types.GetDealObjectivesQueryVariables,
            options?: C,
        ): Promise<Types.GetDealObjectivesQuery> {
            return requester<
                Types.GetDealObjectivesQuery,
                Types.GetDealObjectivesQueryVariables
            >(
                Operations.GqlOperationGetDealObjectivesDocument,
                variables,
                options,
            ) as Promise<Types.GetDealObjectivesQuery>;
        },
        GetDealStageUpdateDate(
            variables: Types.GetDealStageUpdateDateQueryVariables,
            options?: C,
        ): Promise<Types.GetDealStageUpdateDateQuery> {
            return requester<
                Types.GetDealStageUpdateDateQuery,
                Types.GetDealStageUpdateDateQueryVariables
            >(
                Operations.GqlOperationGetDealStageUpdateDateDocument,
                variables,
                options,
            ) as Promise<Types.GetDealStageUpdateDateQuery>;
        },
        GetDealContacts(
            variables: Types.GetDealContactsQueryVariables,
            options?: C,
        ): Promise<Types.GetDealContactsQuery> {
            return requester<
                Types.GetDealContactsQuery,
                Types.GetDealContactsQueryVariables
            >(
                Operations.GqlOperationGetDealContactsDocument,
                variables,
                options,
            ) as Promise<Types.GetDealContactsQuery>;
        },
        CreateCompany(
            variables: Types.CreateCompanyMutationVariables,
            options?: C,
        ): Promise<Types.CreateCompanyMutation> {
            return requester<
                Types.CreateCompanyMutation,
                Types.CreateCompanyMutationVariables
            >(
                Operations.GqlOperationCreateCompanyDocument,
                variables,
                options,
            ) as Promise<Types.CreateCompanyMutation>;
        },
        UpdateCompany(
            variables: Types.UpdateCompanyMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCompanyMutation> {
            return requester<
                Types.UpdateCompanyMutation,
                Types.UpdateCompanyMutationVariables
            >(
                Operations.GqlOperationUpdateCompanyDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCompanyMutation>;
        },
        UpdateCompanyNote(
            variables: Types.UpdateCompanyNoteMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCompanyNoteMutation> {
            return requester<
                Types.UpdateCompanyNoteMutation,
                Types.UpdateCompanyNoteMutationVariables
            >(
                Operations.GqlOperationUpdateCompanyNoteDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCompanyNoteMutation>;
        },
        UpdateCompanyRole(
            variables: Types.UpdateCompanyRoleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCompanyRoleMutation> {
            return requester<
                Types.UpdateCompanyRoleMutation,
                Types.UpdateCompanyRoleMutationVariables
            >(
                Operations.GqlOperationUpdateCompanyRoleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCompanyRoleMutation>;
        },
        DeleteCompany(
            variables: Types.DeleteCompanyMutationVariables,
            options?: C,
        ): Promise<Types.DeleteCompanyMutation> {
            return requester<
                Types.DeleteCompanyMutation,
                Types.DeleteCompanyMutationVariables
            >(
                Operations.GqlOperationDeleteCompanyDocument,
                variables,
                options,
            ) as Promise<Types.DeleteCompanyMutation>;
        },
        exportEmail(
            variables: Types.ExportEmailMutationVariables,
            options?: C,
        ): Promise<Types.ExportEmailMutation> {
            return requester<
                Types.ExportEmailMutation,
                Types.ExportEmailMutationVariables
            >(
                Operations.GqlOperationexportEmailDocument,
                variables,
                options,
            ) as Promise<Types.ExportEmailMutation>;
        },
        CreateContact(
            variables: Types.CreateContactMutationVariables,
            options?: C,
        ): Promise<Types.CreateContactMutation> {
            return requester<
                Types.CreateContactMutation,
                Types.CreateContactMutationVariables
            >(
                Operations.GqlOperationCreateContactDocument,
                variables,
                options,
            ) as Promise<Types.CreateContactMutation>;
        },
        UpdateContact(
            variables: Types.UpdateContactMutationVariables,
            options?: C,
        ): Promise<Types.UpdateContactMutation> {
            return requester<
                Types.UpdateContactMutation,
                Types.UpdateContactMutationVariables
            >(
                Operations.GqlOperationUpdateContactDocument,
                variables,
                options,
            ) as Promise<Types.UpdateContactMutation>;
        },
        UpdateContactNote(
            variables: Types.UpdateContactNoteMutationVariables,
            options?: C,
        ): Promise<Types.UpdateContactNoteMutation> {
            return requester<
                Types.UpdateContactNoteMutation,
                Types.UpdateContactNoteMutationVariables
            >(
                Operations.GqlOperationUpdateContactNoteDocument,
                variables,
                options,
            ) as Promise<Types.UpdateContactNoteMutation>;
        },
        UpdateContactRole(
            variables: Types.UpdateContactRoleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateContactRoleMutation> {
            return requester<
                Types.UpdateContactRoleMutation,
                Types.UpdateContactRoleMutationVariables
            >(
                Operations.GqlOperationUpdateContactRoleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateContactRoleMutation>;
        },
        DeleteContact(
            variables: Types.DeleteContactMutationVariables,
            options?: C,
        ): Promise<Types.DeleteContactMutation> {
            return requester<
                Types.DeleteContactMutation,
                Types.DeleteContactMutationVariables
            >(
                Operations.GqlOperationDeleteContactDocument,
                variables,
                options,
            ) as Promise<Types.DeleteContactMutation>;
        },
        AllPipelinesQuery(
            variables?: Types.AllPipelinesQueryQueryVariables,
            options?: C,
        ): Promise<Types.AllPipelinesQueryQuery> {
            return requester<
                Types.AllPipelinesQueryQuery,
                Types.AllPipelinesQueryQueryVariables
            >(
                Operations.GqlOperationAllPipelinesQueryDocument,
                variables,
                options,
            ) as Promise<Types.AllPipelinesQueryQuery>;
        },
        BulkMove(
            variables: Types.BulkMoveMutationVariables,
            options?: C,
        ): Promise<Types.BulkMoveMutation> {
            return requester<
                Types.BulkMoveMutation,
                Types.BulkMoveMutationVariables
            >(
                Operations.GqlOperationBulkMoveDocument,
                variables,
                options,
            ) as Promise<Types.BulkMoveMutation>;
        },
        BulkUpdateDealCustomParameters(
            variables: Types.BulkUpdateDealCustomParametersMutationVariables,
            options?: C,
        ): Promise<Types.BulkUpdateDealCustomParametersMutation> {
            return requester<
                Types.BulkUpdateDealCustomParametersMutation,
                Types.BulkUpdateDealCustomParametersMutationVariables
            >(
                Operations.GqlOperationBulkUpdateDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<Types.BulkUpdateDealCustomParametersMutation>;
        },
        BulkUpdateDealAttributes(
            variables: Types.BulkUpdateDealAttributesMutationVariables,
            options?: C,
        ): Promise<Types.BulkUpdateDealAttributesMutation> {
            return requester<
                Types.BulkUpdateDealAttributesMutation,
                Types.BulkUpdateDealAttributesMutationVariables
            >(
                Operations.GqlOperationBulkUpdateDealAttributesDocument,
                variables,
                options,
            ) as Promise<Types.BulkUpdateDealAttributesMutation>;
        },
        GetDealsCount(
            variables?: Types.GetDealsCountQueryVariables,
            options?: C,
        ): Promise<Types.GetDealsCountQuery> {
            return requester<
                Types.GetDealsCountQuery,
                Types.GetDealsCountQueryVariables
            >(
                Operations.GqlOperationGetDealsCountDocument,
                variables,
                options,
            ) as Promise<Types.GetDealsCountQuery>;
        },
        GetAllDealsCollection(
            variables: Types.GetAllDealsCollectionQueryVariables,
            options?: C,
        ): Promise<Types.GetAllDealsCollectionQuery> {
            return requester<
                Types.GetAllDealsCollectionQuery,
                Types.GetAllDealsCollectionQueryVariables
            >(
                Operations.GqlOperationGetAllDealsCollectionDocument,
                variables,
                options,
            ) as Promise<Types.GetAllDealsCollectionQuery>;
        },
        GetDealCustomParameters(
            variables: Types.GetDealCustomParametersQueryVariables,
            options?: C,
        ): Promise<Types.GetDealCustomParametersQuery> {
            return requester<
                Types.GetDealCustomParametersQuery,
                Types.GetDealCustomParametersQueryVariables
            >(
                Operations.GqlOperationGetDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<Types.GetDealCustomParametersQuery>;
        },
        UpdateDealCustomParameters(
            variables: Types.UpdateDealCustomParametersMutationVariables,
            options?: C,
        ): Promise<Types.UpdateDealCustomParametersMutation> {
            return requester<
                Types.UpdateDealCustomParametersMutation,
                Types.UpdateDealCustomParametersMutationVariables
            >(
                Operations.GqlOperationUpdateDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<Types.UpdateDealCustomParametersMutation>;
        },
        GetAllIntegrations(
            variables?: Types.GetAllIntegrationsQueryVariables,
            options?: C,
        ): Promise<Types.GetAllIntegrationsQuery> {
            return requester<
                Types.GetAllIntegrationsQuery,
                Types.GetAllIntegrationsQueryVariables
            >(
                Operations.GqlOperationGetAllIntegrationsDocument,
                variables,
                options,
            ) as Promise<Types.GetAllIntegrationsQuery>;
        },
        GetDealComments(
            variables: Types.GetDealCommentsQueryVariables,
            options?: C,
        ): Promise<Types.GetDealCommentsQuery> {
            return requester<
                Types.GetDealCommentsQuery,
                Types.GetDealCommentsQueryVariables
            >(
                Operations.GqlOperationGetDealCommentsDocument,
                variables,
                options,
            ) as Promise<Types.GetDealCommentsQuery>;
        },
        AddDealComment(
            variables: Types.AddDealCommentMutationVariables,
            options?: C,
        ): Promise<Types.AddDealCommentMutation> {
            return requester<
                Types.AddDealCommentMutation,
                Types.AddDealCommentMutationVariables
            >(
                Operations.GqlOperationAddDealCommentDocument,
                variables,
                options,
            ) as Promise<Types.AddDealCommentMutation>;
        },
        UpdateComment(
            variables: Types.UpdateCommentMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCommentMutation> {
            return requester<
                Types.UpdateCommentMutation,
                Types.UpdateCommentMutationVariables
            >(
                Operations.GqlOperationUpdateCommentDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCommentMutation>;
        },
        DeleteComment(
            variables: Types.DeleteCommentMutationVariables,
            options?: C,
        ): Promise<Types.DeleteCommentMutation> {
            return requester<
                Types.DeleteCommentMutation,
                Types.DeleteCommentMutationVariables
            >(
                Operations.GqlOperationDeleteCommentDocument,
                variables,
                options,
            ) as Promise<Types.DeleteCommentMutation>;
        },
        ExportToCrm(
            variables: Types.ExportToCrmMutationVariables,
            options?: C,
        ): Promise<Types.ExportToCrmMutation> {
            return requester<
                Types.ExportToCrmMutation,
                Types.ExportToCrmMutationVariables
            >(
                Operations.GqlOperationExportToCrmDocument,
                variables,
                options,
            ) as Promise<Types.ExportToCrmMutation>;
        },
        GetCrmState(
            variables: Types.GetCrmStateQueryVariables,
            options?: C,
        ): Promise<Types.GetCrmStateQuery> {
            return requester<
                Types.GetCrmStateQuery,
                Types.GetCrmStateQueryVariables
            >(
                Operations.GqlOperationGetCrmStateDocument,
                variables,
                options,
            ) as Promise<Types.GetCrmStateQuery>;
        },
        GetMyHubSpotToken(
            variables?: Types.GetMyHubSpotTokenQueryVariables,
            options?: C,
        ): Promise<Types.GetMyHubSpotTokenQuery> {
            return requester<
                Types.GetMyHubSpotTokenQuery,
                Types.GetMyHubSpotTokenQueryVariables
            >(
                Operations.GqlOperationGetMyHubSpotTokenDocument,
                variables,
                options,
            ) as Promise<Types.GetMyHubSpotTokenQuery>;
        },
        UpdatePerson(
            variables: Types.UpdatePersonMutationVariables,
            options?: C,
        ): Promise<Types.UpdatePersonMutation> {
            return requester<
                Types.UpdatePersonMutation,
                Types.UpdatePersonMutationVariables
            >(
                Operations.GqlOperationUpdatePersonDocument,
                variables,
                options,
            ) as Promise<Types.UpdatePersonMutation>;
        },
        UpdateRelevantCompany(
            variables: Types.UpdateRelevantCompanyMutationVariables,
            options?: C,
        ): Promise<Types.UpdateRelevantCompanyMutation> {
            return requester<
                Types.UpdateRelevantCompanyMutation,
                Types.UpdateRelevantCompanyMutationVariables
            >(
                Operations.GqlOperationUpdateRelevantCompanyDocument,
                variables,
                options,
            ) as Promise<Types.UpdateRelevantCompanyMutation>;
        },
        GetAccountConfiguration(
            variables?: Types.GetAccountConfigurationQueryVariables,
            options?: C,
        ): Promise<Types.GetAccountConfigurationQuery> {
            return requester<
                Types.GetAccountConfigurationQuery,
                Types.GetAccountConfigurationQueryVariables
            >(
                Operations.GqlOperationGetAccountConfigurationDocument,
                variables,
                options,
            ) as Promise<Types.GetAccountConfigurationQuery>;
        },
        GetDealLocationData(
            variables: Types.GetDealLocationDataQueryVariables,
            options?: C,
        ): Promise<Types.GetDealLocationDataQuery> {
            return requester<
                Types.GetDealLocationDataQuery,
                Types.GetDealLocationDataQueryVariables
            >(
                Operations.GqlOperationGetDealLocationDataDocument,
                variables,
                options,
            ) as Promise<Types.GetDealLocationDataQuery>;
        },
        GetGoogleEnrichmentData(
            variables: Types.GetGoogleEnrichmentDataQueryVariables,
            options?: C,
        ): Promise<Types.GetGoogleEnrichmentDataQuery> {
            return requester<
                Types.GetGoogleEnrichmentDataQuery,
                Types.GetGoogleEnrichmentDataQueryVariables
            >(
                Operations.GqlOperationGetGoogleEnrichmentDataDocument,
                variables,
                options,
            ) as Promise<Types.GetGoogleEnrichmentDataQuery>;
        },
        CreateContactsFromExtractedContacts(
            variables: Types.CreateContactsFromExtractedContactsMutationVariables,
            options?: C,
        ): Promise<Types.CreateContactsFromExtractedContactsMutation> {
            return requester<
                Types.CreateContactsFromExtractedContactsMutation,
                Types.CreateContactsFromExtractedContactsMutationVariables
            >(
                Operations.GqlOperationCreateContactsFromExtractedContactsDocument,
                variables,
                options,
            ) as Promise<Types.CreateContactsFromExtractedContactsMutation>;
        },
        CreateReminder(
            variables: Types.CreateReminderMutationVariables,
            options?: C,
        ): Promise<Types.CreateReminderMutation> {
            return requester<
                Types.CreateReminderMutation,
                Types.CreateReminderMutationVariables
            >(
                Operations.GqlOperationCreateReminderDocument,
                variables,
                options,
            ) as Promise<Types.CreateReminderMutation>;
        },
        UpdateReminder(
            variables: Types.UpdateReminderMutationVariables,
            options?: C,
        ): Promise<Types.UpdateReminderMutation> {
            return requester<
                Types.UpdateReminderMutation,
                Types.UpdateReminderMutationVariables
            >(
                Operations.GqlOperationUpdateReminderDocument,
                variables,
                options,
            ) as Promise<Types.UpdateReminderMutation>;
        },
        GetMyRemindersAndMentions(
            variables?: Types.GetMyRemindersAndMentionsQueryVariables,
            options?: C,
        ): Promise<Types.GetMyRemindersAndMentionsQuery> {
            return requester<
                Types.GetMyRemindersAndMentionsQuery,
                Types.GetMyRemindersAndMentionsQueryVariables
            >(
                Operations.GqlOperationGetMyRemindersAndMentionsDocument,
                variables,
                options,
            ) as Promise<Types.GetMyRemindersAndMentionsQuery>;
        },
        DismissReminder(
            variables: Types.DismissReminderMutationVariables,
            options?: C,
        ): Promise<Types.DismissReminderMutation> {
            return requester<
                Types.DismissReminderMutation,
                Types.DismissReminderMutationVariables
            >(
                Operations.GqlOperationDismissReminderDocument,
                variables,
                options,
            ) as Promise<Types.DismissReminderMutation>;
        },
        MarkMentionAsRead(
            variables: Types.MarkMentionAsReadMutationVariables,
            options?: C,
        ): Promise<Types.MarkMentionAsReadMutation> {
            return requester<
                Types.MarkMentionAsReadMutation,
                Types.MarkMentionAsReadMutationVariables
            >(
                Operations.GqlOperationMarkMentionAsReadDocument,
                variables,
                options,
            ) as Promise<Types.MarkMentionAsReadMutation>;
        },
        DismissMention(
            variables: Types.DismissMentionMutationVariables,
            options?: C,
        ): Promise<Types.DismissMentionMutation> {
            return requester<
                Types.DismissMentionMutation,
                Types.DismissMentionMutationVariables
            >(
                Operations.GqlOperationDismissMentionDocument,
                variables,
                options,
            ) as Promise<Types.DismissMentionMutation>;
        },
        GetCompanyTypes(
            variables?: Types.GetCompanyTypesQueryVariables,
            options?: C,
        ): Promise<Types.GetCompanyTypesQuery> {
            return requester<
                Types.GetCompanyTypesQuery,
                Types.GetCompanyTypesQueryVariables
            >(
                Operations.GqlOperationGetCompanyTypesDocument,
                variables,
                options,
            ) as Promise<Types.GetCompanyTypesQuery>;
        },
        GetDealAlert(
            variables: Types.GetDealAlertQueryVariables,
            options?: C,
        ): Promise<Types.GetDealAlertQuery> {
            return requester<
                Types.GetDealAlertQuery,
                Types.GetDealAlertQueryVariables
            >(
                Operations.GqlOperationGetDealAlertDocument,
                variables,
                options,
            ) as Promise<Types.GetDealAlertQuery>;
        },
        GetDynamicFilterMetrics(
            variables: Types.GetDynamicFilterMetricsQueryVariables,
            options?: C,
        ): Promise<Types.GetDynamicFilterMetricsQuery> {
            return requester<
                Types.GetDynamicFilterMetricsQuery,
                Types.GetDynamicFilterMetricsQueryVariables
            >(
                Operations.GqlOperationGetDynamicFilterMetricsDocument,
                variables,
                options,
            ) as Promise<Types.GetDynamicFilterMetricsQuery>;
        },
        GetDealsCollectionByPipeline(
            variables: Types.GetDealsCollectionByPipelineQueryVariables,
            options?: C,
        ): Promise<Types.GetDealsCollectionByPipelineQuery> {
            return requester<
                Types.GetDealsCollectionByPipelineQuery,
                Types.GetDealsCollectionByPipelineQueryVariables
            >(
                Operations.GqlOperationGetDealsCollectionByPipelineDocument,
                variables,
                options,
            ) as Promise<Types.GetDealsCollectionByPipelineQuery>;
        },
        AskQuestion(
            variables: Types.AskQuestionQueryVariables,
            options?: C,
        ): Promise<Types.AskQuestionQuery> {
            return requester<
                Types.AskQuestionQuery,
                Types.AskQuestionQueryVariables
            >(
                Operations.GqlOperationAskQuestionDocument,
                variables,
                options,
            ) as Promise<Types.AskQuestionQuery>;
        },
        AskQuestionRealtime(
            variables: Types.AskQuestionRealtimeSubscriptionVariables,
            options?: C,
        ): AsyncIterable<Types.AskQuestionRealtimeSubscription> {
            return requester<
                Types.AskQuestionRealtimeSubscription,
                Types.AskQuestionRealtimeSubscriptionVariables
            >(
                Operations.GqlOperationAskQuestionRealtimeDocument,
                variables,
                options,
            ) as AsyncIterable<Types.AskQuestionRealtimeSubscription>;
        },
        LiveUserNotifications(
            variables?: Types.LiveUserNotificationsSubscriptionVariables,
            options?: C,
        ): AsyncIterable<Types.LiveUserNotificationsSubscription> {
            return requester<
                Types.LiveUserNotificationsSubscription,
                Types.LiveUserNotificationsSubscriptionVariables
            >(
                Operations.GqlOperationLiveUserNotificationsDocument,
                variables,
                options,
            ) as AsyncIterable<Types.LiveUserNotificationsSubscription>;
        },
        GenerateValuePropEmail(
            variables: Types.GenerateValuePropEmailQueryVariables,
            options?: C,
        ): Promise<Types.GenerateValuePropEmailQuery> {
            return requester<
                Types.GenerateValuePropEmailQuery,
                Types.GenerateValuePropEmailQueryVariables
            >(
                Operations.GqlOperationGenerateValuePropEmailDocument,
                variables,
                options,
            ) as Promise<Types.GenerateValuePropEmailQuery>;
        },
        GenerateValuePropEmailFollowUp(
            variables: Types.GenerateValuePropEmailFollowUpQueryVariables,
            options?: C,
        ): Promise<Types.GenerateValuePropEmailFollowUpQuery> {
            return requester<
                Types.GenerateValuePropEmailFollowUpQuery,
                Types.GenerateValuePropEmailFollowUpQueryVariables
            >(
                Operations.GqlOperationGenerateValuePropEmailFollowUpDocument,
                variables,
                options,
            ) as Promise<Types.GenerateValuePropEmailFollowUpQuery>;
        },
        GenerateValuePropCallScript(
            variables: Types.GenerateValuePropCallScriptQueryVariables,
            options?: C,
        ): Promise<Types.GenerateValuePropCallScriptQuery> {
            return requester<
                Types.GenerateValuePropCallScriptQuery,
                Types.GenerateValuePropCallScriptQueryVariables
            >(
                Operations.GqlOperationGenerateValuePropCallScriptDocument,
                variables,
                options,
            ) as Promise<Types.GenerateValuePropCallScriptQuery>;
        },
        GenerateValuePropEmailStream(
            variables: Types.GenerateValuePropEmailStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<Types.GenerateValuePropEmailStreamSubscription> {
            return requester<
                Types.GenerateValuePropEmailStreamSubscription,
                Types.GenerateValuePropEmailStreamSubscriptionVariables
            >(
                Operations.GqlOperationGenerateValuePropEmailStreamDocument,
                variables,
                options,
            ) as AsyncIterable<Types.GenerateValuePropEmailStreamSubscription>;
        },
        GenerateValuePropEmailFollowUpStream(
            variables: Types.GenerateValuePropEmailFollowUpStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<Types.GenerateValuePropEmailFollowUpStreamSubscription> {
            return requester<
                Types.GenerateValuePropEmailFollowUpStreamSubscription,
                Types.GenerateValuePropEmailFollowUpStreamSubscriptionVariables
            >(
                Operations.GqlOperationGenerateValuePropEmailFollowUpStreamDocument,
                variables,
                options,
            ) as AsyncIterable<Types.GenerateValuePropEmailFollowUpStreamSubscription>;
        },
        GenerateValuePropCallScriptStream(
            variables: Types.GenerateValuePropCallScriptStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<Types.GenerateValuePropCallScriptStreamSubscription> {
            return requester<
                Types.GenerateValuePropCallScriptStreamSubscription,
                Types.GenerateValuePropCallScriptStreamSubscriptionVariables
            >(
                Operations.GqlOperationGenerateValuePropCallScriptStreamDocument,
                variables,
                options,
            ) as AsyncIterable<Types.GenerateValuePropCallScriptStreamSubscription>;
        },
        GetDomainEnrichmentResult(
            variables: Types.GetDomainEnrichmentResultQueryVariables,
            options?: C,
        ): Promise<Types.GetDomainEnrichmentResultQuery> {
            return requester<
                Types.GetDomainEnrichmentResultQuery,
                Types.GetDomainEnrichmentResultQueryVariables
            >(
                Operations.GqlOperationGetDomainEnrichmentResultDocument,
                variables,
                options,
            ) as Promise<Types.GetDomainEnrichmentResultQuery>;
        },
        GetContactEnrichmentResult(
            variables: Types.GetContactEnrichmentResultQueryVariables,
            options?: C,
        ): Promise<Types.GetContactEnrichmentResultQuery> {
            return requester<
                Types.GetContactEnrichmentResultQuery,
                Types.GetContactEnrichmentResultQueryVariables
            >(
                Operations.GqlOperationGetContactEnrichmentResultDocument,
                variables,
                options,
            ) as Promise<Types.GetContactEnrichmentResultQuery>;
        },
        SetAchievementsOptIn(
            variables: Types.SetAchievementsOptInMutationVariables,
            options?: C,
        ): Promise<Types.SetAchievementsOptInMutation> {
            return requester<
                Types.SetAchievementsOptInMutation,
                Types.SetAchievementsOptInMutationVariables
            >(
                Operations.GqlOperationSetAchievementsOptInDocument,
                variables,
                options,
            ) as Promise<Types.SetAchievementsOptInMutation>;
        },
        GetUserAchievements(
            variables?: Types.GetUserAchievementsQueryVariables,
            options?: C,
        ): Promise<Types.GetUserAchievementsQuery> {
            return requester<
                Types.GetUserAchievementsQuery,
                Types.GetUserAchievementsQueryVariables
            >(
                Operations.GqlOperationGetUserAchievementsDocument,
                variables,
                options,
            ) as Promise<Types.GetUserAchievementsQuery>;
        },
        UpdateAchievementState(
            variables: Types.UpdateAchievementStateMutationVariables,
            options?: C,
        ): Promise<Types.UpdateAchievementStateMutation> {
            return requester<
                Types.UpdateAchievementStateMutation,
                Types.UpdateAchievementStateMutationVariables
            >(
                Operations.GqlOperationUpdateAchievementStateDocument,
                variables,
                options,
            ) as Promise<Types.UpdateAchievementStateMutation>;
        },
        GetValueMilestones(
            variables?: Types.GetValueMilestonesQueryVariables,
            options?: C,
        ): Promise<Types.GetValueMilestonesQuery> {
            return requester<
                Types.GetValueMilestonesQuery,
                Types.GetValueMilestonesQueryVariables
            >(
                Operations.GqlOperationGetValueMilestonesDocument,
                variables,
                options,
            ) as Promise<Types.GetValueMilestonesQuery>;
        },
        GetTeamsAchievements(
            variables?: Types.GetTeamsAchievementsQueryVariables,
            options?: C,
        ): Promise<Types.GetTeamsAchievementsQuery> {
            return requester<
                Types.GetTeamsAchievementsQuery,
                Types.GetTeamsAchievementsQueryVariables
            >(
                Operations.GqlOperationGetTeamsAchievementsDocument,
                variables,
                options,
            ) as Promise<Types.GetTeamsAchievementsQuery>;
        },
        GetPipelineStatistics(
            variables: Types.GetPipelineStatisticsQueryVariables,
            options?: C,
        ): Promise<Types.GetPipelineStatisticsQuery> {
            return requester<
                Types.GetPipelineStatisticsQuery,
                Types.GetPipelineStatisticsQueryVariables
            >(
                Operations.GqlOperationGetPipelineStatisticsDocument,
                variables,
                options,
            ) as Promise<Types.GetPipelineStatisticsQuery>;
        },
        GetCustomFieldsForDeals(
            variables: Types.GetCustomFieldsForDealsQueryVariables,
            options?: C,
        ): Promise<Types.GetCustomFieldsForDealsQuery> {
            return requester<
                Types.GetCustomFieldsForDealsQuery,
                Types.GetCustomFieldsForDealsQueryVariables
            >(
                Operations.GqlOperationGetCustomFieldsForDealsDocument,
                variables,
                options,
            ) as Promise<Types.GetCustomFieldsForDealsQuery>;
        },
        GetRemindersForDeals(
            variables: Types.GetRemindersForDealsQueryVariables,
            options?: C,
        ): Promise<Types.GetRemindersForDealsQuery> {
            return requester<
                Types.GetRemindersForDealsQuery,
                Types.GetRemindersForDealsQueryVariables
            >(
                Operations.GqlOperationGetRemindersForDealsDocument,
                variables,
                options,
            ) as Promise<Types.GetRemindersForDealsQuery>;
        },
        GetCommentsForDeals(
            variables: Types.GetCommentsForDealsQueryVariables,
            options?: C,
        ): Promise<Types.GetCommentsForDealsQuery> {
            return requester<
                Types.GetCommentsForDealsQuery,
                Types.GetCommentsForDealsQueryVariables
            >(
                Operations.GqlOperationGetCommentsForDealsDocument,
                variables,
                options,
            ) as Promise<Types.GetCommentsForDealsQuery>;
        },
        DeleteOutreachBlock(
            variables: Types.DeleteOutreachBlockMutationVariables,
            options?: C,
        ): Promise<Types.DeleteOutreachBlockMutation> {
            return requester<
                Types.DeleteOutreachBlockMutation,
                Types.DeleteOutreachBlockMutationVariables
            >(
                Operations.GqlOperationDeleteOutreachBlockDocument,
                variables,
                options,
            ) as Promise<Types.DeleteOutreachBlockMutation>;
        },
        CreateOutreachBlock(
            variables: Types.CreateOutreachBlockMutationVariables,
            options?: C,
        ): Promise<Types.CreateOutreachBlockMutation> {
            return requester<
                Types.CreateOutreachBlockMutation,
                Types.CreateOutreachBlockMutationVariables
            >(
                Operations.GqlOperationCreateOutreachBlockDocument,
                variables,
                options,
            ) as Promise<Types.CreateOutreachBlockMutation>;
        },
        UpdateOutreachBlock(
            variables: Types.UpdateOutreachBlockMutationVariables,
            options?: C,
        ): Promise<Types.UpdateOutreachBlockMutation> {
            return requester<
                Types.UpdateOutreachBlockMutation,
                Types.UpdateOutreachBlockMutationVariables
            >(
                Operations.GqlOperationUpdateOutreachBlockDocument,
                variables,
                options,
            ) as Promise<Types.UpdateOutreachBlockMutation>;
        },
        Translate(
            variables: Types.TranslateQueryVariables,
            options?: C,
        ): Promise<Types.TranslateQuery> {
            return requester<
                Types.TranslateQuery,
                Types.TranslateQueryVariables
            >(
                Operations.GqlOperationTranslateDocument,
                variables,
                options,
            ) as Promise<Types.TranslateQuery>;
        },
        CreateDealActivity(
            variables: Types.CreateDealActivityMutationVariables,
            options?: C,
        ): Promise<Types.CreateDealActivityMutation> {
            return requester<
                Types.CreateDealActivityMutation,
                Types.CreateDealActivityMutationVariables
            >(
                Operations.GqlOperationCreateDealActivityDocument,
                variables,
                options,
            ) as Promise<Types.CreateDealActivityMutation>;
        },
        UpdateDealActivity(
            variables: Types.UpdateDealActivityMutationVariables,
            options?: C,
        ): Promise<Types.UpdateDealActivityMutation> {
            return requester<
                Types.UpdateDealActivityMutation,
                Types.UpdateDealActivityMutationVariables
            >(
                Operations.GqlOperationUpdateDealActivityDocument,
                variables,
                options,
            ) as Promise<Types.UpdateDealActivityMutation>;
        },
        DeleteDealActivity(
            variables: Types.DeleteDealActivityMutationVariables,
            options?: C,
        ): Promise<Types.DeleteDealActivityMutation> {
            return requester<
                Types.DeleteDealActivityMutation,
                Types.DeleteDealActivityMutationVariables
            >(
                Operations.GqlOperationDeleteDealActivityDocument,
                variables,
                options,
            ) as Promise<Types.DeleteDealActivityMutation>;
        },
        OmniSearch(
            variables: Types.OmniSearchQueryVariables,
            options?: C,
        ): Promise<Types.OmniSearchQuery> {
            return requester<
                Types.OmniSearchQuery,
                Types.OmniSearchQueryVariables
            >(
                Operations.GqlOperationOmniSearchDocument,
                variables,
                options,
            ) as Promise<Types.OmniSearchQuery>;
        },
        GetContactExtractionResult(
            variables: Types.GetContactExtractionResultQueryVariables,
            options?: C,
        ): Promise<Types.GetContactExtractionResultQuery> {
            return requester<
                Types.GetContactExtractionResultQuery,
                Types.GetContactExtractionResultQueryVariables
            >(
                Operations.GqlOperationGetContactExtractionResultDocument,
                variables,
                options,
            ) as Promise<Types.GetContactExtractionResultQuery>;
        },
        RunContactExtractionForDeal(
            variables: Types.RunContactExtractionForDealMutationVariables,
            options?: C,
        ): Promise<Types.RunContactExtractionForDealMutation> {
            return requester<
                Types.RunContactExtractionForDealMutation,
                Types.RunContactExtractionForDealMutationVariables
            >(
                Operations.GqlOperationRunContactExtractionForDealDocument,
                variables,
                options,
            ) as Promise<Types.RunContactExtractionForDealMutation>;
        },
        GetActivityLogByDeal(
            variables: Types.GetActivityLogByDealQueryVariables,
            options?: C,
        ): Promise<Types.GetActivityLogByDealQuery> {
            return requester<
                Types.GetActivityLogByDealQuery,
                Types.GetActivityLogByDealQueryVariables
            >(
                Operations.GqlOperationGetActivityLogByDealDocument,
                variables,
                options,
            ) as Promise<Types.GetActivityLogByDealQuery>;
        },
        RunAutoEnrichmentForCompany(
            variables: Types.RunAutoEnrichmentForCompanyMutationVariables,
            options?: C,
        ): Promise<Types.RunAutoEnrichmentForCompanyMutation> {
            return requester<
                Types.RunAutoEnrichmentForCompanyMutation,
                Types.RunAutoEnrichmentForCompanyMutationVariables
            >(
                Operations.GqlOperationRunAutoEnrichmentForCompanyDocument,
                variables,
                options,
            ) as Promise<Types.RunAutoEnrichmentForCompanyMutation>;
        },
        RemoveEnrichmentItemFromCompany(
            variables: Types.RemoveEnrichmentItemFromCompanyMutationVariables,
            options?: C,
        ): Promise<Types.RemoveEnrichmentItemFromCompanyMutation> {
            return requester<
                Types.RemoveEnrichmentItemFromCompanyMutation,
                Types.RemoveEnrichmentItemFromCompanyMutationVariables
            >(
                Operations.GqlOperationRemoveEnrichmentItemFromCompanyDocument,
                variables,
                options,
            ) as Promise<Types.RemoveEnrichmentItemFromCompanyMutation>;
        },
        UpdateCompanyWithEnrichment(
            variables: Types.UpdateCompanyWithEnrichmentMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCompanyWithEnrichmentMutation> {
            return requester<
                Types.UpdateCompanyWithEnrichmentMutation,
                Types.UpdateCompanyWithEnrichmentMutationVariables
            >(
                Operations.GqlOperationUpdateCompanyWithEnrichmentDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCompanyWithEnrichmentMutation>;
        },
        FindContactsForCompany(
            variables: Types.FindContactsForCompanyQueryVariables,
            options?: C,
        ): Promise<Types.FindContactsForCompanyQuery> {
            return requester<
                Types.FindContactsForCompanyQuery,
                Types.FindContactsForCompanyQueryVariables
            >(
                Operations.GqlOperationFindContactsForCompanyDocument,
                variables,
                options,
            ) as Promise<Types.FindContactsForCompanyQuery>;
        },
        GetLinkedinProfileContactInfo(
            variables: Types.GetLinkedinProfileContactInfoQueryVariables,
            options?: C,
        ): Promise<Types.GetLinkedinProfileContactInfoQuery> {
            return requester<
                Types.GetLinkedinProfileContactInfoQuery,
                Types.GetLinkedinProfileContactInfoQueryVariables
            >(
                Operations.GqlOperationGetLinkedinProfileContactInfoDocument,
                variables,
                options,
            ) as Promise<Types.GetLinkedinProfileContactInfoQuery>;
        },
        GetDealContentMetadata(
            variables: Types.GetDealContentMetadataQueryVariables,
            options?: C,
        ): Promise<Types.GetDealContentMetadataQuery> {
            return requester<
                Types.GetDealContentMetadataQuery,
                Types.GetDealContentMetadataQueryVariables
            >(
                Operations.GqlOperationGetDealContentMetadataDocument,
                variables,
                options,
            ) as Promise<Types.GetDealContentMetadataQuery>;
        },
        GetDealContentAttachmentDownloadUrl(
            variables: Types.GetDealContentAttachmentDownloadUrlQueryVariables,
            options?: C,
        ): Promise<Types.GetDealContentAttachmentDownloadUrlQuery> {
            return requester<
                Types.GetDealContentAttachmentDownloadUrlQuery,
                Types.GetDealContentAttachmentDownloadUrlQueryVariables
            >(
                Operations.GqlOperationGetDealContentAttachmentDownloadUrlDocument,
                variables,
                options,
            ) as Promise<Types.GetDealContentAttachmentDownloadUrlQuery>;
        },
        DeleteDealContent(
            variables: Types.DeleteDealContentMutationVariables,
            options?: C,
        ): Promise<Types.DeleteDealContentMutation> {
            return requester<
                Types.DeleteDealContentMutation,
                Types.DeleteDealContentMutationVariables
            >(
                Operations.GqlOperationDeleteDealContentDocument,
                variables,
                options,
            ) as Promise<Types.DeleteDealContentMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
