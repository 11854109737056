import { AbortParams } from 'src/data/api/api-client';
import {
    AskQuestionQuery,
    GqlOperationAskQuestionRealtimeDocument,
    AskQuestionRealtimeSubscriptionVariables,
} from 'src/data/api/graphql/br_process/generated';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { doNothing } from 'src/utils/function.utils';

interface GetQuestionParams extends AbortParams {
    /**
     * The question to ask
     */
    question: string;
    /**
     * The project id -- optional. Is used when asking questions using the old document structure
     */
    projectId?: string;
    /**
     * The document id -- optional. Is used when asking questions using the new document structure.
     * When asking question for neural search based projects, we pass the article ID here.
     */
    documentId?: string;
    /**
     * The deal id -- optional. Is used when asking questions using the new neural search project structure.
     */
    dealId?: string;
    onNextValue?: (value: string) => void;
}

export interface XRayApi {
    getAnswerHybrid: (params: GetQuestionParams) => Promise<string>;
}

export const createXRayApi = (processGqlSdk: ProcessGqlSdk): XRayApi => {
    const getAnswerHybrid = async (params: GetQuestionParams) => {
        return getAnswerSubscription(params);
    };

    const getAnswerSubscription = async ({
        projectId,
        question,
        documentId,
        dealId,
        onNextValue = doNothing,
        signal,
    }: GetQuestionParams): Promise<string> => {
        let answer = '';
        const projectIdValue = projectId ? +projectId : null;

        return new Promise((resolve, reject) => {
            processGqlSdk.runSubscription<
                AskQuestionQuery,
                AskQuestionRealtimeSubscriptionVariables
            >(
                {
                    query: GqlOperationAskQuestionRealtimeDocument,
                    variables: {
                        question,
                        projectId: projectIdValue,
                        documentId,
                        dealId,
                    },
                },
                (value) => {
                    answer += value.actionFeatureXray.answer;
                    onNextValue(answer);
                },
                () => resolve(answer),
                (error) => reject(error),
                signal,
            );
        });
    };

    return {
        getAnswerHybrid,
    };
};
