/* eslint-disable */
import * as Types from './graphql-sdk';

import { DocumentNode } from 'graphql';
import * as Operations from './hooks';

export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        GetCurrentUser(
            variables?: Types.GetCurrentUserQueryVariables,
            options?: C,
        ): Promise<Types.GetCurrentUserQuery> {
            return requester<
                Types.GetCurrentUserQuery,
                Types.GetCurrentUserQueryVariables
            >(
                Operations.GqlOperationGetCurrentUserDocument,
                variables,
                options,
            ) as Promise<Types.GetCurrentUserQuery>;
        },
        GetUserApiTokens(
            variables?: Types.GetUserApiTokensQueryVariables,
            options?: C,
        ): Promise<Types.GetUserApiTokensQuery> {
            return requester<
                Types.GetUserApiTokensQuery,
                Types.GetUserApiTokensQueryVariables
            >(
                Operations.GqlOperationGetUserApiTokensDocument,
                variables,
                options,
            ) as Promise<Types.GetUserApiTokensQuery>;
        },
        Login(
            variables: Types.LoginMutationVariables,
            options?: C,
        ): Promise<Types.LoginMutation> {
            return requester<Types.LoginMutation, Types.LoginMutationVariables>(
                Operations.GqlOperationLoginDocument,
                variables,
                options,
            ) as Promise<Types.LoginMutation>;
        },
        ImpersonateUser(
            variables: Types.ImpersonateUserMutationVariables,
            options?: C,
        ): Promise<Types.ImpersonateUserMutation> {
            return requester<
                Types.ImpersonateUserMutation,
                Types.ImpersonateUserMutationVariables
            >(
                Operations.GqlOperationImpersonateUserDocument,
                variables,
                options,
            ) as Promise<Types.ImpersonateUserMutation>;
        },
        CreateUserApiToken(
            variables: Types.CreateUserApiTokenMutationVariables,
            options?: C,
        ): Promise<Types.CreateUserApiTokenMutation> {
            return requester<
                Types.CreateUserApiTokenMutation,
                Types.CreateUserApiTokenMutationVariables
            >(
                Operations.GqlOperationCreateUserApiTokenDocument,
                variables,
                options,
            ) as Promise<Types.CreateUserApiTokenMutation>;
        },
        DeleteUserApiToken(
            variables: Types.DeleteUserApiTokenMutationVariables,
            options?: C,
        ): Promise<Types.DeleteUserApiTokenMutation> {
            return requester<
                Types.DeleteUserApiTokenMutation,
                Types.DeleteUserApiTokenMutationVariables
            >(
                Operations.GqlOperationDeleteUserApiTokenDocument,
                variables,
                options,
            ) as Promise<Types.DeleteUserApiTokenMutation>;
        },
        updateUserSetting(
            variables: Types.UpdateUserSettingMutationVariables,
            options?: C,
        ): Promise<Types.UpdateUserSettingMutation> {
            return requester<
                Types.UpdateUserSettingMutation,
                Types.UpdateUserSettingMutationVariables
            >(
                Operations.GqlOperationupdateUserSettingDocument,
                variables,
                options,
            ) as Promise<Types.UpdateUserSettingMutation>;
        },
        UpdateUserLanguage(
            variables: Types.UpdateUserLanguageMutationVariables,
            options?: C,
        ): Promise<Types.UpdateUserLanguageMutation> {
            return requester<
                Types.UpdateUserLanguageMutation,
                Types.UpdateUserLanguageMutationVariables
            >(
                Operations.GqlOperationUpdateUserLanguageDocument,
                variables,
                options,
            ) as Promise<Types.UpdateUserLanguageMutation>;
        },
        Logout(
            variables?: Types.LogoutMutationVariables,
            options?: C,
        ): Promise<Types.LogoutMutation> {
            return requester<
                Types.LogoutMutation,
                Types.LogoutMutationVariables
            >(
                Operations.GqlOperationLogoutDocument,
                variables,
                options,
            ) as Promise<Types.LogoutMutation>;
        },
        SendPasswordReset(
            variables: Types.SendPasswordResetMutationVariables,
            options?: C,
        ): Promise<Types.SendPasswordResetMutation> {
            return requester<
                Types.SendPasswordResetMutation,
                Types.SendPasswordResetMutationVariables
            >(
                Operations.GqlOperationSendPasswordResetDocument,
                variables,
                options,
            ) as Promise<Types.SendPasswordResetMutation>;
        },
        SetXSRFToken(
            variables?: Types.SetXsrfTokenMutationVariables,
            options?: C,
        ): Promise<Types.SetXsrfTokenMutation> {
            return requester<
                Types.SetXsrfTokenMutation,
                Types.SetXsrfTokenMutationVariables
            >(
                Operations.GqlOperationSetXsrfTokenDocument,
                variables,
                options,
            ) as Promise<Types.SetXsrfTokenMutation>;
        },
        UpdatePassword(
            variables: Types.UpdatePasswordMutationVariables,
            options?: C,
        ): Promise<Types.UpdatePasswordMutation> {
            return requester<
                Types.UpdatePasswordMutation,
                Types.UpdatePasswordMutationVariables
            >(
                Operations.GqlOperationUpdatePasswordDocument,
                variables,
                options,
            ) as Promise<Types.UpdatePasswordMutation>;
        },
        UpdatePasswordFromResetToken(
            variables: Types.UpdatePasswordFromResetTokenMutationVariables,
            options?: C,
        ): Promise<Types.UpdatePasswordFromResetTokenMutation> {
            return requester<
                Types.UpdatePasswordFromResetTokenMutation,
                Types.UpdatePasswordFromResetTokenMutationVariables
            >(
                Operations.GqlOperationUpdatePasswordFromResetTokenDocument,
                variables,
                options,
            ) as Promise<Types.UpdatePasswordFromResetTokenMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
