import * as Types from './graphql-sdk';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {
    context: { endpoint: '/apps/search/graphql' },
} as const;
export const TenderChildCodePartFragmentDoc = gql`
    fragment TenderChildCodePart on TenderCodeNodeQL {
        id
        name
        hasChildren
        nChildren
        leaf
        root
        procurementSystem
        parent
        children {
            ... on TenderCodeNodeQL {
                id
                name
                hasChildren
                nChildren
                leaf
                root
                procurementSystem
                parent
            }
        }
    }
`;
export const TenderRootCodePartFragmentDoc = gql`
    fragment TenderRootCodePart on TenderCodeRootNodeResultQL {
        id
        name
        hasChildren
        root
        children {
            ...TenderChildCodePart
        }
    }
    ${TenderChildCodePartFragmentDoc}
`;
export const ChildPartFragmentDoc = gql`
    fragment ChildPart on TenderCodeNodeQL {
        id
        name
        hasChildren
        nChildren
        leaf
        root
        procurementSystem
        parent
    }
`;
export const NestedTenderCodesFilteredPartFragmentDoc = gql`
    fragment NestedTenderCodesFilteredPart on TenderCodeRootNodeResultQL {
        id
        name
        hasChildren
        root
        children {
            ... on TenderCodeNodeQL {
                ...ChildPart
                children {
                    ... on TenderCodeNodeQL {
                        ...ChildPart
                        children {
                            ... on TenderCodeNodeQL {
                                ...ChildPart
                                children {
                                    ... on TenderCodeNodeQL {
                                        ...ChildPart
                                        children {
                                            ... on TenderCodeNodeQL {
                                                ...ChildPart
                                                children {
                                                    ... on TenderCodeNodeQL {
                                                        ...ChildPart
                                                        children {
                                                            ... on TenderCodeNodeQL {
                                                                ...ChildPart
                                                                children {
                                                                    ... on TenderCodeNodeQL {
                                                                        ...ChildPart
                                                                        children {
                                                                            ... on TenderCodeNodeQL {
                                                                                ...ChildPart
                                                                                children {
                                                                                    ... on TenderCodeNodeQL {
                                                                                        ...ChildPart
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ChildPartFragmentDoc}
`;
export const BrSearchBrDateTimePartFragmentDoc = gql`
    fragment BrSearchBrDateTimePart on BRTimeQL {
        day
        hour
        microsecond
        minute
        month
        precision
        second
        year
    }
`;
export const BrSearchAddressPartFragmentDoc = gql`
    fragment BrSearchAddressPart on AddressQL {
        city
        continent
        country
        countryCode
        houseNumber
        lat
        lng
        postCode
        postCodeEnd
        postCodeIsExact
        postCodeStart
        precisionAccuracy
        precisionLevel
        precisionType
        rank
        road
        state
    }
`;
export const BrSearchContactPartFragmentDoc = gql`
    fragment BrSearchContactPart on ContactQL {
        associatedItemId
        companyName
        companyRoles
        email
        fax
        homepage
        name
        personRoles
        phoneLandline
        phoneMobile
        title
    }
`;
export const BrSearchTenderDocumentPartFragmentDoc = gql`
    fragment BrSearchTenderDocumentPart on TenderDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        allLotsAwarded
        announcedDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        awardCriteria
        contractEndDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        contractStartDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        competitionDeadlineDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        competitionDeadlineDateEst
        frameworkAgreement
        openingDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        competitionPhase
        documentFamilyId
        documentFamilyRefs
        contractType
        directive
        documentType
        applicationUrl
        contractAddress {
            ... on AddressQL {
                ...BrSearchAddressPart
            }
        }
        contacts {
            ... on ContactQL {
                ...BrSearchContactPart
            }
        }
        procedureType
        quantityScope
        referenceNumber
        tenderType
        awardedDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        cpvCodes
        naicsCodes
        unspscCodes
        rawDataString
    }
    ${BrSearchBrDateTimePartFragmentDoc}
    ${BrSearchAddressPartFragmentDoc}
    ${BrSearchContactPartFragmentDoc}
`;
export const BrSearchArticleDocumentPartFragmentDoc = gql`
    fragment BrSearchArticleDocumentPart on ArticleDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        address {
            ... on AddressQL {
                ...BrSearchAddressPart
            }
        }
        abstract
        paragraphs
        constructionStartDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        constructionEndDate {
            ... on BRTimeQL {
                ...BrSearchBrDateTimePart
            }
        }
        gfa
        nResidentialUnits
        contacts {
            ... on ContactQL {
                ...BrSearchContactPart
            }
        }
    }
    ${BrSearchBrDateTimePartFragmentDoc}
    ${BrSearchAddressPartFragmentDoc}
    ${BrSearchContactPartFragmentDoc}
`;
export const DocumentSearchResultPartFragmentDoc = gql`
    fragment DocumentSearchResultPart on PrescreeningDocGroupWithFolderTagQL {
        created
        documentIds
        folder
        id
        mergedDocument {
            ... on TenderDocumentQL {
                ...BrSearchTenderDocumentPart
            }
            ... on ArticleDocumentQL {
                ...BrSearchArticleDocumentPart
            }
        }
        documents {
            ... on TenderDocumentQL {
                title
            }
            ... on ArticleDocumentQL {
                title
            }
        }
        pipeDbCollection
        updated
        prescreenOutput {
            accept
            confidence
        }
    }
    ${BrSearchTenderDocumentPartFragmentDoc}
    ${BrSearchArticleDocumentPartFragmentDoc}
`;
export const SearchAndSubscriptionPartFragmentDoc = gql`
    fragment SearchAndSubscriptionPart on SearchAndSubscribersQL {
        userId
        itemId
        name
        description
        autoPrescreeningEnabled
        autoPrescreeningInboxLimit
        paused
        subscriberIds
        type
        searchData {
            filterModules {
                key
            }
        }
    }
`;
export const SearchSubscribedPartFragmentDoc = gql`
    fragment SearchSubscribedPart on SearchQL {
        userId
        itemId
        name
        paused
        type
        searchData {
            filterModules {
                key
                filter
                value
            }
        }
    }
`;
export const GqlOperationGetTenderRootCodesDocument = gql`
    query GetTenderRootCodes {
        tenderCodeTree {
            ...TenderRootCodePart
        }
    }
    ${TenderRootCodePartFragmentDoc}
`;

/**
 * __useGetTenderRootCodesQuery__
 *
 * To run a query within a React component, call `useGetTenderRootCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenderRootCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenderRootCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenderRootCodesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetTenderRootCodesQuery,
        Types.GetTenderRootCodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetTenderRootCodesQuery,
        Types.GetTenderRootCodesQueryVariables
    >(GqlOperationGetTenderRootCodesDocument, options);
}
export function useGetTenderRootCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetTenderRootCodesQuery,
        Types.GetTenderRootCodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetTenderRootCodesQuery,
        Types.GetTenderRootCodesQueryVariables
    >(GqlOperationGetTenderRootCodesDocument, options);
}
export function useGetTenderRootCodesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetTenderRootCodesQuery,
              Types.GetTenderRootCodesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetTenderRootCodesQuery,
        Types.GetTenderRootCodesQueryVariables
    >(GqlOperationGetTenderRootCodesDocument, options);
}
export type GetTenderRootCodesQueryHookResult = ReturnType<
    typeof useGetTenderRootCodesQuery
>;
export type GetTenderRootCodesLazyQueryHookResult = ReturnType<
    typeof useGetTenderRootCodesLazyQuery
>;
export type GetTenderRootCodesSuspenseQueryHookResult = ReturnType<
    typeof useGetTenderRootCodesSuspenseQuery
>;
export type GetTenderRootCodesQueryResult = Apollo.QueryResult<
    Types.GetTenderRootCodesQuery,
    Types.GetTenderRootCodesQueryVariables
>;
export const GqlOperationGetChildTenderCodesDocument = gql`
    query GetChildTenderCodes($tenderCodeId: String!) {
        tenderChildCodes(tenderCodeId: $tenderCodeId) {
            ...TenderChildCodePart
        }
    }
    ${TenderChildCodePartFragmentDoc}
`;

/**
 * __useGetChildTenderCodesQuery__
 *
 * To run a query within a React component, call `useGetChildTenderCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildTenderCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildTenderCodesQuery({
 *   variables: {
 *      tenderCodeId: // value for 'tenderCodeId'
 *   },
 * });
 */
export function useGetChildTenderCodesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetChildTenderCodesQuery,
        Types.GetChildTenderCodesQueryVariables
    > &
        (
            | {
                  variables: Types.GetChildTenderCodesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetChildTenderCodesQuery,
        Types.GetChildTenderCodesQueryVariables
    >(GqlOperationGetChildTenderCodesDocument, options);
}
export function useGetChildTenderCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetChildTenderCodesQuery,
        Types.GetChildTenderCodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetChildTenderCodesQuery,
        Types.GetChildTenderCodesQueryVariables
    >(GqlOperationGetChildTenderCodesDocument, options);
}
export function useGetChildTenderCodesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetChildTenderCodesQuery,
              Types.GetChildTenderCodesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetChildTenderCodesQuery,
        Types.GetChildTenderCodesQueryVariables
    >(GqlOperationGetChildTenderCodesDocument, options);
}
export type GetChildTenderCodesQueryHookResult = ReturnType<
    typeof useGetChildTenderCodesQuery
>;
export type GetChildTenderCodesLazyQueryHookResult = ReturnType<
    typeof useGetChildTenderCodesLazyQuery
>;
export type GetChildTenderCodesSuspenseQueryHookResult = ReturnType<
    typeof useGetChildTenderCodesSuspenseQuery
>;
export type GetChildTenderCodesQueryResult = Apollo.QueryResult<
    Types.GetChildTenderCodesQuery,
    Types.GetChildTenderCodesQueryVariables
>;
export const GqlOperationGetFilteredTenderCodesDocument = gql`
    query GetFilteredTenderCodes($textSearch: String!) {
        tenderCodesFiltered(textSearch: $textSearch) {
            ...NestedTenderCodesFilteredPart
        }
    }
    ${NestedTenderCodesFilteredPartFragmentDoc}
`;

/**
 * __useGetFilteredTenderCodesQuery__
 *
 * To run a query within a React component, call `useGetFilteredTenderCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredTenderCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredTenderCodesQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useGetFilteredTenderCodesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetFilteredTenderCodesQuery,
        Types.GetFilteredTenderCodesQueryVariables
    > &
        (
            | {
                  variables: Types.GetFilteredTenderCodesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetFilteredTenderCodesQuery,
        Types.GetFilteredTenderCodesQueryVariables
    >(GqlOperationGetFilteredTenderCodesDocument, options);
}
export function useGetFilteredTenderCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetFilteredTenderCodesQuery,
        Types.GetFilteredTenderCodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetFilteredTenderCodesQuery,
        Types.GetFilteredTenderCodesQueryVariables
    >(GqlOperationGetFilteredTenderCodesDocument, options);
}
export function useGetFilteredTenderCodesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetFilteredTenderCodesQuery,
              Types.GetFilteredTenderCodesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetFilteredTenderCodesQuery,
        Types.GetFilteredTenderCodesQueryVariables
    >(GqlOperationGetFilteredTenderCodesDocument, options);
}
export type GetFilteredTenderCodesQueryHookResult = ReturnType<
    typeof useGetFilteredTenderCodesQuery
>;
export type GetFilteredTenderCodesLazyQueryHookResult = ReturnType<
    typeof useGetFilteredTenderCodesLazyQuery
>;
export type GetFilteredTenderCodesSuspenseQueryHookResult = ReturnType<
    typeof useGetFilteredTenderCodesSuspenseQuery
>;
export type GetFilteredTenderCodesQueryResult = Apollo.QueryResult<
    Types.GetFilteredTenderCodesQuery,
    Types.GetFilteredTenderCodesQueryVariables
>;
export const GqlOperationGetFilterModulesDocument = gql`
    query GetFilterModules {
        filterModules {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;

/**
 * __useGetFilterModulesQuery__
 *
 * To run a query within a React component, call `useGetFilterModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterModulesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetFilterModulesQuery,
        Types.GetFilterModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetFilterModulesQuery,
        Types.GetFilterModulesQueryVariables
    >(GqlOperationGetFilterModulesDocument, options);
}
export function useGetFilterModulesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetFilterModulesQuery,
        Types.GetFilterModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetFilterModulesQuery,
        Types.GetFilterModulesQueryVariables
    >(GqlOperationGetFilterModulesDocument, options);
}
export function useGetFilterModulesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetFilterModulesQuery,
              Types.GetFilterModulesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetFilterModulesQuery,
        Types.GetFilterModulesQueryVariables
    >(GqlOperationGetFilterModulesDocument, options);
}
export type GetFilterModulesQueryHookResult = ReturnType<
    typeof useGetFilterModulesQuery
>;
export type GetFilterModulesLazyQueryHookResult = ReturnType<
    typeof useGetFilterModulesLazyQuery
>;
export type GetFilterModulesSuspenseQueryHookResult = ReturnType<
    typeof useGetFilterModulesSuspenseQuery
>;
export type GetFilterModulesQueryResult = Apollo.QueryResult<
    Types.GetFilterModulesQuery,
    Types.GetFilterModulesQueryVariables
>;
export const GqlOperationGetFilterModuleByIdDocument = gql`
    query GetFilterModuleById($id: Int!) {
        filterModules(filterModuleId: $id) {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;

/**
 * __useGetFilterModuleByIdQuery__
 *
 * To run a query within a React component, call `useGetFilterModuleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterModuleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterModuleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilterModuleByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetFilterModuleByIdQuery,
        Types.GetFilterModuleByIdQueryVariables
    > &
        (
            | {
                  variables: Types.GetFilterModuleByIdQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetFilterModuleByIdQuery,
        Types.GetFilterModuleByIdQueryVariables
    >(GqlOperationGetFilterModuleByIdDocument, options);
}
export function useGetFilterModuleByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetFilterModuleByIdQuery,
        Types.GetFilterModuleByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetFilterModuleByIdQuery,
        Types.GetFilterModuleByIdQueryVariables
    >(GqlOperationGetFilterModuleByIdDocument, options);
}
export function useGetFilterModuleByIdSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetFilterModuleByIdQuery,
              Types.GetFilterModuleByIdQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetFilterModuleByIdQuery,
        Types.GetFilterModuleByIdQueryVariables
    >(GqlOperationGetFilterModuleByIdDocument, options);
}
export type GetFilterModuleByIdQueryHookResult = ReturnType<
    typeof useGetFilterModuleByIdQuery
>;
export type GetFilterModuleByIdLazyQueryHookResult = ReturnType<
    typeof useGetFilterModuleByIdLazyQuery
>;
export type GetFilterModuleByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetFilterModuleByIdSuspenseQuery
>;
export type GetFilterModuleByIdQueryResult = Apollo.QueryResult<
    Types.GetFilterModuleByIdQuery,
    Types.GetFilterModuleByIdQueryVariables
>;
export const GqlOperationSearchByFilterModulesWithNarrativeDocument = gql`
    query SearchByFilterModulesWithNarrative(
        $searchInput: SearchByFilterModulesInputQL!
        $narrative: String!
    ) {
        searchWithPrescreening(
            searchInput: $searchInput
            narrative: $narrative
        ) {
            totalCount
            docGroups {
                ...DocumentSearchResultPart
            }
        }
    }
    ${DocumentSearchResultPartFragmentDoc}
`;

/**
 * __useSearchByFilterModulesWithNarrativeQuery__
 *
 * To run a query within a React component, call `useSearchByFilterModulesWithNarrativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByFilterModulesWithNarrativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByFilterModulesWithNarrativeQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      narrative: // value for 'narrative'
 *   },
 * });
 */
export function useSearchByFilterModulesWithNarrativeQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.SearchByFilterModulesWithNarrativeQuery,
        Types.SearchByFilterModulesWithNarrativeQueryVariables
    > &
        (
            | {
                  variables: Types.SearchByFilterModulesWithNarrativeQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.SearchByFilterModulesWithNarrativeQuery,
        Types.SearchByFilterModulesWithNarrativeQueryVariables
    >(GqlOperationSearchByFilterModulesWithNarrativeDocument, options);
}
export function useSearchByFilterModulesWithNarrativeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SearchByFilterModulesWithNarrativeQuery,
        Types.SearchByFilterModulesWithNarrativeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.SearchByFilterModulesWithNarrativeQuery,
        Types.SearchByFilterModulesWithNarrativeQueryVariables
    >(GqlOperationSearchByFilterModulesWithNarrativeDocument, options);
}
export function useSearchByFilterModulesWithNarrativeSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.SearchByFilterModulesWithNarrativeQuery,
              Types.SearchByFilterModulesWithNarrativeQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.SearchByFilterModulesWithNarrativeQuery,
        Types.SearchByFilterModulesWithNarrativeQueryVariables
    >(GqlOperationSearchByFilterModulesWithNarrativeDocument, options);
}
export type SearchByFilterModulesWithNarrativeQueryHookResult = ReturnType<
    typeof useSearchByFilterModulesWithNarrativeQuery
>;
export type SearchByFilterModulesWithNarrativeLazyQueryHookResult = ReturnType<
    typeof useSearchByFilterModulesWithNarrativeLazyQuery
>;
export type SearchByFilterModulesWithNarrativeSuspenseQueryHookResult =
    ReturnType<typeof useSearchByFilterModulesWithNarrativeSuspenseQuery>;
export type SearchByFilterModulesWithNarrativeQueryResult = Apollo.QueryResult<
    Types.SearchByFilterModulesWithNarrativeQuery,
    Types.SearchByFilterModulesWithNarrativeQueryVariables
>;
export const GqlOperationSearchCountByFilterModulesDocument = gql`
    query SearchCountByFilterModules(
        $searchInput: CountSearchByFilterModulesInputQL!
    ) {
        countSearchByFilterModules(searchInput: $searchInput) {
            totalCount
        }
    }
`;

/**
 * __useSearchCountByFilterModulesQuery__
 *
 * To run a query within a React component, call `useSearchCountByFilterModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCountByFilterModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCountByFilterModulesQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useSearchCountByFilterModulesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.SearchCountByFilterModulesQuery,
        Types.SearchCountByFilterModulesQueryVariables
    > &
        (
            | {
                  variables: Types.SearchCountByFilterModulesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.SearchCountByFilterModulesQuery,
        Types.SearchCountByFilterModulesQueryVariables
    >(GqlOperationSearchCountByFilterModulesDocument, options);
}
export function useSearchCountByFilterModulesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SearchCountByFilterModulesQuery,
        Types.SearchCountByFilterModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.SearchCountByFilterModulesQuery,
        Types.SearchCountByFilterModulesQueryVariables
    >(GqlOperationSearchCountByFilterModulesDocument, options);
}
export function useSearchCountByFilterModulesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.SearchCountByFilterModulesQuery,
              Types.SearchCountByFilterModulesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.SearchCountByFilterModulesQuery,
        Types.SearchCountByFilterModulesQueryVariables
    >(GqlOperationSearchCountByFilterModulesDocument, options);
}
export type SearchCountByFilterModulesQueryHookResult = ReturnType<
    typeof useSearchCountByFilterModulesQuery
>;
export type SearchCountByFilterModulesLazyQueryHookResult = ReturnType<
    typeof useSearchCountByFilterModulesLazyQuery
>;
export type SearchCountByFilterModulesSuspenseQueryHookResult = ReturnType<
    typeof useSearchCountByFilterModulesSuspenseQuery
>;
export type SearchCountByFilterModulesQueryResult = Apollo.QueryResult<
    Types.SearchCountByFilterModulesQuery,
    Types.SearchCountByFilterModulesQueryVariables
>;
export const GqlOperationGetLocationSuggestionsDocument = gql`
    query GetLocationSuggestions($partialText: String!) {
        locationSuggestions(partialString: $partialText) {
            value
            key
            type
            countryCode
            population
            twoFishesId
        }
    }
`;

/**
 * __useGetLocationSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetLocationSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationSuggestionsQuery({
 *   variables: {
 *      partialText: // value for 'partialText'
 *   },
 * });
 */
export function useGetLocationSuggestionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetLocationSuggestionsQuery,
        Types.GetLocationSuggestionsQueryVariables
    > &
        (
            | {
                  variables: Types.GetLocationSuggestionsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetLocationSuggestionsQuery,
        Types.GetLocationSuggestionsQueryVariables
    >(GqlOperationGetLocationSuggestionsDocument, options);
}
export function useGetLocationSuggestionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetLocationSuggestionsQuery,
        Types.GetLocationSuggestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetLocationSuggestionsQuery,
        Types.GetLocationSuggestionsQueryVariables
    >(GqlOperationGetLocationSuggestionsDocument, options);
}
export function useGetLocationSuggestionsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetLocationSuggestionsQuery,
              Types.GetLocationSuggestionsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetLocationSuggestionsQuery,
        Types.GetLocationSuggestionsQueryVariables
    >(GqlOperationGetLocationSuggestionsDocument, options);
}
export type GetLocationSuggestionsQueryHookResult = ReturnType<
    typeof useGetLocationSuggestionsQuery
>;
export type GetLocationSuggestionsLazyQueryHookResult = ReturnType<
    typeof useGetLocationSuggestionsLazyQuery
>;
export type GetLocationSuggestionsSuspenseQueryHookResult = ReturnType<
    typeof useGetLocationSuggestionsSuspenseQuery
>;
export type GetLocationSuggestionsQueryResult = Apollo.QueryResult<
    Types.GetLocationSuggestionsQuery,
    Types.GetLocationSuggestionsQueryVariables
>;
export const GqlOperationGetSourceDomainSuggestionsDocument = gql`
    query GetSourceDomainSuggestions($partialText: String!) {
        autocompleteSourceDomains(partialString: $partialText)
    }
`;

/**
 * __useGetSourceDomainSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetSourceDomainSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceDomainSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceDomainSuggestionsQuery({
 *   variables: {
 *      partialText: // value for 'partialText'
 *   },
 * });
 */
export function useGetSourceDomainSuggestionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetSourceDomainSuggestionsQuery,
        Types.GetSourceDomainSuggestionsQueryVariables
    > &
        (
            | {
                  variables: Types.GetSourceDomainSuggestionsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetSourceDomainSuggestionsQuery,
        Types.GetSourceDomainSuggestionsQueryVariables
    >(GqlOperationGetSourceDomainSuggestionsDocument, options);
}
export function useGetSourceDomainSuggestionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetSourceDomainSuggestionsQuery,
        Types.GetSourceDomainSuggestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetSourceDomainSuggestionsQuery,
        Types.GetSourceDomainSuggestionsQueryVariables
    >(GqlOperationGetSourceDomainSuggestionsDocument, options);
}
export function useGetSourceDomainSuggestionsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetSourceDomainSuggestionsQuery,
              Types.GetSourceDomainSuggestionsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetSourceDomainSuggestionsQuery,
        Types.GetSourceDomainSuggestionsQueryVariables
    >(GqlOperationGetSourceDomainSuggestionsDocument, options);
}
export type GetSourceDomainSuggestionsQueryHookResult = ReturnType<
    typeof useGetSourceDomainSuggestionsQuery
>;
export type GetSourceDomainSuggestionsLazyQueryHookResult = ReturnType<
    typeof useGetSourceDomainSuggestionsLazyQuery
>;
export type GetSourceDomainSuggestionsSuspenseQueryHookResult = ReturnType<
    typeof useGetSourceDomainSuggestionsSuspenseQuery
>;
export type GetSourceDomainSuggestionsQueryResult = Apollo.QueryResult<
    Types.GetSourceDomainSuggestionsQuery,
    Types.GetSourceDomainSuggestionsQueryVariables
>;
export const GqlOperationGetSearchesAndSubscriptionsDocument = gql`
    query GetSearchesAndSubscriptions {
        searchesAndSubscriptions {
            ...SearchAndSubscriptionPart
        }
    }
    ${SearchAndSubscriptionPartFragmentDoc}
`;

/**
 * __useGetSearchesAndSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSearchesAndSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchesAndSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchesAndSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchesAndSubscriptionsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetSearchesAndSubscriptionsQuery,
        Types.GetSearchesAndSubscriptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetSearchesAndSubscriptionsQuery,
        Types.GetSearchesAndSubscriptionsQueryVariables
    >(GqlOperationGetSearchesAndSubscriptionsDocument, options);
}
export function useGetSearchesAndSubscriptionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetSearchesAndSubscriptionsQuery,
        Types.GetSearchesAndSubscriptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetSearchesAndSubscriptionsQuery,
        Types.GetSearchesAndSubscriptionsQueryVariables
    >(GqlOperationGetSearchesAndSubscriptionsDocument, options);
}
export function useGetSearchesAndSubscriptionsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetSearchesAndSubscriptionsQuery,
              Types.GetSearchesAndSubscriptionsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetSearchesAndSubscriptionsQuery,
        Types.GetSearchesAndSubscriptionsQueryVariables
    >(GqlOperationGetSearchesAndSubscriptionsDocument, options);
}
export type GetSearchesAndSubscriptionsQueryHookResult = ReturnType<
    typeof useGetSearchesAndSubscriptionsQuery
>;
export type GetSearchesAndSubscriptionsLazyQueryHookResult = ReturnType<
    typeof useGetSearchesAndSubscriptionsLazyQuery
>;
export type GetSearchesAndSubscriptionsSuspenseQueryHookResult = ReturnType<
    typeof useGetSearchesAndSubscriptionsSuspenseQuery
>;
export type GetSearchesAndSubscriptionsQueryResult = Apollo.QueryResult<
    Types.GetSearchesAndSubscriptionsQuery,
    Types.GetSearchesAndSubscriptionsQueryVariables
>;
export const GqlOperationCreateFilterModuleDocument = gql`
    mutation CreateFilterModule(
        $name: String!
        $data: JSON!
        $type: FilterModuleType!
    ) {
        createFilterModule(
            name: $name
            data: $data
            filterModuleType: $type
            notReusable: false
        ) {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;
export type CreateFilterModuleMutationFn = Apollo.MutationFunction<
    Types.CreateFilterModuleMutation,
    Types.CreateFilterModuleMutationVariables
>;

/**
 * __useCreateFilterModuleMutation__
 *
 * To run a mutation, you first call `useCreateFilterModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterModuleMutation, { data, loading, error }] = useCreateFilterModuleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      data: // value for 'data'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateFilterModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateFilterModuleMutation,
        Types.CreateFilterModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateFilterModuleMutation,
        Types.CreateFilterModuleMutationVariables
    >(GqlOperationCreateFilterModuleDocument, options);
}
export type CreateFilterModuleMutationHookResult = ReturnType<
    typeof useCreateFilterModuleMutation
>;
export type CreateFilterModuleMutationResult =
    Apollo.MutationResult<Types.CreateFilterModuleMutation>;
export type CreateFilterModuleMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateFilterModuleMutation,
    Types.CreateFilterModuleMutationVariables
>;
export const GqlOperationUpdateFilterModuleDocument = gql`
    mutation UpdateFilterModule($id: Int!, $name: String!, $data: JSON!) {
        updateFilterModule(
            filterModuleId: $id
            name: $name
            data: $data
            notReusable: false
        ) {
            ok
        }
    }
`;
export type UpdateFilterModuleMutationFn = Apollo.MutationFunction<
    Types.UpdateFilterModuleMutation,
    Types.UpdateFilterModuleMutationVariables
>;

/**
 * __useUpdateFilterModuleMutation__
 *
 * To run a mutation, you first call `useUpdateFilterModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilterModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilterModuleMutation, { data, loading, error }] = useUpdateFilterModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFilterModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateFilterModuleMutation,
        Types.UpdateFilterModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateFilterModuleMutation,
        Types.UpdateFilterModuleMutationVariables
    >(GqlOperationUpdateFilterModuleDocument, options);
}
export type UpdateFilterModuleMutationHookResult = ReturnType<
    typeof useUpdateFilterModuleMutation
>;
export type UpdateFilterModuleMutationResult =
    Apollo.MutationResult<Types.UpdateFilterModuleMutation>;
export type UpdateFilterModuleMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateFilterModuleMutation,
    Types.UpdateFilterModuleMutationVariables
>;
export const GqlOperationDeleteFilterModuleDocument = gql`
    mutation DeleteFilterModule($id: Int!) {
        deleteFilterModule(filterModuleId: $id) {
            ok
        }
    }
`;
export type DeleteFilterModuleMutationFn = Apollo.MutationFunction<
    Types.DeleteFilterModuleMutation,
    Types.DeleteFilterModuleMutationVariables
>;

/**
 * __useDeleteFilterModuleMutation__
 *
 * To run a mutation, you first call `useDeleteFilterModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilterModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilterModuleMutation, { data, loading, error }] = useDeleteFilterModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFilterModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteFilterModuleMutation,
        Types.DeleteFilterModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteFilterModuleMutation,
        Types.DeleteFilterModuleMutationVariables
    >(GqlOperationDeleteFilterModuleDocument, options);
}
export type DeleteFilterModuleMutationHookResult = ReturnType<
    typeof useDeleteFilterModuleMutation
>;
export type DeleteFilterModuleMutationResult =
    Apollo.MutationResult<Types.DeleteFilterModuleMutation>;
export type DeleteFilterModuleMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteFilterModuleMutation,
    Types.DeleteFilterModuleMutationVariables
>;
export const GqlOperationDeleteSearchDocument = gql`
    mutation DeleteSearch($id: Int!) {
        deleteSearch(searchId: $id) {
            ok
        }
    }
`;
export type DeleteSearchMutationFn = Apollo.MutationFunction<
    Types.DeleteSearchMutation,
    Types.DeleteSearchMutationVariables
>;

/**
 * __useDeleteSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchMutation, { data, loading, error }] = useDeleteSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteSearchMutation,
        Types.DeleteSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteSearchMutation,
        Types.DeleteSearchMutationVariables
    >(GqlOperationDeleteSearchDocument, options);
}
export type DeleteSearchMutationHookResult = ReturnType<
    typeof useDeleteSearchMutation
>;
export type DeleteSearchMutationResult =
    Apollo.MutationResult<Types.DeleteSearchMutation>;
export type DeleteSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteSearchMutation,
    Types.DeleteSearchMutationVariables
>;
export const GqlOperationUpsertSearchDocument = gql`
    mutation UpsertSearch(
        $id: Int
        $name: String!
        $type: SearchType!
        $filterModuleIds: [Int!]!
    ) {
        upsertSearch(
            searchId: $id
            searchName: $name
            searchType: $type
            filterModuleIds: $filterModuleIds
        ) {
            id
        }
    }
`;
export type UpsertSearchMutationFn = Apollo.MutationFunction<
    Types.UpsertSearchMutation,
    Types.UpsertSearchMutationVariables
>;

/**
 * __useUpsertSearchMutation__
 *
 * To run a mutation, you first call `useUpsertSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSearchMutation, { data, loading, error }] = useUpsertSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      filterModuleIds: // value for 'filterModuleIds'
 *   },
 * });
 */
export function useUpsertSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpsertSearchMutation,
        Types.UpsertSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpsertSearchMutation,
        Types.UpsertSearchMutationVariables
    >(GqlOperationUpsertSearchDocument, options);
}
export type UpsertSearchMutationHookResult = ReturnType<
    typeof useUpsertSearchMutation
>;
export type UpsertSearchMutationResult =
    Apollo.MutationResult<Types.UpsertSearchMutation>;
export type UpsertSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.UpsertSearchMutation,
    Types.UpsertSearchMutationVariables
>;
export const GqlOperationSubscribeToSearchDocument = gql`
    mutation SubscribeToSearch($searchId: Int!, $subscriberId: Int!) {
        subscribeSearch(searchId: $searchId, subscriberId: $subscriberId) {
            ...SearchSubscribedPart
        }
    }
    ${SearchSubscribedPartFragmentDoc}
`;
export type SubscribeToSearchMutationFn = Apollo.MutationFunction<
    Types.SubscribeToSearchMutation,
    Types.SubscribeToSearchMutationVariables
>;

/**
 * __useSubscribeToSearchMutation__
 *
 * To run a mutation, you first call `useSubscribeToSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToSearchMutation, { data, loading, error }] = useSubscribeToSearchMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useSubscribeToSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SubscribeToSearchMutation,
        Types.SubscribeToSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SubscribeToSearchMutation,
        Types.SubscribeToSearchMutationVariables
    >(GqlOperationSubscribeToSearchDocument, options);
}
export type SubscribeToSearchMutationHookResult = ReturnType<
    typeof useSubscribeToSearchMutation
>;
export type SubscribeToSearchMutationResult =
    Apollo.MutationResult<Types.SubscribeToSearchMutation>;
export type SubscribeToSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.SubscribeToSearchMutation,
    Types.SubscribeToSearchMutationVariables
>;
export const GqlOperationUnsubscribeToSearchDocument = gql`
    mutation UnsubscribeToSearch($searchId: Int!, $subscriberId: Int!) {
        unsubscribeSearch(searchId: $searchId, subscriberId: $subscriberId) {
            ok
        }
    }
`;
export type UnsubscribeToSearchMutationFn = Apollo.MutationFunction<
    Types.UnsubscribeToSearchMutation,
    Types.UnsubscribeToSearchMutationVariables
>;

/**
 * __useUnsubscribeToSearchMutation__
 *
 * To run a mutation, you first call `useUnsubscribeToSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeToSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeToSearchMutation, { data, loading, error }] = useUnsubscribeToSearchMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useUnsubscribeToSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UnsubscribeToSearchMutation,
        Types.UnsubscribeToSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UnsubscribeToSearchMutation,
        Types.UnsubscribeToSearchMutationVariables
    >(GqlOperationUnsubscribeToSearchDocument, options);
}
export type UnsubscribeToSearchMutationHookResult = ReturnType<
    typeof useUnsubscribeToSearchMutation
>;
export type UnsubscribeToSearchMutationResult =
    Apollo.MutationResult<Types.UnsubscribeToSearchMutation>;
export type UnsubscribeToSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.UnsubscribeToSearchMutation,
    Types.UnsubscribeToSearchMutationVariables
>;
export const GqlOperationPauseSearchDocument = gql`
    mutation PauseSearch($searchId: Int!) {
        pauseSearch(searchId: $searchId) {
            ok
        }
    }
`;
export type PauseSearchMutationFn = Apollo.MutationFunction<
    Types.PauseSearchMutation,
    Types.PauseSearchMutationVariables
>;

/**
 * __usePauseSearchMutation__
 *
 * To run a mutation, you first call `usePauseSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseSearchMutation, { data, loading, error }] = usePauseSearchMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function usePauseSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.PauseSearchMutation,
        Types.PauseSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.PauseSearchMutation,
        Types.PauseSearchMutationVariables
    >(GqlOperationPauseSearchDocument, options);
}
export type PauseSearchMutationHookResult = ReturnType<
    typeof usePauseSearchMutation
>;
export type PauseSearchMutationResult =
    Apollo.MutationResult<Types.PauseSearchMutation>;
export type PauseSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.PauseSearchMutation,
    Types.PauseSearchMutationVariables
>;
export const GqlOperationUnpauseSearchDocument = gql`
    mutation UnpauseSearch($searchId: Int!) {
        unpauseSearch(searchId: $searchId) {
            ok
        }
    }
`;
export type UnpauseSearchMutationFn = Apollo.MutationFunction<
    Types.UnpauseSearchMutation,
    Types.UnpauseSearchMutationVariables
>;

/**
 * __useUnpauseSearchMutation__
 *
 * To run a mutation, you first call `useUnpauseSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpauseSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpauseSearchMutation, { data, loading, error }] = useUnpauseSearchMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useUnpauseSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UnpauseSearchMutation,
        Types.UnpauseSearchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UnpauseSearchMutation,
        Types.UnpauseSearchMutationVariables
    >(GqlOperationUnpauseSearchDocument, options);
}
export type UnpauseSearchMutationHookResult = ReturnType<
    typeof useUnpauseSearchMutation
>;
export type UnpauseSearchMutationResult =
    Apollo.MutationResult<Types.UnpauseSearchMutation>;
export type UnpauseSearchMutationOptions = Apollo.BaseMutationOptions<
    Types.UnpauseSearchMutation,
    Types.UnpauseSearchMutationVariables
>;
export const GqlOperationUpdateSearchPrescreeningSettingsDocument = gql`
    mutation UpdateSearchPrescreeningSettings(
        $searchId: Int!
        $description: String = null
        $autoPrescreeningEnabled: Boolean = null
        $autoPrescreeningInboxLimit: Int = null
    ) {
        updateSearchPrescreeningSettings(
            searchId: $searchId
            description: $description
            autoPrescreeningEnabled: $autoPrescreeningEnabled
            autoPrescreeningInboxLimit: $autoPrescreeningInboxLimit
        ) {
            ok
        }
    }
`;
export type UpdateSearchPrescreeningSettingsMutationFn =
    Apollo.MutationFunction<
        Types.UpdateSearchPrescreeningSettingsMutation,
        Types.UpdateSearchPrescreeningSettingsMutationVariables
    >;

/**
 * __useUpdateSearchPrescreeningSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSearchPrescreeningSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchPrescreeningSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchPrescreeningSettingsMutation, { data, loading, error }] = useUpdateSearchPrescreeningSettingsMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *      description: // value for 'description'
 *      autoPrescreeningEnabled: // value for 'autoPrescreeningEnabled'
 *      autoPrescreeningInboxLimit: // value for 'autoPrescreeningInboxLimit'
 *   },
 * });
 */
export function useUpdateSearchPrescreeningSettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateSearchPrescreeningSettingsMutation,
        Types.UpdateSearchPrescreeningSettingsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateSearchPrescreeningSettingsMutation,
        Types.UpdateSearchPrescreeningSettingsMutationVariables
    >(GqlOperationUpdateSearchPrescreeningSettingsDocument, options);
}
export type UpdateSearchPrescreeningSettingsMutationHookResult = ReturnType<
    typeof useUpdateSearchPrescreeningSettingsMutation
>;
export type UpdateSearchPrescreeningSettingsMutationResult =
    Apollo.MutationResult<Types.UpdateSearchPrescreeningSettingsMutation>;
export type UpdateSearchPrescreeningSettingsMutationOptions =
    Apollo.BaseMutationOptions<
        Types.UpdateSearchPrescreeningSettingsMutation,
        Types.UpdateSearchPrescreeningSettingsMutationVariables
    >;
export const GqlOperationGetTenderTextFromCodesDocument = gql`
    query GetTenderTextFromCodes(
        $cpvCodes: [String!]!
        $naicsCodes: [String!]!
        $unspscCodes: [String!]!
    ) {
        tenderTextFromCodes(
            cpvCodes: $cpvCodes
            naicsCodes: $naicsCodes
            unspscCodes: $unspscCodes
        )
    }
`;

/**
 * __useGetTenderTextFromCodesQuery__
 *
 * To run a query within a React component, call `useGetTenderTextFromCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenderTextFromCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenderTextFromCodesQuery({
 *   variables: {
 *      cpvCodes: // value for 'cpvCodes'
 *      naicsCodes: // value for 'naicsCodes'
 *      unspscCodes: // value for 'unspscCodes'
 *   },
 * });
 */
export function useGetTenderTextFromCodesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetTenderTextFromCodesQuery,
        Types.GetTenderTextFromCodesQueryVariables
    > &
        (
            | {
                  variables: Types.GetTenderTextFromCodesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetTenderTextFromCodesQuery,
        Types.GetTenderTextFromCodesQueryVariables
    >(GqlOperationGetTenderTextFromCodesDocument, options);
}
export function useGetTenderTextFromCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetTenderTextFromCodesQuery,
        Types.GetTenderTextFromCodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetTenderTextFromCodesQuery,
        Types.GetTenderTextFromCodesQueryVariables
    >(GqlOperationGetTenderTextFromCodesDocument, options);
}
export function useGetTenderTextFromCodesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetTenderTextFromCodesQuery,
              Types.GetTenderTextFromCodesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetTenderTextFromCodesQuery,
        Types.GetTenderTextFromCodesQueryVariables
    >(GqlOperationGetTenderTextFromCodesDocument, options);
}
export type GetTenderTextFromCodesQueryHookResult = ReturnType<
    typeof useGetTenderTextFromCodesQuery
>;
export type GetTenderTextFromCodesLazyQueryHookResult = ReturnType<
    typeof useGetTenderTextFromCodesLazyQuery
>;
export type GetTenderTextFromCodesSuspenseQueryHookResult = ReturnType<
    typeof useGetTenderTextFromCodesSuspenseQuery
>;
export type GetTenderTextFromCodesQueryResult = Apollo.QueryResult<
    Types.GetTenderTextFromCodesQuery,
    Types.GetTenderTextFromCodesQueryVariables
>;
