import {
    DealCompanyPartFragment,
    DealContactPartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    CustomParameter,
    createCustomParameter,
} from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { Optional } from 'src/utils/type.utils';
import {
    GoogleEnrichmentOption,
    transformGoogleEnrichmentFromFragment,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { InputStatus } from '@buildingradar/ui_library/input';

export interface DealContactBase {
    id: string;
    role?: string;
    notes?: string;
    isKey?: boolean;
    extracted?: boolean;
    customFields?: CustomParameter[];
    createdDate?: Date;
    parentDeals?: string[];
}

export interface DealContactPerson extends DealContactBase {
    firstName?: string;
    lastName?: string;
    relevantCompany?: { id: string; name: string };
    relevantCompanyId?: string;
    type: DealContactType.person;
}

export interface DealContactCompany extends DealContactBase {
    name: string;
    contactIds?: string[];
    contacts?: { id: string; name: string }[];
    type: DealContactType.company;
    enrichmentOptions?: GoogleEnrichmentOption[];
}

export type DealContactUnionType = DealContactPerson | DealContactCompany;

export type PartialDealContactType =
    | PartialDealContactPerson
    | PartialDealContactCompany;

export type PartialDealContactPerson = Optional<DealContactPerson, 'id'>;
export type PartialDealContactCompany = Optional<
    DealContactCompany,
    'name' | 'id'
>;

export enum DealContactType {
    person = 'person',
    company = 'company',
}

export interface CreatedFromExtraction {
    contacts: DealContactPerson[];
    companies: DealContactCompany[];
}

export const createDealContactPersonFromFragment = (
    rawContact: DealContactPartFragment,
): DealContactPerson => {
    return {
        id: rawContact.id,
        firstName: rawContact.firstName ?? undefined,
        lastName: rawContact.lastName ?? undefined,
        role: rawContact.role ?? undefined,
        notes: rawContact.notes ?? undefined,
        isKey: rawContact.isKey ?? undefined,
        extracted: rawContact.extracted ?? undefined,
        relevantCompany: rawContact.relevantCompany ?? undefined,
        type: DealContactType.person,
        relevantCompanyId: rawContact.relevantCompany?.id,
        customFields: rawContact.customFields
            ? rawContact.customFields
                  .map((field) => createCustomParameter(field))
                  .sort((a, b) => a.order - b.order)
            : [],
        createdDate: new Date(rawContact.createdDate),
        parentDeals: rawContact.parentDeals?.map((deal) => deal.id) ?? [],
    };
};

export const createDealContactCompanyFromFragment = (
    rawCompany: DealCompanyPartFragment,
): DealContactCompany => {
    return {
        id: rawCompany.id,
        name: rawCompany.name,
        role: rawCompany.role ?? undefined,
        notes: rawCompany.notes ?? undefined,
        isKey: rawCompany.isKey ?? undefined,
        extracted: rawCompany.extracted ?? undefined,
        type: DealContactType.company,
        contactIds: rawCompany.contacts
            ? rawCompany.contacts.map((contact) => contact.id)
            : [],
        contacts: (rawCompany.contacts ?? []).map(
            ({ id, firstName, lastName }) => ({
                id,
                name: getFullName(firstName, lastName),
            }),
        ),
        customFields: rawCompany.customFields
            ? rawCompany.customFields
                  .map((field) => createCustomParameter(field))
                  .sort((a, b) => a.order - b.order)
            : [],
        createdDate: new Date(rawCompany.createdDate),
        parentDeals: rawCompany.parentDeals?.map((deal) => deal.id) ?? [],
        enrichmentOptions: rawCompany.placeDetails?.map(
            transformGoogleEnrichmentFromFragment,
        ),
    };
};

export const contactHas = (
    field: PredefinedCustomFields,
    contact: DealContactUnionType,
): boolean => !!contact.customFields?.find((c) => c.name === field)?.value;

export enum PredefinedCustomFields {
    position = 'position',
    mobile = 'mobile',
    telephone = 'telephone',
    mail = 'mail',
    url = 'url',
    address = 'address',
    linkedin = 'linkedin',
    street = 'street',
    city = 'city',
    post_code = 'post_code',
    country = 'country',
    country_iso = 'country_iso',
}

export type TextInputStatus = InputStatus;
