/* eslint-disable */
import * as Types from './graphql-sdk';

import { DocumentNode } from 'graphql';
import * as Operations from './hooks';

export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        CreateProjectFromDocGroup(
            variables: Types.CreateProjectFromDocGroupMutationVariables,
            options?: C,
        ): Promise<Types.CreateProjectFromDocGroupMutation> {
            return requester<
                Types.CreateProjectFromDocGroupMutation,
                Types.CreateProjectFromDocGroupMutationVariables
            >(
                Operations.GqlOperationCreateProjectFromDocGroupDocument,
                variables,
                options,
            ) as Promise<Types.CreateProjectFromDocGroupMutation>;
        },
        GetProjectsOfFolder(
            variables: Types.GetProjectsOfFolderQueryVariables,
            options?: C,
        ): Promise<Types.GetProjectsOfFolderQuery> {
            return requester<
                Types.GetProjectsOfFolderQuery,
                Types.GetProjectsOfFolderQueryVariables
            >(
                Operations.GqlOperationGetProjectsOfFolderDocument,
                variables,
                options,
            ) as Promise<Types.GetProjectsOfFolderQuery>;
        },
        GetProjectById(
            variables: Types.GetProjectByIdQueryVariables,
            options?: C,
        ): Promise<Types.GetProjectByIdQuery> {
            return requester<
                Types.GetProjectByIdQuery,
                Types.GetProjectByIdQueryVariables
            >(
                Operations.GqlOperationGetProjectByIdDocument,
                variables,
                options,
            ) as Promise<Types.GetProjectByIdQuery>;
        },
        GetCustomTitlesOfProjects(
            variables: Types.GetCustomTitlesOfProjectsQueryVariables,
            options?: C,
        ): Promise<Types.GetCustomTitlesOfProjectsQuery> {
            return requester<
                Types.GetCustomTitlesOfProjectsQuery,
                Types.GetCustomTitlesOfProjectsQueryVariables
            >(
                Operations.GqlOperationGetCustomTitlesOfProjectsDocument,
                variables,
                options,
            ) as Promise<Types.GetCustomTitlesOfProjectsQuery>;
        },
        UpdateProjectCustomTitle(
            variables: Types.UpdateProjectCustomTitleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateProjectCustomTitleMutation> {
            return requester<
                Types.UpdateProjectCustomTitleMutation,
                Types.UpdateProjectCustomTitleMutationVariables
            >(
                Operations.GqlOperationUpdateProjectCustomTitleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateProjectCustomTitleMutation>;
        },
        GetAllFoldersCounter(
            variables: Types.GetAllFoldersCounterQueryVariables,
            options?: C,
        ): Promise<Types.GetAllFoldersCounterQuery> {
            return requester<
                Types.GetAllFoldersCounterQuery,
                Types.GetAllFoldersCounterQueryVariables
            >(
                Operations.GqlOperationGetAllFoldersCounterDocument,
                variables,
                options,
            ) as Promise<Types.GetAllFoldersCounterQuery>;
        },
        MoveProjectToFolder(
            variables: Types.MoveProjectToFolderMutationVariables,
            options?: C,
        ): Promise<Types.MoveProjectToFolderMutation> {
            return requester<
                Types.MoveProjectToFolderMutation,
                Types.MoveProjectToFolderMutationVariables
            >(
                Operations.GqlOperationMoveProjectToFolderDocument,
                variables,
                options,
            ) as Promise<Types.MoveProjectToFolderMutation>;
        },
        GetProjectDataForOutreach(
            variables: Types.GetProjectDataForOutreachQueryVariables,
            options?: C,
        ): Promise<Types.GetProjectDataForOutreachQuery> {
            return requester<
                Types.GetProjectDataForOutreachQuery,
                Types.GetProjectDataForOutreachQueryVariables
            >(
                Operations.GqlOperationGetProjectDataForOutreachDocument,
                variables,
                options,
            ) as Promise<Types.GetProjectDataForOutreachQuery>;
        },
        GetProjectsGeneratedData(
            variables: Types.GetProjectsGeneratedDataQueryVariables,
            options?: C,
        ): Promise<Types.GetProjectsGeneratedDataQuery> {
            return requester<
                Types.GetProjectsGeneratedDataQuery,
                Types.GetProjectsGeneratedDataQueryVariables
            >(
                Operations.GqlOperationGetProjectsGeneratedDataDocument,
                variables,
                options,
            ) as Promise<Types.GetProjectsGeneratedDataQuery>;
        },
        WipeInboxedSearchProjects(
            variables: Types.WipeInboxedSearchProjectsMutationVariables,
            options?: C,
        ): Promise<Types.WipeInboxedSearchProjectsMutation> {
            return requester<
                Types.WipeInboxedSearchProjectsMutation,
                Types.WipeInboxedSearchProjectsMutationVariables
            >(
                Operations.GqlOperationWipeInboxedSearchProjectsDocument,
                variables,
                options,
            ) as Promise<Types.WipeInboxedSearchProjectsMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
