import * as Types from './graphql-sdk';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {
    context: { endpoint: '/apps/project/graphql' },
} as const;
export const BrDateTimePartFragmentDoc = gql`
    fragment BrDateTimePart on BRTimeQL {
        day
        hour
        microsecond
        minute
        month
        precision
        second
        year
    }
`;
export const AddressPartFragmentDoc = gql`
    fragment AddressPart on AddressQL {
        city
        continent
        country
        countryCode
        houseNumber
        lat
        lng
        postCode
        postCodeEnd
        postCodeIsExact
        postCodeStart
        precisionAccuracy
        precisionLevel
        precisionType
        rank
        road
        state
    }
`;
export const ContactPartFragmentDoc = gql`
    fragment ContactPart on ContactQL {
        address {
            ... on AddressQL {
                ...AddressPart
            }
        }
        associatedItemId
        companyName
        companyRoles
        email
        fax
        homepage
        name
        personRoles
        phoneLandline
        phoneMobile
        title
    }
    ${AddressPartFragmentDoc}
`;
export const ArticleDocumentPartFragmentDoc = gql`
    fragment ArticleDocumentPart on ArticleDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
        address {
            ... on AddressQL {
                ...AddressPart
            }
        }
        abstract
        paragraphs
        constructionStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        constructionEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        gfa
        nResidentialUnits
    }
    ${BrDateTimePartFragmentDoc}
    ${ContactPartFragmentDoc}
    ${AddressPartFragmentDoc}
`;
export const TenderDocumentPartFragmentDoc = gql`
    fragment TenderDocumentPart on TenderDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
        allLotsAwarded
        announcedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        awardCriteria
        contractEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        contractStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionDeadlineDateEst
        competitionDeadlineDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        frameworkAgreement
        openingDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionPhase
        documentFamilyId
        documentFamilyRefs
        contractType
        directive
        documentType
        applicationUrl
        contractAddress {
            ... on AddressQL {
                ...AddressPart
            }
        }
        procedureType
        quantityScope
        referenceNumber
        tenderType
        awardedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        cpvCodes
        naicsCodes
        unspscCodes
        rawDataString
    }
    ${BrDateTimePartFragmentDoc}
    ${ContactPartFragmentDoc}
    ${AddressPartFragmentDoc}
`;
export const ProjectPartFragmentDoc = gql`
    fragment ProjectPart on RDBProjectQL {
        id
        searchIds
        created
        updated
        folderUpdated
        folderTag
        docGroupId
        documents {
            ... on ArticleDocumentQL {
                ...ArticleDocumentPart
            }
            ... on TenderDocumentQL {
                ...TenderDocumentPart
            }
        }
        mergedDocument {
            ... on ArticleDocumentQL {
                ...ArticleDocumentPart
            }
            ... on TenderDocumentQL {
                ...TenderDocumentPart
            }
        }
    }
    ${ArticleDocumentPartFragmentDoc}
    ${TenderDocumentPartFragmentDoc}
`;
export const GqlOperationCreateProjectFromDocGroupDocument = gql`
    mutation CreateProjectFromDocGroup(
        $folderTag: FolderTag!
        $docGroupsInfo: [DocGroupInfoInputQL!]!
        $searchId: Int
    ) {
        createProjectFromDocGroup(
            folderTag: $folderTag
            docGroupsInfo: $docGroupsInfo
            searchId: $searchId
        ) {
            projectIds
        }
    }
`;
export type CreateProjectFromDocGroupMutationFn = Apollo.MutationFunction<
    Types.CreateProjectFromDocGroupMutation,
    Types.CreateProjectFromDocGroupMutationVariables
>;

/**
 * __useCreateProjectFromDocGroupMutation__
 *
 * To run a mutation, you first call `useCreateProjectFromDocGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFromDocGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFromDocGroupMutation, { data, loading, error }] = useCreateProjectFromDocGroupMutation({
 *   variables: {
 *      folderTag: // value for 'folderTag'
 *      docGroupsInfo: // value for 'docGroupsInfo'
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useCreateProjectFromDocGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateProjectFromDocGroupMutation,
        Types.CreateProjectFromDocGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateProjectFromDocGroupMutation,
        Types.CreateProjectFromDocGroupMutationVariables
    >(GqlOperationCreateProjectFromDocGroupDocument, options);
}
export type CreateProjectFromDocGroupMutationHookResult = ReturnType<
    typeof useCreateProjectFromDocGroupMutation
>;
export type CreateProjectFromDocGroupMutationResult =
    Apollo.MutationResult<Types.CreateProjectFromDocGroupMutation>;
export type CreateProjectFromDocGroupMutationOptions =
    Apollo.BaseMutationOptions<
        Types.CreateProjectFromDocGroupMutation,
        Types.CreateProjectFromDocGroupMutationVariables
    >;
export const GqlOperationGetProjectsOfFolderDocument = gql`
    query GetProjectsOfFolder(
        $folderName: FolderTag!
        $includeUnassigned: Boolean!
        $searchTags: [Int!]!
        $count: Int!
        $cursorDate: DateTime!
        $cursorProjectId: Int = null
    ) {
        getProjectsOfFolder(
            folderName: $folderName
            includeUnassigned: $includeUnassigned
            searchTags: $searchTags
            count: $count
            cursorDate: $cursorDate
            cursorProjectId: $cursorProjectId
        ) {
            projects {
                ... on RDBProjectQL {
                    ...ProjectPart
                }
            }
        }
    }
    ${ProjectPartFragmentDoc}
`;

/**
 * __useGetProjectsOfFolderQuery__
 *
 * To run a query within a React component, call `useGetProjectsOfFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsOfFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsOfFolderQuery({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      includeUnassigned: // value for 'includeUnassigned'
 *      searchTags: // value for 'searchTags'
 *      count: // value for 'count'
 *      cursorDate: // value for 'cursorDate'
 *      cursorProjectId: // value for 'cursorProjectId'
 *   },
 * });
 */
export function useGetProjectsOfFolderQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetProjectsOfFolderQuery,
        Types.GetProjectsOfFolderQueryVariables
    > &
        (
            | {
                  variables: Types.GetProjectsOfFolderQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetProjectsOfFolderQuery,
        Types.GetProjectsOfFolderQueryVariables
    >(GqlOperationGetProjectsOfFolderDocument, options);
}
export function useGetProjectsOfFolderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetProjectsOfFolderQuery,
        Types.GetProjectsOfFolderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetProjectsOfFolderQuery,
        Types.GetProjectsOfFolderQueryVariables
    >(GqlOperationGetProjectsOfFolderDocument, options);
}
export function useGetProjectsOfFolderSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetProjectsOfFolderQuery,
              Types.GetProjectsOfFolderQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetProjectsOfFolderQuery,
        Types.GetProjectsOfFolderQueryVariables
    >(GqlOperationGetProjectsOfFolderDocument, options);
}
export type GetProjectsOfFolderQueryHookResult = ReturnType<
    typeof useGetProjectsOfFolderQuery
>;
export type GetProjectsOfFolderLazyQueryHookResult = ReturnType<
    typeof useGetProjectsOfFolderLazyQuery
>;
export type GetProjectsOfFolderSuspenseQueryHookResult = ReturnType<
    typeof useGetProjectsOfFolderSuspenseQuery
>;
export type GetProjectsOfFolderQueryResult = Apollo.QueryResult<
    Types.GetProjectsOfFolderQuery,
    Types.GetProjectsOfFolderQueryVariables
>;
export const GqlOperationGetProjectByIdDocument = gql`
    query GetProjectById($projectId: Int!) {
        getProject(projectId: $projectId) {
            ... on RDBProjectQL {
                ...ProjectPart
            }
        }
    }
    ${ProjectPartFragmentDoc}
`;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetProjectByIdQuery,
        Types.GetProjectByIdQueryVariables
    > &
        (
            | { variables: Types.GetProjectByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetProjectByIdQuery,
        Types.GetProjectByIdQueryVariables
    >(GqlOperationGetProjectByIdDocument, options);
}
export function useGetProjectByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetProjectByIdQuery,
        Types.GetProjectByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetProjectByIdQuery,
        Types.GetProjectByIdQueryVariables
    >(GqlOperationGetProjectByIdDocument, options);
}
export function useGetProjectByIdSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetProjectByIdQuery,
              Types.GetProjectByIdQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetProjectByIdQuery,
        Types.GetProjectByIdQueryVariables
    >(GqlOperationGetProjectByIdDocument, options);
}
export type GetProjectByIdQueryHookResult = ReturnType<
    typeof useGetProjectByIdQuery
>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<
    typeof useGetProjectByIdLazyQuery
>;
export type GetProjectByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetProjectByIdSuspenseQuery
>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<
    Types.GetProjectByIdQuery,
    Types.GetProjectByIdQueryVariables
>;
export const GqlOperationGetCustomTitlesOfProjectsDocument = gql`
    query GetCustomTitlesOfProjects($projectIds: [Int!]!) {
        getCustomTitlesOfProjects(projectIds: $projectIds) {
            projectId
            title
        }
    }
`;

/**
 * __useGetCustomTitlesOfProjectsQuery__
 *
 * To run a query within a React component, call `useGetCustomTitlesOfProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTitlesOfProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTitlesOfProjectsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useGetCustomTitlesOfProjectsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetCustomTitlesOfProjectsQuery,
        Types.GetCustomTitlesOfProjectsQueryVariables
    > &
        (
            | {
                  variables: Types.GetCustomTitlesOfProjectsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCustomTitlesOfProjectsQuery,
        Types.GetCustomTitlesOfProjectsQueryVariables
    >(GqlOperationGetCustomTitlesOfProjectsDocument, options);
}
export function useGetCustomTitlesOfProjectsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCustomTitlesOfProjectsQuery,
        Types.GetCustomTitlesOfProjectsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCustomTitlesOfProjectsQuery,
        Types.GetCustomTitlesOfProjectsQueryVariables
    >(GqlOperationGetCustomTitlesOfProjectsDocument, options);
}
export function useGetCustomTitlesOfProjectsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCustomTitlesOfProjectsQuery,
              Types.GetCustomTitlesOfProjectsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCustomTitlesOfProjectsQuery,
        Types.GetCustomTitlesOfProjectsQueryVariables
    >(GqlOperationGetCustomTitlesOfProjectsDocument, options);
}
export type GetCustomTitlesOfProjectsQueryHookResult = ReturnType<
    typeof useGetCustomTitlesOfProjectsQuery
>;
export type GetCustomTitlesOfProjectsLazyQueryHookResult = ReturnType<
    typeof useGetCustomTitlesOfProjectsLazyQuery
>;
export type GetCustomTitlesOfProjectsSuspenseQueryHookResult = ReturnType<
    typeof useGetCustomTitlesOfProjectsSuspenseQuery
>;
export type GetCustomTitlesOfProjectsQueryResult = Apollo.QueryResult<
    Types.GetCustomTitlesOfProjectsQuery,
    Types.GetCustomTitlesOfProjectsQueryVariables
>;
export const GqlOperationUpdateProjectCustomTitleDocument = gql`
    mutation UpdateProjectCustomTitle($projectId: Int!, $title: String!) {
        updateProjectCustomTitle(projectId: $projectId, title: $title) {
            ok
        }
    }
`;
export type UpdateProjectCustomTitleMutationFn = Apollo.MutationFunction<
    Types.UpdateProjectCustomTitleMutation,
    Types.UpdateProjectCustomTitleMutationVariables
>;

/**
 * __useUpdateProjectCustomTitleMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCustomTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCustomTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCustomTitleMutation, { data, loading, error }] = useUpdateProjectCustomTitleMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProjectCustomTitleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateProjectCustomTitleMutation,
        Types.UpdateProjectCustomTitleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateProjectCustomTitleMutation,
        Types.UpdateProjectCustomTitleMutationVariables
    >(GqlOperationUpdateProjectCustomTitleDocument, options);
}
export type UpdateProjectCustomTitleMutationHookResult = ReturnType<
    typeof useUpdateProjectCustomTitleMutation
>;
export type UpdateProjectCustomTitleMutationResult =
    Apollo.MutationResult<Types.UpdateProjectCustomTitleMutation>;
export type UpdateProjectCustomTitleMutationOptions =
    Apollo.BaseMutationOptions<
        Types.UpdateProjectCustomTitleMutation,
        Types.UpdateProjectCustomTitleMutationVariables
    >;
export const GqlOperationGetAllFoldersCounterDocument = gql`
    query GetAllFoldersCounter($searchTags: [Int!]!) {
        getAllFolderCounts(searchTags: $searchTags) {
            folderCounts
        }
    }
`;

/**
 * __useGetAllFoldersCounterQuery__
 *
 * To run a query within a React component, call `useGetAllFoldersCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFoldersCounterQuery({
 *   variables: {
 *      searchTags: // value for 'searchTags'
 *   },
 * });
 */
export function useGetAllFoldersCounterQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetAllFoldersCounterQuery,
        Types.GetAllFoldersCounterQueryVariables
    > &
        (
            | {
                  variables: Types.GetAllFoldersCounterQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetAllFoldersCounterQuery,
        Types.GetAllFoldersCounterQueryVariables
    >(GqlOperationGetAllFoldersCounterDocument, options);
}
export function useGetAllFoldersCounterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetAllFoldersCounterQuery,
        Types.GetAllFoldersCounterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetAllFoldersCounterQuery,
        Types.GetAllFoldersCounterQueryVariables
    >(GqlOperationGetAllFoldersCounterDocument, options);
}
export function useGetAllFoldersCounterSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetAllFoldersCounterQuery,
              Types.GetAllFoldersCounterQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetAllFoldersCounterQuery,
        Types.GetAllFoldersCounterQueryVariables
    >(GqlOperationGetAllFoldersCounterDocument, options);
}
export type GetAllFoldersCounterQueryHookResult = ReturnType<
    typeof useGetAllFoldersCounterQuery
>;
export type GetAllFoldersCounterLazyQueryHookResult = ReturnType<
    typeof useGetAllFoldersCounterLazyQuery
>;
export type GetAllFoldersCounterSuspenseQueryHookResult = ReturnType<
    typeof useGetAllFoldersCounterSuspenseQuery
>;
export type GetAllFoldersCounterQueryResult = Apollo.QueryResult<
    Types.GetAllFoldersCounterQuery,
    Types.GetAllFoldersCounterQueryVariables
>;
export const GqlOperationMoveProjectToFolderDocument = gql`
    mutation MoveProjectToFolder(
        $projectId: Int!
        $folder: FolderTag!
        $disqualificationReason: String
        $disqualificationExtraInfo: String
    ) {
        moveProject(
            projectId: $projectId
            folder: $folder
            disqualificationReason: $disqualificationReason
            disqualificationExtraInfo: $disqualificationExtraInfo
        ) {
            ok
        }
    }
`;
export type MoveProjectToFolderMutationFn = Apollo.MutationFunction<
    Types.MoveProjectToFolderMutation,
    Types.MoveProjectToFolderMutationVariables
>;

/**
 * __useMoveProjectToFolderMutation__
 *
 * To run a mutation, you first call `useMoveProjectToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectToFolderMutation, { data, loading, error }] = useMoveProjectToFolderMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      folder: // value for 'folder'
 *      disqualificationReason: // value for 'disqualificationReason'
 *      disqualificationExtraInfo: // value for 'disqualificationExtraInfo'
 *   },
 * });
 */
export function useMoveProjectToFolderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.MoveProjectToFolderMutation,
        Types.MoveProjectToFolderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.MoveProjectToFolderMutation,
        Types.MoveProjectToFolderMutationVariables
    >(GqlOperationMoveProjectToFolderDocument, options);
}
export type MoveProjectToFolderMutationHookResult = ReturnType<
    typeof useMoveProjectToFolderMutation
>;
export type MoveProjectToFolderMutationResult =
    Apollo.MutationResult<Types.MoveProjectToFolderMutation>;
export type MoveProjectToFolderMutationOptions = Apollo.BaseMutationOptions<
    Types.MoveProjectToFolderMutation,
    Types.MoveProjectToFolderMutationVariables
>;
export const GqlOperationGetProjectDataForOutreachDocument = gql`
    query GetProjectDataForOutreach($projectId: Int!) {
        getProject(projectId: $projectId) {
            mergedDocument {
                ... on ArticleDocumentQL {
                    images {
                        url
                        sourceUrl
                        sourceDomain
                        sourceTitle
                        qualityScore
                    }
                }
                ... on TenderDocumentQL {
                    images {
                        url
                        sourceUrl
                        sourceDomain
                        sourceTitle
                        qualityScore
                    }
                }
            }
        }
    }
`;

/**
 * __useGetProjectDataForOutreachQuery__
 *
 * To run a query within a React component, call `useGetProjectDataForOutreachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataForOutreachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataForOutreachQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectDataForOutreachQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetProjectDataForOutreachQuery,
        Types.GetProjectDataForOutreachQueryVariables
    > &
        (
            | {
                  variables: Types.GetProjectDataForOutreachQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetProjectDataForOutreachQuery,
        Types.GetProjectDataForOutreachQueryVariables
    >(GqlOperationGetProjectDataForOutreachDocument, options);
}
export function useGetProjectDataForOutreachLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetProjectDataForOutreachQuery,
        Types.GetProjectDataForOutreachQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetProjectDataForOutreachQuery,
        Types.GetProjectDataForOutreachQueryVariables
    >(GqlOperationGetProjectDataForOutreachDocument, options);
}
export function useGetProjectDataForOutreachSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetProjectDataForOutreachQuery,
              Types.GetProjectDataForOutreachQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetProjectDataForOutreachQuery,
        Types.GetProjectDataForOutreachQueryVariables
    >(GqlOperationGetProjectDataForOutreachDocument, options);
}
export type GetProjectDataForOutreachQueryHookResult = ReturnType<
    typeof useGetProjectDataForOutreachQuery
>;
export type GetProjectDataForOutreachLazyQueryHookResult = ReturnType<
    typeof useGetProjectDataForOutreachLazyQuery
>;
export type GetProjectDataForOutreachSuspenseQueryHookResult = ReturnType<
    typeof useGetProjectDataForOutreachSuspenseQuery
>;
export type GetProjectDataForOutreachQueryResult = Apollo.QueryResult<
    Types.GetProjectDataForOutreachQuery,
    Types.GetProjectDataForOutreachQueryVariables
>;
export const GqlOperationGetProjectsGeneratedDataDocument = gql`
    query GetProjectsGeneratedData($dgrpIds: [String!]!, $language: Language!) {
        getGeneratedDataOfDgrps(dgrpIds: $dgrpIds, language: $language) {
            ... on GeneratedDGRPDataQL {
                dgrpId
                language
                summary
                title
            }
        }
    }
`;

/**
 * __useGetProjectsGeneratedDataQuery__
 *
 * To run a query within a React component, call `useGetProjectsGeneratedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsGeneratedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsGeneratedDataQuery({
 *   variables: {
 *      dgrpIds: // value for 'dgrpIds'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetProjectsGeneratedDataQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetProjectsGeneratedDataQuery,
        Types.GetProjectsGeneratedDataQueryVariables
    > &
        (
            | {
                  variables: Types.GetProjectsGeneratedDataQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetProjectsGeneratedDataQuery,
        Types.GetProjectsGeneratedDataQueryVariables
    >(GqlOperationGetProjectsGeneratedDataDocument, options);
}
export function useGetProjectsGeneratedDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetProjectsGeneratedDataQuery,
        Types.GetProjectsGeneratedDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetProjectsGeneratedDataQuery,
        Types.GetProjectsGeneratedDataQueryVariables
    >(GqlOperationGetProjectsGeneratedDataDocument, options);
}
export function useGetProjectsGeneratedDataSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetProjectsGeneratedDataQuery,
              Types.GetProjectsGeneratedDataQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetProjectsGeneratedDataQuery,
        Types.GetProjectsGeneratedDataQueryVariables
    >(GqlOperationGetProjectsGeneratedDataDocument, options);
}
export type GetProjectsGeneratedDataQueryHookResult = ReturnType<
    typeof useGetProjectsGeneratedDataQuery
>;
export type GetProjectsGeneratedDataLazyQueryHookResult = ReturnType<
    typeof useGetProjectsGeneratedDataLazyQuery
>;
export type GetProjectsGeneratedDataSuspenseQueryHookResult = ReturnType<
    typeof useGetProjectsGeneratedDataSuspenseQuery
>;
export type GetProjectsGeneratedDataQueryResult = Apollo.QueryResult<
    Types.GetProjectsGeneratedDataQuery,
    Types.GetProjectsGeneratedDataQueryVariables
>;
export const GqlOperationWipeInboxedSearchProjectsDocument = gql`
    mutation WipeInboxedSearchProjects($searchId: Int!) {
        wipeInboxedProjectsOfSearch(searchId: $searchId) {
            ok
        }
    }
`;
export type WipeInboxedSearchProjectsMutationFn = Apollo.MutationFunction<
    Types.WipeInboxedSearchProjectsMutation,
    Types.WipeInboxedSearchProjectsMutationVariables
>;

/**
 * __useWipeInboxedSearchProjectsMutation__
 *
 * To run a mutation, you first call `useWipeInboxedSearchProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWipeInboxedSearchProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wipeInboxedSearchProjectsMutation, { data, loading, error }] = useWipeInboxedSearchProjectsMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useWipeInboxedSearchProjectsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.WipeInboxedSearchProjectsMutation,
        Types.WipeInboxedSearchProjectsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.WipeInboxedSearchProjectsMutation,
        Types.WipeInboxedSearchProjectsMutationVariables
    >(GqlOperationWipeInboxedSearchProjectsDocument, options);
}
export type WipeInboxedSearchProjectsMutationHookResult = ReturnType<
    typeof useWipeInboxedSearchProjectsMutation
>;
export type WipeInboxedSearchProjectsMutationResult =
    Apollo.MutationResult<Types.WipeInboxedSearchProjectsMutation>;
export type WipeInboxedSearchProjectsMutationOptions =
    Apollo.BaseMutationOptions<
        Types.WipeInboxedSearchProjectsMutation,
        Types.WipeInboxedSearchProjectsMutationVariables
    >;
