import { type FC, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { LinkClickWarningContainer } from './app-features/external-link/presentation/link-click-warning.container';
import { history } from './history.utils';
import { ImpersonationHeaderContainer } from './presentation/modules/impersonation/impersonation-header.container';
import { MainComponent } from './presentation/modules/main/main.component';
import { ToasterComponent } from './presentation/modules/toaster/toaster.component';
import { ErrorBoundary } from './presentation/shared/error-boundary/error-boundary.component';
import { GlobalStyles } from './resources/styles/global-styles';
import { getRoot } from './utils/element.utils';
import { ApolloProvider } from '@apollo/client';
import { ApolloClientService } from './data/api/graphql/apollo-client';

export const App: FC = () => {
    const theme = createTheme({
        palette: {
            mode: 'light',
        },
        components: {
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            /* Changing the target container for Portal-related elements in the tooltip so that they are injected under the main app wrapper to make TailwindCss Work
             * https://mui.com/material-ui/integrations/interoperability/#tailwind-css
             */
            MuiTooltip: {
                defaultProps: {
                    componentsProps: {
                        popper: {
                            container: getRoot(),
                        },
                    },
                },
            },
            MuiDialog: {
                defaultProps: {
                    container: getRoot(),
                },
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 432,
                md: 767,
                lg: 1439,
                xl: 1920,
            },
        },
    });

    return (
        <Suspense fallback={<></>}>
            <GlobalStyles />
            <Router history={history}>
                <ApolloProvider client={ApolloClientService.client}>
                    <StyledEngineProvider injectFirst>
                        <MuiThemeProvider theme={theme}>
                            <EmotionThemeProvider theme={theme}>
                                <ErrorBoundary>
                                    <ImpersonationHeaderContainer />
                                    <MainComponent />
                                    <ToasterComponent />
                                    <LinkClickWarningContainer />
                                </ErrorBoundary>
                            </EmotionThemeProvider>
                        </MuiThemeProvider>
                    </StyledEngineProvider>
                </ApolloProvider>
            </Router>
        </Suspense>
    );
};
