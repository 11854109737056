import format from '@fragaria/address-formatter';

import { Address } from './address.model';

const getFormattedAddressArray = (address: Address): string[] => {
    const { postCode, city, state, countryCode, country, road, houseNumber } =
        address;

    return format.format(
        {
            houseNumber,
            road,
            city,
            postcode: postCode,
            state,
            country,
        },
        { countryCode, output: 'array' },
    ) as string[];
};

export const getStreetAddress = (
    road: string | undefined,
    houseNumber: string | undefined,
    countryCode: string,
): string => {
    const streetAddress = (
        format.format(
            {
                houseNumber,
                road,
            },
            {
                countryCode,
                output: 'string',
            },
        ) as string
    ).trim();
    // if no houseNumber or Road, the library adds the country code. Hence if it matches, we return empty string
    return streetAddress.toUpperCase() === countryCode.toUpperCase()
        ? ''
        : streetAddress;
};

export const getReadableAddress = (address: Address): string => {
    return getFormattedAddressArray(address).join(', ');
};

export const getAddressForPDF = (address: Address): string => {
    return getFormattedAddressArray(address).join('\n');
};
