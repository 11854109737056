import { AbortParams } from 'src/data/api/api-client';
import {
    FolderTag,
    Language as LanguageSdk,
} from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { ProjectGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { createLead, Lead } from 'src/domain/models/lead/lead.model';
import { ProjectAiGeneratedData } from 'src/domain/models/lead/project-ai-generated-data';
import { Language } from 'src/domain/models/locale/locale.model';
import { getEnumKeyByValue } from 'src/utils/enum.utils';

interface GetLeadsParams extends AbortParams {
    folderName: FolderTag;
    includeUnassigned: boolean;
    searchTags: number[];
    count: number;
    cursorDate: Date;
    cursorProjectId?: number;
    language: Language;
}
interface GetLeadByIdParams extends AbortParams {
    projectId: number;
    language: Language;
}

interface GetProjectsAiGeneratedDataParams extends AbortParams {
    dgrpIds: string[];
    language: Language;
}

interface CreateLeadAsInterestingParams extends AbortParams {
    docGroupsInfo: { id: string; collection: string }[];
}

interface WipeInboxedSearchProjectsParams extends Partial<AbortParams> {
    searchId: number;
}

interface MoveLeadParams extends AbortParams {
    projectId: number;
    folder: FolderTag;
    disqualificationReason?: string;
    disqualificationExtraInfo?: string;
}
export interface LeadsApi {
    getLeads: (params: GetLeadsParams) => Promise<Lead[]>;
    getLeadById: (params: GetLeadByIdParams) => Promise<Lead | undefined>;
    createLeadAsInteresting: (
        params: CreateLeadAsInterestingParams,
    ) => Promise<number>;
    moveLead: (params: MoveLeadParams) => Promise<boolean>;
    wipeInboxedSearchProjects: (
        params: WipeInboxedSearchProjectsParams,
    ) => Promise<boolean>;
}

export const createLeadsApi = (projectGqlSdk: ProjectGqlSdk): LeadsApi => {
    const getLeads = async (params: GetLeadsParams): Promise<Lead[]> => {
        const { language, signal, ...rest } = params;

        const { getProjectsOfFolder } = await projectGqlSdk.GetProjectsOfFolder(
            rest,
            {
                signal,
            },
        );

        const { projects } = getProjectsOfFolder;
        if (!projects.length) return [];

        const generatedProjectsData = await getProjectsAiGeneratedData({
            dgrpIds: projects.map(({ docGroupId }) => docGroupId),
            language: language,
            signal: signal,
        });

        const mappedProjects = projects.map((rawProject) =>
            createLead(rawProject, generatedProjectsData),
        );

        return mappedProjects;
    };

    const getLeadById = async (
        params: GetLeadByIdParams,
    ): Promise<Lead | undefined> => {
        const { language, signal } = params;

        const { getProject } = await projectGqlSdk.GetProjectById(params, {
            signal,
        });

        if (!getProject) return undefined;

        const generatedProjectsData = await getProjectsAiGeneratedData({
            dgrpIds: [getProject.docGroupId],
            language: language,
            signal: signal,
        });

        return createLead(getProject, generatedProjectsData);
    };

    const createLeadAsInteresting = async (
        params: CreateLeadAsInterestingParams,
    ): Promise<number> => {
        const { createProjectFromDocGroup } =
            await projectGqlSdk.CreateProjectFromDocGroup({
                ...params,
                folderTag: FolderTag.Interesting,
            });

        return (
            createProjectFromDocGroup.projectIds.length &&
            createProjectFromDocGroup.projectIds[0]
        );
    };

    const moveLead = async (params: MoveLeadParams) => {
        const { moveProject } = await projectGqlSdk.MoveProjectToFolder(params);
        return moveProject.ok;
    };

    const getProjectsAiGeneratedData = async (
        params: GetProjectsAiGeneratedDataParams,
    ): Promise<ProjectAiGeneratedData[]> => {
        const { language, dgrpIds, signal } = params;
        const { getGeneratedDataOfDgrps } =
            await projectGqlSdk.GetProjectsGeneratedData(
                {
                    language: LanguageToLanguageSdk[language],
                    dgrpIds,
                },
                {
                    signal,
                },
            );

        const data: ProjectAiGeneratedData[] = getGeneratedDataOfDgrps.map(
            (project) => ({
                language:
                    Language[
                        getEnumKeyByValue(Language, project.language) ?? 'En'
                    ],
                dgrpId: project.dgrpId,
                summary: project.summary,
                title: project.title,
            }),
        );

        return data;
    };

    const wipeInboxedSearchProjects = async (
        params: WipeInboxedSearchProjectsParams,
    ): Promise<boolean> => {
        const { searchId, signal } = params;

        const { wipeInboxedProjectsOfSearch } =
            await projectGqlSdk.WipeInboxedSearchProjects(
                { searchId },
                { signal },
            );

        return wipeInboxedProjectsOfSearch.ok;
    };

    return {
        getLeads,
        getLeadById,
        createLeadAsInteresting,
        moveLead,
        wipeInboxedSearchProjects,
    };
};

const LanguageToLanguageSdk: Record<Language, LanguageSdk> = {
    [Language.De]: LanguageSdk.De,
    [Language.En]: LanguageSdk.En,
};
