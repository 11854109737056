import { withFeatures } from 'src/utils/component.utils';
import { ReminderFlyout } from './reminder-flyout';

export const ReminderFlyoutContainer = withFeatures(ReminderFlyout)((f) => ({
    isOpened: f.remindersFeature.isFlyoutOpened,
    dataContext: f.remindersFeature.flyoutDataContext,
    assignToUsers: f.authFeature.assignableUsers,
    onCloseRequested: f.remindersFeature.closeReminderFlyout,
    onCreateReminderRequested: f.remindersFeature.createReminder,
    onUpdateReminderRequested: f.remindersFeature.updateReminder,
    markAsCompleted: f.remindersFeature.markAsCompleted,
    triggerPipelineDataRefresh:
        f.pipelineTableViewFeature.triggerPipelineDataRefreshAfterCellUpdate,
    triggerDashboardDataRefresh:
        f.dashboardTableViewFeature.requestDashboardDeals,
}));
