/**
 * Enum with all available feature flags.
 */
export enum FeatureFlag {
    placeholderFlag = 'placeholderFlag',
    contactExtractionGamePadUi = 'contact-extraction-game-pad-ui',
    autoDealImportWithWeblink = 'auto-deal-import-with-weblink',
    contactFinder = 'contact-finder',
    dealAttachment = 'deal-attachment',
    autoDealImportWithFile = 'auto-deal-import-with-file',
    searchNarratives = 'search-narratives',
    dealSummary = 'deal-summary',
    newSearchConfigurationTable = 'new-search-configuration-table',
    dealAIAttachments = 'deal-ai-attachments',
    notificationsManagement = 'notifications-management',
    newDashboardDealsTable = 'new-dashboard-deals-table',
}

/**
 * Type for a feature flag value.
 */
export type FeatureFlagValue =
    | string
    | number
    | boolean
    | {
          [key: string]: string | number | boolean;
      };

export interface NonAnonymousUserContext {
    isAnonymous: false;
    user_id: string;
    email: string;
    customData: {
        company_bid: number;
    };
}
