import * as Types from './graphql-sdk';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {
    context: { endpoint: '/apps/process/graphql' },
} as const;
export const ProjectIdPartFragmentDoc = gql`
    fragment ProjectIdPart on ProjectQL {
        projectId
    }
`;
export const LinkedFieldPartFragmentDoc = gql`
    fragment LinkedFieldPart on LinkedFieldQL {
        name
        type
    }
`;
export const ObjectivePartFragmentDoc = gql`
    fragment ObjectivePart on ObjectiveQL {
        criteria
        id
        state
        status
        linkedField {
            ...LinkedFieldPart
        }
        type
        order
    }
    ${LinkedFieldPartFragmentDoc}
`;
export const ReminderPartFragmentDoc = gql`
    fragment ReminderPart on ReminderQL {
        id
        assignee
        description
        dueDate
        createdDate
        updatedDate
        completedDate
        creator
        target {
            id
            type
            title
        }
    }
`;
export const DealAlertPartFragmentDoc = gql`
    fragment DealAlertPart on DealAlertQL {
        type
        currentUserRelated
    }
`;
export const ActivityLogPartFragmentDoc = gql`
    fragment ActivityLogPart on ActivityLogQL {
        id
        companyBid
        createdDate
        updatedDate
        userId
        type
        notes
        dealId
        contactId
        relevantCompanyId
        subType
        customType
    }
`;
export const DealPartFragmentDoc = gql`
    fragment DealPart on DealQL {
        id
        title
        assignee
        value {
            currency
            value
        }
        state
        project {
            ...ProjectIdPart
        }
        pipeline {
            id
            name
        }
        notes
        currentObjectives {
            ...ObjectivePart
        }
        currentStage {
            id
        }
        stageUpdatedDate
        createdDate
        completedDate
        exportedDate {
            email
            csv
        }
        reminders {
            ...ReminderPart
        }
        pausedUntil
        alert {
            ...DealAlertPart
        }
        activityLog {
            ...ActivityLogPart
        }
        summary
    }
    ${ProjectIdPartFragmentDoc}
    ${ObjectivePartFragmentDoc}
    ${ReminderPartFragmentDoc}
    ${DealAlertPartFragmentDoc}
    ${ActivityLogPartFragmentDoc}
`;
export const DealAnnualSalesPeriodFragmentDoc = gql`
    fragment DealAnnualSalesPeriod on AnnualSalesPeriodQL {
        year
        frequency
    }
`;
export const DealQuarterlySalesPeriodFragmentDoc = gql`
    fragment DealQuarterlySalesPeriod on QuarterlySalesPeriodQL {
        year
        quarter
        frequency
    }
`;
export const PipelineStatisticsDealPartFragmentDoc = gql`
    fragment PipelineStatisticsDealPart on DealQL {
        id
        title
        assignee
        value {
            currency
            value
        }
        currentStage {
            id
        }
        exportedDate {
            email
            csv
        }
        createdDate
        completedDate
        state
        pausedUntil
        expectedCurrentStageConversionDate
        expectedSalesPeriod {
            ...DealAnnualSalesPeriod
            ...DealQuarterlySalesPeriod
        }
        alert {
            ...DealAlertPart
        }
        project {
            ...ProjectIdPart
        }
    }
    ${DealAnnualSalesPeriodFragmentDoc}
    ${DealQuarterlySalesPeriodFragmentDoc}
    ${DealAlertPartFragmentDoc}
    ${ProjectIdPartFragmentDoc}
`;
export const MinimalDealPartFragmentDoc = gql`
    fragment MinimalDealPart on DealQL {
        ...PipelineStatisticsDealPart
        pipeline {
            name
            id
        }
    }
    ${PipelineStatisticsDealPartFragmentDoc}
`;
export const PipelineDealPartFragmentDoc = gql`
    fragment PipelineDealPart on DealQL {
        id
        alert {
            ...DealAlertPart
        }
        pausedUntil
        title
        currentStage {
            id
        }
        stageUpdatedDate
        assignee
        outreachLogStats {
            count
            lastActivityDate
        }
    }
    ${DealAlertPartFragmentDoc}
`;
export const CustomFieldFragmentDoc = gql`
    fragment CustomField on CustomFieldQL {
        enrichmentDetail {
            id
            source
        }
        acceptedValues
        defaultValue
        label
        name
        type
        value
        order
        isTag
        isVisibleInDealPage
    }
`;
export const DealContactPartFragmentDoc = gql`
    fragment DealContactPart on ContactQL {
        id
        firstName
        lastName
        role
        notes
        customFields {
            ...CustomField
        }
        extracted
        isKey
        relevantCompany {
            id
            name
        }
        createdDate
        parentDeals {
            id
        }
    }
    ${CustomFieldFragmentDoc}
`;
export const GoogleEnrichmentPartFragmentDoc = gql`
    fragment GoogleEnrichmentPart on PlaceDetailsQL {
        name
        type
        formattedAddress
        id
        internationalPhoneNumber
        website
        email
        addressDetail {
            city
            country
            countryCode
            postalCode
            street
            streetNumber
        }
    }
`;
export const DealCompanyPartFragmentDoc = gql`
    fragment DealCompanyPart on RelevantCompanyQL {
        id
        name
        role
        notes
        customFields {
            ...CustomField
        }
        contacts {
            ...DealContactPart
        }
        extracted
        isKey
        createdDate
        parentDeals {
            id
        }
        placeDetails {
            ...GoogleEnrichmentPart
        }
    }
    ${CustomFieldFragmentDoc}
    ${DealContactPartFragmentDoc}
    ${GoogleEnrichmentPartFragmentDoc}
`;
export const ValueCurrencyPartFragmentDoc = gql`
    fragment ValueCurrencyPart on ValueCurrencyQL {
        currency
        value
    }
`;
export const PipelineStagesDataConfigFragmentDoc = gql`
    fragment PipelineStagesDataConfig on PipelineStageConfigQL {
        id
        name
        order
        targetConversionRate
        defaultObjectives {
            criteria
            linkedField {
                name
                type
            }
        }
    }
`;
export const PipelineStagesConfigFragmentDoc = gql`
    fragment PipelineStagesConfig on PipelineStagesConfigQL {
        initialStage
        lastStage
        stages {
            ...PipelineStagesDataConfig
        }
    }
    ${PipelineStagesDataConfigFragmentDoc}
`;
export const PipelineConfigPartFragmentDoc = gql`
    fragment PipelineConfigPart on PipelineConfigQL {
        averageDealValue {
            ...ValueCurrencyPart
        }
        revenueGoal {
            ...ValueCurrencyPart
        }
        timePeriodStart
        stages {
            ...PipelineStagesConfig
        }
        goalsEnabled
    }
    ${ValueCurrencyPartFragmentDoc}
    ${PipelineStagesConfigFragmentDoc}
`;
export const PipelineStageStatisticsPartFragmentDoc = gql`
    fragment PipelineStageStatisticsPart on PipelineStageStatsQL {
        atRiskDeals {
            numberOfDeals
            totalDealValue
        }
        healthyDeals {
            numberOfDeals
            totalDealValue
        }
        inStageDeals {
            numberOfDeals
            totalDealValue
        }
        markedDoneDeals {
            numberOfDeals
            totalDealValue
        }
        disqualifiedDeals {
            numberOfDeals
            totalDealValue
        }
        progressedDeals {
            numberOfDeals
            totalDealValue
        }
        id
        observedConversionRate
        goal
        targetConversionRate
    }
`;
export const PipelineStatisticsPartFragmentDoc = gql`
    fragment PipelineStatisticsPart on PipelineStatsQL {
        stages {
            ...PipelineStageStatisticsPart
        }
    }
    ${PipelineStageStatisticsPartFragmentDoc}
`;
export const PipelinePartFragmentDoc = gql`
    fragment PipelinePart on PipelineQL {
        id
        name
        config {
            ...PipelineConfigPart
        }
        statistics {
            ...PipelineStatisticsPart
        }
    }
    ${PipelineConfigPartFragmentDoc}
    ${PipelineStatisticsPartFragmentDoc}
`;
export const PipelineUpdateNamePartFragmentDoc = gql`
    fragment PipelineUpdateNamePart on PipelineQL {
        id
        name
    }
`;
export const IntegrationPartFragmentDoc = gql`
    fragment IntegrationPart on IntegrationQL {
        id
        name
        integrationType
        provider
        driver
        mapper
        createdDate
        updatedDate
    }
`;
export const CrmStatePartFragmentDoc = gql`
    fragment CrmStatePart on CrmQL {
        state
        remoteUrl
        exportedDate
    }
`;
export const DealCommentFragmentDoc = gql`
    fragment DealComment on CommentQL {
        id
        creatorBid
        content
        createdDate
        updatedDate
        parentId
    }
`;
export const CustomFieldConfigFragmentDoc = gql`
    fragment CustomFieldConfig on CustomFieldConfigQL {
        acceptedValues
        defaultValue
        label
        name
        type
        order
        isTag
        isVisibleInDealPage
    }
`;
export const MentionDealPartFragmentDoc = gql`
    fragment MentionDealPart on DealQL {
        id
        title
    }
`;
export const MentionPartFragmentDoc = gql`
    fragment MentionPart on MentionQL {
        commentId
        creatorBid
        updatedDate
        deal {
            ...MentionDealPart
        }
        isRead
    }
    ${MentionDealPartFragmentDoc}
`;
export const RolesPartFragmentDoc = gql`
    fragment RolesPart on RolesConfig {
        contacts {
            name
        }
        relevantCompanies {
            name
        }
    }
`;
export const PlacePartFragmentDoc = gql`
    fragment PlacePart on PlaceQL {
        id
        name
    }
`;
export const DynamicFilterMetricsPartFragmentDoc = gql`
    fragment DynamicFilterMetricsPart on DynamicFilterMetricsQL {
        count
        dynamicFilter
    }
`;
export const ValueMilestoneFragmentDoc = gql`
    fragment ValueMilestone on ValueMilestoneQL {
        id
        type
        pipeline
    }
`;
export const AchievementDetailsPartFragmentDoc = gql`
    fragment AchievementDetailsPart on UserSingleAchievementDetailsQL {
        achievementState
        achievementStateUpdateDate
    }
`;
export const UserAchievementsFragmentDoc = gql`
    fragment UserAchievements on UserAchievementsDetailsQL {
        resolveANextStep {
            ...AchievementDetailsPart
        }
        planANextStep {
            ...AchievementDetailsPart
        }
        addContactsEmailOrPhone {
            ...AchievementDetailsPart
        }
        askArticleWizard {
            ...AchievementDetailsPart
        }
        assignContactDealRole {
            ...AchievementDetailsPart
        }
        bookedFirstMeeting {
            ...AchievementDetailsPart
        }
        outreachByEmail {
            ...AchievementDetailsPart
        }
        disqualifyADeal {
            ...AchievementDetailsPart
        }
        createYourFirstContact {
            ...AchievementDetailsPart
        }
        createYourFirstDeal {
            ...AchievementDetailsPart
        }
        outreachByPhone {
            ...AchievementDetailsPart
        }
        pauseADeal {
            ...AchievementDetailsPart
        }
    }
    ${AchievementDetailsPartFragmentDoc}
`;
export const ColleagueAchievementsFragmentDoc = gql`
    fragment ColleagueAchievements on ColleagueAchievementsQL {
        defaultPipelineId
        userId
        optInAchievements
        achievements {
            ...UserAchievements
        }
    }
    ${UserAchievementsFragmentDoc}
`;
export const PipelineStatisticsFragmentDoc = gql`
    fragment PipelineStatistics on PipelineStatisticsQL {
        countContacts
        countDeals
        countDealsWon
        countLeadsQualified
        countMeetingsBooked
    }
`;
export const ExtractedContactPartFragmentDoc = gql`
    fragment ExtractedContactPart on ExtractedContactQL {
        firstName
        lastName
        companyName
        legitimateInterestText
        role
        sourceUrls
    }
`;
export const ExtractedCompanyPartFragmentDoc = gql`
    fragment ExtractedCompanyPart on ExtractedCompanyQL {
        legitimateInterestText
        name
        role
        sourceUrls
    }
`;
export const ContactExtractionPartFragmentDoc = gql`
    fragment ContactExtractionPart on ContactExtractionResultQL {
        status
        extractionType
        extractedContacts {
            ...ExtractedContactPart
        }
        extractedCompanies {
            ...ExtractedCompanyPart
        }
    }
    ${ExtractedContactPartFragmentDoc}
    ${ExtractedCompanyPartFragmentDoc}
`;
export const ContactExtractionResultFragmentDoc = gql`
    fragment ContactExtractionResult on CombinedContactExtractionResultQL {
        projectContextResult {
            ...ContactExtractionPart
        }
        webSearchResult {
            ...ContactExtractionPart
        }
    }
    ${ContactExtractionPartFragmentDoc}
`;
export const LinkedinProfileFragmentDoc = gql`
    fragment LinkedinProfile on LinkedInProfileQL {
        title
        location
        linkedinUsername
        headline
        hasPhone
        hasEmail
        fullName
        companyCountry
        companyName
    }
`;
export const LinkedinSearchMetaFragmentDoc = gql`
    fragment LinkedinSearchMeta on SearchMetaDataQL {
        page
        pageSize
        total
    }
`;
export const LinkedinProfileContactInfoFragmentDoc = gql`
    fragment LinkedinProfileContactInfo on LinkedInProfileContactInformationQL {
        personalEmail
        phone
        workEmail
    }
`;
export const DealContentMetadataFragmentDoc = gql`
    fragment DealContentMetadata on DealContentMetadataQL {
        id
        type
        source
        name
        publishedDate
        hasContent
        creationSource
    }
`;
export const DealFileAttachmentDownloadUrlFragmentDoc = gql`
    fragment DealFileAttachmentDownloadUrl on StringQL {
        value
    }
`;
export const InstantMessageFragmentDoc = gql`
    fragment InstantMessage on InstantMessageQL {
        userTargetId
        userSourceId
        type
        content {
            ... on InstantMessageUserDealImportCompletedQL {
                dealId
                title
            }
        }
    }
`;
export const GqlOperationMoveDealToStageDocument = gql`
    mutation MoveDealToStage($dealId: String!, $stageId: String!) {
        moveToStage(dealId: $dealId, stageId: $stageId) {
            ok
        }
    }
`;
export type MoveDealToStageMutationFn = Apollo.MutationFunction<
    Types.MoveDealToStageMutation,
    Types.MoveDealToStageMutationVariables
>;

/**
 * __useMoveDealToStageMutation__
 *
 * To run a mutation, you first call `useMoveDealToStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDealToStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDealToStageMutation, { data, loading, error }] = useMoveDealToStageMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useMoveDealToStageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.MoveDealToStageMutation,
        Types.MoveDealToStageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.MoveDealToStageMutation,
        Types.MoveDealToStageMutationVariables
    >(GqlOperationMoveDealToStageDocument, options);
}
export type MoveDealToStageMutationHookResult = ReturnType<
    typeof useMoveDealToStageMutation
>;
export type MoveDealToStageMutationResult =
    Apollo.MutationResult<Types.MoveDealToStageMutation>;
export type MoveDealToStageMutationOptions = Apollo.BaseMutationOptions<
    Types.MoveDealToStageMutation,
    Types.MoveDealToStageMutationVariables
>;
export const GqlOperationAssignUserToDealDocument = gql`
    mutation AssignUserToDeal($dealId: String!, $update: DealUpdateInputQL!) {
        updateDeal(dealId: $dealId, update: $update) {
            ok
        }
    }
`;
export type AssignUserToDealMutationFn = Apollo.MutationFunction<
    Types.AssignUserToDealMutation,
    Types.AssignUserToDealMutationVariables
>;

/**
 * __useAssignUserToDealMutation__
 *
 * To run a mutation, you first call `useAssignUserToDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserToDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserToDealMutation, { data, loading, error }] = useAssignUserToDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useAssignUserToDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.AssignUserToDealMutation,
        Types.AssignUserToDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.AssignUserToDealMutation,
        Types.AssignUserToDealMutationVariables
    >(GqlOperationAssignUserToDealDocument, options);
}
export type AssignUserToDealMutationHookResult = ReturnType<
    typeof useAssignUserToDealMutation
>;
export type AssignUserToDealMutationResult =
    Apollo.MutationResult<Types.AssignUserToDealMutation>;
export type AssignUserToDealMutationOptions = Apollo.BaseMutationOptions<
    Types.AssignUserToDealMutation,
    Types.AssignUserToDealMutationVariables
>;
export const GqlOperationDisqualifyDealDocument = gql`
    mutation DisqualifyDeal($dealId: String!, $reason: String = null) {
        disqualifyDeal(dealId: $dealId, reason: $reason) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export type DisqualifyDealMutationFn = Apollo.MutationFunction<
    Types.DisqualifyDealMutation,
    Types.DisqualifyDealMutationVariables
>;

/**
 * __useDisqualifyDealMutation__
 *
 * To run a mutation, you first call `useDisqualifyDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisqualifyDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disqualifyDealMutation, { data, loading, error }] = useDisqualifyDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDisqualifyDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DisqualifyDealMutation,
        Types.DisqualifyDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DisqualifyDealMutation,
        Types.DisqualifyDealMutationVariables
    >(GqlOperationDisqualifyDealDocument, options);
}
export type DisqualifyDealMutationHookResult = ReturnType<
    typeof useDisqualifyDealMutation
>;
export type DisqualifyDealMutationResult =
    Apollo.MutationResult<Types.DisqualifyDealMutation>;
export type DisqualifyDealMutationOptions = Apollo.BaseMutationOptions<
    Types.DisqualifyDealMutation,
    Types.DisqualifyDealMutationVariables
>;
export const GqlOperationRestoreDealDocument = gql`
    mutation RestoreDeal($dealId: String!) {
        restoreDeal(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export type RestoreDealMutationFn = Apollo.MutationFunction<
    Types.RestoreDealMutation,
    Types.RestoreDealMutationVariables
>;

/**
 * __useRestoreDealMutation__
 *
 * To run a mutation, you first call `useRestoreDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDealMutation, { data, loading, error }] = useRestoreDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useRestoreDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.RestoreDealMutation,
        Types.RestoreDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.RestoreDealMutation,
        Types.RestoreDealMutationVariables
    >(GqlOperationRestoreDealDocument, options);
}
export type RestoreDealMutationHookResult = ReturnType<
    typeof useRestoreDealMutation
>;
export type RestoreDealMutationResult =
    Apollo.MutationResult<Types.RestoreDealMutation>;
export type RestoreDealMutationOptions = Apollo.BaseMutationOptions<
    Types.RestoreDealMutation,
    Types.RestoreDealMutationVariables
>;
export const GqlOperationPauseDealDocument = gql`
    mutation PauseDeal($dealId: String!, $pausedUntil: DateTime!) {
        pauseDeal(dealId: $dealId, pausedUntil: $pausedUntil) {
            ok
        }
    }
`;
export type PauseDealMutationFn = Apollo.MutationFunction<
    Types.PauseDealMutation,
    Types.PauseDealMutationVariables
>;

/**
 * __usePauseDealMutation__
 *
 * To run a mutation, you first call `usePauseDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseDealMutation, { data, loading, error }] = usePauseDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      pausedUntil: // value for 'pausedUntil'
 *   },
 * });
 */
export function usePauseDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.PauseDealMutation,
        Types.PauseDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.PauseDealMutation,
        Types.PauseDealMutationVariables
    >(GqlOperationPauseDealDocument, options);
}
export type PauseDealMutationHookResult = ReturnType<
    typeof usePauseDealMutation
>;
export type PauseDealMutationResult =
    Apollo.MutationResult<Types.PauseDealMutation>;
export type PauseDealMutationOptions = Apollo.BaseMutationOptions<
    Types.PauseDealMutation,
    Types.PauseDealMutationVariables
>;
export const GqlOperationUnPauseDealDocument = gql`
    mutation UnPauseDeal($dealId: String!) {
        unpauseDeal(dealId: $dealId) {
            ok
        }
    }
`;
export type UnPauseDealMutationFn = Apollo.MutationFunction<
    Types.UnPauseDealMutation,
    Types.UnPauseDealMutationVariables
>;

/**
 * __useUnPauseDealMutation__
 *
 * To run a mutation, you first call `useUnPauseDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnPauseDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unPauseDealMutation, { data, loading, error }] = useUnPauseDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useUnPauseDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UnPauseDealMutation,
        Types.UnPauseDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UnPauseDealMutation,
        Types.UnPauseDealMutationVariables
    >(GqlOperationUnPauseDealDocument, options);
}
export type UnPauseDealMutationHookResult = ReturnType<
    typeof useUnPauseDealMutation
>;
export type UnPauseDealMutationResult =
    Apollo.MutationResult<Types.UnPauseDealMutation>;
export type UnPauseDealMutationOptions = Apollo.BaseMutationOptions<
    Types.UnPauseDealMutation,
    Types.UnPauseDealMutationVariables
>;
export const GqlOperationMarkDealAsDoneDocument = gql`
    mutation MarkDealAsDone($dealId: String!) {
        markDealAsDone(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export type MarkDealAsDoneMutationFn = Apollo.MutationFunction<
    Types.MarkDealAsDoneMutation,
    Types.MarkDealAsDoneMutationVariables
>;

/**
 * __useMarkDealAsDoneMutation__
 *
 * To run a mutation, you first call `useMarkDealAsDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDealAsDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDealAsDoneMutation, { data, loading, error }] = useMarkDealAsDoneMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useMarkDealAsDoneMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.MarkDealAsDoneMutation,
        Types.MarkDealAsDoneMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.MarkDealAsDoneMutation,
        Types.MarkDealAsDoneMutationVariables
    >(GqlOperationMarkDealAsDoneDocument, options);
}
export type MarkDealAsDoneMutationHookResult = ReturnType<
    typeof useMarkDealAsDoneMutation
>;
export type MarkDealAsDoneMutationResult =
    Apollo.MutationResult<Types.MarkDealAsDoneMutation>;
export type MarkDealAsDoneMutationOptions = Apollo.BaseMutationOptions<
    Types.MarkDealAsDoneMutation,
    Types.MarkDealAsDoneMutationVariables
>;
export const GqlOperationCreateNewDealMutationDocument = gql`
    mutation CreateNewDealMutation(
        $pipelineId: String!
        $projectId: String = null
        $title: String!
        $creationSource: DealCreationSource!
        $searchNames: [String!]
        $currentSearchName: String = null
        $projectDataFromCustomText: ProjectDataFromCustomTextQL = null
        $projectDataFromSearchResult: ProjectDataFromSearchResultQL = null
    ) {
        createDeal(
            pipelineId: $pipelineId
            projectId: $projectId
            title: $title
            creationSource: $creationSource
            searchNames: $searchNames
            currentSearchName: $currentSearchName
            projectDataFromCustomText: $projectDataFromCustomText
            projectDataFromSearchResult: $projectDataFromSearchResult
        ) {
            dealId
            ok
        }
    }
`;
export type CreateNewDealMutationMutationFn = Apollo.MutationFunction<
    Types.CreateNewDealMutationMutation,
    Types.CreateNewDealMutationMutationVariables
>;

/**
 * __useCreateNewDealMutationMutation__
 *
 * To run a mutation, you first call `useCreateNewDealMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewDealMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewDealMutationMutation, { data, loading, error }] = useCreateNewDealMutationMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *      creationSource: // value for 'creationSource'
 *      searchNames: // value for 'searchNames'
 *      currentSearchName: // value for 'currentSearchName'
 *      projectDataFromCustomText: // value for 'projectDataFromCustomText'
 *      projectDataFromSearchResult: // value for 'projectDataFromSearchResult'
 *   },
 * });
 */
export function useCreateNewDealMutationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateNewDealMutationMutation,
        Types.CreateNewDealMutationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateNewDealMutationMutation,
        Types.CreateNewDealMutationMutationVariables
    >(GqlOperationCreateNewDealMutationDocument, options);
}
export type CreateNewDealMutationMutationHookResult = ReturnType<
    typeof useCreateNewDealMutationMutation
>;
export type CreateNewDealMutationMutationResult =
    Apollo.MutationResult<Types.CreateNewDealMutationMutation>;
export type CreateNewDealMutationMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateNewDealMutationMutation,
    Types.CreateNewDealMutationMutationVariables
>;
export const GqlOperationUpdateDealInformationDocument = gql`
    mutation UpdateDealInformation(
        $dealId: String!
        $updateDeal: DealUpdateInputQL!
    ) {
        updateDeal(dealId: $dealId, update: $updateDeal) {
            ok
        }
    }
`;
export type UpdateDealInformationMutationFn = Apollo.MutationFunction<
    Types.UpdateDealInformationMutation,
    Types.UpdateDealInformationMutationVariables
>;

/**
 * __useUpdateDealInformationMutation__
 *
 * To run a mutation, you first call `useUpdateDealInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealInformationMutation, { data, loading, error }] = useUpdateDealInformationMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      updateDeal: // value for 'updateDeal'
 *   },
 * });
 */
export function useUpdateDealInformationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateDealInformationMutation,
        Types.UpdateDealInformationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateDealInformationMutation,
        Types.UpdateDealInformationMutationVariables
    >(GqlOperationUpdateDealInformationDocument, options);
}
export type UpdateDealInformationMutationHookResult = ReturnType<
    typeof useUpdateDealInformationMutation
>;
export type UpdateDealInformationMutationResult =
    Apollo.MutationResult<Types.UpdateDealInformationMutation>;
export type UpdateDealInformationMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateDealInformationMutation,
    Types.UpdateDealInformationMutationVariables
>;
export const GqlOperationUpdateObjectiveMutationDocument = gql`
    mutation UpdateObjectiveMutation(
        $objectiveId: String!
        $updateObjective: ObjectiveUpdateInputQL!
    ) {
        updateObjective(objectiveId: $objectiveId, update: $updateObjective) {
            ok
        }
    }
`;
export type UpdateObjectiveMutationMutationFn = Apollo.MutationFunction<
    Types.UpdateObjectiveMutationMutation,
    Types.UpdateObjectiveMutationMutationVariables
>;

/**
 * __useUpdateObjectiveMutationMutation__
 *
 * To run a mutation, you first call `useUpdateObjectiveMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectiveMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectiveMutationMutation, { data, loading, error }] = useUpdateObjectiveMutationMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      updateObjective: // value for 'updateObjective'
 *   },
 * });
 */
export function useUpdateObjectiveMutationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateObjectiveMutationMutation,
        Types.UpdateObjectiveMutationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateObjectiveMutationMutation,
        Types.UpdateObjectiveMutationMutationVariables
    >(GqlOperationUpdateObjectiveMutationDocument, options);
}
export type UpdateObjectiveMutationMutationHookResult = ReturnType<
    typeof useUpdateObjectiveMutationMutation
>;
export type UpdateObjectiveMutationMutationResult =
    Apollo.MutationResult<Types.UpdateObjectiveMutationMutation>;
export type UpdateObjectiveMutationMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateObjectiveMutationMutation,
    Types.UpdateObjectiveMutationMutationVariables
>;
export const GqlOperationGetAllDealsDocument = gql`
    query GetAllDeals($queryParams: QueryParamsQL!) {
        allDeals(queryParams: $queryParams) {
            ...MinimalDealPart
        }
    }
    ${MinimalDealPartFragmentDoc}
`;

/**
 * __useGetAllDealsQuery__
 *
 * To run a query within a React component, call `useGetAllDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDealsQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetAllDealsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetAllDealsQuery,
        Types.GetAllDealsQueryVariables
    > &
        (
            | { variables: Types.GetAllDealsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetAllDealsQuery,
        Types.GetAllDealsQueryVariables
    >(GqlOperationGetAllDealsDocument, options);
}
export function useGetAllDealsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetAllDealsQuery,
        Types.GetAllDealsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetAllDealsQuery,
        Types.GetAllDealsQueryVariables
    >(GqlOperationGetAllDealsDocument, options);
}
export function useGetAllDealsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetAllDealsQuery,
              Types.GetAllDealsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetAllDealsQuery,
        Types.GetAllDealsQueryVariables
    >(GqlOperationGetAllDealsDocument, options);
}
export type GetAllDealsQueryHookResult = ReturnType<typeof useGetAllDealsQuery>;
export type GetAllDealsLazyQueryHookResult = ReturnType<
    typeof useGetAllDealsLazyQuery
>;
export type GetAllDealsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllDealsSuspenseQuery
>;
export type GetAllDealsQueryResult = Apollo.QueryResult<
    Types.GetAllDealsQuery,
    Types.GetAllDealsQueryVariables
>;
export const GqlOperationGetDealDocument = gql`
    query GetDeal($dealId: String!) {
        deal(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;

/**
 * __useGetDealQuery__
 *
 * To run a query within a React component, call `useGetDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealQuery,
        Types.GetDealQueryVariables
    > &
        (
            | { variables: Types.GetDealQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.GetDealQuery, Types.GetDealQueryVariables>(
        GqlOperationGetDealDocument,
        options,
    );
}
export function useGetDealLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealQuery,
        Types.GetDealQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.GetDealQuery, Types.GetDealQueryVariables>(
        GqlOperationGetDealDocument,
        options,
    );
}
export function useGetDealSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealQuery,
              Types.GetDealQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealQuery,
        Types.GetDealQueryVariables
    >(GqlOperationGetDealDocument, options);
}
export type GetDealQueryHookResult = ReturnType<typeof useGetDealQuery>;
export type GetDealLazyQueryHookResult = ReturnType<typeof useGetDealLazyQuery>;
export type GetDealSuspenseQueryHookResult = ReturnType<
    typeof useGetDealSuspenseQuery
>;
export type GetDealQueryResult = Apollo.QueryResult<
    Types.GetDealQuery,
    Types.GetDealQueryVariables
>;
export const GqlOperationExportCsvDocument = gql`
    query ExportCSV($dealId: String!, $delimiter: String!) {
        deal(dealId: $dealId) {
            export {
                csv(delimiter: $delimiter) {
                    row
                    header
                }
            }
        }
    }
`;

/**
 * __useExportCsvQuery__
 *
 * To run a query within a React component, call `useExportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCsvQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      delimiter: // value for 'delimiter'
 *   },
 * });
 */
export function useExportCsvQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ExportCsvQuery,
        Types.ExportCsvQueryVariables
    > &
        (
            | { variables: Types.ExportCsvQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ExportCsvQuery, Types.ExportCsvQueryVariables>(
        GqlOperationExportCsvDocument,
        options,
    );
}
export function useExportCsvLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ExportCsvQuery,
        Types.ExportCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.ExportCsvQuery,
        Types.ExportCsvQueryVariables
    >(GqlOperationExportCsvDocument, options);
}
export function useExportCsvSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.ExportCsvQuery,
              Types.ExportCsvQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ExportCsvQuery,
        Types.ExportCsvQueryVariables
    >(GqlOperationExportCsvDocument, options);
}
export type ExportCsvQueryHookResult = ReturnType<typeof useExportCsvQuery>;
export type ExportCsvLazyQueryHookResult = ReturnType<
    typeof useExportCsvLazyQuery
>;
export type ExportCsvSuspenseQueryHookResult = ReturnType<
    typeof useExportCsvSuspenseQuery
>;
export type ExportCsvQueryResult = Apollo.QueryResult<
    Types.ExportCsvQuery,
    Types.ExportCsvQueryVariables
>;
export const GqlOperationExportBatchCsvDocument = gql`
    query ExportBatchCSV($queryParams: QueryParamsQL!, $delimiter: String!) {
        allDeals(queryParams: $queryParams) {
            id
            export {
                csv(delimiter: $delimiter) {
                    row
                    header
                }
            }
        }
    }
`;

/**
 * __useExportBatchCsvQuery__
 *
 * To run a query within a React component, call `useExportBatchCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportBatchCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportBatchCsvQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *      delimiter: // value for 'delimiter'
 *   },
 * });
 */
export function useExportBatchCsvQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ExportBatchCsvQuery,
        Types.ExportBatchCsvQueryVariables
    > &
        (
            | { variables: Types.ExportBatchCsvQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.ExportBatchCsvQuery,
        Types.ExportBatchCsvQueryVariables
    >(GqlOperationExportBatchCsvDocument, options);
}
export function useExportBatchCsvLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ExportBatchCsvQuery,
        Types.ExportBatchCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.ExportBatchCsvQuery,
        Types.ExportBatchCsvQueryVariables
    >(GqlOperationExportBatchCsvDocument, options);
}
export function useExportBatchCsvSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.ExportBatchCsvQuery,
              Types.ExportBatchCsvQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ExportBatchCsvQuery,
        Types.ExportBatchCsvQueryVariables
    >(GqlOperationExportBatchCsvDocument, options);
}
export type ExportBatchCsvQueryHookResult = ReturnType<
    typeof useExportBatchCsvQuery
>;
export type ExportBatchCsvLazyQueryHookResult = ReturnType<
    typeof useExportBatchCsvLazyQuery
>;
export type ExportBatchCsvSuspenseQueryHookResult = ReturnType<
    typeof useExportBatchCsvSuspenseQuery
>;
export type ExportBatchCsvQueryResult = Apollo.QueryResult<
    Types.ExportBatchCsvQuery,
    Types.ExportBatchCsvQueryVariables
>;
export const GqlOperationGetDealObjectivesDocument = gql`
    query GetDealObjectives($dealId: String!) {
        deal(dealId: $dealId) {
            currentObjectives {
                ...ObjectivePart
            }
        }
    }
    ${ObjectivePartFragmentDoc}
`;

/**
 * __useGetDealObjectivesQuery__
 *
 * To run a query within a React component, call `useGetDealObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealObjectivesQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealObjectivesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealObjectivesQuery,
        Types.GetDealObjectivesQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealObjectivesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealObjectivesQuery,
        Types.GetDealObjectivesQueryVariables
    >(GqlOperationGetDealObjectivesDocument, options);
}
export function useGetDealObjectivesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealObjectivesQuery,
        Types.GetDealObjectivesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealObjectivesQuery,
        Types.GetDealObjectivesQueryVariables
    >(GqlOperationGetDealObjectivesDocument, options);
}
export function useGetDealObjectivesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealObjectivesQuery,
              Types.GetDealObjectivesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealObjectivesQuery,
        Types.GetDealObjectivesQueryVariables
    >(GqlOperationGetDealObjectivesDocument, options);
}
export type GetDealObjectivesQueryHookResult = ReturnType<
    typeof useGetDealObjectivesQuery
>;
export type GetDealObjectivesLazyQueryHookResult = ReturnType<
    typeof useGetDealObjectivesLazyQuery
>;
export type GetDealObjectivesSuspenseQueryHookResult = ReturnType<
    typeof useGetDealObjectivesSuspenseQuery
>;
export type GetDealObjectivesQueryResult = Apollo.QueryResult<
    Types.GetDealObjectivesQuery,
    Types.GetDealObjectivesQueryVariables
>;
export const GqlOperationGetDealStageUpdateDateDocument = gql`
    query GetDealStageUpdateDate($dealId: String!) {
        deal(dealId: $dealId) {
            stageUpdatedDate
        }
    }
`;

/**
 * __useGetDealStageUpdateDateQuery__
 *
 * To run a query within a React component, call `useGetDealStageUpdateDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealStageUpdateDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealStageUpdateDateQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealStageUpdateDateQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealStageUpdateDateQuery,
        Types.GetDealStageUpdateDateQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealStageUpdateDateQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealStageUpdateDateQuery,
        Types.GetDealStageUpdateDateQueryVariables
    >(GqlOperationGetDealStageUpdateDateDocument, options);
}
export function useGetDealStageUpdateDateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealStageUpdateDateQuery,
        Types.GetDealStageUpdateDateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealStageUpdateDateQuery,
        Types.GetDealStageUpdateDateQueryVariables
    >(GqlOperationGetDealStageUpdateDateDocument, options);
}
export function useGetDealStageUpdateDateSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealStageUpdateDateQuery,
              Types.GetDealStageUpdateDateQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealStageUpdateDateQuery,
        Types.GetDealStageUpdateDateQueryVariables
    >(GqlOperationGetDealStageUpdateDateDocument, options);
}
export type GetDealStageUpdateDateQueryHookResult = ReturnType<
    typeof useGetDealStageUpdateDateQuery
>;
export type GetDealStageUpdateDateLazyQueryHookResult = ReturnType<
    typeof useGetDealStageUpdateDateLazyQuery
>;
export type GetDealStageUpdateDateSuspenseQueryHookResult = ReturnType<
    typeof useGetDealStageUpdateDateSuspenseQuery
>;
export type GetDealStageUpdateDateQueryResult = Apollo.QueryResult<
    Types.GetDealStageUpdateDateQuery,
    Types.GetDealStageUpdateDateQueryVariables
>;
export const GqlOperationGetDealContactsDocument = gql`
    query GetDealContacts($dealId: String!) {
        deal(dealId: $dealId) {
            relevantCompanies {
                ...DealCompanyPart
            }
            contacts {
                ...DealContactPart
            }
        }
    }
    ${DealCompanyPartFragmentDoc}
    ${DealContactPartFragmentDoc}
`;

/**
 * __useGetDealContactsQuery__
 *
 * To run a query within a React component, call `useGetDealContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealContactsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealContactsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealContactsQuery,
        Types.GetDealContactsQueryVariables
    > &
        (
            | { variables: Types.GetDealContactsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealContactsQuery,
        Types.GetDealContactsQueryVariables
    >(GqlOperationGetDealContactsDocument, options);
}
export function useGetDealContactsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealContactsQuery,
        Types.GetDealContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealContactsQuery,
        Types.GetDealContactsQueryVariables
    >(GqlOperationGetDealContactsDocument, options);
}
export function useGetDealContactsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealContactsQuery,
              Types.GetDealContactsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealContactsQuery,
        Types.GetDealContactsQueryVariables
    >(GqlOperationGetDealContactsDocument, options);
}
export type GetDealContactsQueryHookResult = ReturnType<
    typeof useGetDealContactsQuery
>;
export type GetDealContactsLazyQueryHookResult = ReturnType<
    typeof useGetDealContactsLazyQuery
>;
export type GetDealContactsSuspenseQueryHookResult = ReturnType<
    typeof useGetDealContactsSuspenseQuery
>;
export type GetDealContactsQueryResult = Apollo.QueryResult<
    Types.GetDealContactsQuery,
    Types.GetDealContactsQueryVariables
>;
export const GqlOperationCreateCompanyDocument = gql`
    mutation CreateCompany(
        $dealId: String!
        $name: String!
        $role: String
        $customInput: RelevantCompanyUpdateCustomInputQL
        $notes: String
        $isKey: Boolean
        $createdFrom: String
    ) {
        createRelevantCompany(
            data: {
                name: $name
                role: $role
                customInput: $customInput
                notes: $notes
                isKey: $isKey
            }
            dealId: $dealId
            createdFrom: $createdFrom
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
    Types.CreateCompanyMutation,
    Types.CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      customInput: // value for 'customInput'
 *      notes: // value for 'notes'
 *      isKey: // value for 'isKey'
 *      createdFrom: // value for 'createdFrom'
 *   },
 * });
 */
export function useCreateCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateCompanyMutation,
        Types.CreateCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateCompanyMutation,
        Types.CreateCompanyMutationVariables
    >(GqlOperationCreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
    typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult =
    Apollo.MutationResult<Types.CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateCompanyMutation,
    Types.CreateCompanyMutationVariables
>;
export const GqlOperationUpdateCompanyDocument = gql`
    mutation UpdateCompany($companyId: String!, $name: String, $role: String) {
        updateRelevantCompany(
            relevantCompanyId: $companyId
            update: { name: $name, role: $role }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
    Types.UpdateCompanyMutation,
    Types.UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateCompanyMutation,
        Types.UpdateCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateCompanyMutation,
        Types.UpdateCompanyMutationVariables
    >(GqlOperationUpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<
    typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult =
    Apollo.MutationResult<Types.UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateCompanyMutation,
    Types.UpdateCompanyMutationVariables
>;
export const GqlOperationUpdateCompanyNoteDocument = gql`
    mutation UpdateCompanyNote($relevantCompanyId: String!, $notes: String!) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: { notes: $notes }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type UpdateCompanyNoteMutationFn = Apollo.MutationFunction<
    Types.UpdateCompanyNoteMutation,
    Types.UpdateCompanyNoteMutationVariables
>;

/**
 * __useUpdateCompanyNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyNoteMutation, { data, loading, error }] = useUpdateCompanyNoteMutation({
 *   variables: {
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCompanyNoteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateCompanyNoteMutation,
        Types.UpdateCompanyNoteMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateCompanyNoteMutation,
        Types.UpdateCompanyNoteMutationVariables
    >(GqlOperationUpdateCompanyNoteDocument, options);
}
export type UpdateCompanyNoteMutationHookResult = ReturnType<
    typeof useUpdateCompanyNoteMutation
>;
export type UpdateCompanyNoteMutationResult =
    Apollo.MutationResult<Types.UpdateCompanyNoteMutation>;
export type UpdateCompanyNoteMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateCompanyNoteMutation,
    Types.UpdateCompanyNoteMutationVariables
>;
export const GqlOperationUpdateCompanyRoleDocument = gql`
    mutation UpdateCompanyRole(
        $relevantCompanyId: String!
        $role: String!
        $isKey: Boolean!
    ) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: { role: $role, isKey: $isKey }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type UpdateCompanyRoleMutationFn = Apollo.MutationFunction<
    Types.UpdateCompanyRoleMutation,
    Types.UpdateCompanyRoleMutationVariables
>;

/**
 * __useUpdateCompanyRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRoleMutation, { data, loading, error }] = useUpdateCompanyRoleMutation({
 *   variables: {
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      role: // value for 'role'
 *      isKey: // value for 'isKey'
 *   },
 * });
 */
export function useUpdateCompanyRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateCompanyRoleMutation,
        Types.UpdateCompanyRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateCompanyRoleMutation,
        Types.UpdateCompanyRoleMutationVariables
    >(GqlOperationUpdateCompanyRoleDocument, options);
}
export type UpdateCompanyRoleMutationHookResult = ReturnType<
    typeof useUpdateCompanyRoleMutation
>;
export type UpdateCompanyRoleMutationResult =
    Apollo.MutationResult<Types.UpdateCompanyRoleMutation>;
export type UpdateCompanyRoleMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateCompanyRoleMutation,
    Types.UpdateCompanyRoleMutationVariables
>;
export const GqlOperationDeleteCompanyDocument = gql`
    mutation DeleteCompany(
        $dealId: String!
        $relevantCompanyId: String!
        $deleteAssociatedContacts: Boolean!
    ) {
        deleteRelevantCompany(
            dealId: $dealId
            relevantCompanyId: $relevantCompanyId
            deleteAssociatedContacts: $deleteAssociatedContacts
        ) {
            ok
        }
    }
`;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<
    Types.DeleteCompanyMutation,
    Types.DeleteCompanyMutationVariables
>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      deleteAssociatedContacts: // value for 'deleteAssociatedContacts'
 *   },
 * });
 */
export function useDeleteCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteCompanyMutation,
        Types.DeleteCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteCompanyMutation,
        Types.DeleteCompanyMutationVariables
    >(GqlOperationDeleteCompanyDocument, options);
}
export type DeleteCompanyMutationHookResult = ReturnType<
    typeof useDeleteCompanyMutation
>;
export type DeleteCompanyMutationResult =
    Apollo.MutationResult<Types.DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteCompanyMutation,
    Types.DeleteCompanyMutationVariables
>;
export const GqlOperationexportEmailDocument = gql`
    mutation exportEmail(
        $senderName: String!
        $toName: String!
        $toEmail: String!
        $language: UserLanguage!
        $dealId: String!
        $dealOwner: String!
        $dealOwnerEmail: String!
        $dealUrl: String!
    ) {
        exportDealEmail(
            inEmail: {
                senderName: $senderName
                toName: $toName
                toEmail: $toEmail
                language: $language
                dealId: $dealId
                dealOwner: $dealOwner
                dealOwnerEmail: $dealOwnerEmail
                dealUrl: $dealUrl
            }
        ) {
            ok
            email {
                enqueuedDate
                id
                mtaMessageId
                senderBid
                sentDate
                state
                template {
                    templateId
                    params {
                        key
                        value
                    }
                }
                addresses {
                    toReceivers {
                        email
                        name
                    }
                    replyTo {
                        email
                        name
                    }
                    fromSender {
                        email
                        name
                    }
                    ccReceivers {
                        email
                        name
                    }
                    bccReceivers {
                        email
                        name
                    }
                }
            }
        }
    }
`;
export type ExportEmailMutationFn = Apollo.MutationFunction<
    Types.ExportEmailMutation,
    Types.ExportEmailMutationVariables
>;

/**
 * __useExportEmailMutation__
 *
 * To run a mutation, you first call `useExportEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEmailMutation, { data, loading, error }] = useExportEmailMutation({
 *   variables: {
 *      senderName: // value for 'senderName'
 *      toName: // value for 'toName'
 *      toEmail: // value for 'toEmail'
 *      language: // value for 'language'
 *      dealId: // value for 'dealId'
 *      dealOwner: // value for 'dealOwner'
 *      dealOwnerEmail: // value for 'dealOwnerEmail'
 *      dealUrl: // value for 'dealUrl'
 *   },
 * });
 */
export function useExportEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ExportEmailMutation,
        Types.ExportEmailMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ExportEmailMutation,
        Types.ExportEmailMutationVariables
    >(GqlOperationexportEmailDocument, options);
}
export type ExportEmailMutationHookResult = ReturnType<
    typeof useExportEmailMutation
>;
export type ExportEmailMutationResult =
    Apollo.MutationResult<Types.ExportEmailMutation>;
export type ExportEmailMutationOptions = Apollo.BaseMutationOptions<
    Types.ExportEmailMutation,
    Types.ExportEmailMutationVariables
>;
export const GqlOperationCreateContactDocument = gql`
    mutation CreateContact(
        $dealId: String!
        $firstName: String
        $lastName: String
        $role: String
        $notes: String
        $relevantCompanyId: String
        $customInput: ContactUpdateCustomInputQL
        $isKey: Boolean
        $createdFrom: String
    ) {
        createContact(
            dealId: $dealId
            data: {
                firstName: $firstName
                lastName: $lastName
                role: $role
                notes: $notes
                relevantCompanyId: $relevantCompanyId
                customInput: $customInput
                isKey: $isKey
            }
            createdFrom: $createdFrom
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
    Types.CreateContactMutation,
    Types.CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      notes: // value for 'notes'
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      customInput: // value for 'customInput'
 *      isKey: // value for 'isKey'
 *      createdFrom: // value for 'createdFrom'
 *   },
 * });
 */
export function useCreateContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateContactMutation,
        Types.CreateContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateContactMutation,
        Types.CreateContactMutationVariables
    >(GqlOperationCreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
    typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
    Apollo.MutationResult<Types.CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateContactMutation,
    Types.CreateContactMutationVariables
>;
export const GqlOperationUpdateContactDocument = gql`
    mutation UpdateContact(
        $contactId: String!
        $firstName: String
        $lastName: String
        $role: String
    ) {
        updateContact(
            update: { firstName: $firstName, lastName: $lastName, role: $role }
            contactId: $contactId
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
    Types.UpdateContactMutation,
    Types.UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateContactMutation,
        Types.UpdateContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateContactMutation,
        Types.UpdateContactMutationVariables
    >(GqlOperationUpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<
    typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult =
    Apollo.MutationResult<Types.UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateContactMutation,
    Types.UpdateContactMutationVariables
>;
export const GqlOperationUpdateContactNoteDocument = gql`
    mutation UpdateContactNote($contactId: String!, $notes: String!) {
        updateContact(contactId: $contactId, update: { notes: $notes }) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export type UpdateContactNoteMutationFn = Apollo.MutationFunction<
    Types.UpdateContactNoteMutation,
    Types.UpdateContactNoteMutationVariables
>;

/**
 * __useUpdateContactNoteMutation__
 *
 * To run a mutation, you first call `useUpdateContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactNoteMutation, { data, loading, error }] = useUpdateContactNoteMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateContactNoteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateContactNoteMutation,
        Types.UpdateContactNoteMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateContactNoteMutation,
        Types.UpdateContactNoteMutationVariables
    >(GqlOperationUpdateContactNoteDocument, options);
}
export type UpdateContactNoteMutationHookResult = ReturnType<
    typeof useUpdateContactNoteMutation
>;
export type UpdateContactNoteMutationResult =
    Apollo.MutationResult<Types.UpdateContactNoteMutation>;
export type UpdateContactNoteMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateContactNoteMutation,
    Types.UpdateContactNoteMutationVariables
>;
export const GqlOperationUpdateContactRoleDocument = gql`
    mutation UpdateContactRole(
        $contactId: String!
        $role: String!
        $isKey: Boolean!
    ) {
        updateContact(
            contactId: $contactId
            update: { role: $role, isKey: $isKey }
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export type UpdateContactRoleMutationFn = Apollo.MutationFunction<
    Types.UpdateContactRoleMutation,
    Types.UpdateContactRoleMutationVariables
>;

/**
 * __useUpdateContactRoleMutation__
 *
 * To run a mutation, you first call `useUpdateContactRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactRoleMutation, { data, loading, error }] = useUpdateContactRoleMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      role: // value for 'role'
 *      isKey: // value for 'isKey'
 *   },
 * });
 */
export function useUpdateContactRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateContactRoleMutation,
        Types.UpdateContactRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateContactRoleMutation,
        Types.UpdateContactRoleMutationVariables
    >(GqlOperationUpdateContactRoleDocument, options);
}
export type UpdateContactRoleMutationHookResult = ReturnType<
    typeof useUpdateContactRoleMutation
>;
export type UpdateContactRoleMutationResult =
    Apollo.MutationResult<Types.UpdateContactRoleMutation>;
export type UpdateContactRoleMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateContactRoleMutation,
    Types.UpdateContactRoleMutationVariables
>;
export const GqlOperationDeleteContactDocument = gql`
    mutation DeleteContact($dealId: String!, $contactId: String!) {
        deleteContact(dealId: $dealId, contactId: $contactId) {
            ok
        }
    }
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<
    Types.DeleteContactMutation,
    Types.DeleteContactMutationVariables
>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteContactMutation,
        Types.DeleteContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteContactMutation,
        Types.DeleteContactMutationVariables
    >(GqlOperationDeleteContactDocument, options);
}
export type DeleteContactMutationHookResult = ReturnType<
    typeof useDeleteContactMutation
>;
export type DeleteContactMutationResult =
    Apollo.MutationResult<Types.DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteContactMutation,
    Types.DeleteContactMutationVariables
>;
export const GqlOperationAllPipelinesQueryDocument = gql`
    query AllPipelinesQuery($queryParams: QueryParamsQL) {
        allPipelines(queryParams: $queryParams) {
            ...PipelinePart
        }
    }
    ${PipelinePartFragmentDoc}
`;

/**
 * __useAllPipelinesQueryQuery__
 *
 * To run a query within a React component, call `useAllPipelinesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPipelinesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPipelinesQueryQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useAllPipelinesQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.AllPipelinesQueryQuery,
        Types.AllPipelinesQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.AllPipelinesQueryQuery,
        Types.AllPipelinesQueryQueryVariables
    >(GqlOperationAllPipelinesQueryDocument, options);
}
export function useAllPipelinesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.AllPipelinesQueryQuery,
        Types.AllPipelinesQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.AllPipelinesQueryQuery,
        Types.AllPipelinesQueryQueryVariables
    >(GqlOperationAllPipelinesQueryDocument, options);
}
export function useAllPipelinesQuerySuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.AllPipelinesQueryQuery,
              Types.AllPipelinesQueryQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.AllPipelinesQueryQuery,
        Types.AllPipelinesQueryQueryVariables
    >(GqlOperationAllPipelinesQueryDocument, options);
}
export type AllPipelinesQueryQueryHookResult = ReturnType<
    typeof useAllPipelinesQueryQuery
>;
export type AllPipelinesQueryLazyQueryHookResult = ReturnType<
    typeof useAllPipelinesQueryLazyQuery
>;
export type AllPipelinesQuerySuspenseQueryHookResult = ReturnType<
    typeof useAllPipelinesQuerySuspenseQuery
>;
export type AllPipelinesQueryQueryResult = Apollo.QueryResult<
    Types.AllPipelinesQueryQuery,
    Types.AllPipelinesQueryQueryVariables
>;
export const GqlOperationBulkMoveDocument = gql`
    mutation BulkMove(
        $dealIds: [String!]!
        $targetPipelineId: String!
        $targetStageId: String!
        $targetDealState: DealState!
        $unpauseDeals: Boolean
    ) {
        bulkMove(
            dealIds: $dealIds
            targetDealState: $targetDealState
            targetPipelineId: $targetPipelineId
            targetStageId: $targetStageId
            unpauseDeals: $unpauseDeals
        ) {
            ok
            updatedDealIds
        }
    }
`;
export type BulkMoveMutationFn = Apollo.MutationFunction<
    Types.BulkMoveMutation,
    Types.BulkMoveMutationVariables
>;

/**
 * __useBulkMoveMutation__
 *
 * To run a mutation, you first call `useBulkMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkMoveMutation, { data, loading, error }] = useBulkMoveMutation({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *      targetPipelineId: // value for 'targetPipelineId'
 *      targetStageId: // value for 'targetStageId'
 *      targetDealState: // value for 'targetDealState'
 *      unpauseDeals: // value for 'unpauseDeals'
 *   },
 * });
 */
export function useBulkMoveMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BulkMoveMutation,
        Types.BulkMoveMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.BulkMoveMutation,
        Types.BulkMoveMutationVariables
    >(GqlOperationBulkMoveDocument, options);
}
export type BulkMoveMutationHookResult = ReturnType<typeof useBulkMoveMutation>;
export type BulkMoveMutationResult =
    Apollo.MutationResult<Types.BulkMoveMutation>;
export type BulkMoveMutationOptions = Apollo.BaseMutationOptions<
    Types.BulkMoveMutation,
    Types.BulkMoveMutationVariables
>;
export const GqlOperationBulkUpdateDealCustomParametersDocument = gql`
    mutation BulkUpdateDealCustomParameters(
        $dealIds: [String!]!
        $name: String!
        $value: String!
    ) {
        bulkUpdateDealCustomValues(
            dealIds: $dealIds
            name: $name
            value: $value
        ) {
            updatedDealIds
        }
    }
`;
export type BulkUpdateDealCustomParametersMutationFn = Apollo.MutationFunction<
    Types.BulkUpdateDealCustomParametersMutation,
    Types.BulkUpdateDealCustomParametersMutationVariables
>;

/**
 * __useBulkUpdateDealCustomParametersMutation__
 *
 * To run a mutation, you first call `useBulkUpdateDealCustomParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateDealCustomParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateDealCustomParametersMutation, { data, loading, error }] = useBulkUpdateDealCustomParametersMutation({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBulkUpdateDealCustomParametersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BulkUpdateDealCustomParametersMutation,
        Types.BulkUpdateDealCustomParametersMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.BulkUpdateDealCustomParametersMutation,
        Types.BulkUpdateDealCustomParametersMutationVariables
    >(GqlOperationBulkUpdateDealCustomParametersDocument, options);
}
export type BulkUpdateDealCustomParametersMutationHookResult = ReturnType<
    typeof useBulkUpdateDealCustomParametersMutation
>;
export type BulkUpdateDealCustomParametersMutationResult =
    Apollo.MutationResult<Types.BulkUpdateDealCustomParametersMutation>;
export type BulkUpdateDealCustomParametersMutationOptions =
    Apollo.BaseMutationOptions<
        Types.BulkUpdateDealCustomParametersMutation,
        Types.BulkUpdateDealCustomParametersMutationVariables
    >;
export const GqlOperationBulkUpdateDealAttributesDocument = gql`
    mutation BulkUpdateDealAttributes(
        $attribute: DealAttribute!
        $dealIds: [String!]!
        $value: JSON
    ) {
        bulkUpdateDealAttributes(
            attribute: $attribute
            dealIds: $dealIds
            value: $value
        ) {
            updatedDealIds
        }
    }
`;
export type BulkUpdateDealAttributesMutationFn = Apollo.MutationFunction<
    Types.BulkUpdateDealAttributesMutation,
    Types.BulkUpdateDealAttributesMutationVariables
>;

/**
 * __useBulkUpdateDealAttributesMutation__
 *
 * To run a mutation, you first call `useBulkUpdateDealAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateDealAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateDealAttributesMutation, { data, loading, error }] = useBulkUpdateDealAttributesMutation({
 *   variables: {
 *      attribute: // value for 'attribute'
 *      dealIds: // value for 'dealIds'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBulkUpdateDealAttributesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BulkUpdateDealAttributesMutation,
        Types.BulkUpdateDealAttributesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.BulkUpdateDealAttributesMutation,
        Types.BulkUpdateDealAttributesMutationVariables
    >(GqlOperationBulkUpdateDealAttributesDocument, options);
}
export type BulkUpdateDealAttributesMutationHookResult = ReturnType<
    typeof useBulkUpdateDealAttributesMutation
>;
export type BulkUpdateDealAttributesMutationResult =
    Apollo.MutationResult<Types.BulkUpdateDealAttributesMutation>;
export type BulkUpdateDealAttributesMutationOptions =
    Apollo.BaseMutationOptions<
        Types.BulkUpdateDealAttributesMutation,
        Types.BulkUpdateDealAttributesMutationVariables
    >;
export const GqlOperationGetDealsCountDocument = gql`
    query GetDealsCount {
        dealsCollection(
            queryParams: {
                filtering: {
                    field: FK_DEAL_STATE
                    fop: FOP_EQ
                    value: "IN_PLAY"
                }
            }
        ) {
            totalCount
        }
    }
`;

/**
 * __useGetDealsCountQuery__
 *
 * To run a query within a React component, call `useGetDealsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealsCountQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetDealsCountQuery,
        Types.GetDealsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealsCountQuery,
        Types.GetDealsCountQueryVariables
    >(GqlOperationGetDealsCountDocument, options);
}
export function useGetDealsCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealsCountQuery,
        Types.GetDealsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealsCountQuery,
        Types.GetDealsCountQueryVariables
    >(GqlOperationGetDealsCountDocument, options);
}
export function useGetDealsCountSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealsCountQuery,
              Types.GetDealsCountQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealsCountQuery,
        Types.GetDealsCountQueryVariables
    >(GqlOperationGetDealsCountDocument, options);
}
export type GetDealsCountQueryHookResult = ReturnType<
    typeof useGetDealsCountQuery
>;
export type GetDealsCountLazyQueryHookResult = ReturnType<
    typeof useGetDealsCountLazyQuery
>;
export type GetDealsCountSuspenseQueryHookResult = ReturnType<
    typeof useGetDealsCountSuspenseQuery
>;
export type GetDealsCountQueryResult = Apollo.QueryResult<
    Types.GetDealsCountQuery,
    Types.GetDealsCountQueryVariables
>;
export const GqlOperationGetAllDealsCollectionDocument = gql`
    query GetAllDealsCollection($queryParams: QueryParamsQL!) {
        dealsCollection(queryParams: $queryParams) {
            pageInfo {
                count
                hasNextPage
            }
            edges {
                node {
                    ...MinimalDealPart
                }
            }
            totalCount
        }
    }
    ${MinimalDealPartFragmentDoc}
`;

/**
 * __useGetAllDealsCollectionQuery__
 *
 * To run a query within a React component, call `useGetAllDealsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDealsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDealsCollectionQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetAllDealsCollectionQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetAllDealsCollectionQuery,
        Types.GetAllDealsCollectionQueryVariables
    > &
        (
            | {
                  variables: Types.GetAllDealsCollectionQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetAllDealsCollectionQuery,
        Types.GetAllDealsCollectionQueryVariables
    >(GqlOperationGetAllDealsCollectionDocument, options);
}
export function useGetAllDealsCollectionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetAllDealsCollectionQuery,
        Types.GetAllDealsCollectionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetAllDealsCollectionQuery,
        Types.GetAllDealsCollectionQueryVariables
    >(GqlOperationGetAllDealsCollectionDocument, options);
}
export function useGetAllDealsCollectionSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetAllDealsCollectionQuery,
              Types.GetAllDealsCollectionQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetAllDealsCollectionQuery,
        Types.GetAllDealsCollectionQueryVariables
    >(GqlOperationGetAllDealsCollectionDocument, options);
}
export type GetAllDealsCollectionQueryHookResult = ReturnType<
    typeof useGetAllDealsCollectionQuery
>;
export type GetAllDealsCollectionLazyQueryHookResult = ReturnType<
    typeof useGetAllDealsCollectionLazyQuery
>;
export type GetAllDealsCollectionSuspenseQueryHookResult = ReturnType<
    typeof useGetAllDealsCollectionSuspenseQuery
>;
export type GetAllDealsCollectionQueryResult = Apollo.QueryResult<
    Types.GetAllDealsCollectionQuery,
    Types.GetAllDealsCollectionQueryVariables
>;
export const GqlOperationGetDealCustomParametersDocument = gql`
    query GetDealCustomParameters($dealId: String!) {
        deal(dealId: $dealId) {
            customFields {
                ...CustomField
            }
        }
    }
    ${CustomFieldFragmentDoc}
`;

/**
 * __useGetDealCustomParametersQuery__
 *
 * To run a query within a React component, call `useGetDealCustomParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealCustomParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealCustomParametersQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealCustomParametersQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealCustomParametersQuery,
        Types.GetDealCustomParametersQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealCustomParametersQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealCustomParametersQuery,
        Types.GetDealCustomParametersQueryVariables
    >(GqlOperationGetDealCustomParametersDocument, options);
}
export function useGetDealCustomParametersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealCustomParametersQuery,
        Types.GetDealCustomParametersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealCustomParametersQuery,
        Types.GetDealCustomParametersQueryVariables
    >(GqlOperationGetDealCustomParametersDocument, options);
}
export function useGetDealCustomParametersSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealCustomParametersQuery,
              Types.GetDealCustomParametersQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealCustomParametersQuery,
        Types.GetDealCustomParametersQueryVariables
    >(GqlOperationGetDealCustomParametersDocument, options);
}
export type GetDealCustomParametersQueryHookResult = ReturnType<
    typeof useGetDealCustomParametersQuery
>;
export type GetDealCustomParametersLazyQueryHookResult = ReturnType<
    typeof useGetDealCustomParametersLazyQuery
>;
export type GetDealCustomParametersSuspenseQueryHookResult = ReturnType<
    typeof useGetDealCustomParametersSuspenseQuery
>;
export type GetDealCustomParametersQueryResult = Apollo.QueryResult<
    Types.GetDealCustomParametersQuery,
    Types.GetDealCustomParametersQueryVariables
>;
export const GqlOperationUpdateDealCustomParametersDocument = gql`
    mutation UpdateDealCustomParameters(
        $dealId: String!
        $update: DealUpdateCustomInputQL!
    ) {
        updateDealCustomValues(dealId: $dealId, update: $update) {
            ok
        }
    }
`;
export type UpdateDealCustomParametersMutationFn = Apollo.MutationFunction<
    Types.UpdateDealCustomParametersMutation,
    Types.UpdateDealCustomParametersMutationVariables
>;

/**
 * __useUpdateDealCustomParametersMutation__
 *
 * To run a mutation, you first call `useUpdateDealCustomParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealCustomParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealCustomParametersMutation, { data, loading, error }] = useUpdateDealCustomParametersMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateDealCustomParametersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateDealCustomParametersMutation,
        Types.UpdateDealCustomParametersMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateDealCustomParametersMutation,
        Types.UpdateDealCustomParametersMutationVariables
    >(GqlOperationUpdateDealCustomParametersDocument, options);
}
export type UpdateDealCustomParametersMutationHookResult = ReturnType<
    typeof useUpdateDealCustomParametersMutation
>;
export type UpdateDealCustomParametersMutationResult =
    Apollo.MutationResult<Types.UpdateDealCustomParametersMutation>;
export type UpdateDealCustomParametersMutationOptions =
    Apollo.BaseMutationOptions<
        Types.UpdateDealCustomParametersMutation,
        Types.UpdateDealCustomParametersMutationVariables
    >;
export const GqlOperationGetAllIntegrationsDocument = gql`
    query GetAllIntegrations {
        allIntegrations {
            ...IntegrationPart
        }
    }
    ${IntegrationPartFragmentDoc}
`;

/**
 * __useGetAllIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetAllIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllIntegrationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetAllIntegrationsQuery,
        Types.GetAllIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetAllIntegrationsQuery,
        Types.GetAllIntegrationsQueryVariables
    >(GqlOperationGetAllIntegrationsDocument, options);
}
export function useGetAllIntegrationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetAllIntegrationsQuery,
        Types.GetAllIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetAllIntegrationsQuery,
        Types.GetAllIntegrationsQueryVariables
    >(GqlOperationGetAllIntegrationsDocument, options);
}
export function useGetAllIntegrationsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetAllIntegrationsQuery,
              Types.GetAllIntegrationsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetAllIntegrationsQuery,
        Types.GetAllIntegrationsQueryVariables
    >(GqlOperationGetAllIntegrationsDocument, options);
}
export type GetAllIntegrationsQueryHookResult = ReturnType<
    typeof useGetAllIntegrationsQuery
>;
export type GetAllIntegrationsLazyQueryHookResult = ReturnType<
    typeof useGetAllIntegrationsLazyQuery
>;
export type GetAllIntegrationsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllIntegrationsSuspenseQuery
>;
export type GetAllIntegrationsQueryResult = Apollo.QueryResult<
    Types.GetAllIntegrationsQuery,
    Types.GetAllIntegrationsQueryVariables
>;
export const GqlOperationGetDealCommentsDocument = gql`
    query GetDealComments($dealId: String!) {
        deal(dealId: $dealId) {
            comments {
                ...DealComment
            }
        }
    }
    ${DealCommentFragmentDoc}
`;

/**
 * __useGetDealCommentsQuery__
 *
 * To run a query within a React component, call `useGetDealCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealCommentsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealCommentsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealCommentsQuery,
        Types.GetDealCommentsQueryVariables
    > &
        (
            | { variables: Types.GetDealCommentsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealCommentsQuery,
        Types.GetDealCommentsQueryVariables
    >(GqlOperationGetDealCommentsDocument, options);
}
export function useGetDealCommentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealCommentsQuery,
        Types.GetDealCommentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealCommentsQuery,
        Types.GetDealCommentsQueryVariables
    >(GqlOperationGetDealCommentsDocument, options);
}
export function useGetDealCommentsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealCommentsQuery,
              Types.GetDealCommentsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealCommentsQuery,
        Types.GetDealCommentsQueryVariables
    >(GqlOperationGetDealCommentsDocument, options);
}
export type GetDealCommentsQueryHookResult = ReturnType<
    typeof useGetDealCommentsQuery
>;
export type GetDealCommentsLazyQueryHookResult = ReturnType<
    typeof useGetDealCommentsLazyQuery
>;
export type GetDealCommentsSuspenseQueryHookResult = ReturnType<
    typeof useGetDealCommentsSuspenseQuery
>;
export type GetDealCommentsQueryResult = Apollo.QueryResult<
    Types.GetDealCommentsQuery,
    Types.GetDealCommentsQueryVariables
>;
export const GqlOperationAddDealCommentDocument = gql`
    mutation AddDealComment($dealId: String!, $data: CommentCreateQL!) {
        addDealComment(dealId: $dealId, data: $data) {
            ...DealComment
        }
    }
    ${DealCommentFragmentDoc}
`;
export type AddDealCommentMutationFn = Apollo.MutationFunction<
    Types.AddDealCommentMutation,
    Types.AddDealCommentMutationVariables
>;

/**
 * __useAddDealCommentMutation__
 *
 * To run a mutation, you first call `useAddDealCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDealCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDealCommentMutation, { data, loading, error }] = useAddDealCommentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddDealCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.AddDealCommentMutation,
        Types.AddDealCommentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.AddDealCommentMutation,
        Types.AddDealCommentMutationVariables
    >(GqlOperationAddDealCommentDocument, options);
}
export type AddDealCommentMutationHookResult = ReturnType<
    typeof useAddDealCommentMutation
>;
export type AddDealCommentMutationResult =
    Apollo.MutationResult<Types.AddDealCommentMutation>;
export type AddDealCommentMutationOptions = Apollo.BaseMutationOptions<
    Types.AddDealCommentMutation,
    Types.AddDealCommentMutationVariables
>;
export const GqlOperationUpdateCommentDocument = gql`
    mutation UpdateComment($commentId: String!, $data: CommentUpdateQL!) {
        updateComment(commentId: $commentId, update: $data) {
            ...DealComment
        }
    }
    ${DealCommentFragmentDoc}
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<
    Types.UpdateCommentMutation,
    Types.UpdateCommentMutationVariables
>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateCommentMutation,
        Types.UpdateCommentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateCommentMutation,
        Types.UpdateCommentMutationVariables
    >(GqlOperationUpdateCommentDocument, options);
}
export type UpdateCommentMutationHookResult = ReturnType<
    typeof useUpdateCommentMutation
>;
export type UpdateCommentMutationResult =
    Apollo.MutationResult<Types.UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateCommentMutation,
    Types.UpdateCommentMutationVariables
>;
export const GqlOperationDeleteCommentDocument = gql`
    mutation DeleteComment($commentId: String!) {
        deleteComment(commentId: $commentId) {
            ok
        }
    }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
    Types.DeleteCommentMutation,
    Types.DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteCommentMutation,
        Types.DeleteCommentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteCommentMutation,
        Types.DeleteCommentMutationVariables
    >(GqlOperationDeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
    typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
    Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteCommentMutation,
    Types.DeleteCommentMutationVariables
>;
export const GqlOperationExportToCrmDocument = gql`
    mutation ExportToCrm($dealId: String!, $integrationId: String!) {
        exportToCrm(dealId: $dealId, integrationId: $integrationId) {
            ok
        }
    }
`;
export type ExportToCrmMutationFn = Apollo.MutationFunction<
    Types.ExportToCrmMutation,
    Types.ExportToCrmMutationVariables
>;

/**
 * __useExportToCrmMutation__
 *
 * To run a mutation, you first call `useExportToCrmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportToCrmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportToCrmMutation, { data, loading, error }] = useExportToCrmMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useExportToCrmMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ExportToCrmMutation,
        Types.ExportToCrmMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ExportToCrmMutation,
        Types.ExportToCrmMutationVariables
    >(GqlOperationExportToCrmDocument, options);
}
export type ExportToCrmMutationHookResult = ReturnType<
    typeof useExportToCrmMutation
>;
export type ExportToCrmMutationResult =
    Apollo.MutationResult<Types.ExportToCrmMutation>;
export type ExportToCrmMutationOptions = Apollo.BaseMutationOptions<
    Types.ExportToCrmMutation,
    Types.ExportToCrmMutationVariables
>;
export const GqlOperationGetCrmStateDocument = gql`
    query GetCrmState($dealId: String!, $integrationId: String!) {
        deal(dealId: $dealId) {
            export {
                crm(integrationId: $integrationId) {
                    ...CrmStatePart
                }
            }
        }
    }
    ${CrmStatePartFragmentDoc}
`;

/**
 * __useGetCrmStateQuery__
 *
 * To run a query within a React component, call `useGetCrmStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrmStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrmStateQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetCrmStateQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetCrmStateQuery,
        Types.GetCrmStateQueryVariables
    > &
        (
            | { variables: Types.GetCrmStateQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCrmStateQuery,
        Types.GetCrmStateQueryVariables
    >(GqlOperationGetCrmStateDocument, options);
}
export function useGetCrmStateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCrmStateQuery,
        Types.GetCrmStateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCrmStateQuery,
        Types.GetCrmStateQueryVariables
    >(GqlOperationGetCrmStateDocument, options);
}
export function useGetCrmStateSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCrmStateQuery,
              Types.GetCrmStateQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCrmStateQuery,
        Types.GetCrmStateQueryVariables
    >(GqlOperationGetCrmStateDocument, options);
}
export type GetCrmStateQueryHookResult = ReturnType<typeof useGetCrmStateQuery>;
export type GetCrmStateLazyQueryHookResult = ReturnType<
    typeof useGetCrmStateLazyQuery
>;
export type GetCrmStateSuspenseQueryHookResult = ReturnType<
    typeof useGetCrmStateSuspenseQuery
>;
export type GetCrmStateQueryResult = Apollo.QueryResult<
    Types.GetCrmStateQuery,
    Types.GetCrmStateQueryVariables
>;
export const GqlOperationGetMyHubSpotTokenDocument = gql`
    query GetMyHubSpotToken {
        me {
            hubspotToken
        }
    }
`;

/**
 * __useGetMyHubSpotTokenQuery__
 *
 * To run a query within a React component, call `useGetMyHubSpotTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyHubSpotTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyHubSpotTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyHubSpotTokenQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetMyHubSpotTokenQuery,
        Types.GetMyHubSpotTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetMyHubSpotTokenQuery,
        Types.GetMyHubSpotTokenQueryVariables
    >(GqlOperationGetMyHubSpotTokenDocument, options);
}
export function useGetMyHubSpotTokenLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetMyHubSpotTokenQuery,
        Types.GetMyHubSpotTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetMyHubSpotTokenQuery,
        Types.GetMyHubSpotTokenQueryVariables
    >(GqlOperationGetMyHubSpotTokenDocument, options);
}
export function useGetMyHubSpotTokenSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetMyHubSpotTokenQuery,
              Types.GetMyHubSpotTokenQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetMyHubSpotTokenQuery,
        Types.GetMyHubSpotTokenQueryVariables
    >(GqlOperationGetMyHubSpotTokenDocument, options);
}
export type GetMyHubSpotTokenQueryHookResult = ReturnType<
    typeof useGetMyHubSpotTokenQuery
>;
export type GetMyHubSpotTokenLazyQueryHookResult = ReturnType<
    typeof useGetMyHubSpotTokenLazyQuery
>;
export type GetMyHubSpotTokenSuspenseQueryHookResult = ReturnType<
    typeof useGetMyHubSpotTokenSuspenseQuery
>;
export type GetMyHubSpotTokenQueryResult = Apollo.QueryResult<
    Types.GetMyHubSpotTokenQuery,
    Types.GetMyHubSpotTokenQueryVariables
>;
export const GqlOperationUpdatePersonDocument = gql`
    mutation UpdatePerson($contactId: String!, $update: ContactUpdateQL!) {
        updateContact(contactId: $contactId, update: $update) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
    Types.UpdatePersonMutation,
    Types.UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePersonMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdatePersonMutation,
        Types.UpdatePersonMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdatePersonMutation,
        Types.UpdatePersonMutationVariables
    >(GqlOperationUpdatePersonDocument, options);
}
export type UpdatePersonMutationHookResult = ReturnType<
    typeof useUpdatePersonMutation
>;
export type UpdatePersonMutationResult =
    Apollo.MutationResult<Types.UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdatePersonMutation,
    Types.UpdatePersonMutationVariables
>;
export const GqlOperationUpdateRelevantCompanyDocument = gql`
    mutation UpdateRelevantCompany(
        $relevantCompanyId: String!
        $update: RelevantCompanyUpdateQL!
    ) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: $update
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type UpdateRelevantCompanyMutationFn = Apollo.MutationFunction<
    Types.UpdateRelevantCompanyMutation,
    Types.UpdateRelevantCompanyMutationVariables
>;

/**
 * __useUpdateRelevantCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateRelevantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelevantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelevantCompanyMutation, { data, loading, error }] = useUpdateRelevantCompanyMutation({
 *   variables: {
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateRelevantCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateRelevantCompanyMutation,
        Types.UpdateRelevantCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateRelevantCompanyMutation,
        Types.UpdateRelevantCompanyMutationVariables
    >(GqlOperationUpdateRelevantCompanyDocument, options);
}
export type UpdateRelevantCompanyMutationHookResult = ReturnType<
    typeof useUpdateRelevantCompanyMutation
>;
export type UpdateRelevantCompanyMutationResult =
    Apollo.MutationResult<Types.UpdateRelevantCompanyMutation>;
export type UpdateRelevantCompanyMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateRelevantCompanyMutation,
    Types.UpdateRelevantCompanyMutationVariables
>;
export const GqlOperationGetAccountConfigurationDocument = gql`
    query GetAccountConfiguration {
        company {
            config {
                actionFeatures {
                    active
                    name
                    order
                    settings
                    companyOutreachStages
                    contactOutreachStages
                }
                salesPeriod {
                    frequency
                }
                customFields {
                    deals {
                        ...CustomFieldConfig
                    }
                    contacts {
                        ...CustomFieldConfig
                    }
                    relevantCompanies {
                        ...CustomFieldConfig
                    }
                }
                dealValueVisible
                roles {
                    ...RolesPart
                }
            }
        }
    }
    ${CustomFieldConfigFragmentDoc}
    ${RolesPartFragmentDoc}
`;

/**
 * __useGetAccountConfigurationQuery__
 *
 * To run a query within a React component, call `useGetAccountConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountConfigurationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetAccountConfigurationQuery,
        Types.GetAccountConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetAccountConfigurationQuery,
        Types.GetAccountConfigurationQueryVariables
    >(GqlOperationGetAccountConfigurationDocument, options);
}
export function useGetAccountConfigurationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetAccountConfigurationQuery,
        Types.GetAccountConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetAccountConfigurationQuery,
        Types.GetAccountConfigurationQueryVariables
    >(GqlOperationGetAccountConfigurationDocument, options);
}
export function useGetAccountConfigurationSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetAccountConfigurationQuery,
              Types.GetAccountConfigurationQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetAccountConfigurationQuery,
        Types.GetAccountConfigurationQueryVariables
    >(GqlOperationGetAccountConfigurationDocument, options);
}
export type GetAccountConfigurationQueryHookResult = ReturnType<
    typeof useGetAccountConfigurationQuery
>;
export type GetAccountConfigurationLazyQueryHookResult = ReturnType<
    typeof useGetAccountConfigurationLazyQuery
>;
export type GetAccountConfigurationSuspenseQueryHookResult = ReturnType<
    typeof useGetAccountConfigurationSuspenseQuery
>;
export type GetAccountConfigurationQueryResult = Apollo.QueryResult<
    Types.GetAccountConfigurationQuery,
    Types.GetAccountConfigurationQueryVariables
>;
export const GqlOperationGetDealLocationDataDocument = gql`
    query GetDealLocationData($dealId: String!, $name: String!) {
        actionFeatureGoogleEnrichmentPlaces(dealId: $dealId, name: $name) {
            ...PlacePart
        }
    }
    ${PlacePartFragmentDoc}
`;

/**
 * __useGetDealLocationDataQuery__
 *
 * To run a query within a React component, call `useGetDealLocationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealLocationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealLocationDataQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetDealLocationDataQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealLocationDataQuery,
        Types.GetDealLocationDataQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealLocationDataQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealLocationDataQuery,
        Types.GetDealLocationDataQueryVariables
    >(GqlOperationGetDealLocationDataDocument, options);
}
export function useGetDealLocationDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealLocationDataQuery,
        Types.GetDealLocationDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealLocationDataQuery,
        Types.GetDealLocationDataQueryVariables
    >(GqlOperationGetDealLocationDataDocument, options);
}
export function useGetDealLocationDataSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealLocationDataQuery,
              Types.GetDealLocationDataQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealLocationDataQuery,
        Types.GetDealLocationDataQueryVariables
    >(GqlOperationGetDealLocationDataDocument, options);
}
export type GetDealLocationDataQueryHookResult = ReturnType<
    typeof useGetDealLocationDataQuery
>;
export type GetDealLocationDataLazyQueryHookResult = ReturnType<
    typeof useGetDealLocationDataLazyQuery
>;
export type GetDealLocationDataSuspenseQueryHookResult = ReturnType<
    typeof useGetDealLocationDataSuspenseQuery
>;
export type GetDealLocationDataQueryResult = Apollo.QueryResult<
    Types.GetDealLocationDataQuery,
    Types.GetDealLocationDataQueryVariables
>;
export const GqlOperationGetGoogleEnrichmentDataDocument = gql`
    query GetGoogleEnrichmentData($language: String!, $placeId: String!) {
        actionFeatureGoogleEnrichmentPlaceDetails(
            language: $language
            placeId: $placeId
        ) {
            ...GoogleEnrichmentPart
        }
    }
    ${GoogleEnrichmentPartFragmentDoc}
`;

/**
 * __useGetGoogleEnrichmentDataQuery__
 *
 * To run a query within a React component, call `useGetGoogleEnrichmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleEnrichmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleEnrichmentDataQuery({
 *   variables: {
 *      language: // value for 'language'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetGoogleEnrichmentDataQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetGoogleEnrichmentDataQuery,
        Types.GetGoogleEnrichmentDataQueryVariables
    > &
        (
            | {
                  variables: Types.GetGoogleEnrichmentDataQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetGoogleEnrichmentDataQuery,
        Types.GetGoogleEnrichmentDataQueryVariables
    >(GqlOperationGetGoogleEnrichmentDataDocument, options);
}
export function useGetGoogleEnrichmentDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetGoogleEnrichmentDataQuery,
        Types.GetGoogleEnrichmentDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetGoogleEnrichmentDataQuery,
        Types.GetGoogleEnrichmentDataQueryVariables
    >(GqlOperationGetGoogleEnrichmentDataDocument, options);
}
export function useGetGoogleEnrichmentDataSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetGoogleEnrichmentDataQuery,
              Types.GetGoogleEnrichmentDataQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetGoogleEnrichmentDataQuery,
        Types.GetGoogleEnrichmentDataQueryVariables
    >(GqlOperationGetGoogleEnrichmentDataDocument, options);
}
export type GetGoogleEnrichmentDataQueryHookResult = ReturnType<
    typeof useGetGoogleEnrichmentDataQuery
>;
export type GetGoogleEnrichmentDataLazyQueryHookResult = ReturnType<
    typeof useGetGoogleEnrichmentDataLazyQuery
>;
export type GetGoogleEnrichmentDataSuspenseQueryHookResult = ReturnType<
    typeof useGetGoogleEnrichmentDataSuspenseQuery
>;
export type GetGoogleEnrichmentDataQueryResult = Apollo.QueryResult<
    Types.GetGoogleEnrichmentDataQuery,
    Types.GetGoogleEnrichmentDataQueryVariables
>;
export const GqlOperationCreateContactsFromExtractedContactsDocument = gql`
    mutation CreateContactsFromExtractedContacts(
        $dealId: String!
        $extractedContacts: [ReformattedExtractedContactInputQL!]!
        $extractedCompanies: [ReformattedExtractedCompanyInputQL!]!
    ) {
        createContactsFromExtractedContacts(
            extractedContacts: $extractedContacts
            dealId: $dealId
            extractedCompanies: $extractedCompanies
        ) {
            contacts {
                ...DealContactPart
            }
            relevantCompanies {
                ...DealCompanyPart
            }
        }
    }
    ${DealContactPartFragmentDoc}
    ${DealCompanyPartFragmentDoc}
`;
export type CreateContactsFromExtractedContactsMutationFn =
    Apollo.MutationFunction<
        Types.CreateContactsFromExtractedContactsMutation,
        Types.CreateContactsFromExtractedContactsMutationVariables
    >;

/**
 * __useCreateContactsFromExtractedContactsMutation__
 *
 * To run a mutation, you first call `useCreateContactsFromExtractedContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactsFromExtractedContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactsFromExtractedContactsMutation, { data, loading, error }] = useCreateContactsFromExtractedContactsMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      extractedContacts: // value for 'extractedContacts'
 *      extractedCompanies: // value for 'extractedCompanies'
 *   },
 * });
 */
export function useCreateContactsFromExtractedContactsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateContactsFromExtractedContactsMutation,
        Types.CreateContactsFromExtractedContactsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateContactsFromExtractedContactsMutation,
        Types.CreateContactsFromExtractedContactsMutationVariables
    >(GqlOperationCreateContactsFromExtractedContactsDocument, options);
}
export type CreateContactsFromExtractedContactsMutationHookResult = ReturnType<
    typeof useCreateContactsFromExtractedContactsMutation
>;
export type CreateContactsFromExtractedContactsMutationResult =
    Apollo.MutationResult<Types.CreateContactsFromExtractedContactsMutation>;
export type CreateContactsFromExtractedContactsMutationOptions =
    Apollo.BaseMutationOptions<
        Types.CreateContactsFromExtractedContactsMutation,
        Types.CreateContactsFromExtractedContactsMutationVariables
    >;
export const GqlOperationCreateReminderDocument = gql`
    mutation CreateReminder(
        $data: ReminderCreateInputQL!
        $launchSource: String = null
    ) {
        createReminder(data: $data, launchSource: $launchSource) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export type CreateReminderMutationFn = Apollo.MutationFunction<
    Types.CreateReminderMutation,
    Types.CreateReminderMutationVariables
>;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      launchSource: // value for 'launchSource'
 *   },
 * });
 */
export function useCreateReminderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateReminderMutation,
        Types.CreateReminderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateReminderMutation,
        Types.CreateReminderMutationVariables
    >(GqlOperationCreateReminderDocument, options);
}
export type CreateReminderMutationHookResult = ReturnType<
    typeof useCreateReminderMutation
>;
export type CreateReminderMutationResult =
    Apollo.MutationResult<Types.CreateReminderMutation>;
export type CreateReminderMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateReminderMutation,
    Types.CreateReminderMutationVariables
>;
export const GqlOperationUpdateReminderDocument = gql`
    mutation UpdateReminder(
        $reminderId: String!
        $update: ReminderUpdateInputQL!
    ) {
        updateReminder(reminderId: $reminderId, update: $update) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export type UpdateReminderMutationFn = Apollo.MutationFunction<
    Types.UpdateReminderMutation,
    Types.UpdateReminderMutationVariables
>;

/**
 * __useUpdateReminderMutation__
 *
 * To run a mutation, you first call `useUpdateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderMutation, { data, loading, error }] = useUpdateReminderMutation({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateReminderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateReminderMutation,
        Types.UpdateReminderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateReminderMutation,
        Types.UpdateReminderMutationVariables
    >(GqlOperationUpdateReminderDocument, options);
}
export type UpdateReminderMutationHookResult = ReturnType<
    typeof useUpdateReminderMutation
>;
export type UpdateReminderMutationResult =
    Apollo.MutationResult<Types.UpdateReminderMutation>;
export type UpdateReminderMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateReminderMutation,
    Types.UpdateReminderMutationVariables
>;
export const GqlOperationGetMyRemindersAndMentionsDocument = gql`
    query GetMyRemindersAndMentions {
        me {
            mentions {
                ...MentionPart
            }
            reminders {
                ...ReminderPart
            }
        }
    }
    ${MentionPartFragmentDoc}
    ${ReminderPartFragmentDoc}
`;

/**
 * __useGetMyRemindersAndMentionsQuery__
 *
 * To run a query within a React component, call `useGetMyRemindersAndMentionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRemindersAndMentionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRemindersAndMentionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRemindersAndMentionsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetMyRemindersAndMentionsQuery,
        Types.GetMyRemindersAndMentionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetMyRemindersAndMentionsQuery,
        Types.GetMyRemindersAndMentionsQueryVariables
    >(GqlOperationGetMyRemindersAndMentionsDocument, options);
}
export function useGetMyRemindersAndMentionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetMyRemindersAndMentionsQuery,
        Types.GetMyRemindersAndMentionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetMyRemindersAndMentionsQuery,
        Types.GetMyRemindersAndMentionsQueryVariables
    >(GqlOperationGetMyRemindersAndMentionsDocument, options);
}
export function useGetMyRemindersAndMentionsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetMyRemindersAndMentionsQuery,
              Types.GetMyRemindersAndMentionsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetMyRemindersAndMentionsQuery,
        Types.GetMyRemindersAndMentionsQueryVariables
    >(GqlOperationGetMyRemindersAndMentionsDocument, options);
}
export type GetMyRemindersAndMentionsQueryHookResult = ReturnType<
    typeof useGetMyRemindersAndMentionsQuery
>;
export type GetMyRemindersAndMentionsLazyQueryHookResult = ReturnType<
    typeof useGetMyRemindersAndMentionsLazyQuery
>;
export type GetMyRemindersAndMentionsSuspenseQueryHookResult = ReturnType<
    typeof useGetMyRemindersAndMentionsSuspenseQuery
>;
export type GetMyRemindersAndMentionsQueryResult = Apollo.QueryResult<
    Types.GetMyRemindersAndMentionsQuery,
    Types.GetMyRemindersAndMentionsQueryVariables
>;
export const GqlOperationDismissReminderDocument = gql`
    mutation DismissReminder($reminderId: String!) {
        completeReminder(reminderId: $reminderId) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export type DismissReminderMutationFn = Apollo.MutationFunction<
    Types.DismissReminderMutation,
    Types.DismissReminderMutationVariables
>;

/**
 * __useDismissReminderMutation__
 *
 * To run a mutation, you first call `useDismissReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissReminderMutation, { data, loading, error }] = useDismissReminderMutation({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *   },
 * });
 */
export function useDismissReminderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DismissReminderMutation,
        Types.DismissReminderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DismissReminderMutation,
        Types.DismissReminderMutationVariables
    >(GqlOperationDismissReminderDocument, options);
}
export type DismissReminderMutationHookResult = ReturnType<
    typeof useDismissReminderMutation
>;
export type DismissReminderMutationResult =
    Apollo.MutationResult<Types.DismissReminderMutation>;
export type DismissReminderMutationOptions = Apollo.BaseMutationOptions<
    Types.DismissReminderMutation,
    Types.DismissReminderMutationVariables
>;
export const GqlOperationMarkMentionAsReadDocument = gql`
    mutation MarkMentionAsRead($mentionId: String!) {
        readCommentMention(commentId: $mentionId) {
            ok
        }
    }
`;
export type MarkMentionAsReadMutationFn = Apollo.MutationFunction<
    Types.MarkMentionAsReadMutation,
    Types.MarkMentionAsReadMutationVariables
>;

/**
 * __useMarkMentionAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMentionAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMentionAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMentionAsReadMutation, { data, loading, error }] = useMarkMentionAsReadMutation({
 *   variables: {
 *      mentionId: // value for 'mentionId'
 *   },
 * });
 */
export function useMarkMentionAsReadMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.MarkMentionAsReadMutation,
        Types.MarkMentionAsReadMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.MarkMentionAsReadMutation,
        Types.MarkMentionAsReadMutationVariables
    >(GqlOperationMarkMentionAsReadDocument, options);
}
export type MarkMentionAsReadMutationHookResult = ReturnType<
    typeof useMarkMentionAsReadMutation
>;
export type MarkMentionAsReadMutationResult =
    Apollo.MutationResult<Types.MarkMentionAsReadMutation>;
export type MarkMentionAsReadMutationOptions = Apollo.BaseMutationOptions<
    Types.MarkMentionAsReadMutation,
    Types.MarkMentionAsReadMutationVariables
>;
export const GqlOperationDismissMentionDocument = gql`
    mutation DismissMention($mentionId: String!) {
        dismissCommentMention(commentId: $mentionId) {
            ok
        }
    }
`;
export type DismissMentionMutationFn = Apollo.MutationFunction<
    Types.DismissMentionMutation,
    Types.DismissMentionMutationVariables
>;

/**
 * __useDismissMentionMutation__
 *
 * To run a mutation, you first call `useDismissMentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissMentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissMentionMutation, { data, loading, error }] = useDismissMentionMutation({
 *   variables: {
 *      mentionId: // value for 'mentionId'
 *   },
 * });
 */
export function useDismissMentionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DismissMentionMutation,
        Types.DismissMentionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DismissMentionMutation,
        Types.DismissMentionMutationVariables
    >(GqlOperationDismissMentionDocument, options);
}
export type DismissMentionMutationHookResult = ReturnType<
    typeof useDismissMentionMutation
>;
export type DismissMentionMutationResult =
    Apollo.MutationResult<Types.DismissMentionMutation>;
export type DismissMentionMutationOptions = Apollo.BaseMutationOptions<
    Types.DismissMentionMutation,
    Types.DismissMentionMutationVariables
>;
export const GqlOperationGetCompanyTypesDocument = gql`
    query GetCompanyTypes {
        company {
            accountType
            licenseType
        }
    }
`;

/**
 * __useGetCompanyTypesQuery__
 *
 * To run a query within a React component, call `useGetCompanyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetCompanyTypesQuery,
        Types.GetCompanyTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCompanyTypesQuery,
        Types.GetCompanyTypesQueryVariables
    >(GqlOperationGetCompanyTypesDocument, options);
}
export function useGetCompanyTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCompanyTypesQuery,
        Types.GetCompanyTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCompanyTypesQuery,
        Types.GetCompanyTypesQueryVariables
    >(GqlOperationGetCompanyTypesDocument, options);
}
export function useGetCompanyTypesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCompanyTypesQuery,
              Types.GetCompanyTypesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCompanyTypesQuery,
        Types.GetCompanyTypesQueryVariables
    >(GqlOperationGetCompanyTypesDocument, options);
}
export type GetCompanyTypesQueryHookResult = ReturnType<
    typeof useGetCompanyTypesQuery
>;
export type GetCompanyTypesLazyQueryHookResult = ReturnType<
    typeof useGetCompanyTypesLazyQuery
>;
export type GetCompanyTypesSuspenseQueryHookResult = ReturnType<
    typeof useGetCompanyTypesSuspenseQuery
>;
export type GetCompanyTypesQueryResult = Apollo.QueryResult<
    Types.GetCompanyTypesQuery,
    Types.GetCompanyTypesQueryVariables
>;
export const GqlOperationGetDealAlertDocument = gql`
    query GetDealAlert($dealId: String!) {
        deal(dealId: $dealId) {
            alert {
                ...DealAlertPart
            }
        }
    }
    ${DealAlertPartFragmentDoc}
`;

/**
 * __useGetDealAlertQuery__
 *
 * To run a query within a React component, call `useGetDealAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealAlertQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealAlertQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealAlertQuery,
        Types.GetDealAlertQueryVariables
    > &
        (
            | { variables: Types.GetDealAlertQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealAlertQuery,
        Types.GetDealAlertQueryVariables
    >(GqlOperationGetDealAlertDocument, options);
}
export function useGetDealAlertLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealAlertQuery,
        Types.GetDealAlertQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealAlertQuery,
        Types.GetDealAlertQueryVariables
    >(GqlOperationGetDealAlertDocument, options);
}
export function useGetDealAlertSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealAlertQuery,
              Types.GetDealAlertQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealAlertQuery,
        Types.GetDealAlertQueryVariables
    >(GqlOperationGetDealAlertDocument, options);
}
export type GetDealAlertQueryHookResult = ReturnType<
    typeof useGetDealAlertQuery
>;
export type GetDealAlertLazyQueryHookResult = ReturnType<
    typeof useGetDealAlertLazyQuery
>;
export type GetDealAlertSuspenseQueryHookResult = ReturnType<
    typeof useGetDealAlertSuspenseQuery
>;
export type GetDealAlertQueryResult = Apollo.QueryResult<
    Types.GetDealAlertQuery,
    Types.GetDealAlertQueryVariables
>;
export const GqlOperationGetDynamicFilterMetricsDocument = gql`
    query GetDynamicFilterMetrics(
        $pipelineId: String!
        $filters: [FilterOpQL!]!
    ) {
        pipeline(pipelineId: $pipelineId) {
            statistics(queryParams: { filtering: $filters }) {
                dynamicFiltersMetrics {
                    ...DynamicFilterMetricsPart
                }
            }
        }
    }
    ${DynamicFilterMetricsPartFragmentDoc}
`;

/**
 * __useGetDynamicFilterMetricsQuery__
 *
 * To run a query within a React component, call `useGetDynamicFilterMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicFilterMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicFilterMetricsQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDynamicFilterMetricsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDynamicFilterMetricsQuery,
        Types.GetDynamicFilterMetricsQueryVariables
    > &
        (
            | {
                  variables: Types.GetDynamicFilterMetricsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDynamicFilterMetricsQuery,
        Types.GetDynamicFilterMetricsQueryVariables
    >(GqlOperationGetDynamicFilterMetricsDocument, options);
}
export function useGetDynamicFilterMetricsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDynamicFilterMetricsQuery,
        Types.GetDynamicFilterMetricsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDynamicFilterMetricsQuery,
        Types.GetDynamicFilterMetricsQueryVariables
    >(GqlOperationGetDynamicFilterMetricsDocument, options);
}
export function useGetDynamicFilterMetricsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDynamicFilterMetricsQuery,
              Types.GetDynamicFilterMetricsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDynamicFilterMetricsQuery,
        Types.GetDynamicFilterMetricsQueryVariables
    >(GqlOperationGetDynamicFilterMetricsDocument, options);
}
export type GetDynamicFilterMetricsQueryHookResult = ReturnType<
    typeof useGetDynamicFilterMetricsQuery
>;
export type GetDynamicFilterMetricsLazyQueryHookResult = ReturnType<
    typeof useGetDynamicFilterMetricsLazyQuery
>;
export type GetDynamicFilterMetricsSuspenseQueryHookResult = ReturnType<
    typeof useGetDynamicFilterMetricsSuspenseQuery
>;
export type GetDynamicFilterMetricsQueryResult = Apollo.QueryResult<
    Types.GetDynamicFilterMetricsQuery,
    Types.GetDynamicFilterMetricsQueryVariables
>;
export const GqlOperationGetDealsCollectionByPipelineDocument = gql`
    query GetDealsCollectionByPipeline($queryParams: QueryParamsQL!) {
        dealsCollection(queryParams: $queryParams) {
            edges {
                node {
                    ...PipelineDealPart
                }
            }
            totalCount
        }
    }
    ${PipelineDealPartFragmentDoc}
`;

/**
 * __useGetDealsCollectionByPipelineQuery__
 *
 * To run a query within a React component, call `useGetDealsCollectionByPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealsCollectionByPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealsCollectionByPipelineQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetDealsCollectionByPipelineQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealsCollectionByPipelineQuery,
        Types.GetDealsCollectionByPipelineQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealsCollectionByPipelineQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealsCollectionByPipelineQuery,
        Types.GetDealsCollectionByPipelineQueryVariables
    >(GqlOperationGetDealsCollectionByPipelineDocument, options);
}
export function useGetDealsCollectionByPipelineLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealsCollectionByPipelineQuery,
        Types.GetDealsCollectionByPipelineQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealsCollectionByPipelineQuery,
        Types.GetDealsCollectionByPipelineQueryVariables
    >(GqlOperationGetDealsCollectionByPipelineDocument, options);
}
export function useGetDealsCollectionByPipelineSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealsCollectionByPipelineQuery,
              Types.GetDealsCollectionByPipelineQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealsCollectionByPipelineQuery,
        Types.GetDealsCollectionByPipelineQueryVariables
    >(GqlOperationGetDealsCollectionByPipelineDocument, options);
}
export type GetDealsCollectionByPipelineQueryHookResult = ReturnType<
    typeof useGetDealsCollectionByPipelineQuery
>;
export type GetDealsCollectionByPipelineLazyQueryHookResult = ReturnType<
    typeof useGetDealsCollectionByPipelineLazyQuery
>;
export type GetDealsCollectionByPipelineSuspenseQueryHookResult = ReturnType<
    typeof useGetDealsCollectionByPipelineSuspenseQuery
>;
export type GetDealsCollectionByPipelineQueryResult = Apollo.QueryResult<
    Types.GetDealsCollectionByPipelineQuery,
    Types.GetDealsCollectionByPipelineQueryVariables
>;
export const GqlOperationAskQuestionDocument = gql`
    query AskQuestion(
        $question: String!
        $projectId: Int
        $documentId: String
        $dealId: String
    ) {
        actionFeatureXray(
            question: $question
            projectId: $projectId
            documentId: $documentId
            dealId: $dealId
        ) {
            answer
        }
    }
`;

/**
 * __useAskQuestionQuery__
 *
 * To run a query within a React component, call `useAskQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAskQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskQuestionQuery({
 *   variables: {
 *      question: // value for 'question'
 *      projectId: // value for 'projectId'
 *      documentId: // value for 'documentId'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useAskQuestionQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.AskQuestionQuery,
        Types.AskQuestionQueryVariables
    > &
        (
            | { variables: Types.AskQuestionQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.AskQuestionQuery,
        Types.AskQuestionQueryVariables
    >(GqlOperationAskQuestionDocument, options);
}
export function useAskQuestionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.AskQuestionQuery,
        Types.AskQuestionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.AskQuestionQuery,
        Types.AskQuestionQueryVariables
    >(GqlOperationAskQuestionDocument, options);
}
export function useAskQuestionSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.AskQuestionQuery,
              Types.AskQuestionQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.AskQuestionQuery,
        Types.AskQuestionQueryVariables
    >(GqlOperationAskQuestionDocument, options);
}
export type AskQuestionQueryHookResult = ReturnType<typeof useAskQuestionQuery>;
export type AskQuestionLazyQueryHookResult = ReturnType<
    typeof useAskQuestionLazyQuery
>;
export type AskQuestionSuspenseQueryHookResult = ReturnType<
    typeof useAskQuestionSuspenseQuery
>;
export type AskQuestionQueryResult = Apollo.QueryResult<
    Types.AskQuestionQuery,
    Types.AskQuestionQueryVariables
>;
export const GqlOperationAskQuestionRealtimeDocument = gql`
    subscription AskQuestionRealtime(
        $question: String!
        $projectId: Int
        $documentId: String
        $dealId: String
    ) {
        actionFeatureXray(
            projectId: $projectId
            question: $question
            documentId: $documentId
            dealId: $dealId
        ) {
            answer
        }
    }
`;

/**
 * __useAskQuestionRealtimeSubscription__
 *
 * To run a query within a React component, call `useAskQuestionRealtimeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAskQuestionRealtimeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskQuestionRealtimeSubscription({
 *   variables: {
 *      question: // value for 'question'
 *      projectId: // value for 'projectId'
 *      documentId: // value for 'documentId'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useAskQuestionRealtimeSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.AskQuestionRealtimeSubscription,
        Types.AskQuestionRealtimeSubscriptionVariables
    > &
        (
            | {
                  variables: Types.AskQuestionRealtimeSubscriptionVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.AskQuestionRealtimeSubscription,
        Types.AskQuestionRealtimeSubscriptionVariables
    >(GqlOperationAskQuestionRealtimeDocument, options);
}
export type AskQuestionRealtimeSubscriptionHookResult = ReturnType<
    typeof useAskQuestionRealtimeSubscription
>;
export type AskQuestionRealtimeSubscriptionResult =
    Apollo.SubscriptionResult<Types.AskQuestionRealtimeSubscription>;
export const GqlOperationLiveUserNotificationsDocument = gql`
    subscription LiveUserNotifications {
        subscribeUserUpdates {
            ...InstantMessage
        }
    }
    ${InstantMessageFragmentDoc}
`;

/**
 * __useLiveUserNotificationsSubscription__
 *
 * To run a query within a React component, call `useLiveUserNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveUserNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveUserNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLiveUserNotificationsSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        Types.LiveUserNotificationsSubscription,
        Types.LiveUserNotificationsSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.LiveUserNotificationsSubscription,
        Types.LiveUserNotificationsSubscriptionVariables
    >(GqlOperationLiveUserNotificationsDocument, options);
}
export type LiveUserNotificationsSubscriptionHookResult = ReturnType<
    typeof useLiveUserNotificationsSubscription
>;
export type LiveUserNotificationsSubscriptionResult =
    Apollo.SubscriptionResult<Types.LiveUserNotificationsSubscription>;
export const GqlOperationGenerateValuePropEmailDocument = gql`
    query GenerateValuePropEmail(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
        $vpoSessionId: String!
    ) {
        actionFeatureValuePropOutreachEmail(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
            vpoSessionId: $vpoSessionId
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropEmailQuery__
 *
 * To run a query within a React component, call `useGenerateValuePropEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropEmailQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *      vpoSessionId: // value for 'vpoSessionId'
 *   },
 * });
 */
export function useGenerateValuePropEmailQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GenerateValuePropEmailQuery,
        Types.GenerateValuePropEmailQueryVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropEmailQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GenerateValuePropEmailQuery,
        Types.GenerateValuePropEmailQueryVariables
    >(GqlOperationGenerateValuePropEmailDocument, options);
}
export function useGenerateValuePropEmailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GenerateValuePropEmailQuery,
        Types.GenerateValuePropEmailQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GenerateValuePropEmailQuery,
        Types.GenerateValuePropEmailQueryVariables
    >(GqlOperationGenerateValuePropEmailDocument, options);
}
export function useGenerateValuePropEmailSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GenerateValuePropEmailQuery,
              Types.GenerateValuePropEmailQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GenerateValuePropEmailQuery,
        Types.GenerateValuePropEmailQueryVariables
    >(GqlOperationGenerateValuePropEmailDocument, options);
}
export type GenerateValuePropEmailQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailQuery
>;
export type GenerateValuePropEmailLazyQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailLazyQuery
>;
export type GenerateValuePropEmailSuspenseQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailSuspenseQuery
>;
export type GenerateValuePropEmailQueryResult = Apollo.QueryResult<
    Types.GenerateValuePropEmailQuery,
    Types.GenerateValuePropEmailQueryVariables
>;
export const GqlOperationGenerateValuePropEmailFollowUpDocument = gql`
    query GenerateValuePropEmailFollowUp(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachFollowUp(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropEmailFollowUpQuery__
 *
 * To run a query within a React component, call `useGenerateValuePropEmailFollowUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropEmailFollowUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropEmailFollowUpQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *   },
 * });
 */
export function useGenerateValuePropEmailFollowUpQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GenerateValuePropEmailFollowUpQuery,
        Types.GenerateValuePropEmailFollowUpQueryVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropEmailFollowUpQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GenerateValuePropEmailFollowUpQuery,
        Types.GenerateValuePropEmailFollowUpQueryVariables
    >(GqlOperationGenerateValuePropEmailFollowUpDocument, options);
}
export function useGenerateValuePropEmailFollowUpLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GenerateValuePropEmailFollowUpQuery,
        Types.GenerateValuePropEmailFollowUpQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GenerateValuePropEmailFollowUpQuery,
        Types.GenerateValuePropEmailFollowUpQueryVariables
    >(GqlOperationGenerateValuePropEmailFollowUpDocument, options);
}
export function useGenerateValuePropEmailFollowUpSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GenerateValuePropEmailFollowUpQuery,
              Types.GenerateValuePropEmailFollowUpQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GenerateValuePropEmailFollowUpQuery,
        Types.GenerateValuePropEmailFollowUpQueryVariables
    >(GqlOperationGenerateValuePropEmailFollowUpDocument, options);
}
export type GenerateValuePropEmailFollowUpQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailFollowUpQuery
>;
export type GenerateValuePropEmailFollowUpLazyQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailFollowUpLazyQuery
>;
export type GenerateValuePropEmailFollowUpSuspenseQueryHookResult = ReturnType<
    typeof useGenerateValuePropEmailFollowUpSuspenseQuery
>;
export type GenerateValuePropEmailFollowUpQueryResult = Apollo.QueryResult<
    Types.GenerateValuePropEmailFollowUpQuery,
    Types.GenerateValuePropEmailFollowUpQueryVariables
>;
export const GqlOperationGenerateValuePropCallScriptDocument = gql`
    query GenerateValuePropCallScript(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachCallScript(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropCallScriptQuery__
 *
 * To run a query within a React component, call `useGenerateValuePropCallScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropCallScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropCallScriptQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *   },
 * });
 */
export function useGenerateValuePropCallScriptQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GenerateValuePropCallScriptQuery,
        Types.GenerateValuePropCallScriptQueryVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropCallScriptQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GenerateValuePropCallScriptQuery,
        Types.GenerateValuePropCallScriptQueryVariables
    >(GqlOperationGenerateValuePropCallScriptDocument, options);
}
export function useGenerateValuePropCallScriptLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GenerateValuePropCallScriptQuery,
        Types.GenerateValuePropCallScriptQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GenerateValuePropCallScriptQuery,
        Types.GenerateValuePropCallScriptQueryVariables
    >(GqlOperationGenerateValuePropCallScriptDocument, options);
}
export function useGenerateValuePropCallScriptSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GenerateValuePropCallScriptQuery,
              Types.GenerateValuePropCallScriptQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GenerateValuePropCallScriptQuery,
        Types.GenerateValuePropCallScriptQueryVariables
    >(GqlOperationGenerateValuePropCallScriptDocument, options);
}
export type GenerateValuePropCallScriptQueryHookResult = ReturnType<
    typeof useGenerateValuePropCallScriptQuery
>;
export type GenerateValuePropCallScriptLazyQueryHookResult = ReturnType<
    typeof useGenerateValuePropCallScriptLazyQuery
>;
export type GenerateValuePropCallScriptSuspenseQueryHookResult = ReturnType<
    typeof useGenerateValuePropCallScriptSuspenseQuery
>;
export type GenerateValuePropCallScriptQueryResult = Apollo.QueryResult<
    Types.GenerateValuePropCallScriptQuery,
    Types.GenerateValuePropCallScriptQueryVariables
>;
export const GqlOperationGenerateValuePropEmailStreamDocument = gql`
    subscription GenerateValuePropEmailStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
        $vpoSessionId: String!
    ) {
        actionFeatureValuePropOutreachEmail(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
            vpoSessionId: $vpoSessionId
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropEmailStreamSubscription__
 *
 * To run a query within a React component, call `useGenerateValuePropEmailStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropEmailStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropEmailStreamSubscription({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *      vpoSessionId: // value for 'vpoSessionId'
 *   },
 * });
 */
export function useGenerateValuePropEmailStreamSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.GenerateValuePropEmailStreamSubscription,
        Types.GenerateValuePropEmailStreamSubscriptionVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropEmailStreamSubscriptionVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.GenerateValuePropEmailStreamSubscription,
        Types.GenerateValuePropEmailStreamSubscriptionVariables
    >(GqlOperationGenerateValuePropEmailStreamDocument, options);
}
export type GenerateValuePropEmailStreamSubscriptionHookResult = ReturnType<
    typeof useGenerateValuePropEmailStreamSubscription
>;
export type GenerateValuePropEmailStreamSubscriptionResult =
    Apollo.SubscriptionResult<Types.GenerateValuePropEmailStreamSubscription>;
export const GqlOperationGenerateValuePropEmailFollowUpStreamDocument = gql`
    subscription GenerateValuePropEmailFollowUpStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachFollowUp(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropEmailFollowUpStreamSubscription__
 *
 * To run a query within a React component, call `useGenerateValuePropEmailFollowUpStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropEmailFollowUpStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropEmailFollowUpStreamSubscription({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *   },
 * });
 */
export function useGenerateValuePropEmailFollowUpStreamSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.GenerateValuePropEmailFollowUpStreamSubscription,
        Types.GenerateValuePropEmailFollowUpStreamSubscriptionVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropEmailFollowUpStreamSubscriptionVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.GenerateValuePropEmailFollowUpStreamSubscription,
        Types.GenerateValuePropEmailFollowUpStreamSubscriptionVariables
    >(GqlOperationGenerateValuePropEmailFollowUpStreamDocument, options);
}
export type GenerateValuePropEmailFollowUpStreamSubscriptionHookResult =
    ReturnType<typeof useGenerateValuePropEmailFollowUpStreamSubscription>;
export type GenerateValuePropEmailFollowUpStreamSubscriptionResult =
    Apollo.SubscriptionResult<Types.GenerateValuePropEmailFollowUpStreamSubscription>;
export const GqlOperationGenerateValuePropCallScriptStreamDocument = gql`
    subscription GenerateValuePropCallScriptStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachCallScript(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;

/**
 * __useGenerateValuePropCallScriptStreamSubscription__
 *
 * To run a query within a React component, call `useGenerateValuePropCallScriptStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGenerateValuePropCallScriptStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateValuePropCallScriptStreamSubscription({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      regenerationSpec: // value for 'regenerationSpec'
 *      outreachBlockIds: // value for 'outreachBlockIds'
 *   },
 * });
 */
export function useGenerateValuePropCallScriptStreamSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.GenerateValuePropCallScriptStreamSubscription,
        Types.GenerateValuePropCallScriptStreamSubscriptionVariables
    > &
        (
            | {
                  variables: Types.GenerateValuePropCallScriptStreamSubscriptionVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.GenerateValuePropCallScriptStreamSubscription,
        Types.GenerateValuePropCallScriptStreamSubscriptionVariables
    >(GqlOperationGenerateValuePropCallScriptStreamDocument, options);
}
export type GenerateValuePropCallScriptStreamSubscriptionHookResult =
    ReturnType<typeof useGenerateValuePropCallScriptStreamSubscription>;
export type GenerateValuePropCallScriptStreamSubscriptionResult =
    Apollo.SubscriptionResult<Types.GenerateValuePropCallScriptStreamSubscription>;
export const GqlOperationGetDomainEnrichmentResultDocument = gql`
    query GetDomainEnrichmentResult(
        $companyName: String!
        $dealId: String!
        $relevantCompanyId: String!
        $contactId: String!
    ) {
        actionFeatureContactEnrichmentCompanyDomain(
            companyName: $companyName
            dealId: $dealId
            relevantCompanyId: $relevantCompanyId
            contactId: $contactId
        ) {
            domain
            name
            providers
        }
    }
`;

/**
 * __useGetDomainEnrichmentResultQuery__
 *
 * To run a query within a React component, call `useGetDomainEnrichmentResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainEnrichmentResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainEnrichmentResultQuery({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      dealId: // value for 'dealId'
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetDomainEnrichmentResultQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDomainEnrichmentResultQuery,
        Types.GetDomainEnrichmentResultQueryVariables
    > &
        (
            | {
                  variables: Types.GetDomainEnrichmentResultQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDomainEnrichmentResultQuery,
        Types.GetDomainEnrichmentResultQueryVariables
    >(GqlOperationGetDomainEnrichmentResultDocument, options);
}
export function useGetDomainEnrichmentResultLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDomainEnrichmentResultQuery,
        Types.GetDomainEnrichmentResultQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDomainEnrichmentResultQuery,
        Types.GetDomainEnrichmentResultQueryVariables
    >(GqlOperationGetDomainEnrichmentResultDocument, options);
}
export function useGetDomainEnrichmentResultSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDomainEnrichmentResultQuery,
              Types.GetDomainEnrichmentResultQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDomainEnrichmentResultQuery,
        Types.GetDomainEnrichmentResultQueryVariables
    >(GqlOperationGetDomainEnrichmentResultDocument, options);
}
export type GetDomainEnrichmentResultQueryHookResult = ReturnType<
    typeof useGetDomainEnrichmentResultQuery
>;
export type GetDomainEnrichmentResultLazyQueryHookResult = ReturnType<
    typeof useGetDomainEnrichmentResultLazyQuery
>;
export type GetDomainEnrichmentResultSuspenseQueryHookResult = ReturnType<
    typeof useGetDomainEnrichmentResultSuspenseQuery
>;
export type GetDomainEnrichmentResultQueryResult = Apollo.QueryResult<
    Types.GetDomainEnrichmentResultQuery,
    Types.GetDomainEnrichmentResultQueryVariables
>;
export const GqlOperationGetContactEnrichmentResultDocument = gql`
    query GetContactEnrichmentResult(
        $dealId: String!
        $contactName: String!
        $company: String!
        $domain: String = null
        $linkedinUrl: String = null
        $contactId: String = null
        $triggeredFromSearchRefinement: Boolean = false
    ) {
        actionFeatureContactEnrichment(
            dealId: $dealId
            company: $company
            contactName: $contactName
            domain: $domain
            linkedinUrl: $linkedinUrl
            contactId: $contactId
            triggeredFromSearchRefinement: $triggeredFromSearchRefinement
        ) {
            emails {
                value
                providers
            }
            phones {
                value
                providers
            }
            linkedins {
                value
                providers
            }
            positions {
                value
                providers
            }
        }
    }
`;

/**
 * __useGetContactEnrichmentResultQuery__
 *
 * To run a query within a React component, call `useGetContactEnrichmentResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactEnrichmentResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactEnrichmentResultQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      contactName: // value for 'contactName'
 *      company: // value for 'company'
 *      domain: // value for 'domain'
 *      linkedinUrl: // value for 'linkedinUrl'
 *      contactId: // value for 'contactId'
 *      triggeredFromSearchRefinement: // value for 'triggeredFromSearchRefinement'
 *   },
 * });
 */
export function useGetContactEnrichmentResultQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetContactEnrichmentResultQuery,
        Types.GetContactEnrichmentResultQueryVariables
    > &
        (
            | {
                  variables: Types.GetContactEnrichmentResultQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetContactEnrichmentResultQuery,
        Types.GetContactEnrichmentResultQueryVariables
    >(GqlOperationGetContactEnrichmentResultDocument, options);
}
export function useGetContactEnrichmentResultLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetContactEnrichmentResultQuery,
        Types.GetContactEnrichmentResultQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetContactEnrichmentResultQuery,
        Types.GetContactEnrichmentResultQueryVariables
    >(GqlOperationGetContactEnrichmentResultDocument, options);
}
export function useGetContactEnrichmentResultSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetContactEnrichmentResultQuery,
              Types.GetContactEnrichmentResultQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetContactEnrichmentResultQuery,
        Types.GetContactEnrichmentResultQueryVariables
    >(GqlOperationGetContactEnrichmentResultDocument, options);
}
export type GetContactEnrichmentResultQueryHookResult = ReturnType<
    typeof useGetContactEnrichmentResultQuery
>;
export type GetContactEnrichmentResultLazyQueryHookResult = ReturnType<
    typeof useGetContactEnrichmentResultLazyQuery
>;
export type GetContactEnrichmentResultSuspenseQueryHookResult = ReturnType<
    typeof useGetContactEnrichmentResultSuspenseQuery
>;
export type GetContactEnrichmentResultQueryResult = Apollo.QueryResult<
    Types.GetContactEnrichmentResultQuery,
    Types.GetContactEnrichmentResultQueryVariables
>;
export const GqlOperationSetAchievementsOptInDocument = gql`
    mutation SetAchievementsOptIn($optIn: Boolean!) {
        updateUserAchievementsEnrolmentOptIn(optIn: $optIn) {
            ok
        }
    }
`;
export type SetAchievementsOptInMutationFn = Apollo.MutationFunction<
    Types.SetAchievementsOptInMutation,
    Types.SetAchievementsOptInMutationVariables
>;

/**
 * __useSetAchievementsOptInMutation__
 *
 * To run a mutation, you first call `useSetAchievementsOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAchievementsOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAchievementsOptInMutation, { data, loading, error }] = useSetAchievementsOptInMutation({
 *   variables: {
 *      optIn: // value for 'optIn'
 *   },
 * });
 */
export function useSetAchievementsOptInMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SetAchievementsOptInMutation,
        Types.SetAchievementsOptInMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SetAchievementsOptInMutation,
        Types.SetAchievementsOptInMutationVariables
    >(GqlOperationSetAchievementsOptInDocument, options);
}
export type SetAchievementsOptInMutationHookResult = ReturnType<
    typeof useSetAchievementsOptInMutation
>;
export type SetAchievementsOptInMutationResult =
    Apollo.MutationResult<Types.SetAchievementsOptInMutation>;
export type SetAchievementsOptInMutationOptions = Apollo.BaseMutationOptions<
    Types.SetAchievementsOptInMutation,
    Types.SetAchievementsOptInMutationVariables
>;
export const GqlOperationGetUserAchievementsDocument = gql`
    query GetUserAchievements {
        userAchievements {
            achievements {
                ...UserAchievements
            }
        }
    }
    ${UserAchievementsFragmentDoc}
`;

/**
 * __useGetUserAchievementsQuery__
 *
 * To run a query within a React component, call `useGetUserAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAchievementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAchievementsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetUserAchievementsQuery,
        Types.GetUserAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetUserAchievementsQuery,
        Types.GetUserAchievementsQueryVariables
    >(GqlOperationGetUserAchievementsDocument, options);
}
export function useGetUserAchievementsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetUserAchievementsQuery,
        Types.GetUserAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetUserAchievementsQuery,
        Types.GetUserAchievementsQueryVariables
    >(GqlOperationGetUserAchievementsDocument, options);
}
export function useGetUserAchievementsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetUserAchievementsQuery,
              Types.GetUserAchievementsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetUserAchievementsQuery,
        Types.GetUserAchievementsQueryVariables
    >(GqlOperationGetUserAchievementsDocument, options);
}
export type GetUserAchievementsQueryHookResult = ReturnType<
    typeof useGetUserAchievementsQuery
>;
export type GetUserAchievementsLazyQueryHookResult = ReturnType<
    typeof useGetUserAchievementsLazyQuery
>;
export type GetUserAchievementsSuspenseQueryHookResult = ReturnType<
    typeof useGetUserAchievementsSuspenseQuery
>;
export type GetUserAchievementsQueryResult = Apollo.QueryResult<
    Types.GetUserAchievementsQuery,
    Types.GetUserAchievementsQueryVariables
>;
export const GqlOperationUpdateAchievementStateDocument = gql`
    mutation UpdateAchievementState(
        $name: UserAchievementName!
        $state: UserAchievementState!
    ) {
        updateUserAchievementState(achievementName: $name, status: $state) {
            ok
        }
    }
`;
export type UpdateAchievementStateMutationFn = Apollo.MutationFunction<
    Types.UpdateAchievementStateMutation,
    Types.UpdateAchievementStateMutationVariables
>;

/**
 * __useUpdateAchievementStateMutation__
 *
 * To run a mutation, you first call `useUpdateAchievementStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchievementStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchievementStateMutation, { data, loading, error }] = useUpdateAchievementStateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateAchievementStateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateAchievementStateMutation,
        Types.UpdateAchievementStateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateAchievementStateMutation,
        Types.UpdateAchievementStateMutationVariables
    >(GqlOperationUpdateAchievementStateDocument, options);
}
export type UpdateAchievementStateMutationHookResult = ReturnType<
    typeof useUpdateAchievementStateMutation
>;
export type UpdateAchievementStateMutationResult =
    Apollo.MutationResult<Types.UpdateAchievementStateMutation>;
export type UpdateAchievementStateMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateAchievementStateMutation,
    Types.UpdateAchievementStateMutationVariables
>;
export const GqlOperationGetValueMilestonesDocument = gql`
    query GetValueMilestones {
        allValueMilestones {
            ...ValueMilestone
        }
    }
    ${ValueMilestoneFragmentDoc}
`;

/**
 * __useGetValueMilestonesQuery__
 *
 * To run a query within a React component, call `useGetValueMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueMilestonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetValueMilestonesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetValueMilestonesQuery,
        Types.GetValueMilestonesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetValueMilestonesQuery,
        Types.GetValueMilestonesQueryVariables
    >(GqlOperationGetValueMilestonesDocument, options);
}
export function useGetValueMilestonesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetValueMilestonesQuery,
        Types.GetValueMilestonesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetValueMilestonesQuery,
        Types.GetValueMilestonesQueryVariables
    >(GqlOperationGetValueMilestonesDocument, options);
}
export function useGetValueMilestonesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetValueMilestonesQuery,
              Types.GetValueMilestonesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetValueMilestonesQuery,
        Types.GetValueMilestonesQueryVariables
    >(GqlOperationGetValueMilestonesDocument, options);
}
export type GetValueMilestonesQueryHookResult = ReturnType<
    typeof useGetValueMilestonesQuery
>;
export type GetValueMilestonesLazyQueryHookResult = ReturnType<
    typeof useGetValueMilestonesLazyQuery
>;
export type GetValueMilestonesSuspenseQueryHookResult = ReturnType<
    typeof useGetValueMilestonesSuspenseQuery
>;
export type GetValueMilestonesQueryResult = Apollo.QueryResult<
    Types.GetValueMilestonesQuery,
    Types.GetValueMilestonesQueryVariables
>;
export const GqlOperationGetTeamsAchievementsDocument = gql`
    query GetTeamsAchievements {
        colleaguesAchievements {
            ...ColleagueAchievements
        }
    }
    ${ColleagueAchievementsFragmentDoc}
`;

/**
 * __useGetTeamsAchievementsQuery__
 *
 * To run a query within a React component, call `useGetTeamsAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsAchievementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsAchievementsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.GetTeamsAchievementsQuery,
        Types.GetTeamsAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetTeamsAchievementsQuery,
        Types.GetTeamsAchievementsQueryVariables
    >(GqlOperationGetTeamsAchievementsDocument, options);
}
export function useGetTeamsAchievementsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetTeamsAchievementsQuery,
        Types.GetTeamsAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetTeamsAchievementsQuery,
        Types.GetTeamsAchievementsQueryVariables
    >(GqlOperationGetTeamsAchievementsDocument, options);
}
export function useGetTeamsAchievementsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetTeamsAchievementsQuery,
              Types.GetTeamsAchievementsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetTeamsAchievementsQuery,
        Types.GetTeamsAchievementsQueryVariables
    >(GqlOperationGetTeamsAchievementsDocument, options);
}
export type GetTeamsAchievementsQueryHookResult = ReturnType<
    typeof useGetTeamsAchievementsQuery
>;
export type GetTeamsAchievementsLazyQueryHookResult = ReturnType<
    typeof useGetTeamsAchievementsLazyQuery
>;
export type GetTeamsAchievementsSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamsAchievementsSuspenseQuery
>;
export type GetTeamsAchievementsQueryResult = Apollo.QueryResult<
    Types.GetTeamsAchievementsQuery,
    Types.GetTeamsAchievementsQueryVariables
>;
export const GqlOperationGetPipelineStatisticsDocument = gql`
    query GetPipelineStatistics(
        $forCurrentUser: Boolean!
        $pipelineId: String!
    ) {
        pipelineStatistics(
            pipelineId: $pipelineId
            forCurrentUser: $forCurrentUser
        ) {
            ...PipelineStatistics
        }
    }
    ${PipelineStatisticsFragmentDoc}
`;

/**
 * __useGetPipelineStatisticsQuery__
 *
 * To run a query within a React component, call `useGetPipelineStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineStatisticsQuery({
 *   variables: {
 *      forCurrentUser: // value for 'forCurrentUser'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useGetPipelineStatisticsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetPipelineStatisticsQuery,
        Types.GetPipelineStatisticsQueryVariables
    > &
        (
            | {
                  variables: Types.GetPipelineStatisticsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetPipelineStatisticsQuery,
        Types.GetPipelineStatisticsQueryVariables
    >(GqlOperationGetPipelineStatisticsDocument, options);
}
export function useGetPipelineStatisticsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetPipelineStatisticsQuery,
        Types.GetPipelineStatisticsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetPipelineStatisticsQuery,
        Types.GetPipelineStatisticsQueryVariables
    >(GqlOperationGetPipelineStatisticsDocument, options);
}
export function useGetPipelineStatisticsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetPipelineStatisticsQuery,
              Types.GetPipelineStatisticsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetPipelineStatisticsQuery,
        Types.GetPipelineStatisticsQueryVariables
    >(GqlOperationGetPipelineStatisticsDocument, options);
}
export type GetPipelineStatisticsQueryHookResult = ReturnType<
    typeof useGetPipelineStatisticsQuery
>;
export type GetPipelineStatisticsLazyQueryHookResult = ReturnType<
    typeof useGetPipelineStatisticsLazyQuery
>;
export type GetPipelineStatisticsSuspenseQueryHookResult = ReturnType<
    typeof useGetPipelineStatisticsSuspenseQuery
>;
export type GetPipelineStatisticsQueryResult = Apollo.QueryResult<
    Types.GetPipelineStatisticsQuery,
    Types.GetPipelineStatisticsQueryVariables
>;
export const GqlOperationGetCustomFieldsForDealsDocument = gql`
    query GetCustomFieldsForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    customFields {
                        isTag
                        label
                        name
                        value
                    }
                }
            }
        }
    }
`;

/**
 * __useGetCustomFieldsForDealsQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldsForDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldsForDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldsForDealsQuery({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *   },
 * });
 */
export function useGetCustomFieldsForDealsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetCustomFieldsForDealsQuery,
        Types.GetCustomFieldsForDealsQueryVariables
    > &
        (
            | {
                  variables: Types.GetCustomFieldsForDealsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCustomFieldsForDealsQuery,
        Types.GetCustomFieldsForDealsQueryVariables
    >(GqlOperationGetCustomFieldsForDealsDocument, options);
}
export function useGetCustomFieldsForDealsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCustomFieldsForDealsQuery,
        Types.GetCustomFieldsForDealsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCustomFieldsForDealsQuery,
        Types.GetCustomFieldsForDealsQueryVariables
    >(GqlOperationGetCustomFieldsForDealsDocument, options);
}
export function useGetCustomFieldsForDealsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCustomFieldsForDealsQuery,
              Types.GetCustomFieldsForDealsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCustomFieldsForDealsQuery,
        Types.GetCustomFieldsForDealsQueryVariables
    >(GqlOperationGetCustomFieldsForDealsDocument, options);
}
export type GetCustomFieldsForDealsQueryHookResult = ReturnType<
    typeof useGetCustomFieldsForDealsQuery
>;
export type GetCustomFieldsForDealsLazyQueryHookResult = ReturnType<
    typeof useGetCustomFieldsForDealsLazyQuery
>;
export type GetCustomFieldsForDealsSuspenseQueryHookResult = ReturnType<
    typeof useGetCustomFieldsForDealsSuspenseQuery
>;
export type GetCustomFieldsForDealsQueryResult = Apollo.QueryResult<
    Types.GetCustomFieldsForDealsQuery,
    Types.GetCustomFieldsForDealsQueryVariables
>;
export const GqlOperationGetRemindersForDealsDocument = gql`
    query GetRemindersForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    reminders {
                        ...ReminderPart
                    }
                }
            }
        }
    }
    ${ReminderPartFragmentDoc}
`;

/**
 * __useGetRemindersForDealsQuery__
 *
 * To run a query within a React component, call `useGetRemindersForDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemindersForDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemindersForDealsQuery({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *   },
 * });
 */
export function useGetRemindersForDealsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetRemindersForDealsQuery,
        Types.GetRemindersForDealsQueryVariables
    > &
        (
            | {
                  variables: Types.GetRemindersForDealsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetRemindersForDealsQuery,
        Types.GetRemindersForDealsQueryVariables
    >(GqlOperationGetRemindersForDealsDocument, options);
}
export function useGetRemindersForDealsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetRemindersForDealsQuery,
        Types.GetRemindersForDealsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetRemindersForDealsQuery,
        Types.GetRemindersForDealsQueryVariables
    >(GqlOperationGetRemindersForDealsDocument, options);
}
export function useGetRemindersForDealsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetRemindersForDealsQuery,
              Types.GetRemindersForDealsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetRemindersForDealsQuery,
        Types.GetRemindersForDealsQueryVariables
    >(GqlOperationGetRemindersForDealsDocument, options);
}
export type GetRemindersForDealsQueryHookResult = ReturnType<
    typeof useGetRemindersForDealsQuery
>;
export type GetRemindersForDealsLazyQueryHookResult = ReturnType<
    typeof useGetRemindersForDealsLazyQuery
>;
export type GetRemindersForDealsSuspenseQueryHookResult = ReturnType<
    typeof useGetRemindersForDealsSuspenseQuery
>;
export type GetRemindersForDealsQueryResult = Apollo.QueryResult<
    Types.GetRemindersForDealsQuery,
    Types.GetRemindersForDealsQueryVariables
>;
export const GqlOperationGetCommentsForDealsDocument = gql`
    query GetCommentsForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    comments {
                        ...DealComment
                    }
                }
            }
        }
    }
    ${DealCommentFragmentDoc}
`;

/**
 * __useGetCommentsForDealsQuery__
 *
 * To run a query within a React component, call `useGetCommentsForDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsForDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsForDealsQuery({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *   },
 * });
 */
export function useGetCommentsForDealsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetCommentsForDealsQuery,
        Types.GetCommentsForDealsQueryVariables
    > &
        (
            | {
                  variables: Types.GetCommentsForDealsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetCommentsForDealsQuery,
        Types.GetCommentsForDealsQueryVariables
    >(GqlOperationGetCommentsForDealsDocument, options);
}
export function useGetCommentsForDealsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCommentsForDealsQuery,
        Types.GetCommentsForDealsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetCommentsForDealsQuery,
        Types.GetCommentsForDealsQueryVariables
    >(GqlOperationGetCommentsForDealsDocument, options);
}
export function useGetCommentsForDealsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetCommentsForDealsQuery,
              Types.GetCommentsForDealsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetCommentsForDealsQuery,
        Types.GetCommentsForDealsQueryVariables
    >(GqlOperationGetCommentsForDealsDocument, options);
}
export type GetCommentsForDealsQueryHookResult = ReturnType<
    typeof useGetCommentsForDealsQuery
>;
export type GetCommentsForDealsLazyQueryHookResult = ReturnType<
    typeof useGetCommentsForDealsLazyQuery
>;
export type GetCommentsForDealsSuspenseQueryHookResult = ReturnType<
    typeof useGetCommentsForDealsSuspenseQuery
>;
export type GetCommentsForDealsQueryResult = Apollo.QueryResult<
    Types.GetCommentsForDealsQuery,
    Types.GetCommentsForDealsQueryVariables
>;
export const GqlOperationDeleteOutreachBlockDocument = gql`
    mutation DeleteOutreachBlock(
        $dealId: String!
        $outreachBlockId: String!
        $vpoSessionId: String!
    ) {
        deleteOutreachBlock(
            dealId: $dealId
            outreachBlockId: $outreachBlockId
            vpoSessionId: $vpoSessionId
        ) {
            ok
        }
    }
`;
export type DeleteOutreachBlockMutationFn = Apollo.MutationFunction<
    Types.DeleteOutreachBlockMutation,
    Types.DeleteOutreachBlockMutationVariables
>;

/**
 * __useDeleteOutreachBlockMutation__
 *
 * To run a mutation, you first call `useDeleteOutreachBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutreachBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutreachBlockMutation, { data, loading, error }] = useDeleteOutreachBlockMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      outreachBlockId: // value for 'outreachBlockId'
 *      vpoSessionId: // value for 'vpoSessionId'
 *   },
 * });
 */
export function useDeleteOutreachBlockMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteOutreachBlockMutation,
        Types.DeleteOutreachBlockMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteOutreachBlockMutation,
        Types.DeleteOutreachBlockMutationVariables
    >(GqlOperationDeleteOutreachBlockDocument, options);
}
export type DeleteOutreachBlockMutationHookResult = ReturnType<
    typeof useDeleteOutreachBlockMutation
>;
export type DeleteOutreachBlockMutationResult =
    Apollo.MutationResult<Types.DeleteOutreachBlockMutation>;
export type DeleteOutreachBlockMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteOutreachBlockMutation,
    Types.DeleteOutreachBlockMutationVariables
>;
export const GqlOperationCreateOutreachBlockDocument = gql`
    mutation CreateOutreachBlock(
        $dealId: String!
        $outreachBlock: OutreachBlockCreateMutationQl!
        $vpoSessionId: String!
    ) {
        createOutreachBlock(
            dealId: $dealId
            outreachBlock: $outreachBlock
            vpoSessionId: $vpoSessionId
        ) {
            id
        }
    }
`;
export type CreateOutreachBlockMutationFn = Apollo.MutationFunction<
    Types.CreateOutreachBlockMutation,
    Types.CreateOutreachBlockMutationVariables
>;

/**
 * __useCreateOutreachBlockMutation__
 *
 * To run a mutation, you first call `useCreateOutreachBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutreachBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutreachBlockMutation, { data, loading, error }] = useCreateOutreachBlockMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      outreachBlock: // value for 'outreachBlock'
 *      vpoSessionId: // value for 'vpoSessionId'
 *   },
 * });
 */
export function useCreateOutreachBlockMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateOutreachBlockMutation,
        Types.CreateOutreachBlockMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateOutreachBlockMutation,
        Types.CreateOutreachBlockMutationVariables
    >(GqlOperationCreateOutreachBlockDocument, options);
}
export type CreateOutreachBlockMutationHookResult = ReturnType<
    typeof useCreateOutreachBlockMutation
>;
export type CreateOutreachBlockMutationResult =
    Apollo.MutationResult<Types.CreateOutreachBlockMutation>;
export type CreateOutreachBlockMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateOutreachBlockMutation,
    Types.CreateOutreachBlockMutationVariables
>;
export const GqlOperationUpdateOutreachBlockDocument = gql`
    mutation UpdateOutreachBlock(
        $dealId: String!
        $outreachBlock: OutreachBlockUpdateMutationQl!
        $vpoSessionId: String!
    ) {
        updateOutreachBlock(
            dealId: $dealId
            outreachBlock: $outreachBlock
            vpoSessionId: $vpoSessionId
        ) {
            ok
        }
    }
`;
export type UpdateOutreachBlockMutationFn = Apollo.MutationFunction<
    Types.UpdateOutreachBlockMutation,
    Types.UpdateOutreachBlockMutationVariables
>;

/**
 * __useUpdateOutreachBlockMutation__
 *
 * To run a mutation, you first call `useUpdateOutreachBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutreachBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutreachBlockMutation, { data, loading, error }] = useUpdateOutreachBlockMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      outreachBlock: // value for 'outreachBlock'
 *      vpoSessionId: // value for 'vpoSessionId'
 *   },
 * });
 */
export function useUpdateOutreachBlockMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateOutreachBlockMutation,
        Types.UpdateOutreachBlockMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateOutreachBlockMutation,
        Types.UpdateOutreachBlockMutationVariables
    >(GqlOperationUpdateOutreachBlockDocument, options);
}
export type UpdateOutreachBlockMutationHookResult = ReturnType<
    typeof useUpdateOutreachBlockMutation
>;
export type UpdateOutreachBlockMutationResult =
    Apollo.MutationResult<Types.UpdateOutreachBlockMutation>;
export type UpdateOutreachBlockMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateOutreachBlockMutation,
    Types.UpdateOutreachBlockMutationVariables
>;
export const GqlOperationTranslateDocument = gql`
    query Translate($text: String!, $language: String!) {
        translate(text: $text, language: $language)
    }
`;

/**
 * __useTranslateQuery__
 *
 * To run a query within a React component, call `useTranslateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateQuery({
 *   variables: {
 *      text: // value for 'text'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useTranslateQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.TranslateQuery,
        Types.TranslateQueryVariables
    > &
        (
            | { variables: Types.TranslateQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.TranslateQuery, Types.TranslateQueryVariables>(
        GqlOperationTranslateDocument,
        options,
    );
}
export function useTranslateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.TranslateQuery,
        Types.TranslateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.TranslateQuery,
        Types.TranslateQueryVariables
    >(GqlOperationTranslateDocument, options);
}
export function useTranslateSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.TranslateQuery,
              Types.TranslateQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.TranslateQuery,
        Types.TranslateQueryVariables
    >(GqlOperationTranslateDocument, options);
}
export type TranslateQueryHookResult = ReturnType<typeof useTranslateQuery>;
export type TranslateLazyQueryHookResult = ReturnType<
    typeof useTranslateLazyQuery
>;
export type TranslateSuspenseQueryHookResult = ReturnType<
    typeof useTranslateSuspenseQuery
>;
export type TranslateQueryResult = Apollo.QueryResult<
    Types.TranslateQuery,
    Types.TranslateQueryVariables
>;
export const GqlOperationCreateDealActivityDocument = gql`
    mutation CreateDealActivity(
        $activityType: ActivityLogType!
        $subType: ActivityLogSubType = null
        $dealId: String = null
        $contactId: String = null
        $relevantCompanyId: String = null
        $notes: String! = ""
        $customType: String! = ""
    ) {
        createActivityLog(
            activityType: $activityType
            subType: $subType
            dealId: $dealId
            contactId: $contactId
            relevantCompanyId: $relevantCompanyId
            notes: $notes
            customType: $customType
        ) {
            ...ActivityLogPart
        }
    }
    ${ActivityLogPartFragmentDoc}
`;
export type CreateDealActivityMutationFn = Apollo.MutationFunction<
    Types.CreateDealActivityMutation,
    Types.CreateDealActivityMutationVariables
>;

/**
 * __useCreateDealActivityMutation__
 *
 * To run a mutation, you first call `useCreateDealActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealActivityMutation, { data, loading, error }] = useCreateDealActivityMutation({
 *   variables: {
 *      activityType: // value for 'activityType'
 *      subType: // value for 'subType'
 *      dealId: // value for 'dealId'
 *      contactId: // value for 'contactId'
 *      relevantCompanyId: // value for 'relevantCompanyId'
 *      notes: // value for 'notes'
 *      customType: // value for 'customType'
 *   },
 * });
 */
export function useCreateDealActivityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateDealActivityMutation,
        Types.CreateDealActivityMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.CreateDealActivityMutation,
        Types.CreateDealActivityMutationVariables
    >(GqlOperationCreateDealActivityDocument, options);
}
export type CreateDealActivityMutationHookResult = ReturnType<
    typeof useCreateDealActivityMutation
>;
export type CreateDealActivityMutationResult =
    Apollo.MutationResult<Types.CreateDealActivityMutation>;
export type CreateDealActivityMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateDealActivityMutation,
    Types.CreateDealActivityMutationVariables
>;
export const GqlOperationUpdateDealActivityDocument = gql`
    mutation UpdateDealActivity(
        $activityLogId: String!
        $contactId: String = null
        $notes: String = ""
        $customType: String = ""
    ) {
        updateActivityLog(
            activityLogId: $activityLogId
            contactId: $contactId
            notes: $notes
            customType: $customType
        ) {
            ...ActivityLogPart
        }
    }
    ${ActivityLogPartFragmentDoc}
`;
export type UpdateDealActivityMutationFn = Apollo.MutationFunction<
    Types.UpdateDealActivityMutation,
    Types.UpdateDealActivityMutationVariables
>;

/**
 * __useUpdateDealActivityMutation__
 *
 * To run a mutation, you first call `useUpdateDealActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealActivityMutation, { data, loading, error }] = useUpdateDealActivityMutation({
 *   variables: {
 *      activityLogId: // value for 'activityLogId'
 *      contactId: // value for 'contactId'
 *      notes: // value for 'notes'
 *      customType: // value for 'customType'
 *   },
 * });
 */
export function useUpdateDealActivityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateDealActivityMutation,
        Types.UpdateDealActivityMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateDealActivityMutation,
        Types.UpdateDealActivityMutationVariables
    >(GqlOperationUpdateDealActivityDocument, options);
}
export type UpdateDealActivityMutationHookResult = ReturnType<
    typeof useUpdateDealActivityMutation
>;
export type UpdateDealActivityMutationResult =
    Apollo.MutationResult<Types.UpdateDealActivityMutation>;
export type UpdateDealActivityMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateDealActivityMutation,
    Types.UpdateDealActivityMutationVariables
>;
export const GqlOperationDeleteDealActivityDocument = gql`
    mutation DeleteDealActivity($activityLogId: String!) {
        deleteActivityLog(activityLogId: $activityLogId) {
            ok
        }
    }
`;
export type DeleteDealActivityMutationFn = Apollo.MutationFunction<
    Types.DeleteDealActivityMutation,
    Types.DeleteDealActivityMutationVariables
>;

/**
 * __useDeleteDealActivityMutation__
 *
 * To run a mutation, you first call `useDeleteDealActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealActivityMutation, { data, loading, error }] = useDeleteDealActivityMutation({
 *   variables: {
 *      activityLogId: // value for 'activityLogId'
 *   },
 * });
 */
export function useDeleteDealActivityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteDealActivityMutation,
        Types.DeleteDealActivityMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteDealActivityMutation,
        Types.DeleteDealActivityMutationVariables
    >(GqlOperationDeleteDealActivityDocument, options);
}
export type DeleteDealActivityMutationHookResult = ReturnType<
    typeof useDeleteDealActivityMutation
>;
export type DeleteDealActivityMutationResult =
    Apollo.MutationResult<Types.DeleteDealActivityMutation>;
export type DeleteDealActivityMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteDealActivityMutation,
    Types.DeleteDealActivityMutationVariables
>;
export const GqlOperationOmniSearchDocument = gql`
    query OmniSearch($searchTerm: String!, $limit: Int!) {
        omniSearch(query: $searchTerm, limit: $limit) {
            comments {
                ...DealComment
            }
            companies {
                ...DealCompanyPart
            }
            contacts {
                ...DealContactPart
            }
            deals {
                ...DealPart
            }
            nextSteps {
                ...ReminderPart
            }
            activityLogs {
                ...ActivityLogPart
            }
        }
    }
    ${DealCommentFragmentDoc}
    ${DealCompanyPartFragmentDoc}
    ${DealContactPartFragmentDoc}
    ${DealPartFragmentDoc}
    ${ReminderPartFragmentDoc}
    ${ActivityLogPartFragmentDoc}
`;

/**
 * __useOmniSearchQuery__
 *
 * To run a query within a React component, call `useOmniSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOmniSearchQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.OmniSearchQuery,
        Types.OmniSearchQueryVariables
    > &
        (
            | { variables: Types.OmniSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.OmniSearchQuery,
        Types.OmniSearchQueryVariables
    >(GqlOperationOmniSearchDocument, options);
}
export function useOmniSearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.OmniSearchQuery,
        Types.OmniSearchQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.OmniSearchQuery,
        Types.OmniSearchQueryVariables
    >(GqlOperationOmniSearchDocument, options);
}
export function useOmniSearchSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.OmniSearchQuery,
              Types.OmniSearchQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.OmniSearchQuery,
        Types.OmniSearchQueryVariables
    >(GqlOperationOmniSearchDocument, options);
}
export type OmniSearchQueryHookResult = ReturnType<typeof useOmniSearchQuery>;
export type OmniSearchLazyQueryHookResult = ReturnType<
    typeof useOmniSearchLazyQuery
>;
export type OmniSearchSuspenseQueryHookResult = ReturnType<
    typeof useOmniSearchSuspenseQuery
>;
export type OmniSearchQueryResult = Apollo.QueryResult<
    Types.OmniSearchQuery,
    Types.OmniSearchQueryVariables
>;
export const GqlOperationGetContactExtractionResultDocument = gql`
    query GetContactExtractionResult($dealId: String!) {
        getContactExtractionResult(dealId: $dealId) {
            ...ContactExtractionResult
        }
    }
    ${ContactExtractionResultFragmentDoc}
`;

/**
 * __useGetContactExtractionResultQuery__
 *
 * To run a query within a React component, call `useGetContactExtractionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactExtractionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactExtractionResultQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetContactExtractionResultQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetContactExtractionResultQuery,
        Types.GetContactExtractionResultQueryVariables
    > &
        (
            | {
                  variables: Types.GetContactExtractionResultQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetContactExtractionResultQuery,
        Types.GetContactExtractionResultQueryVariables
    >(GqlOperationGetContactExtractionResultDocument, options);
}
export function useGetContactExtractionResultLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetContactExtractionResultQuery,
        Types.GetContactExtractionResultQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetContactExtractionResultQuery,
        Types.GetContactExtractionResultQueryVariables
    >(GqlOperationGetContactExtractionResultDocument, options);
}
export function useGetContactExtractionResultSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetContactExtractionResultQuery,
              Types.GetContactExtractionResultQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetContactExtractionResultQuery,
        Types.GetContactExtractionResultQueryVariables
    >(GqlOperationGetContactExtractionResultDocument, options);
}
export type GetContactExtractionResultQueryHookResult = ReturnType<
    typeof useGetContactExtractionResultQuery
>;
export type GetContactExtractionResultLazyQueryHookResult = ReturnType<
    typeof useGetContactExtractionResultLazyQuery
>;
export type GetContactExtractionResultSuspenseQueryHookResult = ReturnType<
    typeof useGetContactExtractionResultSuspenseQuery
>;
export type GetContactExtractionResultQueryResult = Apollo.QueryResult<
    Types.GetContactExtractionResultQuery,
    Types.GetContactExtractionResultQueryVariables
>;
export const GqlOperationRunContactExtractionForDealDocument = gql`
    mutation RunContactExtractionForDeal(
        $dealId: String!
        $language: UserLanguage!
    ) {
        runContactExtractionForDeal(dealId: $dealId, language: $language) {
            ok
        }
    }
`;
export type RunContactExtractionForDealMutationFn = Apollo.MutationFunction<
    Types.RunContactExtractionForDealMutation,
    Types.RunContactExtractionForDealMutationVariables
>;

/**
 * __useRunContactExtractionForDealMutation__
 *
 * To run a mutation, you first call `useRunContactExtractionForDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunContactExtractionForDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runContactExtractionForDealMutation, { data, loading, error }] = useRunContactExtractionForDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRunContactExtractionForDealMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.RunContactExtractionForDealMutation,
        Types.RunContactExtractionForDealMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.RunContactExtractionForDealMutation,
        Types.RunContactExtractionForDealMutationVariables
    >(GqlOperationRunContactExtractionForDealDocument, options);
}
export type RunContactExtractionForDealMutationHookResult = ReturnType<
    typeof useRunContactExtractionForDealMutation
>;
export type RunContactExtractionForDealMutationResult =
    Apollo.MutationResult<Types.RunContactExtractionForDealMutation>;
export type RunContactExtractionForDealMutationOptions =
    Apollo.BaseMutationOptions<
        Types.RunContactExtractionForDealMutation,
        Types.RunContactExtractionForDealMutationVariables
    >;
export const GqlOperationGetActivityLogByDealDocument = gql`
    query GetActivityLogByDeal($dealId: String!) {
        deal(dealId: $dealId) {
            activityLog {
                ...ActivityLogPart
            }
        }
    }
    ${ActivityLogPartFragmentDoc}
`;

/**
 * __useGetActivityLogByDealQuery__
 *
 * To run a query within a React component, call `useGetActivityLogByDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogByDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogByDealQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetActivityLogByDealQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetActivityLogByDealQuery,
        Types.GetActivityLogByDealQueryVariables
    > &
        (
            | {
                  variables: Types.GetActivityLogByDealQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetActivityLogByDealQuery,
        Types.GetActivityLogByDealQueryVariables
    >(GqlOperationGetActivityLogByDealDocument, options);
}
export function useGetActivityLogByDealLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetActivityLogByDealQuery,
        Types.GetActivityLogByDealQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetActivityLogByDealQuery,
        Types.GetActivityLogByDealQueryVariables
    >(GqlOperationGetActivityLogByDealDocument, options);
}
export function useGetActivityLogByDealSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetActivityLogByDealQuery,
              Types.GetActivityLogByDealQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetActivityLogByDealQuery,
        Types.GetActivityLogByDealQueryVariables
    >(GqlOperationGetActivityLogByDealDocument, options);
}
export type GetActivityLogByDealQueryHookResult = ReturnType<
    typeof useGetActivityLogByDealQuery
>;
export type GetActivityLogByDealLazyQueryHookResult = ReturnType<
    typeof useGetActivityLogByDealLazyQuery
>;
export type GetActivityLogByDealSuspenseQueryHookResult = ReturnType<
    typeof useGetActivityLogByDealSuspenseQuery
>;
export type GetActivityLogByDealQueryResult = Apollo.QueryResult<
    Types.GetActivityLogByDealQuery,
    Types.GetActivityLogByDealQueryVariables
>;
export const GqlOperationRunAutoEnrichmentForCompanyDocument = gql`
    mutation RunAutoEnrichmentForCompany(
        $companyId: String!
        $dealId: String!
    ) {
        autoEnrichCompany(relevantCompanyId: $companyId, dealId: $dealId) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type RunAutoEnrichmentForCompanyMutationFn = Apollo.MutationFunction<
    Types.RunAutoEnrichmentForCompanyMutation,
    Types.RunAutoEnrichmentForCompanyMutationVariables
>;

/**
 * __useRunAutoEnrichmentForCompanyMutation__
 *
 * To run a mutation, you first call `useRunAutoEnrichmentForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunAutoEnrichmentForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runAutoEnrichmentForCompanyMutation, { data, loading, error }] = useRunAutoEnrichmentForCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useRunAutoEnrichmentForCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.RunAutoEnrichmentForCompanyMutation,
        Types.RunAutoEnrichmentForCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.RunAutoEnrichmentForCompanyMutation,
        Types.RunAutoEnrichmentForCompanyMutationVariables
    >(GqlOperationRunAutoEnrichmentForCompanyDocument, options);
}
export type RunAutoEnrichmentForCompanyMutationHookResult = ReturnType<
    typeof useRunAutoEnrichmentForCompanyMutation
>;
export type RunAutoEnrichmentForCompanyMutationResult =
    Apollo.MutationResult<Types.RunAutoEnrichmentForCompanyMutation>;
export type RunAutoEnrichmentForCompanyMutationOptions =
    Apollo.BaseMutationOptions<
        Types.RunAutoEnrichmentForCompanyMutation,
        Types.RunAutoEnrichmentForCompanyMutationVariables
    >;
export const GqlOperationRemoveEnrichmentItemFromCompanyDocument = gql`
    mutation RemoveEnrichmentItemFromCompany(
        $companyId: String!
        $placeId: String!
        $field: String = null
    ) {
        removePlaceFromCompany(
            relevantCompanyId: $companyId
            placeId: $placeId
            field: $field
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type RemoveEnrichmentItemFromCompanyMutationFn = Apollo.MutationFunction<
    Types.RemoveEnrichmentItemFromCompanyMutation,
    Types.RemoveEnrichmentItemFromCompanyMutationVariables
>;

/**
 * __useRemoveEnrichmentItemFromCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveEnrichmentItemFromCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnrichmentItemFromCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnrichmentItemFromCompanyMutation, { data, loading, error }] = useRemoveEnrichmentItemFromCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      placeId: // value for 'placeId'
 *      field: // value for 'field'
 *   },
 * });
 */
export function useRemoveEnrichmentItemFromCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.RemoveEnrichmentItemFromCompanyMutation,
        Types.RemoveEnrichmentItemFromCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.RemoveEnrichmentItemFromCompanyMutation,
        Types.RemoveEnrichmentItemFromCompanyMutationVariables
    >(GqlOperationRemoveEnrichmentItemFromCompanyDocument, options);
}
export type RemoveEnrichmentItemFromCompanyMutationHookResult = ReturnType<
    typeof useRemoveEnrichmentItemFromCompanyMutation
>;
export type RemoveEnrichmentItemFromCompanyMutationResult =
    Apollo.MutationResult<Types.RemoveEnrichmentItemFromCompanyMutation>;
export type RemoveEnrichmentItemFromCompanyMutationOptions =
    Apollo.BaseMutationOptions<
        Types.RemoveEnrichmentItemFromCompanyMutation,
        Types.RemoveEnrichmentItemFromCompanyMutationVariables
    >;
export const GqlOperationUpdateCompanyWithEnrichmentDocument = gql`
    mutation UpdateCompanyWithEnrichment(
        $companyId: String!
        $dealId: String!
        $placeId: String!
        $fields: [String!] = null
    ) {
        overrideCompanyInfoWithPlace(
            relevantCompanyId: $companyId
            dealId: $dealId
            placeId: $placeId
            fields: $fields
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export type UpdateCompanyWithEnrichmentMutationFn = Apollo.MutationFunction<
    Types.UpdateCompanyWithEnrichmentMutation,
    Types.UpdateCompanyWithEnrichmentMutationVariables
>;

/**
 * __useUpdateCompanyWithEnrichmentMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWithEnrichmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWithEnrichmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWithEnrichmentMutation, { data, loading, error }] = useUpdateCompanyWithEnrichmentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      dealId: // value for 'dealId'
 *      placeId: // value for 'placeId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateCompanyWithEnrichmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateCompanyWithEnrichmentMutation,
        Types.UpdateCompanyWithEnrichmentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UpdateCompanyWithEnrichmentMutation,
        Types.UpdateCompanyWithEnrichmentMutationVariables
    >(GqlOperationUpdateCompanyWithEnrichmentDocument, options);
}
export type UpdateCompanyWithEnrichmentMutationHookResult = ReturnType<
    typeof useUpdateCompanyWithEnrichmentMutation
>;
export type UpdateCompanyWithEnrichmentMutationResult =
    Apollo.MutationResult<Types.UpdateCompanyWithEnrichmentMutation>;
export type UpdateCompanyWithEnrichmentMutationOptions =
    Apollo.BaseMutationOptions<
        Types.UpdateCompanyWithEnrichmentMutation,
        Types.UpdateCompanyWithEnrichmentMutationVariables
    >;
export const GqlOperationFindContactsForCompanyDocument = gql`
    query FindContactsForCompany(
        $companyName: String!
        $keyword: String
        $page: Int!
        $title: String
        $location: String
    ) {
        getCompanyLinkedinProfiles(
            companyName: $companyName
            page: $page
            keyword: $keyword
            title: $title
            location: $location
        ) {
            profiles {
                ...LinkedinProfile
            }
            metaData {
                ...LinkedinSearchMeta
            }
        }
    }
    ${LinkedinProfileFragmentDoc}
    ${LinkedinSearchMetaFragmentDoc}
`;

/**
 * __useFindContactsForCompanyQuery__
 *
 * To run a query within a React component, call `useFindContactsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactsForCompanyQuery({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      keyword: // value for 'keyword'
 *      page: // value for 'page'
 *      title: // value for 'title'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useFindContactsForCompanyQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.FindContactsForCompanyQuery,
        Types.FindContactsForCompanyQueryVariables
    > &
        (
            | {
                  variables: Types.FindContactsForCompanyQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.FindContactsForCompanyQuery,
        Types.FindContactsForCompanyQueryVariables
    >(GqlOperationFindContactsForCompanyDocument, options);
}
export function useFindContactsForCompanyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.FindContactsForCompanyQuery,
        Types.FindContactsForCompanyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.FindContactsForCompanyQuery,
        Types.FindContactsForCompanyQueryVariables
    >(GqlOperationFindContactsForCompanyDocument, options);
}
export function useFindContactsForCompanySuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.FindContactsForCompanyQuery,
              Types.FindContactsForCompanyQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.FindContactsForCompanyQuery,
        Types.FindContactsForCompanyQueryVariables
    >(GqlOperationFindContactsForCompanyDocument, options);
}
export type FindContactsForCompanyQueryHookResult = ReturnType<
    typeof useFindContactsForCompanyQuery
>;
export type FindContactsForCompanyLazyQueryHookResult = ReturnType<
    typeof useFindContactsForCompanyLazyQuery
>;
export type FindContactsForCompanySuspenseQueryHookResult = ReturnType<
    typeof useFindContactsForCompanySuspenseQuery
>;
export type FindContactsForCompanyQueryResult = Apollo.QueryResult<
    Types.FindContactsForCompanyQuery,
    Types.FindContactsForCompanyQueryVariables
>;
export const GqlOperationGetLinkedinProfileContactInfoDocument = gql`
    query GetLinkedinProfileContactInfo($linkedinUserName: String!) {
        getLinkedinProfileContactInformation(
            linkedinUsername: $linkedinUserName
        ) {
            ...LinkedinProfileContactInfo
        }
    }
    ${LinkedinProfileContactInfoFragmentDoc}
`;

/**
 * __useGetLinkedinProfileContactInfoQuery__
 *
 * To run a query within a React component, call `useGetLinkedinProfileContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedinProfileContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedinProfileContactInfoQuery({
 *   variables: {
 *      linkedinUserName: // value for 'linkedinUserName'
 *   },
 * });
 */
export function useGetLinkedinProfileContactInfoQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetLinkedinProfileContactInfoQuery,
        Types.GetLinkedinProfileContactInfoQueryVariables
    > &
        (
            | {
                  variables: Types.GetLinkedinProfileContactInfoQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetLinkedinProfileContactInfoQuery,
        Types.GetLinkedinProfileContactInfoQueryVariables
    >(GqlOperationGetLinkedinProfileContactInfoDocument, options);
}
export function useGetLinkedinProfileContactInfoLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetLinkedinProfileContactInfoQuery,
        Types.GetLinkedinProfileContactInfoQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetLinkedinProfileContactInfoQuery,
        Types.GetLinkedinProfileContactInfoQueryVariables
    >(GqlOperationGetLinkedinProfileContactInfoDocument, options);
}
export function useGetLinkedinProfileContactInfoSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetLinkedinProfileContactInfoQuery,
              Types.GetLinkedinProfileContactInfoQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetLinkedinProfileContactInfoQuery,
        Types.GetLinkedinProfileContactInfoQueryVariables
    >(GqlOperationGetLinkedinProfileContactInfoDocument, options);
}
export type GetLinkedinProfileContactInfoQueryHookResult = ReturnType<
    typeof useGetLinkedinProfileContactInfoQuery
>;
export type GetLinkedinProfileContactInfoLazyQueryHookResult = ReturnType<
    typeof useGetLinkedinProfileContactInfoLazyQuery
>;
export type GetLinkedinProfileContactInfoSuspenseQueryHookResult = ReturnType<
    typeof useGetLinkedinProfileContactInfoSuspenseQuery
>;
export type GetLinkedinProfileContactInfoQueryResult = Apollo.QueryResult<
    Types.GetLinkedinProfileContactInfoQuery,
    Types.GetLinkedinProfileContactInfoQueryVariables
>;
export const GqlOperationGetDealContentMetadataDocument = gql`
    query GetDealContentMetadata($dealId: String!) {
        deal(dealId: $dealId) {
            contents {
                ...DealContentMetadata
            }
        }
    }
    ${DealContentMetadataFragmentDoc}
`;

/**
 * __useGetDealContentMetadataQuery__
 *
 * To run a query within a React component, call `useGetDealContentMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealContentMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealContentMetadataQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetDealContentMetadataQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealContentMetadataQuery,
        Types.GetDealContentMetadataQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealContentMetadataQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealContentMetadataQuery,
        Types.GetDealContentMetadataQueryVariables
    >(GqlOperationGetDealContentMetadataDocument, options);
}
export function useGetDealContentMetadataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealContentMetadataQuery,
        Types.GetDealContentMetadataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealContentMetadataQuery,
        Types.GetDealContentMetadataQueryVariables
    >(GqlOperationGetDealContentMetadataDocument, options);
}
export function useGetDealContentMetadataSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealContentMetadataQuery,
              Types.GetDealContentMetadataQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealContentMetadataQuery,
        Types.GetDealContentMetadataQueryVariables
    >(GqlOperationGetDealContentMetadataDocument, options);
}
export type GetDealContentMetadataQueryHookResult = ReturnType<
    typeof useGetDealContentMetadataQuery
>;
export type GetDealContentMetadataLazyQueryHookResult = ReturnType<
    typeof useGetDealContentMetadataLazyQuery
>;
export type GetDealContentMetadataSuspenseQueryHookResult = ReturnType<
    typeof useGetDealContentMetadataSuspenseQuery
>;
export type GetDealContentMetadataQueryResult = Apollo.QueryResult<
    Types.GetDealContentMetadataQuery,
    Types.GetDealContentMetadataQueryVariables
>;
export const GqlOperationGetDealContentAttachmentDownloadUrlDocument = gql`
    query GetDealContentAttachmentDownloadUrl(
        $contentId: String!
        $inline: Boolean!
    ) {
        getDealAttachmentDownloadUrl(contentId: $contentId, inline: $inline) {
            ...DealFileAttachmentDownloadUrl
        }
    }
    ${DealFileAttachmentDownloadUrlFragmentDoc}
`;

/**
 * __useGetDealContentAttachmentDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetDealContentAttachmentDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealContentAttachmentDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealContentAttachmentDownloadUrlQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      inline: // value for 'inline'
 *   },
 * });
 */
export function useGetDealContentAttachmentDownloadUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.GetDealContentAttachmentDownloadUrlQuery,
        Types.GetDealContentAttachmentDownloadUrlQueryVariables
    > &
        (
            | {
                  variables: Types.GetDealContentAttachmentDownloadUrlQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.GetDealContentAttachmentDownloadUrlQuery,
        Types.GetDealContentAttachmentDownloadUrlQueryVariables
    >(GqlOperationGetDealContentAttachmentDownloadUrlDocument, options);
}
export function useGetDealContentAttachmentDownloadUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetDealContentAttachmentDownloadUrlQuery,
        Types.GetDealContentAttachmentDownloadUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.GetDealContentAttachmentDownloadUrlQuery,
        Types.GetDealContentAttachmentDownloadUrlQueryVariables
    >(GqlOperationGetDealContentAttachmentDownloadUrlDocument, options);
}
export function useGetDealContentAttachmentDownloadUrlSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              Types.GetDealContentAttachmentDownloadUrlQuery,
              Types.GetDealContentAttachmentDownloadUrlQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.GetDealContentAttachmentDownloadUrlQuery,
        Types.GetDealContentAttachmentDownloadUrlQueryVariables
    >(GqlOperationGetDealContentAttachmentDownloadUrlDocument, options);
}
export type GetDealContentAttachmentDownloadUrlQueryHookResult = ReturnType<
    typeof useGetDealContentAttachmentDownloadUrlQuery
>;
export type GetDealContentAttachmentDownloadUrlLazyQueryHookResult = ReturnType<
    typeof useGetDealContentAttachmentDownloadUrlLazyQuery
>;
export type GetDealContentAttachmentDownloadUrlSuspenseQueryHookResult =
    ReturnType<typeof useGetDealContentAttachmentDownloadUrlSuspenseQuery>;
export type GetDealContentAttachmentDownloadUrlQueryResult = Apollo.QueryResult<
    Types.GetDealContentAttachmentDownloadUrlQuery,
    Types.GetDealContentAttachmentDownloadUrlQueryVariables
>;
export const GqlOperationDeleteDealContentDocument = gql`
    mutation DeleteDealContent($dealId: String!, $contentId: String!) {
        removeDealContent(dealId: $dealId, contentId: $contentId) {
            ok
        }
    }
`;
export type DeleteDealContentMutationFn = Apollo.MutationFunction<
    Types.DeleteDealContentMutation,
    Types.DeleteDealContentMutationVariables
>;

/**
 * __useDeleteDealContentMutation__
 *
 * To run a mutation, you first call `useDeleteDealContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealContentMutation, { data, loading, error }] = useDeleteDealContentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteDealContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteDealContentMutation,
        Types.DeleteDealContentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.DeleteDealContentMutation,
        Types.DeleteDealContentMutationVariables
    >(GqlOperationDeleteDealContentDocument, options);
}
export type DeleteDealContentMutationHookResult = ReturnType<
    typeof useDeleteDealContentMutation
>;
export type DeleteDealContentMutationResult =
    Apollo.MutationResult<Types.DeleteDealContentMutation>;
export type DeleteDealContentMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteDealContentMutation,
    Types.DeleteDealContentMutationVariables
>;
