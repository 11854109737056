import { captureException } from '@sentry/react';
import {
    ApolloClient,
    ApolloLink,
    defaultDataIdFromObject,
    DefaultOptions,
    from,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { onError } from '@apollo/client/link/error';

import { getCookie } from 'src/utils/cookie.utils';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getGraphqlUrl, getWsUrl } from './graphql-client.utils';
import { getWebSocketAvailability } from 'src/utils/websocket.utils';
import { Endpoint } from './endpoint';
import { getI18n, TFunction } from 'react-i18next';
import { showToast } from 'src/data/stores/toaster/utils';
import { NetworkError } from '@apollo/client/errors';
import { GraphQLFormattedError, Kind, OperationTypeNode } from 'graphql';
import { doNothing } from 'src/utils/function.utils';
import { isProductionEnv } from 'src/utils/environment.utils';
import { StrictTypedTypePolicies as BrProcessCachePolicy } from './br_process/generated';
import { StrictTypedTypePolicies as BrProcessMultipartPolicy } from './br_process_multipart/generated';
import { StrictTypedTypePolicies as BrProjectPolicy } from './br_project/generated';
import { StrictTypedTypePolicies as BrSearchPolicy } from './br_search/generated';
import { StrictTypedTypePolicies as BrUserPolicy } from './br_user/generated';

type CacheTypePolicies = BrProcessCachePolicy &
    BrProjectPolicy &
    BrSearchPolicy &
    BrProcessMultipartPolicy &
    BrUserPolicy;

class _ApolloClientService {
    private readonly _cacheTypePolicies: CacheTypePolicies = {
        MentionQL: {
            keyFields: ['commentId'],
        },
        LinkedFilterModuleQL: {
            keyFields: ['key'],
        },
    };

    private readonly _cache: InMemoryCache = new InMemoryCache({
        typePolicies: this._cacheTypePolicies,
        dataIdFromObject: (responseObject) => {
            const { __typename } = responseObject;
            const id = responseObject.itemId ?? responseObject.id;
            if (!__typename || !id) {
                return defaultDataIdFromObject(responseObject);
            }
            return `${__typename}:${id}`;
        },
    });

    private readonly _cacheOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
        },
        mutate: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    };

    private readonly _authMiddleware = new ApolloLink((operation, forward) => {
        const xsrfToken = getCookie('XSRF-TOKEN');
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'X-XSRF-TOKEN': xsrfToken,
            },
        }));
        return forward(operation);
    });

    private readonly _routerMiddleware = new ApolloLink(
        (operation, forward) => {
            const { endpoint = Endpoint.process } = operation.getContext();
            if (endpoint === Endpoint.processMultipart) {
                return createUploadLink({
                    uri: getGraphqlUrl(endpoint),
                    headers: {
                        'Apollo-Require-Preflight': 'true',
                    },
                }).request(operation, forward);
            } else {
                const splitLink = split(
                    ({ query }) => {
                        const definition = getMainDefinition(query);
                        return (
                            definition.kind === Kind.OPERATION_DEFINITION &&
                            definition.operation ===
                                OperationTypeNode.SUBSCRIPTION &&
                            getWebSocketAvailability()
                        );
                    },
                    new GraphQLWsLink(
                        createClient({
                            url: getWsUrl(endpoint),
                        }),
                    ),
                    new HttpLink({
                        uri: getGraphqlUrl(endpoint),
                    }),
                );
                return splitLink.request(operation, forward);
            }
        },
    );

    private readonly _errorMiddleware = onError(
        ({ operation, graphQLErrors, networkError }) => {
            const t: TFunction<'translation', undefined> = getI18n().t;
            const context = operation.getContext();
            if (context.errorToastEnabled ?? true) {
                showToast({
                    title: context.errorToastTitle ?? t('common.error'),
                    message:
                        context.errorToastMessage ?? t('common.error_occurred'),
                    type: 'error',
                    autoClose: context.errorToastCloseDuration ?? 5000,
                });
            }
            const errors: (NetworkError | GraphQLFormattedError)[] = [
                ...(graphQLErrors ?? []),
                ...(networkError ? [networkError] : []),
            ];
            if (context.enableSentryErrorCapture ?? true) {
                try {
                    errors.forEach((error) => {
                        const errorToReport = {
                            ...error,
                            name: operation.operationName,
                        };
                        captureException(errorToReport);
                    });
                } catch {
                    doNothing();
                }
            }
        },
    );

    private readonly _client: ApolloClient<NormalizedCacheObject> =
        new ApolloClient({
            devtools: {
                enabled: !isProductionEnv,
                name: 'BR Apollo Client',
            },
            link: from([
                removeTypenameFromVariables(),
                this._authMiddleware,
                this._errorMiddleware,
                this._routerMiddleware,
            ]),
            cache: this._cache,
            defaultOptions: this._cacheOptions,
            queryDeduplication: true,
        });

    get client() {
        return this._client;
    }
}

export const ApolloClientService = new _ApolloClientService();
